import React from 'react';
import './App.css';

class TableQue extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const que = this.props.data;
    if (!que) {
      return null;
    }

    return (
      <table id="que">
        <tbody>
          <tr>
            <th
              colSpan="5"
              id="textColor"
              style={{
                fontSize: this.props.isSmall ? '100%' : '150%',
                fontWeight: 'bold',
              }}
            >
              {que.huyenKhongNguHanh}
            </th>
          </tr>
          <tr height="10px"></tr>

          <tr>
            <td className="que" style={{ backgroundColor: que.row1[0] ? '#B13B2D' : 'aliceblue' }}></td>
            <td className="que" style={{ backgroundColor: que.row1[1] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row1[2] ? '#B13B2D' : 'aliceblue' }}></td>
          </tr>
          <tr height="3px"></tr>
          <tr>
            <td className="que" style={{ backgroundColor: que.row2[0] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row2[1] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row2[2] ? '#B13B2D' : 'aliceblue' }}></td>
          </tr>
          <tr height="3px"></tr>

          <tr>
            <td className="que" style={{ backgroundColor: que.row3[0] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row3[1] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row3[2] ? '#B13B2D' : 'aliceblue' }}></td>
          </tr>
          <tr height="3px"></tr>

          <tr>
            <td className="que" style={{ backgroundColor: que.row4[0] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row4[1] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row4[2] ? '#B13B2D' : 'aliceblue' }}></td>
          </tr>
          <tr height="3px"></tr>

          <tr>
            <td className="que" style={{ backgroundColor: que.row5[0] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row5[1] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row5[2] ? '#B13B2D' : 'aliceblue' }}></td>
          </tr>
          <tr height="3px"></tr>

          <tr>
            <td className="que" style={{ backgroundColor: que.row6[0] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row6[1] ? '#B13B2D' : 'aliceblue' }}></td>

            <td className="que" style={{ backgroundColor: que.row6[2] ? '#B13B2D' : 'aliceblue' }}></td>
          </tr>
          <tr height="10px"></tr>
          <tr>
            <th
              colSpan="5"
              id="textColor"
              style={{
                fontSize: this.props.isSmall ? '100%' : '150%',
                fontWeight: 'bold',
              }}
            >
              {que.huyenKhongQuaiVan}
            </th>
          </tr>
        </tbody>
      </table>
    );
  }

  // render() {
  //   return (
  //     <table
  //       style={{
  //         width: "100%",
  //         background: "#fff",
  //         height: { height },
  //         borderColor: "black",
  //         borderWidth: "1px",
  //       }}
  //       border="1"
  //     >
  //       <tbody>
  //         <tr>
  //           <td>
  //             <table
  //               style={{ width: "100%", background: "#fff" }}
  //               // border="0"
  //               // cellpadding="0"
  //               // cellspacing="0"
  //               // align="center"
  //               // valign="top"
  //             >
  //               <tbody>
  //                 <tr style={{ height: { height } }}>
  //                   <td style={{}}>{this.renderCuuCungPhiTinh()}</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </td>
  //         </tr>
  //       </tbody>
  //     </table>
  //   );
  // }
}

export default TableQue;
