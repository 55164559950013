import vi from "date-fns/locale/vi";
import * as firebase from "firebase";

import md5 from "md5";
import React from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoogleLogin, { GoogleLogout } from "react-google-login";
import { Button, Dropdown, Image, Input, Modal } from "semantic-ui-react";
import "../App.css";
import globalUtils from "../globalUntils.js";
import thongTinKhoaHoc from "../image/infoSubject.jpg";
import CHUONGTRINHGIANGDAY01 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-01.png";
import CHUONGTRINHGIANGDAY02 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-02.png";
import CHUONGTRINHGIANGDAY03 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-03.png";
import CHUONGTRINHGIANGDAY04 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-04.png";
import CHUONGTRINHGIANGDAY05 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-05.png";
import CHUONGTRINHGIANGDAY06 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-06.png";
import CHUONGTRINHGIANGDAY07 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-07.png";
import CHUONGTRINHGIANGDAY08 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-08.png";
import CHUONGTRINHGIANGDAY09 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-09.png";
import CHUONGTRINHGIANGDAY10 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-10.png";
import CHUONGTRINHGIANGDAY11 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-11.png";
import Logo from "../logo.png";

const mdfive = md5("HuyenKhongTamNguyenFengShuiWebSiteHitekTuongMinh");
registerLocale("vi", vi);

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expireDate: "",
      userName: "",
      modalPressCode: false,
      isCustomer: false,
      modalErrorCode: false,
      modalUsedCode: false,
      modalSuccessCode: false,
      modalEmptyCode: false,
      isLoading: false,
    };
  }
  onSendRedeemCode = (Code) => {
    const getObj = localStorage.getItem(mdfive);
    const emailSavedStorage = JSON.parse(getObj);

    let timeStart = new Date();
    //check trình duyệt
    // var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;
    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
      browserName = "Opera";
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      browserName = "Microsoft Internet Explorer";
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      browserName = "Chrome";
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      browserName = "Safari";
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      browserName = "Firefox";
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) !== -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) !== -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    var database = firebase.app("database").firestore();
    var data = database.collection("redeem_code_HuyenKhong").doc(Code);

    var dataUser = database
      .collection("User_HuyenKhong")
      .doc(emailSavedStorage.email);
    data.get().then((doc) => {
      if (doc.exists) {
        if (!doc.data()?.isActive) {
          if (doc.data()?.level === 1) {
            timeStart.setMonth(timeStart.getMonth() + 3);
            data.update({
              ...doc.data(),
              isActive: true,
            });
            dataUser.set({
              ...doc.data(),
              name: this.state.userName,
              isActive: true,
              level: 1,
              activeOnBrower: browserName,
              exprireDate: new Date(timeStart.toDateString()),
              activeDate: new Date(),
            });
          } else if (doc.data()?.level === 2) {
            timeStart.setMonth(timeStart.getMonth() + 6);
            data.update({
              ...doc.data(),
              isActive: true,
            });
            dataUser.set({
              ...doc.data(),
              name: this.state.userName,
              isActive: true,
              level: 2,
              activeOnBrower: browserName,
              exprireDate: new Date(timeStart.toDateString()),
              activeDate: new Date(),
            });
          } else if (doc.data()?.level === 3) {
            timeStart.setMonth(timeStart.getMonth() + 12);
            data.update({
              ...doc.data(),
              isActive: true,
            });
            dataUser.set({
              ...doc.data(),
              name: this.state.userName,
              isActive: true,
              level: 3,
              activeOnBrower: browserName,
              exprireDate: new Date(timeStart.toDateString()),
              activeDate: new Date(),
            });
          } else if (doc.data()?.level === 4) {
            timeStart.setFullYear(timeStart.getFullYear() + 3);
            data.update({
              ...doc.data(),
              isActive: true,
            });
            dataUser.set({
              ...doc.data(),
              name: this.state.userName,
              isActive: true,
              level: 4,
              activeOnBrower: browserName,
              exprireDate: new Date(timeStart.toDateString()),
              activeDate: new Date(),
            });
          } else if (doc.data()?.level === 5) {
            timeStart.setFullYear(timeStart.getFullYear() + 100);
            data.update({
              ...doc.data(),
              isActive: true,
            });
            dataUser.set({
              ...doc.data(),
              name: this.state.userName,
              isActive: true,
              level: 5,
              activeOnBrower: browserName,
              exprireDate: new Date(timeStart.toDateString()),
              activeDate: new Date(),
            });
          }

          globalUtils.isCustomer = true;
          const dataStorage = {
            userName: this.state.userName,
            email: emailSavedStorage.email,
            isCustomer: globalUtils.isCustomer,
            exprireDate: timeStart,
          };
          const jsonUser = JSON.stringify(dataStorage);

          this.setState({ modalSuccessCode: true }, () =>
            localStorage.setItem(mdfive, jsonUser)
          );
        } else {
          this.setState({ modalUsedCode: true });
        }
      } else {
        this.setState({ modalErrorCode: true });
      }
    });
  };
  componentDidMount() {
    try {
      const emailSaveStorage = localStorage.getItem(mdfive);
      const userData = JSON.parse(emailSaveStorage);
      var database = firebase.app("database").firestore();

      if (userData?.email) {
        var data = database.collection("User_HuyenKhong").doc(userData?.email);
        data.get().then((doc) => {
          if (doc.exists) {
            globalUtils.isLogin = true;
            this.setState({ userName: doc.data().name });
            const expireDate = new Date(doc.data().exprireDate.seconds * 1000);
            this.setState({ expireDate });
            const toDay = new Date();
            if (expireDate < toDay && doc.data().isActive) {
              this.setState({ expireDate: null });
              data
                .update({
                  isActive: false,
                })
                .then((valueSuccess) => {})
                .catch((error) => {
                  console.error("Error updating document: ", error);
                });
              globalUtils.isCustomer = false;
              const dataStorage = {
                userName: this.state.userName,
                email: userData.email,
                isCustomer: globalUtils.isCustomer,
                exprireDate: this.state.expireDate,
              };
              const jsonUser = JSON.stringify(dataStorage);
              this.setState({ isCustomer: false }, () => {
                localStorage.setItem(mdfive, jsonUser);
              });
            } else if (doc.data().isActive) {
              globalUtils.isCustomer = true;
              const dataStorage = {
                userName: this.state.userName,
                email: userData.email,
                isCustomer: globalUtils.isCustomer,
                exprireDate: this.state.expireDate,
              };
              const jsonUser = JSON.stringify(dataStorage);
              this.setState({ isCustomer: true }, () => {
                localStorage.setItem(mdfive, jsonUser);
              });
            }
          }
        });
      }
    } catch (error) {
      localStorage.clear();
    }
  }
  login = (response) => {
    this.setState({ isLoading: true });
    const database = firebase.app("database").firestore();

    if (response.accessToken) {
      globalUtils.isLogin = true;
      this.setState({ userName: response.profileObj.name });

      // var newDoc = database.collection('User_HuyenKhong').doc();
      var expireDate = new Date();
      var data = database
        .collection("User_HuyenKhong")
        .doc(response.profileObj.email);

      data.get().then((doc) => {
        //check tồn tại
        if (doc.exists) {
          globalUtils.isLogin = true;
          //giải quyết vụ 5 ngày là customer, sau 5 ngày không là gì cả
          expireDate = new Date(doc.data().exprireDate.seconds * 1000);

          const toDay = new Date();

          if (expireDate < toDay && doc.data().isActive) {
            data
              .update({
                isActive: false,
              })
              .then((valueSuccess) => {})
              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });
            globalUtils.isCustomer = false;
            this.setState({ isCustomer: false });
          } else if (doc.data().isActive) {
            globalUtils.isCustomer = true;
            this.setState({ isCustomer: true });
          }
        } else {
          // trường hợp đăng nhập lần đầu thì ghi vô data base
          expireDate.setDate(expireDate.getDate() + 5);

          database
            .collection("User_HuyenKhong")
            .doc(response.profileObj.email)
            .set({
              email: response.profileObj.email,
              name: response.profileObj.name,
              isActive: true,
              loginDate: new Date(),
              exprireDate: expireDate,
            });
        }
        const dataStorage = {
          userName: response.profileObj.name,
          email: response.profileObj.email,
          isCustomer: globalUtils.isCustomer,
          exprireDate: expireDate,
        };
        const jsonUser = JSON.stringify(dataStorage);
        localStorage.setItem(mdfive, jsonUser);

        this.setState({ isLoading: false });

        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      });
    }
  };
  logout = (response) => {
    this.setState({ isLoading: true });

    globalUtils.isLogin = false;
    globalUtils.isCustomer = false;
    this.setState({ isCustomer: false });

    localStorage.clear();

    setTimeout(() => {
      window.location.reload(false);
      this.setState({ isLoading: false });
    }, 500);
  };
  renderModalErrorPressCode = (nameModal, actionsCloseModal, content) => {
    return (
      <Modal open={nameModal} size="small">
        <Modal.Content>
          <p>{content}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Đóng" onClick={actionsCloseModal} />
        </Modal.Actions>
      </Modal>
    );
  };
  render() {
    return (
      <table style={{ width: "850px", margin: "0 auto" }}>
        <tbody>
          <tr className="to-hide-print">
            <td>
              <div>
                <table>
                  <tbody>
                    <tr height="70px">
                      <td width="10%">
                        <img alt="logo" src={Logo} height="66px" width="66px" />
                      </td>
                      <td width="30%" className="text-center">
                        <h1 id="web-name">LỊCH VẠN NIÊN</h1>
                        <h2 style={{ fontSize: "20px" }} id="slogan-web">
                          Phong Thủy Tường Minh
                        </h2>
                      </td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY01}
                                  alt="Học viện phong thủy"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY02}
                                  alt="Học viện tam hợp"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY03}
                                  alt="Học viện lục pháp"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY04}
                                  alt="Học viện đại quái"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY05}
                                  alt="Học viện phi tinh"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY06}
                                  alt="Học viện nhân tướng"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY07}
                                  alt="Học viện kinh dịch"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY08}
                                  alt="Học viện chiêm tinh"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY09}
                                  alt="Học viện kỳ môn"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY10}
                                  alt="Học viện trạch nhật"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                              <td>
                                <img
                                  src={CHUONGTRINHGIANGDAY11}
                                  alt="Học viện bát tự"
                                  width="30px"
                                  height="unset"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <div
                          style={{
                            float: "right",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <Modal
                            trigger={
                              <Button
                                style={{ padding: "5px", borderRadius: "5px" }}
                              >
                                <p style={{ lineBreak: "unset" }}>
                                  Thông tin Website
                                </p>
                              </Button>
                            }
                          >
                            <Modal.Content>
                              <div>
                                <Image size="massive" src={thongTinKhoaHoc} />
                              </div>
                            </Modal.Content>
                          </Modal>
                          <Button icon onClick={() => window.print()}>
                            <i className="fa fa-print" aria-hidden="true"></i>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default Header;
