import DanImage from "../src/image/con_giap/dan.svg";
import DanRedImage from "../src/image/con_giap/danRed.svg";
import DauImage from "../src/image/con_giap/dau.svg";
import DauRedImage from "../src/image/con_giap/dauRed.svg";
import HoiImage from "../src/image/con_giap/hoi.svg";
import HoiRedImage from "../src/image/con_giap/hoiRed.svg";
import MaoImage from "../src/image/con_giap/mao.svg";
import MaoRedImage from "../src/image/con_giap/maoRed.svg";
import MuiImage from "../src/image/con_giap/mui.svg";
import MuiRedImage from "../src/image/con_giap/muiRed.svg";
import NgoImage from "../src/image/con_giap/ngo.svg";
import NgoRedImage from "../src/image/con_giap/ngoRed.svg";
import SuuImage from "../src/image/con_giap/suu.svg";
import SuuRedImage from "../src/image/con_giap/suuRed.svg";
import ThanImage from "../src/image/con_giap/than.svg";
import ThanRedImage from "../src/image/con_giap/thanRed.svg";
import ThinImage from "../src/image/con_giap/thin.svg";
import ThinRedImage from "../src/image/con_giap/thinRed.svg";
import TiImage from "../src/image/con_giap/ti.svg";
import TiRedImage from "../src/image/con_giap/tiRed.svg";
import TuatImage from "../src/image/con_giap/tuat.svg";
import TuatRedImage from "../src/image/con_giap/tuatRed.svg";
import TyImage from "../src/image/con_giap/ty.svg";
import TyRedImage from "../src/image/con_giap/tyRed.svg";

import imageNewYear from "../src/image/background-lich/happyNewYear.png";
import imageBlue from "../src/image/background-lich/image_blue.png";
import imageGreen from "../src/image/background-lich/image_green.png";
import imageGreenBlue from "../src/image/background-lich/image_greenblue.png";
import imageOrange from "../src/image/background-lich/image_orange.png";
import imagePink from "../src/image/background-lich/image_pink.png";
import imageRed from "../src/image/background-lich/image_red.png";
import imageXanhNhat from "../src/image/background-lich/image_xanhNhat.png";
export default class Const {
  static colorEachDay = {
    thang1: {
      ngay1: {
        firstColor: "#EE333F",
        secondColor: "#0082C7",
        image: imageNewYear,
        descriptImage: ``,
      },
      ngay2: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay3: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay4: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay5: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay6: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay7: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay8: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay9: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay10: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay11: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay12: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay13: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay14: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay15: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay16: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay17: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay18: {
        firstColor: "#EE333F",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay19: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay20: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay21: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay22: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay23: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay24: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay25: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay26: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay27: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay28: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay29: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay30: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay31: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
    },
    thang2: {
      ngay1: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay2: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay3: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay4: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay5: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay6: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay7: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay8: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay9: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay10: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay11: {
        firstColor: "#EE333F",
        secondColor: "#EE333F",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay12: {
        firstColor: "#EE333F",
        secondColor: "#EE333F",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay13: {
        firstColor: "#EE333F",
        secondColor: "#EE333F",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay14: {
        firstColor: "#EE333F",
        secondColor: "#EE333F",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay15: {
        firstColor: "#EE333F",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay16: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay17: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay18: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay19: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay20: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay21: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay22: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay23: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay24: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay25: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay26: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay27: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay28: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay29: { firstColor: "black", secondColor: "black" },
    },
    thang3: {
      ngay1: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay2: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay3: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay4: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay5: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay6: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay7: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay8: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay9: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay10: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay11: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay12: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay13: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay14: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay15: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay16: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay17: {
        firstColor: "#EE333F",
        secondColor: "#EE333F",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay18: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay19: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay20: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay21: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay22: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay23: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay24: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay25: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay26: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay27: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay28: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay29: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay30: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay31: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
    },
    thang4: {
      ngay1: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay2: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay3: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay4: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay5: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay6: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay7: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay8: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay9: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay10: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay11: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay12: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay13: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay14: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay15: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay16: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay17: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay18: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay19: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay20: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay21: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay22: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay23: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay24: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay25: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay26: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay27: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay28: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay29: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay30: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay31: { firstColor: "black", secondColor: "black" },
    },
    thang5: {
      ngay1: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay2: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay3: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay4: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay5: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay6: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay7: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay8: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay9: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay10: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay11: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay12: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay13: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay14: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay15: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay16: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay17: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay18: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay19: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay20: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay21: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay22: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay23: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay24: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay25: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay26: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay27: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay28: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay29: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay30: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay31: {
        firstColor: "#EE333F",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
    },
    thang6: {
      ngay1: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay2: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay3: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay4: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay5: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay6: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay7: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay8: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay9: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay10: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay11: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay12: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay13: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay14: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay15: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay16: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay17: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay18: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay19: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay20: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay21: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay22: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay23: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay24: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay25: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay26: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay27: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay28: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay29: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay30: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay31: { firstColor: "#fff", secondColor: "#fff" },
    },
    thang7: {
      ngay1: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay2: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay3: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay4: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay5: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay6: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay7: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay8: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay9: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay10: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay11: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay12: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay13: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay14: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay15: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay16: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay17: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay18: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay19: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay20: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay21: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay22: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay23: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay24: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay25: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay26: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay27: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay28: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay29: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay30: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay31: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
    },
    thang8: {
      ngay1: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay2: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay3: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay4: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay5: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay6: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay7: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay8: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay9: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay10: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay11: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay12: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay13: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay14: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay15: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay16: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay17: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay18: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay19: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay20: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay21: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay22: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay23: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay24: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay25: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay26: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay27: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay28: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay29: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay30: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay31: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
    },
    thang9: {
      ngay1: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay2: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay3: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay4: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay5: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay6: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay7: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay8: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay9: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay10: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay11: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay12: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay13: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay14: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay15: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay16: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay17: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay18: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay19: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay20: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay21: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay22: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay23: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay24: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay25: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay26: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay27: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay28: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay29: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay30: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay31: { firstColor: "#fff", secondColor: "#fff" },
    },
    thang10: {
      ngay1: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay2: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay3: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay4: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay5: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay6: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay7: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay8: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay9: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay10: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay11: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay12: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay13: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay14: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay15: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay16: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay17: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay18: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay19: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay20: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay21: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay22: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay23: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay24: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay25: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay26: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay27: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay28: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay29: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay30: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay31: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
    },
    thang11: {
      ngay1: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay2: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay3: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay4: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay5: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay6: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay7: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay8: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay9: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay10: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay11: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay12: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay13: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay14: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay15: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay16: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay17: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay18: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay19: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay20: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay21: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay22: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay23: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay24: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay25: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay26: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay27: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay28: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay29: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay30: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay31: { firstColor: "#fff", secondColor: "#fff" },
    },
    thang12: {
      ngay1: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay2: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay3: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay4: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay5: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay6: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay7: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay8: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay9: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay10: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay11: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay12: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay13: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay14: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay15: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay16: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay17: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay18: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay19: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay20: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay21: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay22: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay23: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay24: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
      ngay25: {
        firstColor: "#ED2790",
        secondColor: "#0EB057",
        image: imagePink,
        descriptImage: `Làng chài Cinque Terre,\nLiguria, Ý.`,
      },
      ngay26: {
        firstColor: "#D4313B",
        secondColor: "#0082C7",
        image: imageRed,
        descriptImage: `Biển Miami,\nFlorida, Mỹ.`,
      },
      ngay27: {
        firstColor: "#008DD0",
        secondColor: "#E39A31",
        image: imageXanhNhat,
        descriptImage: `Mù Cang Chải - Yên Bái,\nViệt Nam.`,
      },
      ngay28: {
        firstColor: "#009D53",
        secondColor: "#ED2790",
        image: imageGreen,
        descriptImage: `Bến cảng Alanya,\nThổ Nhĩ Kỳ.`,
      },
      ngay29: {
        firstColor: "#0668B1",
        secondColor: "#F37536",
        image: imageBlue,
        descriptImage: `Mùa Xuân núi Phú Sĩ,\nNhật Bản.`,
      },
      ngay30: {
        firstColor: "#00ACAF",
        secondColor: "#0088CC",
        image: imageGreenBlue,
        descriptImage: `Nông thôn Vermont,\nMỹ.`,
      },
      ngay31: {
        firstColor: "#F26C36",
        secondColor: "#00ACAF",
        image: imageOrange,
        descriptImage: `Đồi trà Ô Long,\nSa Pa, Việt Nam.`,
      },
    },
  };
  static trucSao = {
    Kien: { id: 1, name: "Kiến" },
    Man: { id: 2, name: "Mãn" },
    Dinh: { id: 3, name: "Định" },
    Thanh: { id: 4, name: "Thành" },
    Khai: { id: 5, name: "Khai" },
    Be: { id: 6, name: "Bế" },
    Tru: { id: 7, name: "Trừ" },
    Binh: { id: 8, name: "Bình" },
    Chap: { id: 9, name: "Chấp" },
    Pha: { id: 10, name: "Phá" },
    Nguy: { id: 11, name: "Nguy" },
    Thu: { id: 12, name: "Thu" },
  };
  static timeSelection = [
    { id: 1, time: "Giờ Tý 23h-01h", value: 23 },
    { id: 2, time: "Giờ Sửu 01h-03h", value: 1 },
    { id: 3, time: "Giờ Dần 03h-05h", value: 3 },
    { id: 4, time: "Giờ Mẹo 05h-07h", value: 5 },
    { id: 5, time: "Giờ Thìn 07h-09h", value: 7 },
    { id: 6, time: "Giờ Tỵ 09h-11h", value: 9 },
    { id: 7, time: "Giờ Ngọ 11h-13h", value: 11 },
    { id: 8, time: "Giờ Mùi 13h-15h", value: 13 },
    { id: 9, time: "Giờ Thân 15h-17h", value: 15 },
    { id: 10, time: "Giờ Dậu 17h-19h", value: 17 },
    { id: 11, time: "Giờ Tuất 19h-21h", value: 19 },
    { id: 12, time: "Giờ Hợi 21h-23h", value: 21 },
  ];
  static initDateData = [
    {
      header: "Giờ",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
    {
      header: "Ngày",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
    {
      header: "Tháng",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
    {
      header: "Năm",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
    {
      header: "Tọa",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
    {
      header: "Năm sinh",
      canChi: "-",
      diaChi: "-",
      qua: "-",
      que: {
        tenque: "-",
        stt: 0,
        que: "-",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
    },
  ];
  static initHourDate = [
    {
      header: "23:00\n-\n01:00",
      picture: TyImage,
      pictureRed: TyRedImage,
      canChi: "Bính",
      diaChi: "Tý",
      qua: "Tốn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Bạch Hổ",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Bạch Hổ",
    },
    {
      header: "01:00\n-\n03:00",
      picture: SuuImage,
      pictureRed: SuuRedImage,

      canChi: "Đinh",
      diaChi: "Sửu",
      qua: "Cấn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Ngọc Đường",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Nguyên Vũ",
    },
    {
      header: "03:00\n-\n05:00",
      picture: DanImage,
      pictureRed: DanRedImage,

      canChi: "Mậu",
      diaChi: "Dần",
      qua: "Khảm",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Thiên Lao",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Câu Trận",
    },
    {
      header: "05:00\n-\n07:00",
      picture: MaoImage,
      pictureRed: MaoRedImage,

      canChi: "Kỷ",
      diaChi: "Mão",
      qua: "Khôn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Huyền Vũ",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Chu Tước",
    },
    {
      header: "07:00\n-\n09:00",
      picture: ThinImage,
      pictureRed: ThinRedImage,

      canChi: "Canh",
      diaChi: "Thìn",
      qua: "Đoàn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Ti Mệnh",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Bạc Hổ",
    },
    {
      header: "09:00\n-\n11:00",
      picture: TiImage,
      pictureRed: TiRedImage,

      canChi: "Tân",
      diaChi: "Tỵ",
      qua: "Tốn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Câu Trần",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Chu Tước",
    },
    {
      header: "11:00\n-\n13:00",
      picture: NgoImage,
      pictureRed: NgoRedImage,

      canChi: "Nhâm",
      diaChi: "Ngọ",
      qua: "Đoài",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Thanh Lao",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Chu Tước",
    },
    {
      header: "13:00\n-\n15:00",
      picture: MuiImage,
      pictureRed: MuiRedImage,

      canChi: "Quý",
      diaChi: "Mùi",
      qua: "Ly",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Minh Đường",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Chu Tước",
    },
    {
      header: "15:00\n-\n17:00",
      picture: ThanImage,
      pictureRed: ThanRedImage,

      canChi: "Giáp",
      diaChi: "Thân",
      qua: "Càng",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Thiên Hình",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Câu Trận",
    },
    {
      header: "17:00\n-\n19:00",
      picture: DauImage,
      pictureRed: DauRedImage,

      canChi: "Ất",
      diaChi: "Dậu",
      qua: "Chấn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Chu Tước",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Câu Trận",
    },
    {
      header: "19:00\n-\n21:00",
      picture: TuatImage,
      pictureRed: TuatRedImage,

      canChi: "Bính",
      diaChi: "Tuất",
      qua: "Cấn",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Kim Quỹ",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Câu Trận",
    },
    {
      header: "21:00\n-\n23:00",
      picture: HoiImage,
      pictureRed: HoiRedImage,

      canChi: "Đinh",
      diaChi: "Hợi",
      qua: "Khảm",
      que: {
        tenque: "",
        stt: 0,
        que: "",
        huyenKhongNguHanh: 0,
        huyenKhongQuaiVan: 0,
        row1: [true, true, true],
        row2: [true, true, true],
        row3: [true, true, true],
        row4: [true, true, true],
        row5: [true, true, true],
        row6: [true, true, true],
      },
      hoangDao: "Bảo Quang",
      phiTinhGio: null,
      haDo: "Hà Đồ",
      hacDao: "Câu Trận",
    },
  ];
  static ngayKy = {
    maVuongKyNhat: { code: 1, name: "Ma Vương" },
    duongCongKyNhat: { code: 2, name: "Dương Công" },
    tuLy: { code: 3, name: "Tứ Ly" },
    tuTuyet: { code: 4, name: "Tứ Tuyệt" },
  };
  static Can = {
    Canh: { code: 0, name: "Canh", hanViet: "Geng", chinese: "庚" },
    Tan: { code: 1, name: "Tân", hanViet: "Xin", chinese: "辛" },
    Nham: { code: 2, name: "Nhâm", hanViet: "Ren", chinese: "壬" },
    Qui: { code: 3, name: "Quý", hanViet: "Gui", chinese: "癸" },
    Giap: { code: 4, name: "Giáp", hanViet: "Jia", chinese: "甲" },
    At: { code: 5, name: "Ất", hanViet: "Yi", chinese: "乙" },
    Binh: { code: 6, name: "Bính", hanViet: "Bing", chinese: "丙" },
    Dinh: { code: 7, name: "Đinh", hanViet: "Ding", chinese: "丁" },
    Mau: { code: 8, name: "Mậu", hanViet: "Wu", chinese: "戊" },
    Ky: { code: 9, name: "Kỷ", hanViet: "Ji", chinese: "己" },
  };
  static CanArr = [
    { code: 0, key: "can.canh", name: "Canh", hanViet: "Geng", chinese: "庚" },
    { code: 1, key: "can.tan", name: "Tân", hanViet: "Xin", chinese: "辛" },
    { code: 2, key: "can.nham", name: "Nhâm", hanViet: "Ren", chinese: "壬" },
    { code: 3, key: "can.quy", name: "Quý", hanViet: "Gui", chinese: "癸" },
    { code: 4, key: "can.giap", name: "Giáp", hanViet: "Jia", chinese: "甲" },
    { code: 5, key: "can.at", name: "Ất", hanViet: "Yi", chinese: "乙" },
    { code: 6, key: "can.binh", name: "Bính", hanViet: "Bing", chinese: "丙" },
    { code: 7, key: "can.dinh", name: "Đinh", hanViet: "Ding", chinese: "丁" },
    { code: 8, key: "can.mau", name: "Mậu", hanViet: "Wu", chinese: "戊" },
    { code: 9, key: "can.ky", name: "Kỷ", hanViet: "Ji", chinese: "己" },
  ];
  static Chi = {
    Ty: {
      code: 0,
      name: "Tý",
      hanViet: "Zi",
      chinese: "子",
      eng: "Rat",
      coordinate: "0deg",
    },
    Suu: {
      code: 1,
      name: "Sửu",
      hanViet: "Chou",
      chinese: "丑",
      eng: "Ox",
      coordinate: "31deg",
    },
    Dan: {
      code: 2,
      name: "Dần",
      hanViet: "Yin",
      chinese: "寅",
      eng: "Tiger",
      coordinate: "45deg",
    },
    Mao: {
      code: 3,
      name: "Mão",
      hanViet: "Mao",
      chinese: "卯",
      eng: "Cat",
      coordinate: "90deg",
    },
    Thin: {
      code: 4,
      name: "Thìn",
      hanViet: "Chen",
      chinese: "辰",
      eng: "Dragon",
      coordinate: "121deg",
    },
    Ti: {
      code: 5,
      name: "Tỵ",
      hanViet: "Si",
      chinese: "巳",
      eng: "Snake",
      coordinate: "149deg",
    },
    Ngo: {
      code: 6,
      name: "Ngọ",
      hanViet: "Wu",
      chinese: "午",
      eng: "Horse",
      coordinate: "180deg",
    },
    Mui: {
      code: 7,
      name: "Mùi",
      hanViet: "Wei",
      chinese: "未",
      eng: "Goat",
      coordinate: "210deg",
    },
    Than: {
      code: 8,
      name: "Thân",
      hanViet: "Shen",
      chinese: "申",
      eng: "Monkey",
      coordinate: "225deg",
    },
    Dau: {
      code: 9,
      name: "Dậu",
      hanViet: "You",
      chinese: "酉",
      eng: "Rooster",
      coordinate: "270deg",
    },
    Tuat: {
      code: 10,
      name: "Tuất",
      hanViet: "Xu",
      chinese: "戌",
      eng: "Dog",
      coordinate: "300deg",
    },
    Hoi: {
      code: 11,
      name: "Hợi",
      hanViet: "Hai",
      chinese: "亥",
      eng: "Pig",
      coordinate: "315deg",
    },
  };
  static ChiArr = [
    { code: 0, key: "chi.ty", name: "Tý", chinese: "子", hanViet: "Zi" },
    { code: 1, key: "chi.suu", name: "Sửu", chinese: "丑", hanViet: "Chou" },
    { code: 2, key: "chi.dan", name: "Dần", chinese: "寅", hanViet: "Yin" },
    { code: 3, key: "chi.mao", name: "Mão", chinese: "卯", hanViet: "Mao" },
    { code: 4, key: "chi.thin", name: "Thìn", chinese: "辰", hanViet: "Chen" },
    { code: 5, key: "chi.ti", name: "Tỵ", chinese: "巳", hanViet: "Si" },
    { code: 6, key: "chi.ngo", name: "Ngọ", chinese: "午", hanViet: "Wu" },
    { code: 7, key: "chi.mui", name: "Mùi", chinese: "未", hanViet: "Wei" },
    { code: 8, key: "chi.than", name: "Thân", chinese: "申", hanViet: "Shen" },
    { code: 9, key: "chi.dau", name: "Dậu", chinese: "酉", hanViet: "You" },
    { code: 10, key: "chi.tuat", name: "Tuất", chinese: "戌", hanViet: "Xu" },
    { code: 11, key: "chi.hoi", name: "Hợi", chinese: "亥", hanViet: "Hai" },
  ];
  static CanChi = {
    giapTy: {
      code: 0,
      name: "Giáp Tý",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atSuu: { code: 1, name: "Ất Sửu", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhDan: { code: 2, name: "Bính Dần", taiThan: "Tây", hyThan: "Tây Nam" },
    dinhMao: { code: 3, name: "Đinh Mão", taiThan: "Nam", hyThan: "Tây" },
    mauThin: { code: 4, name: "Mậu Thìn", taiThan: "Bắc", hyThan: "Đông Nam" },
    kyTi: { code: 5, name: "Kỷ Tỵ", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhNgo: { code: 6, name: "Canh Ngọ", taiThan: "Đông", hyThan: "Tây Bắc" },
    tanMui: { code: 7, name: "Tân Mùi", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamThan: { code: 8, name: "Nhâm Thân", taiThan: "Nam", hyThan: "Nam" },
    quyDau: { code: 9, name: "Quý Dậu", taiThan: "Nam", hyThan: "Đông Nam" },
    giapTuat: {
      code: 10,
      name: "Giáp Tuất",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atHoi: { code: 11, name: "Ất Hợi", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhTy: { code: 12, name: "Bính Tý", taiThan: "Tây", hyThan: "Tây Nam" },
    dinhSuu: { code: 13, name: "Đinh Sửu", taiThan: "Tây", hyThan: "Nam" },
    mauDan: { code: 14, name: "Mậu Dần", taiThan: "Bắc", hyThan: "Đông Nam" },
    kyMao: { code: 15, name: "Kỷ Mão", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhThin: {
      code: 16,
      name: "Canh Thìn",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
    },
    tanTi: { code: 17, name: "Tân Tỵ", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamNgo: { code: 18, name: "Nhâm Ngọ", taiThan: "Nam", hyThan: "Nam" },
    quiMui: { code: 19, name: "Quý Mùi", taiThan: "Nam", hyThan: "Đông Nam" },
    giapThan: {
      code: 20,
      name: "Giáp Thân",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atDau: { code: 21, name: "Ất Dậu", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhTuat: {
      code: 22,
      name: "Bính Tuất",
      taiThan: "Tây",
      hyThan: "Tây Nam",
    },
    dinhHoi: { code: 23, name: "Đinh Hợi", taiThan: "Tây", hyThan: "Nam" },
    mauTy: { code: 24, name: "Mậu Tý", taiThan: "Bắc", hyThan: "Đông Nam" },
    kySuu: { code: 25, name: "Kỷ Sửu", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhDan: { code: 26, name: "Canh Dần", taiThan: "Đông", hyThan: "Tây Bắc" },
    tanMao: { code: 27, name: "Tân Mão", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamThin: { code: 28, name: "Nhâm Thìn", taiThan: "Nam", hyThan: "Nam" },
    quyTi: { code: 29, name: "Quý Tỵ", taiThan: "Nam", hyThan: "Đông Nam" },
    giapNgo: {
      code: 30,
      name: "Giáp Ngọ",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atMui: { code: 31, name: "Ất Mùi", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhThan: {
      code: 32,
      name: "Bính Thân",
      taiThan: "Tây",
      hyThan: "Tây Nam",
    },
    dinhDau: { code: 33, name: "Đinh Dậu", taiThan: "Tây", hyThan: "Nam" },
    mauTuat: { code: 34, name: "Mậu Tuất", taiThan: "Bắc", hyThan: "Đông Nam" },
    kyHoi: { code: 35, name: "Kỷ Hợi", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhTy: { code: 36, name: "Canh Tý", taiThan: "Đông", hyThan: "Tây Bắc" },
    tanSuu: { code: 37, name: "Tân Sửu", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamDan: { code: 38, name: "Nhâm Dần", taiThan: "Nam", hyThan: "Nam" },
    quiMao: { code: 39, name: "Quý Mão", taiThan: "Nam", hyThan: "Đông Nam" },
    giapThin: {
      code: 40,
      name: "Giáp Thìn",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atTi: { code: 41, name: "Ất Tỵ", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhNgo: { code: 42, name: "Bính Ngọ", taiThan: "Tây", hyThan: "Tây Nam" },
    dinhMui: { code: 43, name: "Đinh Mùi", taiThan: "Tây", hyThan: "Nam" },
    mauThan: { code: 44, name: "Mậu Thân", taiThan: "Bắc", hyThan: "Đông Nam" },
    kydau: { code: 45, name: "Kỷ Dậu", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhTuat: {
      code: 46,
      name: "Canh Tuất",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
    },
    tanHoi: { code: 47, name: "Tân Hợi", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamTy: { code: 48, name: "Nhâm Tý", taiThan: "Nam", hyThan: "Nam" },
    quiSuu: { code: 49, name: "Quý Sửu", taiThan: "Nam", hyThan: "Đông Nam" },
    giapDan: {
      code: 50,
      name: "Giáp Dần",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
    },
    atMao: { code: 51, name: "Ất Mão", taiThan: "Đông Nam", hyThan: "Tây Bắc" },
    binhThin: {
      code: 52,
      name: "Bính Thìn",
      taiThan: "Tây",
      hyThan: "Tây Nam",
    },
    dinhTi: { code: 53, name: "Đinh Tỵ", taiThan: "Tây", hyThan: "Nam" },
    mauNgo: { code: 54, name: "Mậu Ngọ", taiThan: "Bắc", hyThan: "Đông Nam" },
    kyMui: { code: 55, name: "Kỷ Mùi", taiThan: "Bắc", hyThan: "Đông Bắc" },
    canhThan: {
      code: 56,
      name: "Canh Thân",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
    },
    tanDau: { code: 57, name: "Tân Dậu", taiThan: "Đông", hyThan: "Tây Nam" },
    nhamTuat: { code: 58, name: "Nhâm Tuất", taiThan: "Nam", hyThan: "Nam" },
    quyHoi: { code: 59, name: "Quý Hợi", taiThan: "Nam", hyThan: "Đông Nam" },
  };
  static CanChiArr = [
    {
      code: 1,
      name: "Giáp Tý",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "357.5deg",
    },
    {
      code: 2,
      name: "Ất Sửu",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "31deg",
    },
    {
      code: 3,
      name: "Bính Dần",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "65deg",
    },
    {
      code: 4,
      name: "Đinh Mão",
      taiThan: "Nam",
      hyThan: "Tây",
      coordinate: "98deg",
    },
    {
      code: 5,
      name: "Mậu Thìn",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "132.5deg",
    },
    {
      code: 6,
      name: "Kỷ Tỵ",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "160deg",
    },
    {
      code: 7,
      name: "Canh Ngọ",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "200deg",
    },
    {
      code: 8,
      name: "Tân Mùi",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "232.5deg",
    },
    {
      code: 9,
      name: "Nhâm Thân",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "267.5deg",
    },
    {
      code: 10,
      name: "Quý Dậu",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "295deg",
    },
    {
      code: 11,
      name: "Giáp Tuất",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "301deg",
    },
    {
      code: 12,
      name: "Ất Hợi",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "329deg",
    },
    {
      code: 13,
      name: "Bính Tý",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "9deg",
    },
    {
      code: 14,
      name: "Đinh Sửu",
      taiThan: "Tây",
      hyThan: "Nam",
      coordinate: "37deg",
    },
    {
      code: 15,
      name: "Mậu Dần",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "70deg",
    },
    {
      code: 16,
      name: "Kỷ Mão",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 17,
      name: "Canh Thìn",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 18,
      name: "Tân Tỵ",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 19,
      name: "Nhâm Ngọ",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 20,
      name: "Quý Mùi",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 21,
      name: "Giáp Thân",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 22,
      name: "Ất Dậu",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 23,
      name: "Bính Tuất",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 24,
      name: "Đinh Hợi",
      taiThan: "Tây",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 25,
      name: "Mậu Tý",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "14deg",
    },
    {
      code: 26,
      name: "Kỷ Sửu",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "42.5deg",
    },
    {
      code: 27,
      name: "Canh Dần",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "76deg",
    },
    {
      code: 28,
      name: "Tân Mão",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 29,
      name: "Nhâm Thìn",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 30,
      name: "Quý Tỵ",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 31,
      name: "Giáp Ngọ",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 32,
      name: "Ất Mùi",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 33,
      name: "Bính Thân",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 34,
      name: "Đinh Dậu",
      taiThan: "Tây",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 35,
      name: "Mậu Tuất",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 36,
      name: "Kỷ Hợi",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 37,
      name: "Canh Tý",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "20deg",
    },
    {
      code: 38,
      name: "Tân Sửu",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "47.5deg",
    },
    {
      code: 39,
      name: "Nhâm Dần",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 40,
      name: "Quý Mão",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 41,
      name: "Giáp Thìn",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 42,
      name: "Ất Tỵ",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 43,
      name: "Bính Ngọ",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 44,
      name: "Đinh Mùi",
      taiThan: "Tây",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 45,
      name: "Mậu Thân",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 46,
      name: "Kỷ Dậu",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 47,
      name: "Canh Tuất",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 48,
      name: "Tân Hợi",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 49,
      name: "Nhâm Tý",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "25deg",
    },
    {
      code: 50,
      name: "Quý Sửu",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "53deg",
    },
    {
      code: 51,
      name: "Giáp Dần",
      taiThan: "Đông Nam",
      hyThan: "Đông Bắc",
      coordinate: "59deg",
    },
    {
      code: 52,
      name: "Ất Mão",
      taiThan: "Đông Nam",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 53,
      name: "Bính Thìn",
      taiThan: "Tây",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 54,
      name: "Đinh Tỵ",
      taiThan: "Tây",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 55,
      name: "Mậu Ngọ",
      taiThan: "Bắc",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
    {
      code: 56,
      name: "Kỷ Mùi",
      taiThan: "Bắc",
      hyThan: "Đông Bắc",
      coordinate: "deg",
    },
    {
      code: 57,
      name: "Canh Thân",
      taiThan: "Đông",
      hyThan: "Tây Bắc",
      coordinate: "deg",
    },
    {
      code: 58,
      name: "Tân Dậu",
      taiThan: "Đông",
      hyThan: "Tây Nam",
      coordinate: "deg",
    },
    {
      code: 59,
      name: "Nhâm Tuất",
      taiThan: "Nam",
      hyThan: "Nam",
      coordinate: "deg",
    },
    {
      code: 60,
      name: "Quý Hợi",
      taiThan: "Nam",
      hyThan: "Đông Nam",
      coordinate: "deg",
    },
  ];
  static HourArr = {
    GioTy: { code: 0, name: "Tý" },
    GioSuu: { code: 1, name: "Sửu" },
    GioDan: { code: 2, name: "Dần" },
    GioMao: { code: 3, name: "Mão" },
    GioThin: { code: 4, name: "Thìn" },
    GioTi: { code: 5, name: "Tỵ" },
    GioNgo: { code: 6, name: "Ngọ" },
    GioMui: { code: 7, name: "Mùi" },
    GioThan: { code: 8, name: "Thân" },
    GioDau: { code: 9, name: "Dậu" },
    GioTuat: { code: 10, name: "Tuất" },
    GioHoi: { code: 11, name: "Hợi" },
  };
  static Que = [
    {
      tenque: `Khôn Vị Địa \n(Earth)`,
      stt: 1,
      que: "Jia Zi",
      queViet: "Giáp Tý",
      xungTuoi: "Canh Ngọ,Mậu Ngọ",

      cordCompass: "357.5deg",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Mao],
      gioXau: [this.Chi.Thin, this.Chi.Ti, this.Chi.Tuat, this.Chi.Hoi],
      napAm: "Hải Trung Kim",
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Lôi Phục \n(Earth)",
      stt: 1,
      que: "Jia Zi",
      cordCompass: "2.5deg",

      queViet: "Giáp Tý",
      xungTuoi: "Canh Ngọ,Mậu Ngọ",

      napAm: "Hải Trung Kim",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Mao],
      gioXau: [this.Chi.Thin, this.Chi.Ti, this.Chi.Tuat, this.Chi.Hoi],
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Lôi Phệ Hạp\n(Chewing)",
      stt: 2,
      que: "Yi Chou",
      queViet: "Ất Sửu",
      xungTuoi: "Tân Mùi,Kỷ Mùi",

      napAm: "Hải Trung Kim",
      cordCompass: "31deg",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ti, this.Chi.Than],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Hoi,
        this.Chi.Ngo,
        this.Chi.Mui,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Phong Hỏa Gia Nhân\n(Family)",
      stt: 3,
      que: "Bing Yin",
      queViet: "Bính Dần",
      xungTuoi: "Canh Thân,Nhâm Thân",

      cordCompass: "65deg",

      napAm: "Lư Trung Hỏa",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Thin, this.Chi.Mui],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Than,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Trạch Tổn\n(Diminishing)",
      stt: 4,
      que: "Ding Mao",
      queViet: "Đinh Mão",
      xungTuoi: "Tân Dậu,Quý Dậu",

      cordCompass: "98deg",

      napAm: "Lư Trung Hỏa",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Than,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Trạch Lý\n(Treading)",
      stt: 5,
      que: "Wu Chen",
      queViet: "Mậu Thìn",
      xungTuoi: "Giáp Tuất,Nhâm Tuất",

      cordCompass: "132.5deg",

      napAm: "Đại Lâm Mộc",
      gioTot: [this.Chi.Dan, this.Chi.Ti, this.Chi.Than],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Suu,
        this.Chi.Ngo,
        this.Chi.Mui,
        this.Chi.Tuat,
      ],
      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Thiên Đại Tráng\n(Invigorating)",
      stt: 6,
      que: "Ji Si",
      queViet: "Kỷ Tỵ",
      xungTuoi: "Ất Hợi,Quý Hợi",

      cordCompass: "160deg",

      napAm: "Đại Lâm Mộc",
      gioTot: [this.Chi.Suu],
      gioXau: [this.Chi.Ty, this.Chi.Mao, this.Chi.Dau],
      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Phong Hằng\n(Constancy)",
      stt: 7,
      que: "Geng Wu",
      queViet: "Canh Ngọ",
      xungTuoi: "Giáp Tý,Bính Tý",

      napAm: "Lộ Bàng Thổ",
      cordCompass: "199.5deg",
      gioTot: [this.Chi.Suu, this.Chi.Mao, this.Chi.Ngo, this.Chi.Than],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Mui,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thiên Thủy Tụng\n(Arguing)",
      stt: 8,
      que: "Xin Wei",
      queViet: "Tân Mùi",
      xungTuoi: "Ất Sửu,Đinh Sửu",

      napAm: "Lộ Bàng Thổ",
      cordCompass: "232.5deg",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ti, this.Chi.Than],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Ngo,
        this.Chi.Mui,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Thủy Sư\n(Troop)",
      stt: 9,
      que: "Ren Shen",
      queViet: "Nhâm Thân",
      xungTuoi: "Mậu Dần,Bính Dần",

      napAm: "Kiếm Phong Kim",
      cordCompass: "267deg",
      gioTot: [this.Chi.Ty, this.Chi.Thin, this.Chi.Ti],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Than,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Sơn Tiệm\n(Gradual Progress)",
      stt: 10,
      que: "Gui You",
      queViet: "Quý Dậu",
      xungTuoi: "Đinh Mão,Kỷ Mão",

      napAm: "Kiếm Phong Kim",
      cordCompass: "295deg",
      gioTot: [this.Chi.Dan, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Than,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thủy Sơn Kiển\n(Impeding)",
      stt: 11,
      que: "Jia Xu",
      queViet: "Giáp Tuất",
      xungTuoi: "Canh Thìn,Mậu Thìn",

      napAm: "Sơn Đầu Hỏa",
      cordCompass: "301deg",
      gioTot: [this.Chi.Ti, this.Chi.Hoi],
      gioXau: [this.Chi.Ty, this.Chi.Ngo, this.Chi.Mui, this.Chi.Tuat],
      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Địa Tấn\n(Advancing)",
      stt: 12,
      que: "Yi Hai",
      queViet: "Ất Hợi",
      xungTuoi: "Tân Tỵ,Kỷ Tỵ",

      napAm: "Sơn Đầu Hỏa",
      cordCompass: "329deg",
      gioTot: [this.Chi.Suu, this.Chi.Thin, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ti,
        this.Chi.Than,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Lôi Di\n(Nurture)",
      stt: 13,
      que: "Bing Zi",
      queViet: "Bính Tý",
      xungTuoi: "Canh Ngọ,Nhâm Ngọ",

      napAm: "Giản Hạ Thủy",
      cordCompass: "9deg",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Mao],
      gioXau: [this.Chi.Thin, this.Chi.Ti, this.Chi.Tuat, this.Chi.Hoi],
      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Lôi Tùy\n(Following)",
      stt: 14,
      que: "Ding Chou",
      queViet: "Đinh Sửu",
      xungTuoi: "Tân Mùi,Quý Mùi",

      napAm: "Giản Hạ Thủy",
      cordCompass: "37deg",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ti],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Mui,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Hỏa Phong\n(Abundance)",
      stt: 15,
      que: "Wu Yin",
      queViet: "Mậu Dần",
      xungTuoi: "Giáp Thân,Nhâm Thân",

      napAm: "Thành Đầu Thổ",
      cordCompass: "70deg",
      gioTot: [this.Chi.Suu, this.Chi.Thin, this.Chi.Ti, this.Chi.Mui],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Than,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Trạch Tiết\n(Regulating)",
      stt: 16,
      que: "Ji Mao",
      queViet: "Kỷ Mão",
      xungTuoi: "Ất Dậu,Quý Dậu",

      napAm: "Thành Đầu Thổ",
      cordCompass: "104deg",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Than,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },

    {
      tenque: "Địa Thiên Thái\n(Greatness)",
      stt: 17,
      que: "Geng Chen",
      queViet: "Canh Thìn",
      xungTuoi: "Giáp Tuất,Bính Tuất",

      napAm: "Bạch Lạp Kim",
      cordCompass: "138deg",
      gioTot: [this.Chi.Dan, this.Chi.Thin],
      gioXau: [this.Chi.Ty, this.Chi.Mao, this.Chi.Mui, this.Chi.Tuat],
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },

    {
      tenque: "Hỏa Thiên Đại Hũu\n(Great Harvest)",
      stt: 18,
      que: "Xin Si",
      queViet: "Tân Tỵ",
      xungTuoi: "Ất Hợi,Đinh Hợi",

      napAm: "Bạch Lạp Kim",
      cordCompass: "169deg",
      gioTot: [this.Chi.Suu, this.Chi.Thin, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ti,
        this.Chi.Than,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Tốn Vi Phong\n(Wind)",
      stt: 19,
      que: "Ren Wu",
      queViet: "Nhâm Ngọ",
      xungTuoi: "Mậu Tý,Bính Tý",

      napAm: "Dương Liễu Mộc",
      cordCompass: "205deg",
      gioTot: [this.Chi.Suu, this.Chi.Mao, this.Chi.Ngo],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Trạch Thủy Khốn\n(Trapped)",
      stt: 20,
      que: "Gui Wei",
      queViet: "Quý Mùi",
      xungTuoi: "Đinh Sửu,Kỷ Sửu",

      napAm: "Dương Liễu Mộc",
      cordCompass: "233deg",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ti],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Suu,
        this.Chi.Ngo,
        this.Chi.Mui,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Thủy Vị Tế\n(Unaccomplished)",
      stt: 21,
      que: "Jia Shen",
      queViet: "Giáp Thân",
      xungTuoi: "Canh Dần,Mậu Dần",

      napAm: "Tuyền Trung Thủy",
      cordCompass: "239deg",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Thin, this.Chi.Ti],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Than,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thiên Sơn Độn\n(Retreat)",
      stt: 22,
      que: "Yi You",
      queViet: "Ất Dậu",
      xungTuoi: "Tân Mão,Kỷ Mão",

      napAm: "Tuyền Trung Thủy",
      cordCompass: "273deg",
      gioTot: [this.Chi.Ty, this.Chi.Dan, this.Chi.Dau],
      gioXau: [
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Than,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Cấn Vi Sơn\n(Mountain)",
      stt: 23,
      que: "Bing Xu",
      queViet: "Bính Tuất",
      xungTuoi: "Canh Thìn,Nhâm Thìn",

      napAm: "Ốc Thượng Thổ",
      cordCompass: "307deg",
      gioTot: [this.Chi.Thin, this.Chi.Ti],
      gioXau: [this.Chi.Mao, this.Chi.Ngo, this.Chi.Mui, this.Chi.Tuat],
      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Lôi Địa Dự\n(Elated)",
      stt: 24,
      que: "Ding Hai",
      queViet: "Đinh Hợi",
      xungTuoi: "Tân Tỵ,Quý Tỵ",

      napAm: "Ốc Thượng Thổ",
      cordCompass: "335deg",
      gioTot: [this.Chi.Suu, this.Chi.Thin, this.Chi.Ngo],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ti,
        this.Chi.Than,
      ],
      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thủy Lôi Truân\n(Sprouting)",
      stt: 25,
      que: "Wu Zi",
      queViet: "Mậu Tý",
      xungTuoi: "Giáp Ngọ,Nhâm Ngọ",

      napAm: "Tích Lịch Hỏa",
      cordCompass: "14deg",
      gioTot: [this.Chi.Mao, this.Chi.Ti, this.Chi.Than],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Thin,
        this.Chi.Ngo,
        this.Chi.Mui,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Lôi Vô Vọng\n(WithoutEmbroiling)",
      stt: 26,
      que: "Ji Chou",
      queViet: "Kỷ Sửu",
      xungTuoi: "Ất Mùi,Quý Mùi",

      napAm: "Tích Lịch Hỏa",
      cordCompass: "42deg",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ti],
      gioXau: [
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Ngo,
        this.Chi.Mui,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Ly Vi Hỏa\n(Fire)",
      stt: 27,
      que: "Geng Yin",
      queViet: "Canh Dần",
      xungTuoi: "Giáp Thân,Bính Thân",

      napAm: "Tùng Bách Mộc",
      cordCompass: "76deg",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Thin, this.Chi.Ti],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Than,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },

    {
      tenque: "Trạch Hỏa Cách\n(Fire)",
      stt: 27,
      que: "Geng Yin",
      queViet: "Canh Dần",
      xungTuoi: "Giáp Thân,Bính Thân",

      cordCompass: "82deg",

      napAm: "Tùng Bách Mộc",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Thin, this.Chi.Ti],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Than,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },

    {
      tenque: "Phong Trạch Trung Phu (Trustworthy)",
      stt: 27,
      que: "Xin Mao",
      queViet: "Tân Mão",
      xungTuoi: "Ất Dậu,Đinh Dậu",

      napAm: "Tùng Bách Mộc",
      cordCompass: "110deg",
      gioTot: [this.Chi.Ty, this.Chi.Dan, this.Chi.Mao, this.Chi.Thin],
      gioXau: [this.Chi.Suu, this.Chi.Than, this.Chi.Tuat, this.Chi.Hoi],
      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },

    {
      tenque: "Sơn Thiên Đại Súc\n(Big Accruing)",
      stt: 28,
      que: "Ren Chen",
      queViet: "Nhâm Thìn",
      xungTuoi: "Mậu Tuất,Bính Tuất",

      cordCompass: "143deg",

      napAm: "Trường Lưu Thủy",
      gioTot: [this.Chi.Dan, this.Chi.Thin, this.Chi.Ti],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Mui,
        this.Chi.Tuat,
      ],
      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Thiên Quải\n(Eradicating)",
      stt: 29,
      que: "Gui Si",
      queViet: "Quý Tỵ",
      xungTuoi: "Đinh Hợi,Kỷ Hợi",

      napAm: "Trường Lưu Thủy",
      cordCompass: "172deg",
      gioTot: [this.Chi.Suu, this.Chi.Thin],
      gioXau: [this.Chi.Ty, this.Chi.Dan, this.Chi.Than, this.Chi.Dau],
      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },

    {
      tenque: "Càn Vi Thiên\n(Heaven)",
      stt: 30,
      que: "Jia Wu",
      queViet: "Giáp Ngọ",
      xungTuoi: "Canh Tý,Mậu Tý",

      cordCompass: "177deg",

      napAm: "Sa Trung Kim",
      gioTot: [this.Chi.Mao, this.Chi.Ngo],
      gioXau: [this.Chi.Thin, this.Chi.Ti, this.Chi.Tuat, this.Chi.Hoi],
      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Phong Cấu\n(Heaven)",
      stt: 30,
      que: "Jia Wu",
      queViet: "Giáp Ngọ",
      xungTuoi: "Canh Tý,Mậu Tý",

      cordCompass: "183deg",

      napAm: "Sa Trung Kim",
      gioTot: [this.Chi.Mao, this.Chi.Ngo],
      gioXau: [this.Chi.Thin, this.Chi.Ti, this.Chi.Tuat, this.Chi.Hoi],
      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thủy Phong Tỉnh\n(Well)",
      stt: 31,
      que: "Yi Wei",
      queViet: "Ất Mùi",
      xungTuoi: "Tân Sửu,Kỷ Sửu",

      cordCompass: "214deg",

      napAm: "Sa Trung Kim",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Than],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Mui,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Lôi Thủy Giải\n(Relief)",
      stt: 32,
      que: "Bing Shen",
      queViet: "Bính Thân",
      xungTuoi: "Canh Dần,Nhâm Dần",

      cordCompass: "245deg",

      napAm: "Sơn Hạ Hỏa",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Mui, this.Chi.Tuat],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Than,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },

    {
      tenque: "Trạch Sơn Hàm\n(Conjoining)",
      stt: 33,
      que: "Ding You",
      queViet: "Đinh Dậu",
      xungTuoi: "Tân Mão,Quý Mão",

      napAm: "Sơn Hạ Hỏa",
      cordCompass: "278deg",
      gioTot: [this.Chi.Ty, this.Chi.Dan, this.Chi.Ngo],
      gioXau: [
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Than,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Địa Sơn Khiêm\n(Humbling)",
      stt: 34,
      que: "Wu Xu",
      queViet: "Mậu Tuất",
      xungTuoi: "Giáp Thìn,Nhâm Thìn",

      cordCompass: "312deg",

      napAm: "Bình Địa Mộc",
      gioTot: [this.Chi.Dan, this.Chi.Than],
      gioXau: [this.Chi.Ty, this.Chi.Suu, this.Chi.Ngo, this.Chi.Tuat],
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Địa Quan\n(Observing)",
      stt: 35,
      que: "Ji Hai",
      queViet: "Kỷ Hợi",
      xungTuoi: "Ất Tỵ,Quý Tỵ",

      cordCompass: "340deg",

      napAm: "Bình Địa Mộc",
      gioTot: [this.Chi.Suu, this.Chi.Ngo],
      gioXau: [this.Chi.Mao, this.Chi.Ti, this.Chi.Than, this.Chi.Dau],
      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Lôi Ích\n(Benefiting)",
      stt: 36,
      que: "Geng Zi",
      queViet: "Canh Tý",
      xungTuoi: "Giáp Ngọ,Bính Ngọ",

      cordCompass: "20deg",

      napAm: "Bích Thượng Thổ",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Mao, this.Chi.Than],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Mui,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Địa Hỏa Minh Di\n(Lackluster)",
      stt: 37,
      que: "Xin Chou",
      queViet: "Tân Sửu",
      xungTuoi: "Ất Mùi,Đinh Mùi",

      cordCompass: "48deg",

      napAm: "Bích Thượng Thổ",
      gioTot: [this.Chi.Dan, this.Chi.Than, this.Chi.Hoi], //dần thân hợi
      gioXau: [
        this.Chi.Ti,
        this.Chi.Thin,
        this.Chi.Ngo,
        this.Chi.Mui,
        this.Chi.Dau,
      ], // tý thìn ngọ mùi dậu
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Hỏa Đồng Nhân (Fraternity)",
      stt: 38,
      que: "Ren Yin",
      queViet: "Nhâm Dần",
      xungTuoi: "Mậu Thân,Bính Thân",

      cordCompass: "87deg",

      napAm: "Kim Bạc Kim",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Mui],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Than,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Trạch Quy Muội\n(Marrying)",
      stt: 39,
      que: "Gui Mao",
      queViet: "Quý Mão",
      xungTuoi: "Đinh Dậu,Kỷ Dậu",

      cordCompass: "115deg",

      napAm: "Kim Bạc Kim",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Than,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Trạch Khuê\n(Conflicting)",
      stt: 40,
      que: "Jia Chen",
      queViet: "Giáp Thìn",
      xungTuoi: "Canh Tuất,Mậu Tuất",

      cordCompass: "121deg",

      napAm: "Phúc Đăng Hỏa",
      gioTot: [this.Chi.Than],
      gioXau: [this.Chi.Mao, this.Chi.Mui, this.Chi.Tuat],
      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Thiên Nhu\n(Waiting)",
      stt: 41,
      que: "Yi Si",
      queViet: "Ất Tỵ",
      xungTuoi: "Tân Hợi,Kỷ Hợi",

      cordCompass: "149deg",

      napAm: "Phúc Đăng Hỏa",
      gioTot: [this.Chi.Suu, this.Chi.Ngo],
      gioXau: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ti, this.Chi.Dau],
      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Phong Đại Quá (Exceedingly)",
      stt: 42,
      que: "Bing Wu",
      queViet: "Bính Ngọ",
      xungTuoi: "Canh Tý,Nhâm Tý",

      cordCompass: "188deg",

      napAm: "Thiên Hà Thủy",
      gioTot: [this.Chi.Suu, this.Chi.Ngo, this.Chi.Than, this.Chi.Dau],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Mui,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Phong Cổ\n(Corrupting)",
      stt: 43,
      que: "Ding Wei",
      queViet: "Đinh Mùi",
      xungTuoi: "Tân Sửu,Quý Sửu",

      cordCompass: "216deg",

      napAm: "Thiên Hà Thủy",
      gioTot: [this.Chi.Ti, this.Chi.Than],
      gioXau: [this.Chi.Ty, this.Chi.Suu, this.Chi.Thin, this.Chi.Mui],
      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Thủy Hoán\n(Dispersing)",
      stt: 44,
      que: "Wu Shen",
      queViet: "Mậu Thân",
      xungTuoi: "Giáp Dần,Nhâm Dần",

      cordCompass: "250deg",

      napAm: "Đại Dịch Thổ",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Thin, this.Chi.Ti],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Than,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Sơn Lữ\n(Traveling)",
      stt: 46,
      que: "Ji You",
      queViet: "Kỷ Dậu",
      xungTuoi: "Ất Mão,Quý Mão",

      cordCompass: "284deg",

      napAm: "Đại Dịch Thổ",
      gioTot: [this.Chi.Ty, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Thiên Địa Bĩ\n(Obstruction)",
      stt: 47,
      que: "Geng Xu",
      queViet: "Canh Tuất",
      xungTuoi: "Giáp Thìn,Bính Thìn",

      cordCompass: "318deg",

      napAm: "Thoa Xuyến Kim",
      gioTot: [this.Chi.Ti, this.Chi.Than],
      gioXau: [this.Chi.Ty, this.Chi.Mao, this.Chi.Mui, this.Chi.Tuat],
      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Thủy Địa Tỷ\n(Alliance)",
      stt: 48,
      que: "Xin Hai",
      queViet: "Tân Hợi",
      xungTuoi: "Ất Tỵ,Đinh Tỵ",

      cordCompass: "346deg",

      napAm: "Thoa Xuyến Kim",
      gioTot: [this.Chi.Suu, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ti,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Chấn Vi Lôi\n(Thunder)",
      stt: 49,
      que: "Ren Zi",
      queViet: "Nhâm Tý",
      xungTuoi: "Mậu Ngọ,Bính Ngọ",

      cordCompass: "26deg",

      napAm: "Tang Đố Mộc",
      gioTot: [this.Chi.Ty, this.Chi.Suu, this.Chi.Ngo],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Hỏa bí\n(Beautifying)",
      stt: 50,
      que: "Gui Chou",
      queViet: "Quý Sửu",
      xungTuoi: "Đinh Mùi,Kỷ Mùi",

      cordCompass: "52.5deg",

      napAm: "Tang Đố Mộc",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ti],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Suu,
        this.Chi.Ngo,
        this.Chi.Mui,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Hỏa Ký Tế\n(Success)",
      stt: 51,
      que: "Jia Yin",
      queViet: "Giáp Dần",
      xungTuoi: "Canh Thân,Mậu Thân",

      cordCompass: "59deg",

      napAm: "Đại Khê Thủy",
      gioTot: [this.Chi.Suu, this.Chi.Mui, this.Chi.Tuat],
      gioXau: [
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Than,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Địa Trạch Lâm\n(Approaching)",
      stt: 52,
      que: "Yi Mao",
      queViet: "Ất Mão",
      xungTuoi: "Tân Dậu,Kỷ Dậu",

      cordCompass: "93deg",

      napAm: "Đại Khê Thủy",
      gioTot: [this.Chi.Ty, this.Chi.Mao, this.Chi.Ngo],
      gioXau: [
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Đoài Vi Trạch\n(Marsh)",
      stt: 53,
      que: "Bing Chen",
      queViet: "Bính Thìn",
      xungTuoi: "Canh Tuất,Nhâm Tuất",

      cordCompass: "126.5deg",

      napAm: "Sa Trung Thổ",
      gioTot: [this.Chi.Dan, this.Chi.Than, this.Chi.Dau],
      gioXau: [
        this.Chi.Suu,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Mui,
        this.Chi.Tuat,
      ],
      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },

    {
      tenque: "Phong Thiên Tiểu Súc(SmallAccruing)",
      stt: 54,
      que: "Ding Si",
      queViet: "Đinh Tỵ",
      xungTuoi: "Tân Hợi,Quý Hợi",

      cordCompass: "155deg",

      napAm: "Sa Trung Thổ",
      gioTot: [this.Chi.Thin, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Than,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Phong Đỉnh\n(Holding)",
      stt: 55,
      que: "Wu Wu",
      queViet: "Mậu Ngọ",
      xungTuoi: "Giáp Tý,Nhâm Tý",

      cordCompass: "164deg",

      napAm: "Thiên Thượng Hỏa",
      gioTot: [this.Chi.Mao, this.Chi.Ngo, this.Chi.Than],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Thin,
        this.Chi.Ti,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Phong Thăng\n(Ascending)",
      stt: 56,
      que: "Ji Wei",
      queViet: "Kỷ Mùi",
      xungTuoi: "Ất Sửu,Quý Sửu",

      cordCompass: "128deg",

      napAm: "Thiên Thượng Hỏa",
      gioTot: [this.Chi.Dan, this.Chi.Mao, this.Chi.Ti, this.Chi.Ngo],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Mui,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },

    {
      tenque: "Khảm Vi Thủy\n(Water)",
      stt: 57,
      que: "Geng Shen",
      queViet: "Canh Thân",
      xungTuoi: "Giáp Dần,Bính Dần",

      cordCompass: "262deg",

      napAm: "Thạch Lựu Mộc",
      gioTot: [this.Chi.Thin, this.Chi.Ti, this.Chi.Mui],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Thủy Mông\n(Water)",
      stt: 57,
      que: "Geng Shen",
      queViet: "Canh Thân",
      xungTuoi: "Giáp Dần,Bính Dần",

      cordCompass: "256deg",

      napAm: "Thạch Lựu Mộc",
      gioTot: [this.Chi.Thin, this.Chi.Ti, this.Chi.Mui],
      gioXau: [
        this.Chi.Dan,
        this.Chi.Mao,
        this.Chi.Ngo,
        this.Chi.Dau,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },

    {
      tenque: "Lôi Sơn Tiểu Quá\n(Less Exceeding)",
      stt: 58,
      que: "Xin You",
      queViet: "Tân Dậu",
      xungTuoi: "Ất Mão,Đinh Mão",

      cordCompass: "290deg",

      napAm: "Thạch Lựu Mộc",
      gioTot: [this.Chi.Dan, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Suu,
        this.Chi.Thin,
        this.Chi.Than,
        this.Chi.Tuat,
        this.Chi.Hoi,
      ],
      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Trạch Địa Tụy\n(Gathering)",
      stt: 59,
      que: "Ren Xu",
      queViet: "Nhâm Tuất",
      xungTuoi: "Mậu Thìn,Bính Thìn",

      cordCompass: "323.5deg",

      napAm: "Đại Hải Thủy",
      gioTot: [this.Chi.Ti, this.Chi.Than],
      gioXau: [this.Chi.Ty, this.Chi.Suu, this.Chi.Mao, this.Chi.Tuat],
      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Địa Bác\n(Exfoliating)",
      stt: 60,
      que: "Gui Hai",
      queViet: "Quý Hợi",
      xungTuoi: "Đinh Tỵ,Kỷ Tỵ",

      cordCompass: "351.5deg",

      napAm: "Đại Hải Thủy",
      gioTot: [this.Chi.Thin, this.Chi.Ngo, this.Chi.Mui],
      gioXau: [
        this.Chi.Ty,
        this.Chi.Dan,
        this.Chi.Ti,
        this.Chi.Than,
        this.Chi.Dau,
      ],
      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
  ];
  static QueSortConGiap = [
    {
      tenque: `Khôn Vị Địa \n(Earth)`,
      stt: 1,
      que: "Jia Zi",
      queViet: "Giáp Tý",
      xungTuoi: "Canh Ngọ,Mậu Ngọ",
      cordCompass: "357.5deg",
      napAm: "Hải Trung Kim",
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Lôi Phục \n(Earth)",
      stt: 1,
      que: "Jia Zi",
      cordCompass: "2.5deg",
      queViet: "Giáp Tý",
      xungTuoi: "Canh Ngọ,Mậu Ngọ",
      napAm: "Hải Trung Kim",
      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Lôi Di\n(Nurture)",
      stt: 13,
      que: "Bing Zi",
      queViet: "Bính Tý",
      xungTuoi: "Canh Ngọ,Nhâm Ngọ",

      napAm: "Giản Hạ Thủy",
      cordCompass: "9deg",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Lôi Truân\n(Sprouting)",
      stt: 25,
      que: "Wu Zi",
      queViet: "Mậu Tý",
      xungTuoi: "Giáp Ngọ,Nhâm Ngọ",

      napAm: "Tích Lịch Hỏa",
      cordCompass: "14deg",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Phong Lôi Ích\n(Benefiting)",
      stt: 36,
      que: "Geng Zi",
      queViet: "Canh Tý",
      xungTuoi: "Giáp Ngọ,Bính Ngọ",

      cordCompass: "20deg",

      napAm: "Bích Thượng Thổ",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Chấn Vi Lôi\n(Thunder)",
      stt: 49,
      que: "Ren Zi",
      queViet: "Nhâm Tý",
      xungTuoi: "Mậu Ngọ,Bính Ngọ",

      cordCompass: "26deg",

      napAm: "Tang Đố Mộc",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Lôi Phệ Hạp\n(Chewing)",
      stt: 2,
      que: "Yi Chou",
      queViet: "Ất Sửu",
      xungTuoi: "Tân Mùi,Kỷ Mùi",

      napAm: "Hải Trung Kim",
      cordCompass: "31deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Lôi Tùy\n(Following)",
      stt: 14,
      que: "Ding Chou",
      queViet: "Đinh Sửu",
      xungTuoi: "Tân Mùi,Quý Mùi",

      napAm: "Giản Hạ Thủy",
      cordCompass: "37deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Lôi Vô Vọng\n(WithoutEmbroiling)",
      stt: 26,
      que: "Ji Chou",
      queViet: "Kỷ Sửu",
      xungTuoi: "Ất Mùi,Quý Mùi",

      napAm: "Tích Lịch Hỏa",
      cordCompass: "42deg",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Địa Hỏa Minh Di\n(Lackluster)",
      stt: 37,
      que: "Xin Chou",
      queViet: "Tân Sửu",
      xungTuoi: "Ất Mùi,Đinh Mùi",

      cordCompass: "48deg",

      napAm: "Bích Thượng Thổ",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Hỏa bí\n(Beautifying)",
      stt: 50,
      que: "Gui Chou",
      queViet: "Quý Sửu",
      xungTuoi: "Đinh Mùi,Kỷ Mùi",

      cordCompass: "52.5deg",

      napAm: "Tang Đố Mộc",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Hỏa Ký Tế\n(Success)",
      stt: 51,
      que: "Jia Yin",
      queViet: "Giáp Dần",
      xungTuoi: "Canh Thân,Mậu Thân",

      cordCompass: "59deg",

      napAm: "Đại Khê Thủy",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Phong Hỏa Gia Nhân\n(Family)",
      stt: 3,
      que: "Bing Yin",
      queViet: "Bính Dần",
      xungTuoi: "Canh Thân,Nhâm Thân",

      cordCompass: "65deg",

      napAm: "Lư Trung Hỏa",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Hỏa Phong\n(Abundance)",
      stt: 15,
      que: "Wu Yin",
      queViet: "Mậu Dần",
      xungTuoi: "Giáp Thân,Nhâm Thân",

      napAm: "Thành Đầu Thổ",
      cordCompass: "70deg",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Ly Vi Hỏa\n(Fire)",
      stt: 27,
      que: "Geng Yin",
      queViet: "Canh Dần",
      xungTuoi: "Giáp Thân,Bính Thân",

      napAm: "Tùng Bách Mộc",
      cordCompass: "76deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Hỏa Cách\n(Fire)",
      stt: 27,
      que: "Geng Yin",
      queViet: "Canh Dần",
      xungTuoi: "Giáp Thân,Bính Thân",

      cordCompass: "82deg",

      napAm: "Tùng Bách Mộc",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Hỏa Đồng Nhân (Fraternity)",
      stt: 38,
      que: "Ren Yin",
      queViet: "Nhâm Dần",
      xungTuoi: "Mậu Thân,Bính Thân",

      cordCompass: "87deg",

      napAm: "Kim Bạc Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, true, true],
    },
    {
      tenque: "Địa Trạch Lâm\n(Approaching)",
      stt: 52,
      que: "Yi Mao",
      queViet: "Ất Mão",
      xungTuoi: "Tân Dậu,Kỷ Dậu",

      cordCompass: "93deg",

      napAm: "Đại Khê Thủy",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Trạch Tổn\n(Diminishing)",
      stt: 4,
      que: "Ding Mao",
      queViet: "Đinh Mão",
      xungTuoi: "Tân Dậu,Quý Dậu",

      cordCompass: "98deg",

      napAm: "Lư Trung Hỏa",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Trạch Tiết\n(Regulating)",
      stt: 16,
      que: "Ji Mao",
      queViet: "Kỷ Mão",
      xungTuoi: "Ất Dậu,Quý Dậu",

      napAm: "Thành Đầu Thổ",
      cordCompass: "104deg",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Phong Trạch Trung Phu (Trustworthy)",
      stt: 27,
      que: "Xin Mao",
      queViet: "Tân Mão",
      xungTuoi: "Ất Dậu,Đinh Dậu",

      napAm: "Tùng Bách Mộc",
      cordCompass: "110deg",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Trạch Quy Muội\n(Marrying)",
      stt: 39,
      que: "Gui Mao",
      queViet: "Quý Mão",
      xungTuoi: "Đinh Dậu,Kỷ Dậu",

      cordCompass: "115deg",

      napAm: "Kim Bạc Kim",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Trạch Khuê\n(Conflicting)",
      stt: 40,
      que: "Jia Chen",
      queViet: "Giáp Thìn",
      xungTuoi: "Canh Tuất,Mậu Tuất",

      cordCompass: "121deg",

      napAm: "Phúc Đăng Hỏa",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Đoài Vi Trạch\n(Marsh)",
      stt: 53,
      que: "Bing Chen",
      queViet: "Bính Thìn",
      xungTuoi: "Canh Tuất,Nhâm Tuất",

      cordCompass: "126.5deg",

      napAm: "Sa Trung Thổ",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Trạch Lý\n(Treading)",
      stt: 5,
      que: "Wu Chen",
      queViet: "Mậu Thìn",
      xungTuoi: "Giáp Tuất,Nhâm Tuất",

      cordCompass: "132.5deg",

      napAm: "Đại Lâm Mộc",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Địa Thiên Thái\n(Greatness)",
      stt: 17,
      que: "Geng Chen",
      queViet: "Canh Thìn",
      xungTuoi: "Giáp Tuất,Bính Tuất",

      napAm: "Bạch Lạp Kim",
      cordCompass: "138deg",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Sơn Thiên Đại Súc\n(Big Accruing)",
      stt: 28,
      que: "Ren Chen",
      queViet: "Nhâm Thìn",
      xungTuoi: "Mậu Tuất,Bính Tuất",

      cordCompass: "143deg",

      napAm: "Trường Lưu Thủy",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thủy Thiên Nhu\n(Waiting)",
      stt: 41,
      que: "Yi Si",
      queViet: "Ất Tỵ",
      xungTuoi: "Tân Hợi,Kỷ Hợi",

      cordCompass: "149deg",

      napAm: "Phúc Đăng Hỏa",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Phong Thiên Tiểu Súc(SmallAccruing)",
      stt: 54,
      que: "Ding Si",
      queViet: "Đinh Tỵ",
      xungTuoi: "Tân Hợi,Quý Hợi",

      cordCompass: "155deg",

      napAm: "Sa Trung Thổ",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Lôi Thiên Đại Tráng\n(Invigorating)",
      stt: 6,
      que: "Ji Si",
      queViet: "Kỷ Tỵ",
      xungTuoi: "Ất Hợi,Quý Hợi",

      cordCompass: "160deg",

      napAm: "Đại Lâm Mộc",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Hỏa Thiên Đại Hũu\n(Great Harvest)",
      stt: 18,
      que: "Xin Si",
      queViet: "Tân Tỵ",
      xungTuoi: "Ất Hợi,Đinh Hợi",

      napAm: "Bạch Lạp Kim",
      cordCompass: "169deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Trạch Thiên Quải\n(Eradicating)",
      stt: 29,
      que: "Gui Si",
      queViet: "Quý Tỵ",
      xungTuoi: "Đinh Hợi,Kỷ Hợi",

      napAm: "Trường Lưu Thủy",
      cordCompass: "172deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Càn Vi Thiên\n(Heaven)",
      stt: 30,
      que: "Jia Wu",
      queViet: "Giáp Ngọ",
      xungTuoi: "Canh Tý,Mậu Tý",

      cordCompass: "177deg",

      napAm: "Sa Trung Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, true, true],
    },
    {
      tenque: "Thiên Phong Cấu\n(Heaven)",
      stt: 30,
      que: "Jia Wu",
      queViet: "Giáp Ngọ",
      xungTuoi: "Canh Tý,Mậu Tý",

      cordCompass: "183deg",

      napAm: "Sa Trung Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Trạch Phong Đại Quá (Exceedingly)",
      stt: 42,
      que: "Bing Wu",
      queViet: "Bính Ngọ",
      xungTuoi: "Canh Tý,Nhâm Tý",

      cordCompass: "188deg",

      napAm: "Thiên Hà Thủy",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Phong Đỉnh\n(Holding)",
      stt: 55,
      que: "Wu Wu",
      queViet: "Mậu Ngọ",
      xungTuoi: "Giáp Tý,Nhâm Tý",

      cordCompass: "164deg",

      napAm: "Thiên Thượng Hỏa",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Lôi Phong Hằng\n(Constancy)",
      stt: 7,
      que: "Geng Wu",
      queViet: "Canh Ngọ",
      xungTuoi: "Giáp Tý,Bính Tý",

      napAm: "Lộ Bàng Thổ",
      cordCompass: "199.5deg",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Tốn Vi Phong\n(Wind)",
      stt: 19,
      que: "Ren Wu",
      queViet: "Nhâm Ngọ",
      xungTuoi: "Mậu Tý,Bính Tý",

      napAm: "Dương Liễu Mộc",
      cordCompass: "205deg",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thủy Phong Tỉnh\n(Well)",
      stt: 31,
      que: "Yi Wei",
      queViet: "Ất Mùi",
      xungTuoi: "Tân Sửu,Kỷ Sửu",

      cordCompass: "214deg",

      napAm: "Sa Trung Kim",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Phong Cổ\n(Corrupting)",
      stt: 43,
      que: "Ding Wei",
      queViet: "Đinh Mùi",
      xungTuoi: "Tân Sửu,Quý Sửu",

      cordCompass: "216deg",

      napAm: "Thiên Hà Thủy",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Phong Thăng\n(Ascending)",
      stt: 56,
      que: "Ji Wei",
      queViet: "Kỷ Mùi",
      xungTuoi: "Ất Sửu,Quý Sửu",

      cordCompass: "128deg",

      napAm: "Thiên Thượng Hỏa",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thiên Thủy Tụng\n(Arguing)",
      stt: 8,
      que: "Xin Wei",
      queViet: "Tân Mùi",
      xungTuoi: "Ất Sửu,Đinh Sửu",

      napAm: "Lộ Bàng Thổ",
      cordCompass: "232.5deg",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Trạch Thủy Khốn\n(Trapped)",
      stt: 20,
      que: "Gui Wei",
      queViet: "Quý Mùi",
      xungTuoi: "Đinh Sửu,Kỷ Sửu",

      napAm: "Dương Liễu Mộc",
      cordCompass: "233deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Thủy Vị Tế\n(Unaccomplished)",
      stt: 21,
      que: "Jia Shen",
      queViet: "Giáp Thân",
      xungTuoi: "Canh Dần,Mậu Dần",

      napAm: "Tuyền Trung Thủy",
      cordCompass: "239deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Lôi Thủy Giải\n(Relief)",
      stt: 32,
      que: "Bing Shen",
      queViet: "Bính Thân",
      xungTuoi: "Canh Dần,Nhâm Dần",

      cordCompass: "245deg",

      napAm: "Sơn Hạ Hỏa",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Thủy Hoán\n(Dispersing)",
      stt: 44,
      que: "Wu Shen",
      queViet: "Mậu Thân",
      xungTuoi: "Giáp Dần,Nhâm Dần",

      cordCompass: "250deg",

      napAm: "Đại Dịch Thổ",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Sơn Thủy Mông\n(Water)",
      stt: 57,
      que: "Geng Shen",
      queViet: "Canh Thân",
      xungTuoi: "Giáp Dần,Bính Dần",

      cordCompass: "262deg",

      napAm: "Thạch Lựu Mộc",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Khảm Vi Thủy\n(Water)",
      stt: 57,
      que: "Geng Shen",
      queViet: "Canh Thân",
      xungTuoi: "Giáp Dần,Bính Dần",

      cordCompass: "256deg",

      napAm: "Thạch Lựu Mộc",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 1,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Thủy Sư\n(Troop)",
      stt: 9,
      que: "Ren Shen",
      queViet: "Nhâm Thân",
      xungTuoi: "Mậu Dần,Bính Dần",

      napAm: "Kiếm Phong Kim",
      cordCompass: "267deg",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, true, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thiên Sơn Độn\n(Retreat)",
      stt: 22,
      que: "Yi You",
      queViet: "Ất Dậu",
      xungTuoi: "Tân Mão,Kỷ Mão",

      napAm: "Tuyền Trung Thủy",
      cordCompass: "273deg",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 4,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Trạch Sơn Hàm\n(Conjoining)",
      stt: 33,
      que: "Ding You",
      queViet: "Đinh Dậu",
      xungTuoi: "Tân Mão,Quý Mão",

      napAm: "Sơn Hạ Hỏa",
      cordCompass: "278deg",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 9,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Hỏa Sơn Lữ\n(Traveling)",
      stt: 46,
      que: "Ji You",
      queViet: "Kỷ Dậu",
      xungTuoi: "Ất Mão,Quý Mão",

      cordCompass: "284deg",

      napAm: "Đại Dịch Thổ",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 8,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Lôi Sơn Tiểu Quá\n(Less Exceeding)",
      stt: 58,
      que: "Xin You",
      queViet: "Tân Dậu",
      xungTuoi: "Ất Mão,Đinh Mão",

      cordCompass: "290deg",

      napAm: "Thạch Lựu Mộc",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 3,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Phong Sơn Tiệm\n(Gradual Progress)",
      stt: 10,
      que: "Gui You",
      queViet: "Quý Dậu",
      xungTuoi: "Đinh Mão,Kỷ Mão",

      napAm: "Kiếm Phong Kim",
      cordCompass: "295deg",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 7,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thủy Sơn Kiển\n(Impeding)",
      stt: 11,
      que: "Jia Xu",
      queViet: "Giáp Tuất",
      xungTuoi: "Canh Thìn,Mậu Thìn",

      napAm: "Sơn Đầu Hỏa",
      cordCompass: "301deg",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 2,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Cấn Vi Sơn\n(Mountain)",
      stt: 23,
      que: "Bing Xu",
      queViet: "Bính Tuất",
      xungTuoi: "Canh Thìn,Nhâm Thìn",

      napAm: "Ốc Thượng Thổ",
      cordCompass: "307deg",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 1,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Địa Sơn Khiêm\n(Humbling)",
      stt: 34,
      que: "Wu Xu",
      queViet: "Mậu Tuất",
      xungTuoi: "Giáp Thìn,Nhâm Thìn",

      cordCompass: "312deg",

      napAm: "Bình Địa Mộc",

      huyenKhongNguHanh: 1,
      huyenKhongQuaiVan: 6,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, true, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Thiên Địa Bĩ\n(Obstruction)",
      stt: 47,
      que: "Geng Xu",
      queViet: "Canh Tuất",
      xungTuoi: "Giáp Thìn,Bính Thìn",

      cordCompass: "318deg",

      napAm: "Thoa Xuyến Kim",

      huyenKhongNguHanh: 9,
      huyenKhongQuaiVan: 9,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
    {
      tenque: "Trạch Địa Tụy\n(Gathering)",
      stt: 59,
      que: "Ren Xu",
      queViet: "Nhâm Tuất",
      xungTuoi: "Mậu Thìn,Bính Thìn",

      cordCompass: "323.5deg",

      napAm: "Đại Hải Thủy",

      huyenKhongNguHanh: 4,
      huyenKhongQuaiVan: 4,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Hỏa Địa Tấn\n(Advancing)",
      stt: 12,
      que: "Yi Hai",
      queViet: "Ất Hợi",
      xungTuoi: "Tân Tỵ,Kỷ Tỵ",

      napAm: "Sơn Đầu Hỏa",
      cordCompass: "329deg",

      huyenKhongNguHanh: 3,
      huyenKhongQuaiVan: 3,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Lôi Địa Dự\n(Elated)",
      stt: 24,
      que: "Ding Hai",
      queViet: "Đinh Hợi",
      xungTuoi: "Tân Tỵ,Quý Tỵ",

      napAm: "Ốc Thượng Thổ",
      cordCompass: "335deg",

      huyenKhongNguHanh: 8,
      huyenKhongQuaiVan: 8,
      row1: [true, false, true],
      row2: [true, false, true],
      row3: [true, true, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Phong Địa Quan\n(Observing)",
      stt: 35,
      que: "Ji Hai",
      queViet: "Kỷ Hợi",
      xungTuoi: "Ất Tỵ,Quý Tỵ",

      cordCompass: "340deg",

      napAm: "Bình Địa Mộc",

      huyenKhongNguHanh: 2,
      huyenKhongQuaiVan: 2,
      row1: [true, true, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Thủy Địa Tỷ\n(Alliance)",
      stt: 48,
      que: "Xin Hai",
      queViet: "Tân Hợi",
      xungTuoi: "Ất Tỵ,Đinh Tỵ",

      cordCompass: "346deg",

      napAm: "Thoa Xuyến Kim",

      huyenKhongNguHanh: 7,
      huyenKhongQuaiVan: 7,
      row1: [true, false, true],
      row2: [true, true, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },

    {
      tenque: "Sơn Địa Bác\n(Exfoliating)",
      stt: 60,
      que: "Gui Hai",
      queViet: "Quý Hợi",
      xungTuoi: "Đinh Tỵ,Kỷ Tỵ",

      cordCompass: "351.5deg",

      napAm: "Đại Hải Thủy",

      huyenKhongNguHanh: 6,
      huyenKhongQuaiVan: 6,
      row1: [true, true, true],
      row2: [true, false, true],
      row3: [true, false, true],
      row4: [true, false, true],
      row5: [true, false, true],
      row6: [true, false, true],
    },
  ];
  static nhiThapBatTu = [
    { code: 0, name: "Giác", good: true, additional: ",riêng mai táng xấu" },
    { code: 1, name: "Cang", good: false, additional: "" },
    { code: 2, name: "Đê", good: false, additional: "" },
    { code: 3, name: "Phòng", good: true, additional: "" },
    { code: 4, name: "Tâm", good: false, additional: "" },
    { code: 5, name: "Vĩ", good: true, additional: "" },
    { code: 6, name: "Cơ", good: true, additional: "" },
    { code: 7, name: "Đẩu", good: true, additional: "" },
    { code: 8, name: "Ngưu", good: false, additional: "" },
    { code: 9, name: "Nữ", good: false, additional: "" },
    { code: 10, name: "Hư", good: false, additional: "" },
    { code: 11, name: Const.trucSao.Nguy.name, good: false, additional: "" },
    { code: 12, name: "Thất", good: true, additional: "" },
    { code: 13, name: "Bích", good: true, additional: "" },
    { code: 14, name: "Khuê", good: false, additional: ",riêng xây dựng tốt" },
    { code: 15, name: "Lâu", good: true, additional: "" },
    { code: 16, name: "Vị", good: true, additional: "" },
    { code: 17, name: "Mão", good: false, additional: "" },
    { code: 18, name: "Tất", good: true, additional: "" },
    { code: 19, name: "Chủy", good: false, additional: ",riêng xây dựng tốt" },
    { code: 20, name: "Sâm", good: false, additional: ",riêng xây dựng tốt" },
    { code: 21, name: "Tỉnh", good: true, additional: "" },
    { code: 22, name: "Quỷ", good: false, additional: ",riêng mai táng tốt" },
    { code: 23, name: "Liễu", good: false, additional: "" },
    { code: 24, name: "Tinh", good: false, additional: ",riêng xây dựng tốt" },
    { code: 25, name: "Trương", good: true, additional: "" },
    { code: 26, name: "Dực", good: false, additional: "" },
    { code: 27, name: "Chẩn", good: true, additional: "" },
  ];
  static HoangDao = {
    thanhLong: { code: 1, Name: "Thanh Long", isHoangDao: true },
    minhDuong: { code: 2, Name: "Minh Đường", isHoangDao: true },
    kimQuy: { code: 3, Name: "Kim Quỹ", isHoangDao: true },
    thienDuc: { code: 4, Name: "Thiên Đức", isHoangDao: true },
    ngocDuong: { code: 5, Name: "Ngọc Đường", isHoangDao: true },
    tuMenh: { code: 6, Name: "Tư Mệnh", isHoangDao: true },
    thienHinh: { code: 7, Name: "Thiên Hình", isHoangDao: false },
    chuTuoc: { code: 8, Name: "Chu Tước", isHoangDao: false },
    bachHo: { code: 9, Name: "Bạch Hổ", isHoangDao: false },
    thienLao: { code: 10, Name: "Thiên Lao", isHoangDao: false },
    huyenVu: { code: 11, Name: "Huyền Vũ", isHoangDao: false },
    cauTran: { code: 12, Name: "Câu Trần", isHoangDao: false },
  };
  static saoThanSatThangThienDucToTaiSat = {
    thangTy: [
      "Tỵ",
      "Thân",
      "Nhâm",
      "Đinh",
      "Đông Nam",
      "Bính",
      "Ngọ",
      "Tý",
      "Tỵ",
      "Ngọ",
    ],
    thangSuu: [
      "Canh",
      "Ất",
      "Canh",
      "Ất",
      "Tây",
      "Giáp",
      "Mùi",
      "Hợi",
      "Dần",
      "Mão",
    ],
    thangDan: [
      "Đinh",
      "Nhâm",
      "Bính",
      "Tân",
      "Nam",
      "Nhâm",
      "Thân",
      "Tuất",
      "Hợi",
      "Tý",
    ],
    thangMao: [
      "Thân",
      "Tỵ",
      "Giáp",
      "Tỵ",
      "Tây Nam",
      "Canh",
      "Dậu",
      "Dậu",
      "Thân",
      "Dậu",
    ],
    thangThin: [
      "Nhâm",
      "Đinh",
      "Nhâm",
      "Đinh",
      "Bắc",
      "Bính",
      "Tuất",
      "Thân",
      "Tỵ",
      "Ngọ",
    ],
    thangTi: [
      "Tân",
      "Bính",
      "Canh",
      "Ất",
      "Tây",
      "Giáp",
      "Hợi",
      "Mùi",
      "Dần",
      "Mão",
    ],
    thangNgo: [
      "Hợi",
      "Dần",
      "Bính",
      "Tân",
      "Tây Bắc",
      "Nhâm",
      "Tý",
      "Ngọ",
      "Hợi",
      "Tý",
    ],
    thangMui: [
      "Giáp",
      "Kỷ",
      "Giáp",
      "Kỷ",
      "Đông",
      "Canh",
      "Sửu",
      "Tỵ",
      "Thân",
      "Dậu",
    ],
    thangThan: [
      "Quý",
      "Mậu",
      "Nhâm",
      "Đinh",
      "Bắc",
      "Bính",
      "Dần",
      "Thìn",
      "Tỵ",
      "Ngọ",
    ],
    thangDau: [
      "Cấn",
      " ",
      "Canh",
      "Ất",
      "Đông Bắc",
      "Giáp",
      "Mão",
      "Mão",
      "Dần",
      "Mão",
    ],
    thangTuat: [
      "Bính",
      "Tân",
      "Bính",
      "Tân",
      "Nam",
      "Nhâm",
      "Thìn",
      "Dần",
      "Hợi",
      "Tý",
    ],
    thangHoi: [
      "Ất",
      "Canh",
      "Giáp",
      "Kỷ",
      "Đông",
      "Canh",
      "Tỵ",
      "Sửu",
      "Thân",
      "Dậu",
    ],
  };
  static kiengKiTrongNgay = [
    // tháng sửu
    [
      {
        catThan:
          "Nguyệt Không,Thiên Ân,Thiên Xá,Thiên Nguyệt,Tứ Tướng,Quan Nhật,Lục Hợp,Tục Thế,Thiên Y",
        hungThan:
          "Thiên Lại,Chí Tử,Huyết Chi,Thổ Phù,Qui Kị,Huyết Kị,Thiên Hình,Phục Binh,Thiên Sứ,Hoàng Sa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát",
        nen: "An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Binh Cát,Binh Phúc,Binh Bảo,Nguyệt Đức Hợp,Thiên Ân,Tứ Tướng,Thủ Nhật,Yếu An",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Đại Họa,Tiểu Hồng Sa,Thổ Kỵ,Thổ Phủ,Vãng Vong,Chu Tước",
        nen: "Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Ân,Thiên Tài,Binh Cát,Binh Phúc,Binh Bảo,Thời Đức,Tướng Nhật,Cát Kì,Bất Tương,Ngọc Vũ,Ngũ Hợp,Kim Quĩ,Ô Phệ Đối",
        hungThan: "Kiếp Sát,Thiên Tặc,Ngũ Hư",
        nen: "",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Ân,Địa Tài,Lộc Khố,Thiên Phú,Kim Đường,Binh Phúc,Binh Bảo,Binh Cát,Dân Nhật,Thiên Vu,Phúc Đức,Thiên Thương,Bất Tương,Kim Quĩ,Ngũ Hợp,Bảo Quang,Ô Phệ Đối",
        hungThan: "Tai Sát,Thiên Hỏa,Thiên Ôn,Phi Ma,Thổ Ôn,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Thiên Mã,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Hà Khôi,Tử Thần,Nguyệt Sát,Nguyệt Hư,Ngũ Mộ,Thiên Bảng,Tiểu Hao,Tai Sát,Câu Giảo,Ngõa Toái,Tứ Đại Mộ,Bạch Hổ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Tam Hợp,Thời Âm,Lục Nghi,Ngọc Đường",
        hungThan:
          "Yếm Đối,Chiêu Dao,Tử Khí,Cửu Khảm,Cửu Tiêu,Phục Nhật,Trùng Nhật,Tử Phù,Đại Hao,Trùng Nhật,Trùng Tang,Tứ Phế",
        nen: "Tu Tạo Động Thổ,Lập Ước Giao Dịch",
        khongNen: "Xuất Hành,Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Minh Tinh,Kính An,Thủ Nhật,Nguyệt Đức,Kính An,Giải Thần,Ô Phệ",
        hungThan:
          "Nguyệt Hại,Nguyệt Hỏa,Mộc Mã,Phá Bại,Hoang Vu,Thiên Địa Chuyển,Tứ Phế,Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Thiên Hình",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng",
        khongNen: "",
      },

      {
        catThan: "Nguyệt Ân,Phổ Hộ",
        hungThan:
          "Nguyệt Phá,Đại Hao,Nguyệt Hư,Thần Cách,Nguyệt Sát,Bát Thành,Tứ Kích,Cửu Không,Huyền Vũ",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Thiên Quan,Phúc Sinh,Hoạt Nhãn,Thiên Quý,Đại Hồng Sa,Dương Đức,Ngũ Phú,Phúc Sinh,Trừ Thần,Tư Mệnh,Ô Phệ",
        hungThan:
          "Du Họa,Ngũ Li,Lôi Công,Khô Tiêu,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ",
        nen: "Khai Trương,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Lập Ước Giao Dịch",
      },

      {
        catThan:
          "Mẫu Thương,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Trừ Thần,Ô Phệ,Hỉ Thần,Thiên Quý",
        hungThan:
          "Địa Nang,Đại Sát,Ngũ Li,Câu Trần,Thiên Lao,Thiên Hỏa,Thọ Tử,Câu Trần,Lâm Nhật,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Nguyệt Không,Đại Hồng Sa,Tứ Tướng,Thánh Tâm,Thanh Long",
        hungThan:
          "Thiên Cương,Phục Binh,Thiên Cẩu Nhật Thời,Ngũ Quỷ,Bát Tòa,Hoang Vu,Nguyệt Hình,Ngũ Hư,Bát Phong",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo,Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Tứ Tướng,Âm Đức,Vương Nhật,Dịch Mã,Nguyệt Tài,Ích Hậu, m Đức,Phúc Hậu,Thiên Hậu,Thời Dương,Sinh Khí,Ích Hậu,Minh Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Trùng Nhật,Đại Hỏa,Thiên Cẩu Nhật Thời,Thiên Tặc,Địa Hỏa,Nhân Cách",
        nen: "Dâng Biểu Sớ,Tu Tạo Động Thổ,Khai Trương,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Xuất Hành,Cưới Gả",
      },

      {
        catThan: "Quan Nhật,Lục Hợp,Thiên Y,Bất Tương,Tục Thế,Ô Phệ Đối",
        hungThan:
          "Thiên Lại,Chí Tử,Huyết Chi,Thiên Sứ,Hoàng Sa,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế,Phu Sát,Thổ Phù,Qui Kị,Huyết Kị,Xúc Thủy Long,Thiên Hình",
        nen: "An Táng,Cải Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Thủ Nhật,Bất Tương,Yếu An,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Vãng Vong,Chu Tước,Tiểu Hồng Sa,Thổ Phủ,Thổ Kỵ,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thời Đức,Thiên Tài,Ngọc Vũ,Binh Cát,Binh Phúc,Binh Bảo,Tướng Nhật,Cát Kì,Ngọc Đường,Ngũ Hợp,Kim Quỹ",
        hungThan: "Kiếp Sát,Thiên Tặc,Ngũ Hư,Địa Tặc,Phi Liêm,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Địa Tài,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo,Dân Nhật,Thiên Vu,Phúc Đức,Thiên Thương,Bất Tương,Kim Đường,Ngũ Hợp,Bảo Quang",
        hungThan:
          "Tai Sát,Thiên Hỏa,Phục Nhật,Thiên Ôn,Phi Ma,Trùng Nhật,Trùng Tang,Thổ Ôn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Binh Cát,Binh Phúc,Binh Bảo,Nguyệt Đức,Thiên Ân,Thiên Mã,Bất Tương",
        hungThan:
          "Hà Khôi,Thiên Bảng,Tiểu Hao,Ương Bại,Câu Giảo,Ngõa Toái,Thiên Địa Chuyển,Tứ Đại Mộ,Tử Thần,Nguyệt Sát,Nguyệt Hư,Bạch Hổ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Nguyệt Ân,Tam Hợp,Thời Âm,Lục Nghi,Ngọc Đường",
        hungThan: "Yếm Đối,Chiêu Dao,Tử Khí,Cửu Khảm,Cửu Tiêu,Trùng Nhật",
        nen: "Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Xuất Hành,Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Kính An,Giải Trừ,Ô Phệ,Minh Tinh,Thiên Quý,Thủ Nhật",
        hungThan:
          "Nguyệt Hại,Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Thiên Hình,Nguyệt Hỏa,Mộc Mã,Phá Bại,Thiên Địa Chuyển,Hoang Vu,Tứ Phế,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Phổ Hộ,Thiên Quý,Thiên Nguyện",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Kích,Cửu Không,Xúc Thủy Long,Huyền Vũ,Nguyệt Sát,Bất Thành",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Mẫu Thương,Tứ Tướng,Dương Đức,Ngũ Phú,Phúc Sinh,Trừ Thần,Tư Mệnh,Ô Phệ,Thiên Quan,Phúc Tinh,Đại Hồng Sa",
        hungThan: "Du Họa,Ngũ Li,Phục Binh,Lôi Công,Khô Tiêu,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Dộng Thổ,Khai Trương,An Táng",
        khongNen: "Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Mẫu Thương,Tứ Tướng,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Hỉ Thần",
        hungThan:
          "Đại Sát,Ngũ Li,Câu Trần,Đại Họa,Thiên Lao,Thiên Hỏa,Thọ Tử,Câu Trần,Lâm Nhật,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan: "Thánh Tâm,Thanh Long,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Nguyệt Hình,Ngũ Hư,Thiên Cẩu Nhật Thời,Ngũ Quỷ,Bát Tòa,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Âm Đức,Nguyệt Tài,Phúc Hậu,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Ích Hậu,Minh Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Trùng Nhật,Thiên Cẩu Nhật Thời,Thiên Tặc,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Quan Nhật,Thiên Y,Lục Hợp,Tục Thế",
        hungThan:
          "Thiên Lại,Chí Tử,Huyết Chi,Thổ Phù,Qui Kị,Huyết Kị,Thiên Hình,Trục Trận,Thiên Sứ,Hoàng Sa,Thiên Địa Chuyển,Nguyệt Chuyển,Phu Sát",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Thủ Nhật,Bất Tương,Yếu An,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Vãng Vong,Phục Nhật,Chu Tước,Tiểu Hồng Sa,Trùng Nhật,Trùng Tang,Thổ Phủ,Thổ Kỵ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Thiên Tài,Binh Cát,Binh Phúc,Binh Bảo,Nguyệt Đức,Thời Đức,Tướng Nhật,Cát Kì,Bất Tương,Ngọc Vũ,Ngũ Hợp,Kim Quĩ,Ô Phệ Đối",
        hungThan:
          "Kiếp Sát,Thiên Tặc,Ngũ Hư,Địa Tặc,Phi Liêm,Hoang Vu,Thiên Địa Chuyển",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,An Táng,Cải Táng",
        khongNen: "Xuất Hành,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Ân,Địa Tài,Lộc Khố,Nguyệt  n,Thiên Phú,Binh Cát,Binh Phú,Binh Bảo,Dân Nhật,Thiên Vu,Phúc Đức,Thiên Thương,Bất Tương,Kim Đường,Ngũ Hợp,Bảo Quang,Ô Phệ Đối",
        hungThan: "Tai Sát,Thiên Hỏa,Thiên Ôn,Phi Ma,Thổ Ôn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Mã,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Hà Khôi,Tử Khí,Nguyệt Sát,Nguyệt Hư,Bạch Hổ,Thiên Bảng,Tiểu Hao,Phá Bại,Câu Giảo,Ngõa Toái,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Tam Hợp,Âm Đức,Thiên Quý,Lục Nghi,Ngọc Đường",
        hungThan:
          "Yếm Đối,Tử Phù,Đại Hao,Tứ Phế,Chiêu Dao,Tử Khí,Cửu Khảm,Cửu Tiêu,Trùng Nhật",
        nen: "Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Xuất Hành,Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Minh Tinh,Thủ Nhật,Tứ Tướng,Kính An,Giải Thần,Ô Phệ",
        hungThan:
          "Phục Binh,Nguyệt Hỏa,Mộc Mã,Hoang Vu,Phá Bại,Tứ Phế,Nguyệt Hại,Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Thiên Lao",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Đức Hợp,Nguyệt Đức Hợp,Tứ Tướng,Phổ Hộ",
        hungThan:
          "Nguyệt Phá,Nguyệt Hư,Đại Họa,Thần Cách,Nguyệt Sát,Bát Thành,Đại Hao,Tứ Kích,Cửu Không,Huyền Vũ",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Dương Đức,Ngũ Phú,Phúc Sinh,Trừ Thần,Tư Mệnh,Ô Phệ,Thiên Quan,Phúc Tinh,Đại Hồng Sa",
        hungThan:
          "Du Họa,Ngũ Li,Lôi Công,Khô Tiêu,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm",
        nen: "Khai Trương,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Lập Ước Giao Dịch",
      },

      {
        catThan:
          "Mẫu Thương,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Trừ Thần,Ô Phệ,Hỉ Thần",
        hungThan:
          "Đại Sát,Ngũ Li,Câu Trần,Thiên Lao,Thiên Hỏa,Thọ Tử,Câu Trần,Lâm Nhật,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Thánh Tâm,Thanh Long,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Nguyệt Hình,Ngũ Hư,Nguyệt Hình,Thiên Cương,Thiên Cẩu Nhật Thời,Ngũ Quỷ,Bát Tòa,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước,Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Âm Đức,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Ích Hậu,Minh Đường,Thiên Mã,Nguyệt Tài,Ích Hậu,Phúc Hậu",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Phục Nhật,Trùng Nhật,Thiên Cẩu Nhật Thời,Thiên Tặc,Địa Hỏa,Nhân Cách,Trùng Tang",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Thiên Y,Lục Hợp,Nguyệt Đức,Quan Nhật,Lục Hợp,Bất Tương,Tục Thế,Ô Phệ Đối",
        hungThan:
          "Thiên Lại,Chí Tử,Huyết Chi,Thổ Phù,Qui Kị,Huyết Kị,Thiên Hình,Thiên Sứ,Hoàng Sa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát",
        nen: "An Táng,Cải Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Ân,Thủ Nhật,Bất Tương,Yếu An,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Vãng Vong,Chu Tước,Tiểu Hồng Sa,Thổ Phù,Thổ Kỵ",
        nen: "Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thời Đức,Tướng Nhật,Cát Kì,Ngọc Vũ,Ngũ Hợp,Kim Quĩ,Ô Phệ Đối,Thiên Tài,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Kiếp Sát,Thiên Tặc,Ngũ Hư,Địa Tặc,Phi Liêm,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Dân Nhật,Địa Tài,Lộc Khố,Thiên Phú,Kim Đường,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Thiên Vu,Phúc Đức,Thiên Hương,Kim Đường,Ngũ Hợp,Bảo Quang,Ô Phệ Đối",
        hungThan: "Tai Sát,Thiên Hỏa,Thiên Ôn,Phi Ma,Thổ Ôn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Không,Binh Cát,Binh Phúc,Binh Bảo,Tứ Tướng,Thiên Mã",
        hungThan:
          "Hà Khôi,Phục Binh,Thiên Bảng,Tiểu Hao,Ương Bại,Câu Giảo,Ngõa Toái,Tứ Đại Mộ,Chí Tử,Nguyệt Sát,Nguyệt Hư,Bạch Hổ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Tứ Tướng,Tam Hợp,Thời Âm,Lục Nghi,Ngọc Đường",
        hungThan:
          "Yếm Đối,Đại Họa,Tử Phù,Đại Hao,Tứ Phế,Chiêu Dao,Tử Khí,Cửu Khảm,Cửu Tiêu,Trùng Nhật",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Xuất Hành",
      },

      {
        catThan: "Kính An,Minh Tinh,Thủ Nhật,Giải Thần,Ô Phệ",
        hungThan:
          "Nguyệt Hại,Nguyệt Hỏa,Mộc Mã,Phá Bại,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Tứ Phế,Ngũ Hư,Thiên Lao",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Phổ Hộ",
        hungThan:
          "Nguyệt Phá,Nguyệt Hư,Thần Cách,Nguyệt Sát,Bất Thành,Bàng Phế,Tứ Phế,Đại Hao,Tứ Kích,Cửu Không,Bát Chuyên,Huyền Vũ,Dương Phá  m Xung",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Mẫu Thương,Thiên Quan,Phúc Tinh,Hoạt Nhãn,Đại Hồng Sa,Dương Đức,Ngũ Phú,Phúc Sinh,Trừ Thần,Tư Mệnh",
        hungThan: "Du Họa,Ngũ Hư,Lôi Công,Khô Tiêu,Đao Chẩn,Thổ Cấm",
        nen: "Khai Trương,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Lập Ước Giao Dịch",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Tam Hợp,Lâm Nhật,Thiên Kỉ,Thiên Y,Trừ Thần,Ô Phệ,Hỉ Thần",
        hungThan:
          "Đại Sát,Phục Nhật,Ngũ Li,Câu Trần,Thiên Lao,Thiên Hỏa,Thọ Tử,Câu Trần,Lâm Nhật,Trùng Tang,Trùng Nhật,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Đại Hồng Sa,Nguyệt Đức,Thiên Ân,Thánh Tâm,Thanh Long",
        hungThan:
          "Thiên Cương,Nguyệt Hình,Ngũ Hư,Thiên Cẩu Nhật Thời,Ngũ Quỷ,Bát Tòa,Hoang Vu,Thiên Địa Chuyển",
        nen: "",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Thiên Ân,Nguyệt Tài,Phúc Hậu,Nguyệt Ân,Âm Đức,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Ích Hậu,Minh Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Trùng Nhật,Thiên Cẩu Nhật Thời,Thiên Tặc,Địa Hỏa,Nhân Cách",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Quan Nhật,Lục Hợp,Tục Thế,Thiên Y,Thiên Quý",
        hungThan:
          "Thiên Lại,Chí Tử,Huyết Chi,Tứ Kị,Lục Xà,Thổ Phù,Qui Kị,Huyết Kị,Thiên Hình,Trục Trận,Thiên Sứ,Hoàng Sa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Thủ Nhật,Yếu An,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quan",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Vãng Vong,Bát Chuyên,Xúc Thủy Long,Chu Tước,Dương Thác,Tiểu Hồng Sa,Thổ Kỵ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước,Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Tứ Tướng,Thời Đức,Tướng Nhật,Cát Kì,Ngọc Vũ,Ngũ Hợp,Kim Quĩ,Ô Phệ Đối,Thiên Tài,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Kiếp Sát,Thiên Tặc,Ngũ Hư,Bát Phong,Bát Chuyên,Phục Binh,Địa Tặc,Phi Liêm,Hoang Vu",
        nen: "",
        khongNen: "Xuất Hành,Cưới Gả,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Địa Tài,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo,Nguyệt Đức Hợp,Tứ Tướng,Dân Nhật,Thiên Vu,Phúc Đức,Thiên Thương,Kim Đường,Ngũ Hợp,Bảo Quang,Ô Phệ Đối",
        hungThan: "Tai Sát,Thiên Hỏa,Địa Nang,Đại Họa,Thiên Ôn,Phi Ma,Thổ Ôn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tườn",
      },

      {
        catThan: "Thiên Mã,Bất Tương,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Hà Khôi,Tử Thần,Nguyệt Sát,Nguyệt Hư,Bạch Hổ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Tam Hợp,Thời Âm,Lục Nghi,Ngọc Đường",
        hungThan:
          "Yếm Đối,Chiêu Dao,Tử Khí,Tứ Phế,Cửu Khảm,Cửu Tiêu,Trùng Nhật,Tử Phù,Bàng Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Kính An,Giải Thần,Minh Tinh,Thủ Nhật",
        hungThan:
          "Nguyệt Hại,Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Thiên Lao,Mộc Mã,Ương Bại,Hoang Vu,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Phổ Hộ",
        hungThan:
          "Nguyệt Phá,Nguyệt Hư,Thần Cách,Huyền Vũ,Nguyệt Sát,Bất Thành,Trùng Nhật,Trùng Tang,Đại Hao,Tứ Kích,Cửu Không,Phục Nhật,Bát Chuyên,Huyền Vũ",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Thiên Quan,Phúc Tinh,Đại Hồng Sa,Nguyệt Đức,Mẫu Thương,Dương Đức,Ngũ Phú,Phúc Sinh,Trừ Thần,Tư Mệnh,Ô Phệ",
        hungThan:
          "Du Họa,Ngũ Li,Bát Chuyên,Lôi Công,Khô Tiêu,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Mẫu Thương,Nguyệt Ân,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Trừ Thần,Ô Phệ,Hỉ Thần",
        hungThan:
          "Tứ Hao,Đại Sát,Ngũ Li,Câu Trần,Thiên Lao,Thiên Hỏa,Thọ Tử,Câu Trần,Lâm Nhật,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan: "Thánh Tâm,Thanh Long,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Nguyệt Hình,Ngũ Hư,Thiên Cẩu Nhật Thời,Ngũ Quỷ,Bát Tòa,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "Cúng Tế,Đuổi Bắt,Săn Bắn",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Âm Đức,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Ích Hậu,Minh Đường,Nguyệt Tài,Ích Hậu,Thiên Quý,Phúc Hậu",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tứ Cùng,Lục Xà,Trùng Nhật,Đại Hội, m Thác,Thiên Cẩu Nhật Thời,Thiên Tặc,Địa Hỏa,Nhân Cách, m Thác",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },
    ],
    //Tháng dần
    [
      {
        catThan:
          "Thiên Ân,Mẫu Thương,Thời Dương,Sinh Khí,Ích Hậu,Thanh Long,Sinh Khí,Ích Hậu,Binh Cát,Binh Phúc,Thiên Quý,Mẫu Thương,Đại Hồng Sa",
        hungThan:
          "Tai Sát,Thiên Hỏa,Tứ Kị,Bát Long,Phục Nhật,Tai Sát,Thiên Ngục,Thiên Cẩu Nhật Thời,Thiên Hỏa,Phi Ma,Trùng Tang,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen: "Cưới Gả,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Tục Thế,Minh Đường,Thiên Y,Tục Thế,Binh Cát,Binh Phúc,Thiên Quý",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Thiên Tặc,Ngũ Hư,Thổ Phù,Qui Kị,Huyết Kị,Nguyệt Hư,Địa Tặc,Nguyệt Sát,Huyết Chi,Huyết Kỵ,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Ân,Nguyệt Ân,Tứ Tướng,Thiên Thương,Vương Nhật,Bất Tương,Yếu An,Ngũ Hợp,Ô Phệ Đối,Nguyệt Đức,Nguyệt Ân,Yếu An,Binh Cát,Binh Phúc,Binh Bảo,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Vãng Vong,Thiên Hình,Nguyệt Kiến,Bất Thành,Vãng Vong,Lôi Công,Thổ Phủ,Thổ Kỵ",
        nen: "Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Đức,Thiên Ân,Tứ Tướng,Quan Nhật,Cát Kì,Bất Tương,Ngọc Vũ,Ngũ Hợp,Ô Phệ Đối,Thiên Đức,Ngọc Tự,Binh Cát,Binh Phúc,Binh Bảo,Quan Nhật",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Chu Tước,Chu Tước,Ương Bại,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Thủ Nhật,Thiên Vu,Phúc Đức,Lục Nghi,Kim Đường,Kim Quĩ,Lộc Khố,Thiên Phú,Thiên Tài,Kim Đường",
        hungThan:
          "Yếm Đối,Thiên Tặc,Thổ Ôn,Khô Tiêu,Phu Sát,Chiếu Dao,Cửu Không,Cửu Khảm,Cửu Tiêu",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Tướng Nhật,Bảo Quang,Địa Tài,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hình,Nguyệt Hại,Du Họa,Ngũ Hư,Trùng Nhật,Nguyệt Hình,Nguyệt Hại,Thiên Cương,Nguyệt Hỏa,Thần Cách,Mộc Mã,Tiểu Hồng Sa,Tiểu Hao,Ngõa Toái,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thời Đức,Dân Nhật,Tam Hợp,Lâm Nhật,Thiên Mã,Thời Âm,Ô Phệ,Thiên Mã,Thiên Hợp,Nguyệt Tài,Dân Nhật",
        hungThan:
          "Tử Khí,Địa Nang,Bạch Hổ,Thiên Bảng,Tử Phù,Hoàng Sa,Đại Hao,Ngũ Quỷ,Lâm Nhật,Trùng Nhật,Bàng Phế,Tứ Phế.",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Nguyệt Đức Hợp,Kính An,Ngọc Đường,Nguyệt Đức Hợp,Kính An",
        hungThan:
          "Tiểu Hao,Thiên Ôn,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Tứ Địa Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Không,Dịch Mã,Thiên Hậu,Phổ Hộ,Giải Thần,Ô Phệ,Thiên Đức Hợp,Nguyệt Không,Dịch Mã,Minh Tinh,Phổ Hộ,Giải Thần",
        hungThan:
          "Nguyệt Phá,Đại Hao,Ngũ Li,Thiên Lao,Nguyệt Phá,Phục Binh,Phá Bại,Tứ Phế",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Âm Đức,Phúc Sinh,Trừ Thần,Ô Phệ,Phúc Tinh,Phúc Sinh,Âm Đức,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Ngũ Li,Huyền Vũ,Đại Họa,Thiên Sứ,Huyền Vũ,Nhân Cách,Hoang Vu,Thiên Địa Chuyển,Tứ Phế",
        nen: "An Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Dương Đức,Tam Hợp,Thiên Hỷ,Thiên Y,Tư Mệnh,Hỉ Thần,Thiên Quan,Tam Hợp,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tứ Kích,Đại Sát,Phục Nhật,Đại Hội,Nguyệt Yểm,Địa Hỏa,Thọ Tử,Phi Liêm,Trùng Tang",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Mẫu Thương,Thiên Nguyện,Lục Hợp,Ngũ Phú,Thánh Tâm,Lục Hợp,Ngũ Phú,Thánh Tâm,Thiên Quý,Mẫu Thương",
        hungThan:
          "Hà Khôi,Kiếp Sát,Tứ Cùng,Bát Long,Trùng Nhật,Câu Trần,Kiếp Sát,Thiên Cẩu Nhật Thời,Câu Trần,Câu Giảo,Hà Khôi,Bát Tòa,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức,Mẫu Thương,Nguyệt Ân,Tứ Tướng,Thời Dương,Sinh Khí,Bất Tương,Ích Hậu,Thanh Long,Ô Phệ Đối,Nguyệt Đức,Nguyệt Ân,Sinh Khí,Ích Hậu,Binh Cát Binh Phúc Binh Bảo,Mẫu Thương,Đại Hồng Sa",
        hungThan:
          "Tai Sát,Thiên Hỏa,Xúc Thủy Long,Tai Sát,Thiên Ngục,Thiên Cẩu Nhật Thời,Thiên Hỏa,Phi Ma,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức,Tứ Tướng,Bất Tương,Tục Thế,Minh Đường,Thiên Đức,Thiên Y,Tục Thế,Binh Cát,Binh Phúc,Binh Bảo.",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Thiên Tặc,Ngũ Hư,Bát Phong,Thổ Phù,Qui Kị,Huyết Kị,Nguyệt Hư,Địa Tặc,Nguyệt Sát,Huyết Chi,Huyết Kỵ,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Xá,Vượng Nhật,Thiên Thương,Yếu An,Ngũ Hợp,Yếu An,Binh Cát Binh Phúc Binh Bảo,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Vãng Vong,Thiên Hình,Nguyệt Kiến,Bất Thành,Vãng Vong,Lôi Công,Thổ Phủ,Thổ Kỵ",
        nen: "Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Ân,Quan Nhật,Cát Kì,Bất Tương,Ngọc Vũ,Ngũ Hợp,Ngọc Tử,Binh Cát Binh Phú Binh Bảo,Quan Nhật",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Chu Tước,Chu Tước,Ương Bại,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "Xuất Hành,Cưới Gả,Lập Ước Giao Dịch",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Thủ Nhật,Thiên Vu,Phúc Đức,Lục Nghi,Kim Đường,Kim Quĩ,Lộc Khố,Thiên Phú,Thiên Tài,Kim Đường",
        hungThan:
          "Yếm Đối,Chiêu Dao,Cửu Không,Cửu Khảm,Cửu Tiêu,Thiên Tặc,Trùng Nhật,Thổ Ôn,Khổ Tiêu,Bàng Phế,Tứ Phế,Phu Sát",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thiên Ân,Tướng Nhật,Bảo Quang,Nguyệt Đức Hợp,Địa Tài,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hình,Nguyệt Hại,Du Họa,Ngũ Hư,Trùng Nhật,Nguyệt Hình,Nguyệt Hại,Thiên Cương,Nguyệt Hỏa,Thần Cách,Mộc Mã,Tiểu Hồng Sa,Tiểu Hao,Ngõa Toái,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Xuất Hành",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Không,Thiên Ân,Thời Đức,Dân Nhật,Tam Hợp,Lâm Nhật,Thiên Mã,Thời Âm,Ô Phệ,Thiên Đức Hợp,Nguyệt Không,Thiên Mã,Tam Hợp,Nguyệt Tài,Dân Nhật",
        hungThan:
          "Tử Khí,Bạch Hổ,Phục Binh,Thiên Bảng,Tử Phù,Hoàng Sa,Đại Hao,Ngũ Quỷ,Trùng Nhật",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước,Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan: "Thiên Ân,Kính An,Ngọc Đường",
        hungThan:
          "Tiểu Hao,Xúc Thủy Long,Đại Họa,Thiên Ôn,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Dịch Mã,Thiện Hậu,Phổ Hộ,Giải Thần,Trừ Thần,Ô Phệ,Dịch Mã,Minh Tinh,Phổ Hộ,Giải Thần,Thiên Quý",
        hungThan:
          "Nguyệt Phá,Đại Hao,Phục Nhật,Ngũ Li,Thiên Lao,Nguyệt Phá,Phá Bại,Trùng Nhật,Tứ Phế",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Âm Đức,Phúc Sinh,Trừ Thần,Ô Phệ,Phúc Tinh,Phúc Sinh,Âm Đức,Thiên Quý,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Ngũ Li,Huyền Vũ,Thiên Sứ,Huyền Vũ,Nhân Cách,Thiên Địa Chuyển,Hoang Vu,Tứ Phế,Tứ Đại Mộ",
        nen: "An Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức,Nguyệt Ân,Tứ Tướng,Dương Đức,Tam Hợp,Thiên Hỉ,Thiên Y,Tư Mệnh,Nguyệt Đức,Hỉ Thần,Thiên Quan,Nguyệt Ân,Tam Hợp,Đại Hồng Sa",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tứ Kích,Đại Sát,Nguyệt Yểm,Địa Hỏa,Thọ Tử,Phi Liêm",
        nen: "Dâng Biểu Sớ,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Xuất Hành,Cưới Gả",
      },

      {
        catThan:
          "Thiên Đức,Mẫu Thương,Tứ Tướng,Lục Hợp,Ngũ Phúc,Bất Tương,Thánh Tâm,Thiên Đức,Lục Hợp,Ngũ Quỷ,Thánh Tâm,Mẫu Thương",
        hungThan:
          "Hà Khôi,Kiếp Sát,Trùng Nhật,Cầu Tần,Kiếp Sát,Thiên Cẩu Nhật Thời,Câu Trần,Câu Giảo,Hà Khôi,Bát Tòa,Đao Chấn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Mẫu Thương,Thời Dương,Sinh Khí,Ích Hậu,Thanh Long,Sinh Khí,Ích Hậu,Binh Cát,Binh Phúc,Thiên Quý,Mẫu Thương,Đại Hồng Sa",
        hungThan:
          "Tai Sát,Thiên Hỏa,Tai Sát,Thiên Ngục,Thiên Cẩu Nhật Thời,Thiên Hỏa,Phi Ma,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen: "Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan:
          "Bất Tương,Tục Thế,Minh Đường,Thiên Y,Tục Thế,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Thiên Tặc,Ngũ Hư,Thổ Phù,Qui Kị,Huyết Kị,Nguyệt Hư,Địa Tặc,Nguyệt Sa,Huyết Chi,Huyết Kỵ,Hoang Vu",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Vượng Nhật,Thiên Thương,Bất Tương,Yếu An,Ngũ Hợp,Ô Phệ Đối,Yếu An,Binh Cát Binh Phúc Binh Bảo,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Vãng Vong,Thiên Hình,Nguyệt Kiến,Bất Thành,Vãng Vong,Trùng Nhật,Lôi Công,Thổ Phủ,Thổ Kỵ,Bàng Phế,Tứ Phế",
        nen: "Lập Ước Giao Dịch,Nạp Tài",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Quan Nhật,Cát Kì,Bất Tương,Ngọc Vũ,Ngũ Hợp,Ô Phệ Đối,Nguyệt Đức Hợp,Ngọc Tử,Binh Cát Binh Phúc Binh Bảo,Quan Nhật",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Chu Tước,Ương Bại,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Không,Thủ Nhật,Thiên Vu,Phúc Đức,Lục Nghi,Kim Đường,Kim Quĩ,Thiên Đức Hợp,Nguyệt Không,Lộc Khố,Thiên Phú,Thiên Tài,Kim Đường",
        hungThan:
          "Yếm Đối,Chiêu Dao,Cửu Không,Cửu Khảm,Cửu Tiêu,Phục Binh,Thiên Tài,Thổ Ôn,Khô Tiêu,Phủ Sát",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan: "Tướng Nhật,Bảo Quang,Địa Tài,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hình,Nguyệt Hại,Du Họa,Ngũ Hư,Trùng Nhật,Nguyệt Hình,Nguyệt Hại,Đại Họa,Thiên Cương,Nguyệt Hỏa,Thần Cách,Mộc Mã,Tiểu Hồng Sa,Tiểu Hao,Ngõa Toái,Hoang Vu,Thiên Địa Chuyển",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thời Đức,Dân Nhật,Tam Hợp,Lâm Nhật,Thiên Mã,Thời Âm,Ô Phệ,Thiên Mã,Tam Hợp,Nguyệt Tài,Thiên Nguyện,Thiên Quý,Dân Nhật",
        hungThan:
          "Tử Khí,Phục Nhật,Bạch Hổ,Thiên Bảng,Tử Phù,Hoàng Sa,Đại Hao,Ngũ Quỷ,Lâm Nhật,Trùng Tang",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch",
        khongNen: "Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Kính An,Ngọc Đường,Kính An,Thiên Quý",
        hungThan: "Tiểu Hao,Ngũ Mộ,Thiên Ôn,Thiên Địa Chuyển,Tứ Địa Mộ",
        nen: "",
        khongNen:
          "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Nguyệt Ân,Tứ Tướng,Dịch Mã,Thiên Hậu,Phổ Hộ,Giải Thần,Trừ Thần,Ô Phệ,Nguyệt Đức,Nguyệt Ân,Dịch Mã,Minh Tinh,Phổ Hộ,Giải Thần",
        hungThan:
          "Nguyệt Phá,Đại Hao,Ngũ Li,Thiên Lao,Nguyệt Phá,Phá Bại,Tứ Phế",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Tứ Tướng,Âm Đức,Phúc Sinh,Trừ Thần,Ô Phệ,Thiên Đức,Phúc Tinh,Phúc Sinh,Âm Đức,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Ngũ Li,Huyền Vũ,Thiên Sứ,Huyền Vũ,Nhân Cách,Hoang Vu,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Dương Đức,Tam Hợp,Thiên Hỉ,Thiên Y,Tư Mệnh,Hỉ Thần,Thiên Quan,Tam Hợp,Đại Hồng Sa",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tử Kích,Đại Sát,Nguyệt Yểm,Địa Hỏa,Thọ Tử,Phi Liêm",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Lục Hợp,Ngũ Phú,Bất Tương,Thánh Tâm,Lục Hợp,Ngũ Phú,Thánh Tâm,Mẫu Thương",
        hungThan:
          "Hà Khôi,Kiếp Sát,Trùng Nhật,Câu Trần,Kiếp Sát,Thiên Cẩu Nhật Thời,Câu Trần,Câu Giảo,Hà Khôi,Bát Tòa,Đao Chẩn,Thổ Cấm",
        nen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Thời Dương,Sinh Khí,Bất Tương,Ích Hậu,Thanh Long,Ô Phệ Đối,Sinh Khí,Ích Hậu,Binh Cát,Binh Phúc,Binh Bảo,Mẫu Thương,Đại Hồng Sa",
        hungThan:
          "Tai Sát,Thiên Hỏa,Địa Nang,Tai Sát,Thiên Ngục,Thiên Cẩu Nhật Thời,Thiên Hỏa,Phi Ma,Trùng Nhật,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn",
        nen: "",
        khongNen: "Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Bất Tương,Tục Thế,Minh Đường,Nguyệt Đức Hợp, Thiên Y,Tục Thế,Binh Cát Binh Phúc Binh Bảo",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Thiên Tặc,Ngũ Hư,Thổ Phù,Qui Kị,Huyết Kị,Nguyệt Hư,Địa Tặc,Nguyêt Sát,Huyết Chi,Huyết Kỵ,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước,Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Không,Vương Nhật,Thiên Thương,Yếu An,Ngũ Hợp,Ô Phệ Đối,Thiên Đức Hợp,Nguyệt Không,Yếu An,Binh Cát Binh Phúc Binh Bảo,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Vãng Vong,Thiên Hình,Nguyệt Kiến,Phục Binh,Bất Thành,Vãng Vong,Lôi Công,Thổ Phủ,Thổ Kỵ",
        nen: "Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Quan Nhật,Cát Kì,Ngọc Vũ,Ngũ Hợp,Ô Phệ Đối,Binh Cát Binh Phú Binh Bảo,Quan Nhật",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Chu Tước,Đại Họa,Chu Tước,Ương Bại,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "Xuất Hành,Lập Ước Giao Dịch,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thủ Nhật,Thiên Vu,Phúc Đức,Lục Nghi,Kim Đường,Kim Quĩ,Lộc Khố,Thiên Phú,Thiên Tài,Kim Đường,Thiên Quý",
        hungThan:
          "Yếm Đối,Chiêu Dao,Cửu Không,Cửu Khảm,Cửu Tiêu,Phục Nhật,Thiên Tài,Thổ Ôn,Khô Tiêu,Trùng Tang,Phu Sát",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Tướng Nhật,Bảo Quang,Địa Tài,Hoạt Nhãn,Thiên Quý",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hại,Du Họa,Ngũ Hư,Trùng Nhật,Nguyệt Hình,Nguyệt Hại,Thiên Cương,Nguyệt Hỏa,Thần Cách,Mộc Mã,Tiểu Hồng Sa,Tiểu Hao,Ngõa Toái,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Nguyệt Ân,Tứ Tướng,Thời Đức,Dân Nhật,Tam Hợp,Lâm Nhật,Thiên Mã,Thời Âm,Ô Phệ,Nguyệt Đức,Nguyệt Ân,Thiên Mã,Tam Hợp,Nguyệt Tài,Dân Nhật",
        hungThan:
          "Tử Khí,Bạch Hổ,Thiên Bảng,Tử Phù,Hoàng Sa,Đại Hao,Ngũ Quỷ,Lâm Nhật",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan: "Thiên Đức,Tứ Tướng,Kính An,Ngọc Đường,Thiên Đức,Kính An",
        hungThan: "Tiểu Hao,Bát Chuyên,Thiên Ôn,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Dịch Mã,Thiên Hậu,Phổ Hộ,Giải Thần,Trừ Thần,Dịch Mã,Minh Tinh,Phổ Hộ,Giải Thần",
        hungThan:
          "Nguyệt Phá,Đại Hao,Ngũ Li,Thiên Hao,Nguyệt Phá,Ương Bại,Tứ Phế",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Âm Đức,Phúc Sinh,Trừ Thần,Ô Phệ,Phúc Tinh,Phúc Sinh,Âm Đức,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Ngũ Li,Huyền Vũ,Thiên Sứ,Huyền Vũ,Nhân Cách,Hoang Vu,Tứ Phế",
        nen: "An Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Ân,Dương Đức,Tam Hợp,Thiên Hỉ,Thiên Y,Tư Mệnh,Hỉ Thần,Thiên Quan,Tam Hợp,Đại Hồng Sa",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tứ Kích,Đại Sát, m Thác,Nguyệt Yểm,Địa Hỏa,Thọ Tử, m Sai,Trùng Nhật,Phi Liêm,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước,Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thiên Ân,Mẫu Thương,Lục Hợp,Ngũ Phú,Bất Tương,Thánh Tâm,Nguyệt Đức Hợp,Lục Hợp,Ngũ Phú,Thánh Tâm,Mẫu Thương",
        hungThan:
          "Hà Khôi,Kiếp Sát,Trùng Nhật,Câu Trần,Kiếp Sát,Thiên Cẩu Nhật Thời,Câu Trần,Câu Giảo,Hà Khôi,Bát Tòa,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Không,Thiên Ân,Mẫu Thương,Thời Dương,Sinh Khí,Ích Hậu,Thanh Long,Ô Phệ Đối,Thiên Đức Hợp,Nguyệt Không,Sinh Khí,Ích Hậu,Binh Cát,Binh Phúc,Binh Bảo,Mẫu Thương,Đại Hồng Sa",
        hungThan:
          "Tai Sát,Thiên Hỏa,Tứ Hao,Tai Sát,Phục Binh,Thiên Ngục,Thiên Cẩu Nhật Thời,Thiên Hỏa,Phi Ma,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Tục Thế,Minh Đường,Thiên Y,Tục Thế,Binh Cát Binh Phúc Binh Bảo",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Thiên Tặc,Ngũ Hư,Thổ Phù,Qui Kị,Huyết Kị,Bát Chuyên,Xúc Thủy Long,Nguyệt Hư,Đại Họa,Địa Tặc,Nguyệt Sát,Huyết Chi,Huyết Kỵ,Hoang Vu,Thiên Địa Chuyển",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Vương Nhật,Thiên Thương,Yếu An,Ngũ Hợp,Ô Phệ Đối,Yếu An,Binh Cát Binh Phúc Binh Bảo,Thiên Quý,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Vãng Vong,Phục Nhật,Bát Chuyên,Thiên Hình,Dương Thác,Nguyệt Kiến,Bất Thành,Vãng Vong,Dương Sai,Lôi Công,Trùng Tang,Thổ Phủ,Thổ Kỵ",
        nen: "Lập Ước Giao Dịch",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Quan Nhật,Cát Kì,Ngọc Vũ,Vũ Hợp,Ô Phệ Đối,Ngọc Tử,Binh Cát Binh Phú Binh Bảo,Thiên Quý,Quan Nhật",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Chu Tước,Chu Tước,Ương Bại,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Tứ Đại Mộ",
        nen: "Xuất Hành,Lập Ước Giao,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức,Nguyệt Ân,Tứ Tướng,Thủ Nhật,Thiên Vu,Phúc Đức,Lục Nghi,Kim Đường,Kim Quĩ,Nguyệt Đức,Lộc Khố,Nguyệt Ân,Thiên Phú,Thiên Tài,Kim Đường",
        hungThan:
          "Yếm Đối,Chiêu Dao,Cửu Không,Cửu Khảm,Cửu Tiêu,Thiên Tặc,Thổ Ôn,Khô Tiêu,Phu Sát",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức,Tứ Tướng,Tướng Nhật,Bảo Quang,Thiên Đức,Địa Tài,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hình,Nguyệt Hại,Du Họa,Ngũ Hư,Bát Phong,Trùng Nhật,Nguyệt Hình,Nguyệt Hại,Thiên Cương,Nguyệt Hỏa,Thần Cách,Mộc Mã,Tiểu Hồng Sa,Tiểu Hao,Ngõa Toái,Hoang Vu",
        nen: "",
        khongNen: "Xuất Hành",
      },

      {
        catThan:
          "Thời Đức,Dân Nhật,Tam Hợp,Lâm Nhật,Thiên Mã,Thời Dương,Thiên Mã,Tam Hợp,Nguyệt Tài,Dân Nhật",
        hungThan:
          "Tử Khí,Bạch Hổ,Thiên Bảng,Tử Phù,Hoàng Sa,Đại Hao,Ngũ Quỷ,Lâm Nhật",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan: "Kính An,Ngọc Đường",
        hungThan: "Tiểu Hao,Bát Chuyên,Thiên Ôn,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Dịch Mã,Thiên Hậu,Phổ Hộ,Giải Thần,Trừ Thần,Ô Phệ,Dịch Mã,Minh Tinh,Phổ Hộ,Giải Thần",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Phế,Ngũ Li,Bát Chuyên,Thiên Lao,Nguyệt Phá,Phá Bại,Trùng Nhật,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Âm Đức,Phúc Sinh,Trừ Thần,Ô Phệ,Nguyệt Đức Hợp,Phúc Tinh,Phúc Sinh,Âm Đức,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Tứ Phệ,Ngũ Hư,Ngũ Li,Huyền Vũ,Tam  m,Thiên Sứ,Huyền Vũ,Nhân Cách,Thiên Địa Chuyển,Hoang Vu,Tứ Phế,Bàng Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Không,Dương Đức,Tam Hợp,Thiên Hỉ,Thiên Y,Tư Mệnh,Thiên Đức Hợp,Nguyệt Không,Hỉ Thần,Thiên Quan,Tam Hợp,Đại Hồng Sa",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tứ Kích,Đại Sát,Nguyệt Yểm,Phục Binh,Địa Hỏa,Thọ Tử,Phi Liêm",
        nen: "Dâng Biểu Sớ,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "Xuất Hành,Cưới Gả",
      },

      {
        catThan:
          "Mẫu Thương,Lục Hợp,Ngũ Phú,Thánh Tâm,Lục Hợp,Ngũ Phú,Thánh Tâm,Mẫu Thương",
        hungThan:
          "Hà Khôi,Kiếp Sát,Trùng Nhật,Câu Trần,Kiếp Sát,Đại Họa,Thiên Cẩu Nhật Thời,Câu Trần,Câu Giảo,Hà Khôi,Bát Tòa,Thiên Địa Trù,Đao Chẩn,Thổ Cấm",
        nen: "",
        khongNen: "Cưới Gả,An Táng,Cải Táng",
      },
    ],
    // tháng mão
    [
      {
        catThan:
          "Nguyệt Đức,Nguyệt Đức,Thiên Quan,Binh Cát Binh Phúc Binh Bảo,Thiên Quý,Mẫu Thương,Đại Hồng Sa,Thiên Ân,Mẫu Thương,Dương Đức,Tư Mệnh",
        hungThan:
          "Thiên Cương,Nguyệt Hình,Đại Thời,Đại Bại,Hàm Trì,Thiên Tặc,Tứ Kị,Bát Long,Nguyệt Hình,Thiên Cương,Thiên Cẩu Nhật Thời,Địa Tặc,Bát Tòa,Ngõa Toái,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Thời Dương,Sinh Khí,Thiên Thương,Bất Tương,Kính An,Sinh Khí,Kính An,Binh Cát Binh Phúc Binh Bảo,Thiên Quý",
        hungThan:
          "Ngũ Hư,Cửu Không,Cửu Khảm,Cửu Tiêu,Phục Nhật,Câu Trần,Thiên Cẩu Nhật Thời,Câu Trần,Khô Tiêu,Trùng Tang,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ",
        khongNen:
          "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Tứ Tướng,Vương Nhật,Ngũ Phú,Bất Tương,Phổ Hộ,Ngũ Hợp,Thanh Long,Ô Phệ Đối,Thiên Y,Ngũ Phú,Phổ Hộ,Binh Cát Binh Phúc Binh Bảo,Phúc Hậu",
        hungThan: "Du Họa,Huyết Chi,Qui Kị,Hoàng Sa,Ngũ Quỷ,Ương Bại,Huyết Chi",
        nen: "Lập Ước Giao Dịch,Nạp Tài,Cải Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Ân,Nguyệt Ân,Tứ Tướng,Quan Nhật,Cát Kỳ,Lục Nghi,Phúc Sinh,Ngũ Hợp,Minh Đường,Ô Phệ Đối,Nguyệt Ân,Phúc Tinh,Phúc Sinh,Quan Nhật",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Yếm Đối,Chiêu Dao,Nguyệt Kiến,Thiên Ngục,Thiên Hỏa,Thần Cách,Thổ Phủ,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "Xuất Hành,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Cải Táng",
        khongNen: "Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Thiên Ân,Thủ Nhật,Cát Kì",
        hungThan: "Nguyệt Hại,Thiên Hình,Nguyệt Hại,Nguyệt Hỏa,Thọ Tử,Phu Sát",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Thánh Tâm,Thiên Đức Hợp,Nguyệt Đức Hợp,Lộc Khố,Dịch Mã,Thiên Phú,Nguyệt Tài,Thánh Tâm",
        hungThan:
          "Ngũ Hư,Thổ Phù,Đại Sát,Vãng Vong,Trùng Nhật,Chu Tước,Chu Tước,Vãng Vong,Thổ Ôn,Phi Liêm,Thổ Kỵ,Hoang Vu",
        nen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Không,Thời Đức,Dân Nhật,Ích Hậu,Kim Quĩ,Ô Phệ,Nguyệt Không,Thiên Tài,Ích Hậu,Dân Nhật",
        hungThan:
          "Hà Khôi,Tử Thần,Thiên Lại,Chí Tử,Phục Binh,Thiên Sứ,Bất Thành,Tiểu Hao,Câu Giảo,Hà Khôi,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Âm Đức,Tam Hợp,Thời Âm,Tục Thế,Bảo Quang,Địa Tài,Tam Hợp,Tục Thế,Âm Đức",
        hungThan:
          "Tử Khí,Huyết Kị,Đại Họa,Tử Phù,Đại Hao,Mộc Mã,Nhân Cách,Trùng Nhật,Huyết Kỵ,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Tứ Đại Mộ",
        nen: "Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Mã,Yến An,Giải Thần,Trừ Thần,Ô Phệ,Thiên Đức,Thiên Mã,Yếu An,Giải Thần",
        hungThan: "Kiếp Sát,Tiểu Hao,Ngũ Li,Bạch Hổ,Kiếp Sát,Thiên Bảng,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Ngọc Vũ,Trừ Thần,Ngọc Đường,Ô Phệ,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Nguyệt Yếm,Địa Hỏa,Ngũ Hư,Ngũ Li,Nguyệt Phá,Nguyệt Yểm,Tai Sát,Thiên Tặc,Phi Ma,Địa Hỏa,Tiểu Hồng Sa,Hoang Vu,Thiên Địa Chuyển,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Nguyện,Lục Hợp,Kim Đường,Minh Tinh,Hoạt Nhãn,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Tứ Kích,Thiên Lao,Nguyệt Hư,Phá Bại,Thiên Ôn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Mẫu Thương,Tam Hợp,Lâm Nhật,Thiên Y,Bất Tương,Hỉ Thần,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Tứ Cùng,Bát Long,Lâm Nhật,Lôi Công,Trùng Tang,Thiên Địa Chuyển,Đao Chẩn,Tứ Đại Mộ,Phục Nhật,Trùng Nhật,Huyền Vũ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa,Tứ Tướng,Dương Đức,Bất Tương,Tư Mệnh,Ô Phệ Đối",
        hungThan:
          "Thiên Cương,Thiên Cẩu Nhật Thời,Địa Tặc,Bát Tòa,Ngõa Toai,Lỗ Ban,Đao Chẩn,Nguyệt Hình,Đại Thời,Thiên Tặc,Hàm Trì,Đại Bại,Xúc Thủy Long",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tường,Thời Dương,Sinh Khí,Thiên Thương,Bất Tương,Kính An,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Ngũ Hư,Bát Phong,Thiên Cẩu Nhật Thời,Câu Giảo,Hoang Vu,Cửu Không,Cửu Khảm,Cửu Tiêu,Câu Trần",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Xá,Vương Nhật,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Ngũ Phú,Phổ Hộ,Ngũ Hợp,Thanh Long,Phúc Hậu",
        hungThan: "Du Họa,Huyết Chi,Qui Kị,Hoàng Sa,Ngũ Quỷ,Ương Bại",
        nen: "Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Phúc Tinh,Thiên Ân,Quan Nhật,Lục Nghi,Phúc Sinh,Ngũ Hợp,Minh Đường",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thiên Lao,Thiên Hỏa,Thần Cách,Thổ Phủ,Yếm Đối,Chiêu Dao,Tiểu Hội,Thiên Địa Chuyển,Nguyệt Chuyển,Thiên Địa Trù",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Thiên Không,Thiên Ân,Thủ Nhật,Cát Kì,Nguyệt Không",
        hungThan:
          "Nguyệt Hại,Thiên Hình,Phục Binh,Nguyệt Hỏa,Thọ Tử,Bàng Phế,Tư Phế,Phu Sát",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Thánh Tâm,Thiên Đức Hợp,Lộc Khố,Thiên Phu,Nguyệt Tài",
        hungThan:
          "Ngũ Hư,Thổ Phù,Đại Sát,Vãng Vong,Đại Họa,Thổ Ôn,Phi Liêm,Thổ Kỵ,Thiên Địa Chuyển,Hoang Vu,Bàng Phê,Tứ Phế,Trùng Nhật,Chu Tước",
        nen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Thời Đức,Dân Nhật,Ích Hậu,Thiên Tài,Kim Quĩ,Ô Phệ",
        hungThan:
          "Hà Khôi,Tử Thần,Thiên Lại,Chí Tử,Thiên Sứ,Bất Thành,Tiểu Hao,Câu Giảo,Hà Khôi",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Âm Đức,Tam Hợp,Thời Âm,Tục Thế,Bảo Quang,Địa Tài",
        hungThan:
          "Tử Khí,Huyết Kị,Xúc Thủy Long,Tử Phù,Đại Hao,Mộc Mã,Nhân Cách,Thiên Địa Trù,Tứ Đại Mộ",
        nen: "Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Mã,Yếu An,Giải Thần,Trừ Thần,Ô Phệ,Thiên Đức,Thiên Quý",
        hungThan: "Kiếp Sát,Tiểu Hao,Ngũ Li,Bạch Hổ,Thiên Bảng,Tứ Phế",
        nen: "",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Ngọc Vũ,Trừ Thần,Ngọc Đường,Ô Phệ,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Nguyệt Yếm,Địa Hỏa,Ngũ Hư,Phục Nhật,Ngũ Li,Đại Hội,Thiên Tặc,Phi Ma,Tiểu Hồng Sa,Trùng Tang,Thiên Địa Chuyển,Hoang Vu,Tứ Phế,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Tứ Tướng,Lục Hợp,Bất Tương,Kim Đường,Minh Tinh,Hoạt Nhãn,Đại Hồng Sa",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Tứ Kích,Thiên Hao,Nguyệt Hư,Thiên Ôn,Phá Bại",
        nen: "",
        khongNen: "Dâng Biểu Sớ",
      },

      {
        catThan:
          "Mẫu Thương,Nguyệt Ân,Tứ Tướng,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Bất Tương,Hỉ Thần,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Trùng Nguyệt,Huyền Vũ,Lâm Nhật,Lôi Công,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Dương Đức,Tư Mệnh,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Thiên Cẩu Nhật Thời,Địa Tặc,Bát Tòa,Ngõa Toái,Lỗ Ban,Đao Chẩn,Nguyệt Hình,Đại Thời,Đại Bại,Hàm Trì,Thiên Tặc",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Binh Cát,Binh Phúc,Binh Bảo,Thời Dương,Sinh Khí,Thiên Thương,Bất Tương,Kính An",
        hungThan:
          "Ngũ Hư,Cửu Không,Thiên Cẩu Nhật Thời,Cửu Khảm,Hoang Vu,Khô Tiêu,Câu Trần",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Không,Vượng Nhật,Ngũ Phú,Bất Tương,Phổ Hộ,Ngũ Hợp,Thanh Long,Ô Phệ Đối,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Phúc Hậu",
        hungThan:
          "Du Họa,Huyết Chi,Qui Kị,Phục Binh,Hoàng Sa,Ngũ Quỷ,Ương Bại,Bàng Phế,Tứ Phế",
        nen: "Lập Ước Giao Dịch,Cải Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Dộng Thổ,Khai Trương,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Quan Nhật,Lục Nghi,Phúc Sinh,Ngũ Hợp,Minh Đường,Ô Phệ Đối,Phúc Tinh",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Yếm Đối,Chiêu Dao,Đại Họa,Thiên Lao,Thiên Hỏa,Thần Cách,Trùng Nhật,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Chuyển,Nguyệt Chuyển,Tứ Phế",
        nen: "Xuất Hành,Lập Ước Giao Dịch",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thủ Nhật,Cát Kỳ",
        hungThan: "Nguyệt Hại,Thiên Hình,Nguyệt Hỏa,Thọ Tử,Phu Sát",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tướng Nhật,Dịch Mã,Thiên Đức Hợp,Lộc Khố,Thiên Phú,Nguyệt Tài,Thiên Hậu,Thiên Vu,Phúc Đức,Thánh Tâm",
        hungThan:
          "Ngũ Hư,Thổ Ôn,Phi Liêm,Thổ Kỵ,Hoang Vu,Thiên Địa Trù,Thổ Phù,Đại Sát,Vãng Vong,Trùng Nhật,Chu Tước",
        nen: "Khai Trương,Lập Ước Giao Dịch",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Thời Đức,Dân Nhật,Thiên Tài,Thiên Quý,Ích Hậu,Kim Quỹ,Ô Phệ",
        hungThan:
          "Hà Khôi,Tử Thần,Thiên Lại,Chí Tử,Thiên Sứ,Bất Thành,Tiểu Hao,Câu Giảo",
        nen: "",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Âm Đức,Địa Tài,Thiên Quý,Tam Hợp,Thời Âm,Tục Thế,Bảo Quang",
        hungThan:
          "Tử Khí,Ngũ Mộ,Địa Nang,Huyết Kỵ,Phục Nhật,Tử Phù,Đại Hao,Mộc Mã,Nhân Cách,Trùng Tang,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "Nạp Tài",
        khongNen:
          "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Tứ Tướng,Thiên Mã,Yếu An,Giải Thần,Trừ Thần,Ô Phệ",
        hungThan: "Kiếp Sát,Thiên Bảng,Tứ Phế,Tiểu Hao,Ngũ Li,Bạch Hổ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Ân,Thủ Nhật,Tứ Tướng,Ngọc Vũ,Trừ Thần,Ngọc Đường,Ô Phệ",
        hungThan:
          "Nguyệt Phá,Đại Hao,Thiên Tặc,Phi Ma,Tiểu Hồng Sa,Hoang Vu,Tứ Phế,Tai Sát,Thiên Hỏa,Nguyệt Yếm,Địa Hỏa,Ngũ Hư,Ngũ Li",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Lục Hợp,Kim Đường,Hoạt Nhãn,Minh Tinh,Đại Hồng Sa",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Tứ Kích,Thiên Lao,Thiên Ôn,Phá Bại",
        nen: "",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Mẫu Thương,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Bất Tương,Hỉ Thần,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Trùng Nhật,Huyền Vũ,Lâm Nhật,Lôi Công,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa,Mẫu Thương,Dương Đức,Bất Tương,Tư Mệnh,Ô Phệ Đối",
        hungThan:
          "Thiên Cương,Phục Binh,Thiên Cẩu Nhật Thời,Địa Tặc,Bát Tòa,Ngõa Toái,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn,Nguyệt Hình,Đại Thời,Đại Bại,Hàm Trì,Thiên Tặc",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thời Dương,Sinh Khí,Thiên Thương,Kính An,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Ngũ Hư,Đại Họa,Thiên Cẩu Nhật Thời,Trùng Nhật,Trùng Tang,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế,Cửu Không,Cửu Khảm,Cửu Tiêu,Câu Trần",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Vương Nhật,Thiên Y,Ngũ Phú,Binh Cát,Binh Phúc,Binh Bảo,Phúc Hậu,Phổ Hộ,Ngũ Hợp,Thanh Long,Ô Phệ Đối",
        hungThan: "Du Họa,Huyết Chi,Qui Kị,Hoàng Sa,Ngũ Quỷ,Ương Bại",
        nen: "Lập Ước Giao Dịch,Nạp Tài,Cải Táng",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Dộng Thổ,Khai Trương",
      },

      {
        catThan:
          "Quan Nhật,Lục Nghi,Phúc Tinh,Phúc Sinh,Ngũ Hợp,Minh Đường,Ô Phệ Đối",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Thiên Lao,Thiên Hỏa,Thần Cách,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Yếm Đối,Chiêu Dao",
        nen: "Xuất Hành,Lập Ước Giao Dịch",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Đức,Thủ Nhật,Cát Kỳ,Thiên Quý",
        hungThan: "Nguyệt Hại,Thiên Hình,Nguyệt Hỏa,Thọ Tử,Phu Sát",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Tướng Nhật,Thiên Đức Hợp,Lộc Khố,Thiên Phú,Nguyệt Tài,Thiên Quý,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Thánh Tâm",
        hungThan:
          "Ngũ Hư,Thổ Phù,Đại Sát,Vãng Vong,Phục Nhật,Trùng Nhật,Chu Tước,Thổ Ôn,Phi Liêm,Trùng Tang,Thổ Kỵ,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "Khai Trương,Lập Ước Giao Dịch",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Tứ Tướng,Thời Đức,Dân Nhật,Ích Hậu,Thiên Tài,Kim Quĩ,Ô Phệ",
        hungThan:
          "Hà Khôi,Tử Thần,Thiên Lại,Chí Tử,Thiên Sứ,Bất Thành,Tiểu Hao,Câu Giảo",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Tang,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Ân,Địa Tài,Tứ Tướng,Âm Đức,Tam Hợp,Thời Âm,Tục Thế,Bảo Quang",
        hungThan:
          "Tử Khí,Huyết Kị,Tử Phù,Đại Hao,Mộc Mã,Nhân Cách,Tứ Đại Mộ,Bát Chuyên",
        nen: "Xuất Hành,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả",
      },

      {
        catThan: "Thiên Mã,Yếu An,Giải Thần,Thiên Đức,Trừ Thần",
        hungThan: "Kiếp Sát,Tiểu Hao,Ngũ Li,Bạch Hổ,Thiên Bảng,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Đức Hợp,Thiên Ân,Ngọc Vũ,Trừ Thần,Ngọc Đường,Ô Phệ",
        hungThan:
          "Nguyệt Phá,Thiên Tặc,Phi Ma,Địa Hỏa,Tiểu Hồng Sa,Hoang Vu,Tứ Phế,Đại Hao,Tai Sát,Thiên Hỏa,Nguyệt Yếm,Địa Hỏa,Ngũ Hư,Ngũ Li, m Đạo Xung Dương",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Không,Minh Tinh,Hoạt Nhãn,Đại Hồng Sa,Thiên Ân,Lục Hợp,Bất Tương,Kim Đường",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Tứ Kích,Thiên Lao,Phục Binh,Thiên Ôn,Phá Bại,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Xuất Hành,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Tam Hợp,Hỉ Thần,Binh Cát,Binh Phúc,Binh Bảo,Lâm Nhật,Thiên Hỉ,Thiên Y",
        hungThan:
          "Trùng Nhật,Huyền Vũ,Đại Họa,Lâm Nhật,Lôi Công,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa,Dương Đức,Tư Mệnh,Ô Phệ Đối",
        hungThan:
          "Thiên Cương,Thiên Cẩu Nhật Thời,Địa Tặc,Bát Tòa,Ngõa Toái,Lỗ Ban,Đao Chẩn,Nguyệt Hình,Đại Thời,Đại Bại,Hàm Trì,Thiên Tặc,Tứ Hao",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Ân,Kính An,Binh Cát,Binh Phúc,Binh Bảo,Thời Dương,Sinh Khí,Thiên Thương,Kính An",
        hungThan:
          "Ngũ Hư,Thiên Cẩu Nhật Thời,Khô Tiêu,Hoang Vu,Thiên Địa Chuyển,Cửu Không,Cửu Khảm,Địa Nang,Bát Chuyên,Xúc Thủy Long,Câu Trần",
        nen: "Dâng Biểu Sớ,Xuất Hành",
        khongNen:
          "Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Phúc Hậu,Vương Nhật,Ngũ Phú,Phổ Hộ,Ngũ Hợp,Thanh Long,Ô Phệ Đối",
        hungThan:
          "Du Họa,Huyết Chi,Qui Kị,Bát Chuyên,Hoàng Sa,Ngũ Quỷ,Ương Bại",
        nen: "Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Quan Nhật,Phúc Tinh,Thiên Quý,Lục Nghi,Phúc Sinh,Ngũ Hợp,Minh Đường,Ô Phệ Đối",
        hungThan:
          "Nguyệt Kiến,Thiên Lao,Thiên Hỏa,Thần Cách,Trùng Tang,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Tứ Đại Mộ,Tiểu Hao,Thổ Phủ,Yếm Đối,Chiêu Dao,Phục Nhật,Dương Thác",
        nen: "Xuất Hành,Lập Ước Giao Dịch",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Tứ Tướng,Thủ Nhật,Cát Kì",
        hungThan: "Nguyệt Hại,Thiên Hình,Nguyệt Hỏa,Thọ Tử,Phu Sát",
        nen: "Xuất Hành,Tu Tạo Động Thổ",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Thánh Tâm,Thiên Đức Hợp,Lộc Khố,Nguyệt  n,Thiên Phú,Nguyệt Tài",
        hungThan:
          "Ngũ Hư,Thổ Ôn,Phi Liêm,Thổ Kỵ,Hoang Vu,Bát Phong,Thổ Phù,Đại Sát,Vãng Vong,Trùng Nhật,Chu Tước",
        nen: "Khai Trương,Lập Ước Giao Dịch",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thời Đức,Thiên Tài,Dân Nhật,Ích Hậu,Kim Quĩ",
        hungThan:
          "Hà Khôi,Tử Thần,Thiên Sứ,Bất Thành,Tiểu Hao,Câu Giảo,Thiên Lại,Chí Tử",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Âm Đức,Địa Tài,Tam Hợp,Thời Âm,Tục Thế,Bảo Quang",
        hungThan:
          "Tử Khí,Huyết Kị,Bát Chuyên,Tử Phù,Đại Hao,Mộc Mã,Nhân Cách,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Mã,Yến An,Giải Thần,Trừ Thần,Ô Phệ,Thiên Đức",
        hungThan:
          "Kiếp Sát,Phục Binh,Thiên Bảng,Bàng Phế,Tiểu Hao,Tứ Phế,Ngũ Li,Bát Chuyên,Bạch Hổ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Ngọc Vũ,Ngọc Đường,Trừ Thần,Ô Phệ,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Họa,Phi Ma,Tiểu Hồng Sa,Trùng Nhật,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Đại Hao,Tai Sát,Thiên Hỏa,Nguyệt Yếm,Địa Hỏa,Tứ Phế,Ngũ Li, m Thác",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Lục Hợp,Kim Đường,Minh Tinh,Hoạt Nhãn,Đại Hồng Sa",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Tứ Kích,Thiên Lao,Phá Bại",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Mẫu Thương,Hỉ Thần,Binh Cát,Binh Phúc,Binh Bảo,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y",
        hungThan:
          "Trùng Nhật,Huyền Vũ,Lôi Công,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm",
        nen: "",
        khongNen: "Cưới Gả,An Táng,Cải Táng",
      },
    ],
    //Tháng thìn
    [
      {
        catThan:
          "Hỉ Thần,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Mẫu Thương,Đại Hồng Sa,Thiên Ân,Tam Hợp,Thiên Y,Thiên Tương,Bất Tương,Thánh Tâm",
        hungThan:
          "Hoàng Sa,Phá Bại,Lỗ Ban,Đao Chẩn,Tứ Kị,Bát Long,Địa Nang,Qui Kị,Thiên Lao",
        nen: "Xuất Hành,Khai Trương,Lập Ước Giao Dịch",
        khongNen:
          "Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng",
      },

      {
        catThan:
          "Thiên Ân,Bất Tương,Ích Hậu,Thiên Quý,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Hà Khôi,Ngũ Hư,Huyền Vũ,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Ương Bại,Câu Giảo,Bát Tòa,Ngõa Toái,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Quan,Phúc Hậu,Thiên Ân,Tứ Tướng,Dương Đức,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Lục Nghi,Tục Thế,Ngũ Hợp,Tư Mệnh,Ô Phệ Đối",
        hungThan:
          "Yếm Đối,Chiêu Dao,Huyết Kị,Phục Binh,Thiên Cẩu Nhật Thời,Thiên Tặc",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Thiên Ân,Tứ Tướng,Quan Nhật,Yếu An,Ngũ Hợp,Ô Phệ Đối,Thiên Y",
        hungThan:
          "Nguyệt Hại,Đại Họa,Thiên Sứ,Nguyệt Hỏa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Thiên Lại,Chí Tử,Huyết Chi,Câu Trần",
        nen: "",
        khongNen: "Nạp Tài",
      },

      {
        catThan: "Thiên Ân,Thủ Nhật,Ngọc Vũ,Thanh Long",
        hungThan:
          "Nguyệt Kiến,Nguyệt Hư,Thiên Ôn,Nguyệt Sát,Ngũ Quỷ,Trùng Tang,Thổ Phủ,Phu Sát,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Ngũ Mộ,Phục Nhật,Tiểu Hội,Đơn  m",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Âm Đức,Nguyệt Tài, m Đức,Tướng Nhật,Cát Kì,Ngũ Phú,Kim Đường,Minh Đường",
        hungThan:
          "Kiếp Sát,Ngũ Hư,Trùng Nhật,Nhân Cách,Lôi Công,Trùng Tang,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Ân,Lộc Khố,Nguyệt  n,Thiên Phú,Thời Đức,Dân Nhật,Thiên Vu,Phúc Đức,Ô Phệ",
        hungThan:
          "Tai Sát,Thiên Ngục,Thiên Hỏa,Đại Sát,Phi Ma,Thổ Ôn,Phi Liêm,Bàng Phế,Tứ Phế,Thiên Hình",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Tiểu Hao,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Tứ Đại Mộ,Nguyệt Sát,Nguyệt Hư,Chu Tước",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Đức,Tam Hợp,Thiên Tài,Lâm Nhật,Thời Âm,Kính An,Trừ Thần,Kim Quĩ,Ô Phệ",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tử Khí,Vãng Vong,Ngũ Li,Cô Thần,Tử Phù,Đại Hao,Địa Hỏa,Lâm Nhật,Thổ Kỵ,Tứ Phế",
        nen: "",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
      },

      {
        catThan: "Lục Hợp,Phổ Hộ,Trừ Thần,Bảo Quang,Ô Phệ,Địa Tài,Thủ Nhật",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Thổ Phù,Ngũ Li,Mộc Mã,Hoang Vu,Thiên Địa Chuyển,Tứ Phế",
        nen: "Cưới Gả,An Táng",
        khongNen:
          "Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Mã,Bất Tương,Phúc Sinh,Giải Thần,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Kích,Cửu Không,Cửu Khảm,Cửu Tiêu,Bạch Hổ,Bất Thành,Thiên Bảng",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Bất Tương,Ngọc Đường,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Du Họa,Thiên Tặc,Tứ Cùng,Bát Long,Trùng Nhật,Địa Tặc,Thọ Tử,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Xuất Hành,Cưới Gả,Mở Kho Xuất Tiền Hàng,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Mẫu Thương,Tứ Tướng,Tam Hợp,Thiên Hỉ,Thiên Y,Thiên Thương,Bất Tương,Thánh Tâm,Ô Phệ Đối,Hỉ Thần,Minh Tinh,Binh Cát,Binh Phúc Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Qui Kị,Xúc Thủy Long,Thiên Lao,Phục Binh,Hoàng Sa,Phá Bại,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Tứ Tướng,Bất Tương,Ích Hậu,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Hà Khôi,Đại Họa,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Ương Bại,Câu Giảo,Bát Tòa,Ngõa Toái,Hoang Vu,Ngũ Hư,Bát Phong,Huyền Vũ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Quan,Thiên Xá,Phúc Hậu,Dương Đức,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Lục Nghi,Tục Thế,Ngũ Hợp,Tư Mệnh",
        hungThan:
          "Yếm Đối,Chiêu Dao,Huyết Kị,Phục Nhật,Thiên Cẩu Nhật Thời,Thiên Tặc,Trùng Tang",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch",
        khongNen: "",
      },

      {
        catThan: "Thiên Ân,Quan Nhật,Yếu An,Ngũ Hợp,Thiên Y",
        hungThan:
          "Nguyệt Hại,Thiên Lại,Chí Tử,Huyết Chi,Câu Trần,Thiên Sứ,Nguyệt Hỏa,Trùng Nhật,Trùng Tang,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Nguyệt Ân,Thủ Nhật,Ngọc Vũ,Thanh Long",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình, m Vị,Nguyệt Hư,Thiên Ôn,Nguyệt Sát,Ngũ Quỷ,Thổ Phủ,Bàng Phế,Tứ Phế,Phu Sát",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Ân,Âm Đức,Tướng Nhật,Cát Kì,Ngũ Phú,Kim Đường,Minh Đường,Nguyệt Tài",
        hungThan:
          "Kiếp Sát,Ngũ Hư,Trùng Nhật,Nhân Cách,Lôi Công,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Đức,Lộc Khố,Thiên Phú,Thiên Ân,Thời Đức,Dân Nhật,Thiên Vu,Phúc Đức,Ô Phệ,",
        hungThan:
          "Tai Sát,Thiên Hỏa,Địa Nang,Đại Sát,Thiên Hành,Thiên Ngục,Phi Ma,Thổ Ôn,Phi Liêm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Thiên Ân,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Sát,Nguyệt Hư,Xúc Thủy Long,Chu Tước,Tiểu Hao,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Tam Hợp,Thiên Tài,Thiên Quý,Lâm Nhật,Thời Âm,Kính An,Trừ Thần,Kim Quĩ,Ô Phệ",
        hungThan:
          "Nguyệt Yếm,Tử Phù,Đại Hao,Lâm Nhật,Thổ Kỵ,Tứ Phế,Địa Hỏa,Tử Khí,Vãng Vong,Ngũ Li,Hành Ngận",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Nguyện,Địa Tài,Thiên Nguyện,Thiên Quý,Thủ Nhật,Lục Hợp,Bất Tương,Phổ Hộ,Trừ Thần,Bảo Quang,Ô Phệ",
        hungThan:
          "Đại Thời,Mộc Mã,Thiên Địa Chuyển,Hoang Vu,Tứ Phế,Tứ Đại Mộ,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Thổ Phù,Ngũ Li",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Không,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa,Tứ Tướng,Thiên Mã,Bất Tương,Phúc Sinh,Giải Thần",
        hungThan:
          "Nguyệt Phá,Phục Binh,Thiên Bảng,Bất Thành,Khô Tiêu,Đại Hao,Tứ Kích,Cửu Không,Cửu Khảm,Cửu Tiêu,Bạch Hổ",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Binh Cát,Binh Phúc,Binh Bảo,Mẫu Thương,Tứ Tương,Bất Tương,Ngọc Đường",
        hungThan:
          "Du Họa,Thiên Tặc,Trùng Nhật,Đại Họa,Địa Tặc,Thọ Tử,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Tu Tạo Động Thổ",
        khongNen: "Xuất Hành,Cưới Gả,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Mẫu Thương,Tam Hợp,Thiên Hỉ,Thiên Y,Thiên Thương,Thánh Tâm",
        hungThan:
          "Qui Kị,Phục Nhật,Thiên Lao,Hoàng Sa,Phá Bại,Trùng Tang,Lỗ Ban,Đao Chẩn",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "An Táng,Cải Táng",
      },

      {
        catThan: "Bất Tương,Ích Hậu,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Hà Khôi,Ngũ Hư,Huyền Vũ,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Ương Bại,Câu Giảo,Bát Tòa,Trùng Nhật,Ngõa Toái,Trùng Tang,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Nguyệt Ân,Thiên Quan,Phúc Hậu,Dương Đức,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Lục Nghi,Tục Thế,Ngũ Hợp,Tư Mệnh,Ô Phệ Đối",
        hungThan:
          "Yếm Đối,Chiêu Dao,Huyết Kị,Thiên Cẩu Nhật Thời,Thiên Tặc,Huyết Kỵ,Bàng Phế,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả",
      },

      {
        catThan: "Quan Nhật,Yếu An,Ngũ Hợp,Ô Phệ Đối,Thiên Y",
        hungThan:
          "Nguyệt Hại,Thiên Lại,Chí Tử,Huyết Chi,Câu Trần,Thiên Sứ,Nguyệt Hỏa,Câu Trần,Huyết Chi,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Đức,Nguyệt Đức,Thổ Phủ,Thanh Long,Ngọc Vũ",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thủ Nhật,Nguyệt Hình,Nguyệt Hư,Thiên Ôn,Nguyệt Sát,Ngũ Quỷ,Thổ Phủ,Phu Sát",
        nen: "",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Âm Đức,Nguyệt Tài,Tướng Nhật,Cát Kì,Ngũ Phú,Kim Đường,Minh Đường",
        hungThan:
          "Kiếp Sát,Ngũ Hư,Trùng Nhật,Nhân Cách,Lôi Công,Hoang Vu,Thiên Địa Chuyển",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thời Đức,Dân Nhật,Thiên Vu,Phúc Đức,Ô Phệ,Lộc Khố,Thiên Phú,Thiên Quý",
        hungThan:
          "Tai Sát,Thiên Hỏa,Đại Sát,Thiên Hình,Thiên Ngục,Phi Ma,Thổ Ôn,Phi Liêm",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Hoạt Nhãn,Thiên Quý",
        hungThan:
          "Thiên Cương,Tử Thần,Tiểu Hao,Thiên Địa Chuyển,Tứ Đại Mộ,Nguyệt Sát,Nguyệt Hư,Chu Tước",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Không,Tứ Tướng,Tam Hợp,Lâm Nhật,Thời Âm,Kính An,Trừ Thần,Kim Quĩ,Ô Phệ,Thiên Tài",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tử Khí,Vãng Vong,Ngũ Li,Liễu Lệ,Phục Binh,Tử Phù,Đại Hao,Lâm Nhật,Thổ Kỵ,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Địa Tài,Tứ Tướng,Lục Hợp,Bất Tương,Phổ Hộ,Trừ Thần,Bảo Quang,Ô Phệ,Thủ Nhật",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Thổ Phù,Ngũ Li,Đại Hỏa,Mộc Mã,Hoang Vu,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng.",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Mã,Phúc Sinh,Giải Thần,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tức Kích,Cửu Không,Cửu Khảm,Khô Tiêu,Phục Nhật,Bạch Hổ,Thiên Bảng,Bất Thành,Trùng Tang",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Bất Tương,Ngọc Đường,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Du Họa,Thiên Tặc,Trùng Nhật,Địa Tặc,Thọ Tử,Trùng Tang,Đao Chẩn,Thổ Cấm",
        nen: "",
        khongNen: "Xuất Hành,Cưới Gả,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Nguyệt Ân,Tam Hợp,Thiên Hỉ,Thiên Y,Thiên Thương,Thánh Tâm,Ô Phệ Đối,Hỉ Thần,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Qui Kị,Thiên Lao,Hoàng Sa,Ương Bại, m Sai,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Ích Hậu,Binh Cát Binh Phúc Binh Bảo",
        hungThan:
          "Hà Khôi,Ngũ Hư,Huyền Vũ,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Ương Bại,Câu Giảo,Bát Tòa,Ngõa Toái,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Đức,Thiên Quan,Phúc Hậu,Dương Đức,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Lục Nghi,Tục Thế,Ngũ Hợp,Tư Mệnh,Ô Phệ Đối",
        hungThan: "Yếm Đối,Chiêu Dao,Huyết Kị,Thiên Cẩu Nhật Thời,Thiên Tặc",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Di Chuyển,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch",
        khongNen: "",
      },

      {
        catThan: "Quan Nhật,Yếu An,Ngũ Hợp,Ô Phệ Đối,Thiên Y",
        hungThan:
          "Nguyệt Hại,Thiên Lại,Chí Tử,Huyết Chi,Câu Trần,Thiên Sứ,Nguyệt Hỏa,Câu Trần,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thủ Nhật,Ngọc Vũ,Thanh Long,Thiên Quý",
        hungThan:
          "Nguyệt Kiến,Nguyệt Hư,Thiên Ôn,Nguyệt Sát,Ngũ Quỷ,Dương Sai,Phu Sát,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Dương Thác",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Âm Đức,Tướng Nhật,Cát Kì,Ngũ Phú,Kim Đường,Minh Đường,Nguyệt Tài, m Đức,Thiên Quý",
        hungThan:
          "Kiếp Sát,Ngũ Hư,Trùng Nhật,Nhân Cách,Lôi Công,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Tứ Tướng,Thời Đức,Dân Nhật,Thiên Vu,Phúc Đức,Ô Phệ,Thiên Phú,Lộc Khố",
        hungThan:
          "Tai Sát,Thiên Hỏa,Đại Sát,Thiên Hình,Phục Binh,Thiên Ngục,Phi Ma,Thổ Ôn,Phi Liêm",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Đức Hợp,Tứ Tướng,Thiên Đức Hợp,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Sát,Nguyệt Hư,Bát Chuyên,Chu Tước,Đại Họa,Tiểu Hao,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Tam Hợp,Thiên Tài,Lâm Nhật,Thời Âm,Kính An,Trừ Thần,Kim Quĩ",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tử Khí,Vãng Vong,Phục Nhật,Ngũ Li,Cô Thần,Tử Phù,Đại Hao,Lâm Nhật,Trùng Tang,Thổ Kỵ,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Lục Hợp,Bất Tương,Phổ Hộ,Trừ Thần,Ô Phệ,Địa Tài,Thủ Nhật",
        hungThan:
          "Đại Thời,Trùng Nhật,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Thổ Phù,Ngũ Li",
        nen: "Cưới Gả,An Táng.",
        khongNen:
          "Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Ân,Nguyệt Ân,Thiên Mã,Phúc Sinh,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa,Giải Thần",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Kích,Cửu Không,Cửu Khảm,Khô Tiêu,Bạch Hổ,Bàng Phế,Tứ Phế",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan: "Thiên Ân,Mẫu Thương,Ngọc Đường,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Du Họa,Thiên Tặc,Trùng Nhật,Địa Tặc,Thọ Tử,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm",
        nen: "",
        khongNen: "Xuất Hành,Cưới Gả,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Đức,Thiên Ân,Mẫu Thương,Tam Hợp,Thiên Hỉ,Thiên Y,Thiên Thương,Thánh Tâm,Ô Phệ Đối,Hỉ Thần,Minh Tinh,Thánh Tâm,Binh Cát Binh Phúc Binh Bảo,Đại Hồng Sa",
        hungThan: "Tứ Hao,Qui Kị,Thiên Lao,Hoàng Sa,Phá Bại,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Thiên Ân,Ích Hậu,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Hà Khôi,Thiên Cẩu Nhật Thời,Ngũ Hư,Bát Chuyên,Huyền Vũ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Dương Đức,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Lục Nghi,Tục Thế,Ngũ Hợp,Tư Mệnh,Ô Phệ Đối,Thiên Quan,Thiên Quý,Phúc Hậu",
        hungThan:
          "Yếm Đối,Chiêu Dao,Huyết Kị,Bát Chuyên,Thiên Cẩu Nhật Thời,Thiên Tặc",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch",
        khongNen: "Cưới Gả,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Quan Nhật,Yếu An,Ngũ Hợp,Ô Phệ Đối,Thiên Quý,Thiên Y",
        hungThan:
          "Nguyệt Hại,Thiên Sứ,Nguyệt Hỏa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Tứ Đại Mộ,Thiên Lại,Chí Tử,Huyết Chi,Câu Trần",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan: "Nguyệt Không,Tứ Tướng,Thủ Nhật,Ngọc Vũ,Thanh Long",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phù,Nguyệt Hình,Nguyệt Hư,Phục Binh,Thiên Ôn,Nguyệt Sát,Thổ Quỷ,Thổ Phủ,Phu Sát",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Tứ Tướng,Âm Đức,Tướng Nhật,Cát Kì,Ngũ Phú,Kim Đường,Minh Đường,Nguyệt Tài",
        hungThan:
          "Kiếp Sát,Ngũ Hư,Bát Phong,Trùng Nhật,Đại Họa,Nhân Cách,Lôi Công,Hoang Vu",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Xuất Hành",
      },

      {
        catThan: "Thời Đức,Dân Nhật,Thiên Vu,Phúc Đức,Lộc Khố,Thiên Phú",
        hungThan:
          "Tai Sát,Thiên Hỏa,Đại Sát,Phục Nhật,Thiên Hình,Thiên Ngục,Thiên Hỏa,Phi Ma,Thổ Ôn,Phi Liêm,Trùng Tang",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Tiểu Hao,Trùng Nhật,Trùng Tang,Tứ Đại Mộ,Nguyệt Sát,Nguyệt Hư,Bát Chuyên,Chu Tước",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Ân,Tam Hợp,Lâm Nhật,Thiên Tài,Thời Âm,Kính An,Trừ Thần,Kim Quĩ,Ô Phệ",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tử Khí,Tứ Phế,Vãng Vong,Ngũ Li,Bát Chuyên,Cô Thần, m Thác,Tử Phù,Đại Hao,Lâm Nhật,Thổ Kỵ,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Lục Hợp,Phổ Hộ,Trừ Thần,Bảo Quang,Ô Phệ,Địa Tài,Thủ Nhật",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Tứ Phế,Ngũ Hư,Thổ Phù,Ngũ Li,Mộc Mã,Thiên Địa Chuyển,Hoang Vu,Bàng Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Đức,Thiên Mã,Phúc Sinh,Giải Thần,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Kích,Cửu Không,Cửu Khảm,Khô Tiêu,Bạch Hổ,Thiên Bảng,Bất Thành",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan: "Mẫu Thương,Ngọc Đường,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Du Họa,Thiên Tặc,Trùng Nhật,Địa Tặc,Thọ Tử,Thiên Địa Trù,Đao Chẩn,Thổ Cấm",
        nen: "",
        khongNen: "Xuất Hành,Cưới Gả,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },
    ],
    //Tháng Tỵ
    [
      {
        catThan:
          "Nguyệt Không,Thiên Ân,Thiên Mã,Bất Tương,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Thủ Nhật",
        hungThan:
          "Thiên Sứ,Phục Binh,Thiên Bảng,Tai Sát,Hoang Vu,Tứ Phế,Chí Tử,Ngũ Hư,Bạch Hổ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thiên Ân,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Lục Nghi,Ngọc Đường,Hỉ Thần",
        hungThan: "Yếm Đối,Chiêu Dao,Tứ Kích,Quy Kị,Đại Họa,Lâm Nhật",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thiên Ân,Mẫu Thương,Kính An,Ngũ Hợp,Ô Phệ Đối,Minh Tinh,Thiên Quý",
        hungThan:
          "Thiên Cương,Thiên Cẩu Nhật Thời,Nguyệt Hỏa,Phá Bại,Bát Tòa,Thiên Địa Chuyển,Thiên Địa Trù,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ,Kiếp Sát,Nguyệt Hại,Thổ Phù,Phục Nhật,Thiên Lao",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Âm Đức,Thời Dương,Sinh Khí,Phổ Hộ,Ngũ Hợp,Ô Phệ Đối,Thiên Quý",
        hungThan:
          "Tai Sát,Thiên Hỏa,Huyền Vũ,Thiên Cẩu Nhật Thời,Phi Ma,Nhân Cách,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen: "Khai Trương",
      },

      {
        catThan:
          "Thiên Quan,Thiên Ân,Thiên Y,Phúc Tinh,Phúc Sinh,Đại Hồng Sa,Tứ Tướng,Thời Đức,Dương Đức,Phúc Sinh,Tư Mệnh",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Ngũ Hư,Tuyệt  m,Thiên Địa Chuyển,Hoang Vu",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Nguyệt Ân,Tứ Tướng,Vương Nhật,Đại Hồng Sa,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Trùng Nhật,Câu Trần,Tiểu Hội Thuần Dương,Dương Thác,Thọ Tử,Tiểu Hồng Sa,Bất Thành.",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Nguyệt Đức,Quan Nhật,Cát Kì,Thánh Tâm,Thanh Long,Ô Phệ",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Hoàng Sa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,An Táng,Cải Táng.",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức,Lộc Khố,Thiên Phú,Nguyệt Tài,Thủ Nhật,Thiên Vu,Phúc Đức,Ích Hậu,Minh Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Cửu Không,Cửu Khảm,Khô Tiêu,Đại Sát,Cô Thần,Nguyệt Hư,Thiên Tặc,Địa Hỏa,Nguyệt Sát,Thổ Ôn,Phi Liêm,Phu Sát.",
        nen: "",
        khongNen: "Xuất Hành,Cưới Gả",
      },

      {
        catThan: "Tướng Nhật,Lục Hợp,Ngũ Phú,Tục Thế,Trừ Thần,Ô Phệ",
        hungThan:
          "Hà Khôi,Tử Thần,Nguyệt Hình,Du Họa,Ngũ Hư,Huyết Kị,Ngũ Li,Thiên Hình,Mộc Mã,Tiểu Hao,Câu Giảo,Trùng Nhật,Lôi Công,Ngõa Toái,Huyết Kỵ,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Dân Nhật,Tam Hợp,Thời Âm,Yếu An,Trừ Thần,Ô Phệ,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tử Khí,Ngũ Li,Chu Tước,Thiên Ôn,Thiên Ngục,Thiên Hỏa,Tử Phù,Đại Hao,Ngũ Quỷ,Bàng Phế,Tứ Phế",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Không,Bất Tương,Ngọc Vũ,Giải Thần,Kim Quĩ,Thiên Tài,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Tiểu Hao,Thiên Tặc,Phục Binh,Địa Tặc,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Cưới Gả",
        khongNen:
          "Xuất Hành,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Dịch Mã,Thiên Hậu,Thiên Thương,Bất Tương,Kim Đường,Bảo Quang,Địa Tài,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Nguyệt Phá,Đại Hao,Vãng Vong,Trùng Nhật,Thần Cách,Thổ Kỵ,Tứ Phế",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thiên Mã,Bất Tương,Ô Phệ Đối,Hoạt Nhãn,Thiên Nguyện,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Tứ Kị,Thất Điểu,Ngũ Hư,Phục Nhật,Xúc Thủy Long,Bạch Hổ,Thiên Sứ,Thiên Bảng,Ương Bại,Thiên Địa Chuyển,Hoang Vu,Thiên Địa Trù,Tứ Phế,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ",
        khongNen: "Cưới Gả,An Táng",
      },

      {
        catThan:
          "Tam Hợp,Hỉ Thần,Thiên Quý,Lâm Nhật,Thiên Hỉ,Thiên Y,Lục Nghi,Ngọc Đường",
        hungThan: "Yếm Đối,Chiêu Dao,Tứ Kích,Qui Kị,Lâm Nhật",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Cưới Gả",
      },

      {
        catThan: "Mẫu Thương,Tứ Tướng,Kính An,Ngũ Hợp,Minh Tinh",
        hungThan:
          "Thiên Cương,Kiếp Sát,Nguyệt Hại,Thổ Phù,Thiên Lao,Nguyệt Hại,Thiên Cẩu Nhật Thời,Nguyệt Hỏa,Phá Bại,Bát Tòa,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường, An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Nguyệt Ân,Tứ Tướng,Âm Đức,Thời Dương,Sinh Khí,Phổ Hộ,Ngũ Hợp,",
        hungThan:
          "Tai Sát,Thiên Hỏa,Địa Nang,Huyền Vũ,Thiên Cẩu Nhật Thời,Phi Ma,Nhân Cách,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Ân,Thời Đức,Dương Đức,Phúc Sinh,Tư Mệnh,Thiên Quan,Thiên Y,Phúc Tinh,Đại Hồng Sa",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Ngũ Hư,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Đức,Thiên Ân,Vương Nhật,Phúc Hậu,Đại Hồng Sa",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Trùng Nhật,Câu Trần,Thọ Tử,Tiểu Hồng Sa,Bất Thành",
        nen: "Dâng Biểu Sớ,Cưới Gả",
        khongNen: "Xuất Hành,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Thiên Ân,Quan Nhật,Cát Kì,Thánh Tâm,Thanh Long,Ô Phệ",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Hoàng Sa,Trùng Nhật,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế",
        nen: "Xuất Hành,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Lộc Khố,Thiên Phú,Nguyệt Tài,Thiên Nhật,Thiên Vu,Phúc Đức,Ích Hậu,Minh Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Cửu Không,Cửu Khảm,Cửu Tiêu,Đại Sát,Xúc Thủy Long,Cô Thần,Nguyệt Hư,Thiên Tặc,Nguyệt Sát,Thổ Ôn,Phi Liêm,Khô Tiêu,Bàng Phế,Tứ Phế,Phu Sát",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Tướng Nhật,Lục Hợp,Ngũ Phú,Bất Tương,Tục Thế,Trừ Thần,Ô Phệ",
        hungThan:
          "Hà Khôi,Tử Thần,Nguyệt Hình,Du Họa,Ngũ Hư,Bát Phong,Huyết Kị,Ngũ Li,Thiên Hình,Phục Binh,Mộc Mã,Tiểu Hao,Câu Giảo,Lôi Công,Ngõa Toái,Hoang Vu",
        nen: "",
        khongNen:
          "Xuất Hành,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Dân Nhật,Tam Hợp,Thời Âm,Bất Tương,Yếu An,Trừ Thần,Ô Phệ,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tử Khí,Ngũ Li,Câu Trần,Đại Họa,Thiên Ôn,Thiên Ngục,Thiên Hỏa,Tử Phù,Đại Hao,Chu Tước,Ngũ Quỷ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Bất Tương,Giải Thần,Kim Quĩ,Thiên Tài,Ngọc Vũ,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Tiểu Hao,Thiên Tặc,Phục Nhật,Địa Tặc,Tứ Đại Mộ,Thiên Địa Chuyển,Thiên Địa Trù",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Dịch Mã,Địa Tài,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Thiên Hậu,Thiên Thương,Bất Tương,Kim Đường,Bảo Quang",
        hungThan:
          "Nguyệt Phá,Thần Cách,Vãng Vong,Thổ Kỵ,Tứ Phế,Đại Hao,Tứ Cùng,Thất Điểu ,Vãng Vong,Trùng Nhật",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Tứ Tướng,Thiên Mã,Bát Tương,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Bạch Hổ,Thiên Sứ,Thiên Bảng,Ương Bại,Thiên Địa Chuyển,Hoang Vu,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Lục Nghi,Ngọc Đường,Hỉ Thần",
        hungThan: "Yếm Đối,Chiêu Dao,Tứ Kích,Qui Kị,Lâm Nhật",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả",
      },

      {
        catThan: "Nguyệt Đức,Mẫu Thương,Kính An,Ngũ Hợp,Ô Phệ Đối,Minh Tinh",
        hungThan:
          "Thiên Cương,Kiếp Sát,Nguyệt Hại,Thổ Phù,Thiên Lao,Thiên Cẩu Nhật Thời,Nguyệt Hỏa,Phá Bại,Bát Tòa,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Đức,Mẫu Thương,Âm Đức,Thời Dương,Sinh Khí,Phổ Hộ,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Tai Sát,Thiên Hỏa,Huyền Vũ,Thiên Cẩu Nhật Thời,Phi Ma,Nhân Cách,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Thời Đức,Dương Đức,Phúc Sinh,Tư Mệnh,Thiên Quan,Thiên Y,Phúc Tinh,Phúc Sinh,Đại Hồng Sa",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Ngũ Hư,Trùng Nhật,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Vương Nhật,Phúc Hậu,Đại Hồng Sa",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Trùng Nhật,Câu Trần,Thọ Tử,Tiểu Hồng Sa,Bất Thành,Thổ Phù,. Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Xá,Quan Nhật,Cát Kì,Thánh Tâm,Thanh Long,Ô Phệ",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Phục Binh,Hoàng Sa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng.",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thủ Nhật,Thu Vu,Phúc Đức,Ích Hậu,Minh Đường,Lộc Khố,Thiên Phú,Nguyệt Tài",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Cửu Không,Cửu Khảm,Cửu Tiêu,Đại Sát,Hành Ngận,Nguyệt Hư,Đại Họa,Thiên Tặc,Địa Hỏa,Nguyệt Sát,Thổ Ôn,Phi Liêm,Khô Tiêu,Phu Sát",
        nen: "",
        khongNen: "Xuất Hành,Cưới Gả",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thiên Nguyện,Tướng Nhật,Lục Hợp,Ngũ Phú,Bất Tương,Tục Thế,Trừ Thần,Ô Phệ,Thiên Quý",
        hungThan:
          "Hà Khôi,Tử Thần,Nguyệt Hình,Du Họa,Ngũ Hư,Huyết Kị,Phục Nhật,Ngũ Li,Thiên Hình,Mộc Mã,Tiểu Hao,Câu Giảo,Lôi Công,Ngõa Toái,Thiên Địa Chuyển,Hoang Vu,Thiên Địa Trù,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan:
          "Dân Nhật,Tam Hợp,Thời Âm,Bất Tương,Yếu An,Trừ Thần,Ô Phệ,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Tử Khí,Ngũ Li,Chu Tước,Thiên Ôn,Thiên Ngục,Thiên Hỏa,Tử Phù,Đại Hao,Chu Tước,Ngũ Quỷ",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Tứ Tướng,Bất Tương,Ngọc Vũ,Giải Thần,Kim Quĩ,Thiên Tài,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Tiểu Hao,Thiên Tặc,Địa Tặc,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ",
        khongNen:
          "Xuất Hành,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Dịch Mã,Thiên Hậu,Thiên Thương,Kim Đường,Bảo Quang,Địa Tài,Nguyệt  n,Binh Cát Binh Phúc Binh Bảo",
        hungThan:
          "Nguyệt Phá,Đại Hao,Vãng Vong,Trùng Nhật,Thần Cách,Thổ Kỵ,Tứ Phế",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Mã,Ô Phệ Đối,Hoạt Nhãn,Binh Cát Binh Phúc Binh Bảo,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Bạch Hổ,Thiên Sứ,Thiên Bảng,Ương Bại,Hoang Vu,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Lục Nghi,Ngọc Đường,Hỉ Thần",
        hungThan: "Yếm Đối,Chiêu Dao,Tứ Kích,Qui Kị,Lâm Nhật",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan: "Mẫu Thương,Kính An,Ngũ Hợp,Ô Phệ Đối,Minh Tinh",
        hungThan:
          "Thiên Cương,Kiếp Sát,Thiên Cẩu Nhật Thời,Nguyệt Hỏa,Phá Bại,Bát Tòa,Trùng Nhật,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm,Nguyệt Hại,Thổ Phù,Thiên Lao",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường, An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Âm Đức,Thời Dương,Sinh Khí,Phổ Hộ,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Tai Sát,Thiên Hỏa,Huyền Vũ,Thiên Cẩu Nhật Thời,Phi Ma,Nhân Cách,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn",
        nen: "",
        khongNen: "Xuất Hành",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Quan,Thiên Y,Phúc Tinh,Đại Hồng Sa,Thời Đức,Dương Đức,Phúc Sinh,Tư Mệnh",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Ngũ Hư,Bát Phong,Phục Binh,Hoang Vu",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Nguyệt Đức Hợp,Vương Nhật,Phúc Hậu,Đại Hồng Sa",
        hungThan:
          "Nguyệt Kiến,Đại Họa,Thọ Tử,Tiểu Hồng Sa,Bất Thành,Tiểu Thời,Thổ Phủ,Trùng Nhật,Câu Trần",
        nen: "Dâng Biểu Sớ,Cưới Gả.",
        khongNen: "Xuất Hành,Tu Tạo Động Thổ",
      },

      {
        catThan:
          "Thiên Đức Hợp,Quan Nhật,Cát Kì,Thánh Tâm,Thanh Long,Ô Phệ,Thiên Quý",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Phục Nhật,Tuế Bạc,Hoàng Sa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thủ Nhật,Lộc Khố,Thiên Phú,Nguyệt Tài,Thiên Quý,Thiên Vu,Phúc Đức,Ích Hậu,Minh Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Cửu Không,Cửu Khảm,Cửu Tiêu,Đại Sát,Bát Chuyên,Liễu Lệ, m Thác,Nguyệt Hư,Thiên Tặc,Nguyệt Sát,Thổ Ôn,Phi Liêm,Khô Tiêu,Phu Sát",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường, An Táng,Cải Táng",
      },

      {
        catThan:
          "Tứ Tướng,Tướng Nhật,Lục Hợp,Ngũ Phú,Bất Tương,Tục Thế,Trừ Thần",
        hungThan:
          "Hà Khôi,Tử Thần,Nguyệt Hình,Du Họa,Ngũ Hư,Huyết Kị,Ngũ Li,Thiên Hình,Mộc Mã,Tiểu Hao,Câu Giảo,Lôi Công,Huyết Kỵ,Thiên Địa Chuyển,Hoang Vu",
        nen: "",
        khongNen: "Dâng Biểu Sớ",
      },

      {
        catThan:
          "Thiên Ân,Nguyệt Ân,Tứ Tướng,Dân Nhật,Tam Hợp,Thời Âm,Yếu An,Trừ Thần,Ô Phệ,Binh Cát Binh Phúc Binh Bảo",
        hungThan:
          "Tử Khí,Địa Nang,Ngũ Li,Câu Trần,Thiên Ôn,Thiên Ngục,Thiên Hỏa,Tử Phù,Đại Hao,Chu Tước,Ngũ Quỷ",
        nen: "Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Ân,Ngọc Vũ,Giải Thần,Kim Quĩ,Thiên Tài,Binh Cát Binh Phúc Binh Bảo",
        hungThan: "Tiểu Hao,Thiên Tặc,Địa Tặc,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,An Táng",
        khongNen: "Xuất Hành,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Đức,Thiên Ân,Dịch Mã,Thiên Hậu,Thiên Thương,Kim Đường,Bảo Quang,Địa Tài,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Nguyệt Phá,Đại Hao,Vãng Vong,Trùng Nhật,Thần Cách,Vãng Vong,Thổ Kỵ,Tứ Phế",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Thiên Mã,Ô Phệ Đối,Hoạt Nhãn,Binh Cát Binh Phúc Binh Bảo,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Tứ Phế,Ngũ Hư,Bạch Hổ,Thiên Sứ,Thiên Bảng,Ương Bại,Trùng Nhật,Hoang Vu,Bàng Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Lục Nghi,Ngọc Đường,Hỉ Thần",
        hungThan:
          "Yếm Đối,Lâm Nhật,Bàng Phế,Tứ Phế,Chiêu Dao,Tử Kích,Qui Kị,Bát Chuyên,Xúc Thủy Long",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Cưới Gả",
      },

      {
        catThan: "Nguyệt Không,Mẫu Thương,Kính An,Ngũ Hợp,Minh Tinh,Ô Phệ Đối",
        hungThan:
          "Thiên Cương,Kiếp Sát,Nguyệt Hại,Thổ Phù,Bát Chuyên,Thiên Lao,Phục Binh,Thiên Cẩu Nhật Thời,Nguyệt Hỏa,Ương Bại,Bát Tòa,Đao Chẩn,Thổ Kỵ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Mẫu Thương,Âm Đức,Thời Dương,Sinh Khí,Phổ Hộ,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Tai Sát,Thiên Hỏa,Tứ Hao,Huyền Vũ,Đại Họa,Thiên Cẩu Nhật Thời,Huyền Vũ,Phi Ma,Nhân Cách,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thời Đức,Thiên Quan,Thiên Y,Phúc Tinh,Phúc Sinh,Thiên Quý,Đại Hồng Sa,Dương Đức,Phúc Sinh,Tư Mệnh",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Kị,Ngũ Hư,Phục Nhật,Huyết Chi,Thiên Địa Chuyển,Hoang Vu,Thiên Địa Trù,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Vương Nhật,Thiên Quý,Phúc Hậu,Đại Hồng Sa",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Trùng Nhật,Câu Trần,Dương Thác,Thọ Tử,Tiểu Hồng Sa,Bất Thành,Câu Trần,Dương Sai,Thổ Phủ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Tứ Tướng,Quan Nhật,Cát Kì,Thánh Tâm,Thanh Long",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Tuế Bạc,Hồng Sa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Thủ Nhật,Thiên Vu,Phúc Đức,Ích Hậu,Minh Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Cửu Không,Cửu Khảm,Cửu Tiêu,Đại Sát,Bát Chuyên,Cô Thần, m Thác",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Đức,Tướng Nhật,Lục Hợp,Ngũ Phú,Tục Thế,Trừ Thần,Ô Phệ",
        hungThan:
          "Hà Khôi,Tử Thần,Nguyệt Hình,Du Họa,Ngũ Hư,Huyết Kị,Ngũ Li,Bát Chuyên,Thiên Hình,Mộc Mã,Tiểu Hao,Câu Giảo,Lôi Công,Ngõa Toái,Hoang Vu",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Thiên Đức,Dân Nhật,Tam Hợp,Thời Âm,Yếu An,Trừ Thần,Ô Phệ,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tử Khí,Ngũ Li,Chu Tước,Thiên Ôn,Thiên Ngục,Thiên Hỏa,Tử Phù,Đại Hao,Chu Tước,Ngũ Quỷ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Ngọc Vũ,Giải Thần,Kim Quĩ,Thiên Tài,Binh Cát Binh Phúc Binh Bảo",
        hungThan:
          "Tiêu Hao,Thiên Tặc,Địa Tặc,Trùng Nhật,Tứ Phế,Bàng Phế,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Xuất Hành,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Dịch Mã,Địa Tài,Binh Cát,Binh Phúc,Binh Bảo,Thiên Hậu,Thiên Thương,Kim Đường,Bảo Quang",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Phế,Vãng Vong,Trùng Nhật, m Dương Giao Phá,Thần Cách,Thổ Kỵ,Bàng Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },
    ],
    //Tháng Ngọ
    [
      {
        catThan:
          "Thiên Ân,Lục Nghi,Giải Thần,Kim Quĩ,Thiên Tài,Giải Thần,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Yếm Đối,Chiêu Dao,Ngũ Hư,Tai Sát,Thiên Ngục,Thiên Tặc,Thiên Hỏa,Phi Ma,Thọ Tử,Hoang Vu,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Thiên Ân,Âm Đức,Thánh Tâm,Bảo Quang,Địa Tài",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Nguyệt Hại,Tứ Kích,Nguyệt Hại,Nguyệt Hư,Nguyệt Hỏa,Nguyệt Sát,Nhân Cách",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Ân,Mẫu Thương,Tam Hợp,Thiên Mã,Thiên Hỉ,Thiên Y,Ích Hậu,Ngũ Hợp,Ô Phệ Đối,Thiên Đức Hợp,Hỉ Thần,Thiên Quý",
        hungThan:
          "Đại Sát,Qui Kị,Bạch Hổ,Thiên Bảng,Hoàng Sa,Lôi Công,Phi Liêm,Thiên Địa Chuyển,Thiên Địa Trù,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Thu Nạp Người,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Thiên Quý,Tục Thế,Ngũ Hợp,Ngọc Đường,Ô Phệ Đối",
        hungThan:
          "Hà Khôi,Đại Thời,Đại Bại,Hàm Trì,Cửu Khảm,Huyết Kị,Vãng Vong,Phục Nhật,Thiên Cẩu Nhật Thời,Ngũ Quỷ,Câu Giảo,Bát Tòa,Ngõa Toái,Khô Tiêu,Trùng Tang,Huyết Kỵ,Thổ Kỵ,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Nguyệt Ân,Tứ Tướng,Thời Đức,Thời Dương,Sinh Khí,Yếu An,Minh Tinh,Đại Hồng Sa",
        hungThan:
          "Ngũ Hư,Cửu Không,Thiên Lao,Thiên Cẩu Nhật Thời,Phá Bại,Trùng Nhật,Thiên Địa Chuyển,Hoang Vu",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Tứ Tướng,Vương Nhật,Ngọc Vũ,Thiên Y,Phúc Hậu,Đại Hồng Sa",
        hungThan: "Du Họa,Huyết Chi,Trùng Nhật,Huyền Vũ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Dương Đức,Quan Nhật,Kim Đường,Tư Mệnh,Ô Phệ,Thiên Quan",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Nguyệt Yếm,Địa Hỏa,Thổ Phù,Thiên Ôn,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Nguyệt Đức Hợp,Thủ Nhật,Cát Kì,Lục Hợp",
        hungThan: "Câu Trần,Phu Sát",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Không,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Trừ Thần,Thanh Long,Ô Phệ,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Ngũ Hư,Ngũ Li,Phục Binh,Thổ Ôn,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Khai Trương,An Táng",
        khongNen: "Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Dân Nhật,Nguyệt Tài,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Bất Tương,Kính An,Trừ Thần,Minh Đường,Ô Phệ",
        hungThan:
          "Thiên Cương,Tử Thần,Thiên Lại,Chí Tử,Thiên Tặc,Ngũ Li,Đại Họa,Thiên Sứ,Thần Cách,Địa Tặc,Tiểu Hồng Sa,Bất Thành,Tiểu Hao,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tam Hợp,Lâm Nhật,Thời Âm,Thiên Thương,Bất Tương,Phổ Hộ,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Tử Khí,Thiên Hình,Đại Hao,Mộc Mã,Lâm Nhật,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Ngũ Phú,Bất Tương,Phúc Sinh,Thiên Đức,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Kiếp Sát,Tiểu Hao,Trùng Nhật,Chu Tước,Ương Bại,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Lục Nghi,Giải Thần,Kim Quĩ,Ô Phệ Đối,Thiên Tài,Thiên Quý,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Yếm Đối,Chiêu Dao,Tứ Kị,Thất Mã,Ngũ Hư,Xúc Thủy Long,Thiên Tặc,Phi Ma,Thọ Tử,Thiên Địa Chuyển,Hoang Vu,Thiên Địa Trù,Tứ Phế,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Âm Đức,Thánh Tâm,Bảo Quang,Địa Tài,Thiên Nguyện, m Đức,Thiên Quý",
        hungThan:
          "Nguyệt Sát,Nguyệt Hỏa,Nhân Cách,Trùng Tang,Nguyệt Hư,Nguyệt Hại,Tứ Kích,Phục Nhật",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Thiên Đức Hợp,Hỉ Thần,Nguyệt Ân,Tứ Tướng,Tam Hợp,Thiên Mã,Thiên Hỷ,Thiên Y,Ích Hậu,Ngũ Hợp",
        hungThan:
          "Đại Sát,Qui Kị,Bạch Hổ,Thiên Bảng,Hoàng Sa,Trùng Nhật,Lôi Công,Phi Liêm,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan: "Thiên Ân,Mẫu Thương,Tứ Tướng,Tục Thế,Ngũ Hợp,Ngọc Đường",
        hungThan:
          "Hà Khôi,Đại Thời,Đại Bại,Hàm Trì,Cửu Khảm,Cửu Tiêu,Huyết Kị,Vãng Vong,Thiên Cẩu Nhật Thời,Ngũ Quỷ,Câu Giảo,Bát Tòa,Ngõa Toái,Khô Tiêu,Huyết Kỵ,Thổ Kỵ,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Thời Đức,Thời Dương,Sinh Khí,Minh Tinh,Đại Hồng Sa,Yếu An",
        hungThan:
          "Ngũ Hư,Cửu Không,Thiên Lao. Thiên Cẩu Nhật Thời,Phá Bại,Hoang Vu",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thiên Ân,Vương Nhật,Ngọc Vũ,Thiên Y,Phúc Hậu,Đại Hồng Sa",
        hungThan: "Du Họa,Huyết Chi,Trùng Nhật,Huyền Vũ",
        nen: "",
        khongNen: "Xuất Hành",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Quan,Thiên Ân,Dương Đức,Quan Nhật,Kim Đường,Tư Mệnh,Ô Phệ",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Nguyệt Yếm,Địa Hỏa,Thổ Phù,Phục Binh,Thiên Ôn,Địa Hỏa,Thổ Phủ,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Thiên Ân,Thủ Nhật,Cát Kì,Lục Hợp,Bất Tương,",
        hungThan:
          "Xúc Thủy Long,Câu Trần,Đại Họa,Câu Trần,Bàng Phế,Tứ Phế,Phu Sát",
        nen: "Xuất Hành,Cưới Gả,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Bất Tương,Trừ Thần,Thanh Long,Ô Phệ,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Ngũ Hư,Bát Phong,Ngũ Li,Thổ Ôn,Hoang Vu",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,An Táng",
        khongNen: "Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Dân Nhật,Nguyệt Tài,Hoạt Nhãn,Binh Cát,Bính Phúc,Binh Bảo,Bất Tương,Kính An,Trừ Thần,Minh Đường,Ô Phệ",
        hungThan:
          "Thiên Cương,Tử Thần,Thiên Sứ,Thần Cách,Địa Tặc,Tiểu Hồng Sa,Bất Thành,Tiểu Hao,Thiên Lại,Chí Tử,Thiên Tặc,Ngũ Li",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Binh Cát,Binh Phúc,Binh Bảo,Tam Hợp,Lâm Nhật,Thời Âm,Thiên Thương,Bất Tương,Phổ Hộ",
        hungThan:
          "Tử Khí,Ngũ Mộ,Thiên Hình,Tử Phù,Đại Hao,Mộc Mã,Lâm Nhật,Thiên Địa Chuyển,Thiên Địa Trù,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Ngũ Phú,Phúc Sinh,Thiên Đức,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Kiếp Sát,Ương Bại,Trùng Tang,Tứ Phế,Tiểu Hao,Tứ Cùng,Thất Mã,Phục Nhật,Trùng Nhật,Chu Tước",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Lục Nghi,Giải Thần,Kim Quĩ,Thiên Tài,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Thiên Ngục,Thiên Tặc,Phi Ma,Thọ Tử,Trùng Nhật,Thiên Địa Chuyển,Hoang Vu,Tứ Phế,Đại Hao,Tai Sát,Thiên Hỏa,Yếm Đối,Chiêu Dao,Ngũ Hư",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Tứ Tướng,Âm Đức,Thánh Tâm,Bảo Quang,Địa Tài",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Tứ Kích,Nguyệt Hại,Nhân Cách",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Tam Hợp,Thiên Mã,Thiên Hỉ,Thiên Y,Ích Hậu,Ngũ Hợp,Ô Phệ Đối,Thiên Đức Hợp,Hỉ Thần",
        hungThan:
          "Đại Sát,Qui Kị,Bạch Hổ,Thiên Bảng,Hoàng Sa,Lôi Công,Phi Liêm,Đao Chẩn,Thổ Cấm",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Mẫu Thương,Tục Thế,Ngũ Hợp,Ngọc Đường,Ô Phệ Đối",
        hungThan:
          "Hà Khôi,Đại Thời,Đại Bại,Hàm Trì,Cửu Khảm,Huyết Kị,Vãng Vong,Thiên Cẩu Nhật Thời,Ngũ Quỷ,Câu Giảo,Bát Tòa,Ngõa Toái,Khô Tiêu,Thổ Kỵ,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
      },

      {
        catThan:
          "Nguyệt Không,Thời Đức,Thời Dương,Sinh Khí,Yếu An,Minh Tinh,Đại Hồng Sa",
        hungThan:
          "Ngũ Hư,Cửu Không,Thiên Lao,Phục Binh,Thiên Cẩu Nhật Thời,Phá Bại,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Vương Nhật,Ngọc Vũ,Thiên Y,Phúc Hậu,Đại Hồng Sa",
        hungThan:
          "Du Họa,Huyết Chi,Trùng Nhật,Huyền Vũ,Đại Họa,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Xá,Thiên Quan,Đương Đức,Quan Nhật,Kim Đường,Tư Mệnh,Ô Phệ",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Nguyệt Yếm,Địa Hỏa,Thổ Phù,Thiên Ôn,Thiên Địa Chuyển,. Thiên Địa Trù,Nguyệt Chuyển",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thủ Nhật,Cát Kì,Lục Hợp,Bất Tương",
        hungThan: "Câu Trần,Phu Sát",
        nen: "Xuất Hành,Cưới Gả,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Bất Tương,Trừ Thần,Thanh Long,Ô Phệ",
        hungThan:
          "Ngũ Hư,Ngũ Li,Thổ Ôn,Thiên Địa Chuyển,Hoang Vu,Thiên Địa Trù,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Dân Nhật,Kính An,Trừ Thần,Minh Đường,Ô Phệ,Nguyệt Tài,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Thiên Cương,Tử Thần,Thiên Lại,Chí Tử,Thiên Tặc,Phục Nhật,Ngũ Li,Thiên Sứ,Thần Cách,Địa Tặc,Tiểu Hồng Sa,Bất Thành,Tiểu Hao,Trùng Tang",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Tam Hợp,Lâm Nhật,Thời Âm,Thiên Thương,Bất Tương,Phổ Hộ,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tử Khí,Thiên Hình,Tử Phù,Đại Hao,Mộc Mã,Lâm Nhật,Trùng Nhật,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan:
          "Tứ Tướng,Ngũ Phú,Phúc Sinh,Thiên Đức,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Kiếp Sát,Ương Bại,Tứ Phế,Tiểu Hao,Trùng Nhật,Chu Tước",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Lục Nghi,Thủ Nhật,Thiên Tài,Giải Thần,Kim Quĩ,Ô Phệ Đối",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Ngục,Thiên Tặc,Phi Ma,Thọ Tử,Hoang Vu,Tứ Phế,Thiên Hỏa,Yếm Đối,Chiêu Dao,Ngũ Hư",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Nguyệt Đức Hợp,Địa Tài,Âm Đức,Thánh Tâm,Bảo Quang",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Nguyệt Hại,Tứ Kích,Nhân Cách",
        nen: "",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Không,Mẫu Thương,Tam Hợp,Thiên Mã,Thiên Hỉ,Thiên Y,Ích Hậu,Ngũ Hợp,Ô Phệ Đối,Thiên Đức Hợp,Hỉ Thần",
        hungThan:
          "Đại Sát,Qui Kị,Bạch Hổ,Phục Binh,Thiên Bảng,Hoàng Sa,Lôi Công,Phi Liêm,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Mẫu Thương,Tục Thế,Ngũ Hợp,Ngọc Đường,Ô Phệ Đối",
        hungThan:
          "Hà Khôi,Đại Thời,Đại Bại,Hàm Trì,Cửu Khảm,Cửu Tiêu,Huyết Kị,Vãng Vong,Đại Hao,Thiên Cẩu Nhật Thời,Câu Giảo,Ngũ Quỷ,Bát Tòa,Ngõa Toái,Khô Tiêu,Huyết Kỵ,Thổ Kỵ,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chấn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thời Đức,Thời Dương,Sinh Khí,Yếu An,Minh Tinh,Đại Hồng Sa",
        hungThan:
          "Nguyệt Hư,Bát Phong,Cửu Không,Địa Nang,Thiên Lao,Thiên Cẩu Nhật Thời,Phá Bại,Hoang Vu",
        nen: "Dâng Biểu Sớ,Xuất Hành",
        khongNen:
          "Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Vương Nhật,Ngọc Vũ,Thiên Y,Phúc Hậu,Đại Hồng Sa",
        hungThan: "Du Họa,Huyết Chi,Trùng Nhật,Huyền Vũ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Dương Đức,Quan Nhật,Kim Đường,Tư Mệnh,Ô Phệ,Thiên Quan,Thiên Quý",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Nguyệt Yếm,Địa Hỏa,Thổ Phù,Đại Hội, m Dương Câu Thác,Thiên Ôn,Địa Hỏa, m Sai,Dương Thác,Thổ Phủ,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Thiên Nguyện,Thủ Nhật,Cát Kì,Lục Hợp,Thiên Quý",
        hungThan: "Phục Nhật,Bát Chuyên,Câu Trần,Trùng Tang,Phu Sát",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Bất Tương,Trừ Thần,Thanh Long,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Ngũ Hư,Ngũ Li,Trùng Nhật,Thổ Ôn,Thiên Địa Chuyển,Hoang Vu",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương",
        khongNen: "Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Ân,Nguyệt Tài,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Tứ Tường,Dân Nhật,Kính An,Trừ Thần,Minh Đường,Ô Phệ",
        hungThan:
          "Thiên Cương,Tử Thần,Thiên Sứ,Thần Cách,Địa Tặc,Tiểu Hồng Sa,Bất Thành,Tiểu Hao,Thiên Lại,Chí Tử,Thiên Tặc,Ngũ Li",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Tam Hợp,Lâm Nhật,Thời Âm,Thiên Thương,Phổ Hộ,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Tử Khí,Thiên Hình,Tử Phù,Đại Hao,Mộc Mã,Lâm Nhật,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thiên Ân,Ngũ Phú,Phúc Sinh,Thiên Đức,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Kiếp Sát,Tiểu Hao,Trùng Nhật,Chu Tước,Tứ Phế,Ương Bại",
        nen: "",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Ân,Lục Nghi,Giải Thần,Kim Quĩ,Ô Phệ Đối,Thiên Tài,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Yếm Đối,Chiêu Dao,Tứ Phế,Ngũ Hư, m Dương Kích Xung,Phục Binh,Thiên Ngục,Thiên Tặc,Phi Ma,Thọ Tử,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Thiên Ân,Âm Đức,Thánh Tâm,Bảo Quang,Địa Tài",
        hungThan:
          "Nguyệt Sát,Đại Họa,Nguyệt Hỏa,Nhân Cách,Bàng Phế,Tứ Phế,Nguyệt Hư,Nguyệt Hại,Tứ Kích,Bát Chuyên,Xúc Thủy Long",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Tam Hợp,Thiên Mã,Thiên Hỉ,Thiên Y,Ích Hậu,Ngũ Hợp,Ô Phệ Đối,Thiên Đức Hợp,Hỉ Thần,Tam Hợp",
        hungThan:
          "Đại Sát,Qui Kị,Bát Chuyên,Bạch Hổ,Thiên Bảng,Hoàng Sa,Lôi Công,Phi Liêm,Đao Chẩn,Thổ Kỵ",
        nen: "Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Cải Táng",
        khongNen: "Cưới Gả,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Mẫu Thương,Tục Thế,Ngũ Hợp,Ngọc Đường,Ô Phệ Đối",
        hungThan:
          "Hà Khôi,Đại Thời,Đại Bại,Hàm Trì,Tứ Hao,Cửu Khảm,Huyết Kị,Vãng Vong,Thiên Cẩu Nhật Thời,Ngũ Quỷ,Câu Giảo,Bát Tòa,Ngoãi Toái,Khô Tiêu,Huyết Kỵ,Thổ Kỵ,Lỗ Ban,Đao Chấn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Thời Đức,Minh Tinh,Thiên Quý,Thời Dương,Sinh Khí,Yếu An,Đại Hồng Sa",
        hungThan:
          "Ngũ Hư,Cửu Không,Thiên Lao,Thiên Cẩu Nhật Thời,Phá Bại,Thiên Địa Chuyển,Hoang Vu,Thiên Địa Trù,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan: "Vương Nhật,Ngọc Vũ,Thiên Y,Thiên Quý,Phúc Hậu,Đại Hồng Sa",
        hungThan: "Du Họa,Huyết Chi,Phục Nhật,Trùng Nhật,Huyền Vũ,Trùng Tang",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Ân,Thiên Quan,Tứ Tướng,Dương Đức,Quan Nhật,Kim Đường,Tư Mệnh",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Nguyệt Yếm,Địa Hỏa,Thổ Phù,Tiểu Hội,Trùng Nhật,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Tứ Tướng,Thủ Nhật,Cát Kì,Lục Hợp",
        hungThan: "Bát Chuyên,Câu Trần,Phu Sát",
        nen: "Xuất Hành,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng.",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Trừ Thần,Thanh Long,Ô Phệ,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Ngũ Hư,Ngũ Li,Bát Chuyên,Thổ Kỵ,Hoang Vũ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Khai Trương,An Táng",
        khongNen: "Cưới Gả,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Dân Nhật,Kính An,Trừ Thần,Minh Đường,Ô Phệ,Nguyệt Tài,Hoạt Nhãn,Binh Cát,Binh Phúc Binh Bảo",
        hungThan:
          "Thiên Cương,Tử Thần,Thiên Lại,Thiên Sứ,Địa Tặc,Tiểu Hồng Sa,Bất Thành,Tiểu Hao,Chí Tử,Thiên Tặc,Ngũ Li",
        nen: "",
        khongNen: "Xuất Hành,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Không,Tam Hợp,Binh Cát,Binh Phúc,Binh Bảo,Lâm Nhật,Thời Âm,Thiên Thương,Phổ Hộ",
        hungThan:
          "Tử Khí,Địa Nang,Thiên Hình,Phục Binh,Tử Phù,Đại Hao,Mộc Mã,Lâm Nhật,Bàng Phế,Tứ Phế,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Cưới Gả,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Tu Tạo Động Thổ",
      },

      {
        catThan:
          "Ngũ Phú,Bất Tương,Phúc Sinh,Thiên Phúc,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Kiếp Sát,Đại Họa,Tiểu Hao,Chu Tước,Ương Bại,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },
    ],
    //Tháng Mùi
    [
      {
        catThan: "Thiên Đức,Nguyệt Đức,Thiên Ân,Kim Đường,Giải Thần,Thủ Nhật",
        hungThan:
          "Nguyệt Hại,Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Cửu Khảm,Cửu Tiêu,Qui Kị,Thiên Hình,Thiên Ôn,Nguyệt Hỏa,Hoang Vu,Tứ Phế",
        nen: "Dâng Biểu Sớ,Họp Thân Hữu,Xuất Hành,Động Thổ,An Táng",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Thiên Ân",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Kích,Cửu Không,Chu Tước,Nguyệt Hình,Chu Tước,Tiểu Hồng Sa,Bất Thành",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Ân,Thiên Tài,Hoạt Nhãn,Thiên Quý,Mẫu Thương,Ngũ Phú,Ngũ Hợp,Kim Quĩ,Ô Phệ Đối",
        hungThan:
          "Du Họa,Thiên Địa Chuyển,Thiên Địa Trù,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ",
        nen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Cải Táng.",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Kính An,Ngũ Hợp,Bảo Quang,Ô Phệ Đối,Hỉ Thần,Địa Tài,Thiên Quý",
        hungThan:
          "Đại Sát,Thiên Ngục,Thiên Hỏa,Lâm Nhật,Phi Liêm,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Thiên Ân,Tứ Tướng,Thời Đức,Thiên Mã,Phổ Hộ,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Ngũ Hư,Ngũ Mộ,Bạch Hổ,Thiên Bảng,Thiên Cẩu Nhật Thời,Bát Tọa,Thiên Địa Trù,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Phúc Tinh,Phúc Hậu,Đại Hồng Sa,Nguyệt Đức Hợp,Tứ Tướng,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Phúc Sinh,Ngọc Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Trùng Nhật,Phục Nhật, m Thác,Trùng Tang,Thiên Cẩu Nhật Thời,Thiên Tặc",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Quan Nhật,Lục Hợp,Ô Phệ,Thiên Y,Minh Tinh,Thánh Tâm",
        hungThan:
          "Thiên Lại,Chí Tử,Huyết Chi,Vãng Vong,Thiên Lao,Phục Binh,Thiên Sứ,Phá Bại,Thọ Tử,Thổ Kỵ,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển",
        nen: "An Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Nguyệt Ân,Thủ Nhật,Thánh Tâm,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Huyền Vũ,Địa Họa,Thần Cách,Phu Sát",
        nen: "Xuất Hành,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Dương Đức,Tướng Nhật,Cát Kì,Bất Tương,Ích Hậu,Trừ Thần,Tư Mệnh,Ô Phệ,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Kiếp Sát,Thiên Tặc,Ngũ Hư,Ngũ Li,Ngũ Quỷ,Bàng Phế,Tứ Phế,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Dân Nhật,Thiên Vu,Phúc Đức,Thiên Thương,Bất Tương,Tục Thế,Trừ Thần,Ô Phệ,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tai Sát,Thiên Hỏa,Huyết Kị,Ngũ Li,Câu Trần,Phi Ma,Câu Trần,Trùng Nhật,Thổ Ôn,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Binh Cát,Binh Phúc,Binh Bảo,Nguyệt Đức,Bất Tương,Yếu An,Thanh Long",
        hungThan:
          "Hà Khôi,Tử Thần,Nguyệt Sát,Nguyệt Hư,Thổ Phù,Tiểu Hao,Ương Sát,Câu Giảo,Ngõa Toái,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Âm Đức,Tam Hợp,Thời Âm,Lục Nghị,Ngọc Vũ,Minh Đường,Nguyệt Tài",
        hungThan:
          "Yếm Đối,Chiêu Dao,Tử Khí,Trùng Nhật,Tử Phù,Đại Hao,Nhân Cách,Lôi Công,Tứ Phế",
        nen: "Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan: "Kim Đường,Giải Thần,Ô Phệ Đối,Thiên Quý,Thủ Nhật",
        hungThan:
          "Nguyệt Hại,Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Tứ Kị,Thất Mã,Ngũ Hư,Cửu Khảm,Cửu Tiêu,Qui Kị,Xúc Thủy Long,Thiên Hình,Thiên Ôn,Nguyệt Hỏa,Hoàng Sa,Mộc Mã,Khô Tiêu,Thiên Địa Chuyển,Hoang Vu,Thiên Địa Chuyển,Tứ Phế,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Quý",
        hungThan:
          "Nguyệt Phá,Đại Hao,Nguyệt Hình,Tứ Kích,Cửu Không,Chu Tước,Bất Thành,Tiểu Hồng Sa",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Mẫu Thương,Thiên Tài,Hoạt Nhãn,Tứ Tướng,Ngũ Phú,Ngũ Hợp,Kim Quĩ",
        hungThan: "Du Họa",
        nen: "Xuất Hành,Tu Tạo Động Thổ,Khai Trường,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Hỉ Thần,Địa Tài,Nguyệt Đức Hợp,Thiên Ân,Mẫu Thương,Tứ Tướng,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Kính An,Ngũ Hợp,Bảo Quang",
        hungThan:
          "Đại Sát,Phục Nhật,Thiên Ngục,Thiên Hỏa,Lâm Nhật,Phi Liêm,Trùng Tang,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan: "Nguyệt Không,Thiên Ân,Thời Đức,Thiên Mã,Phổ Hộ,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Ngũ Hư,Bạch Hổ,Phục Binh,Thiển Bảng,Thiên Cẩu Nhật Thời,Bát Tòa,Hoang Vu",
        nen: "Nạp Tài",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Nguyệt Ân,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Phúc Sinh,Ngọc Đường,Phúc Hậu,Đại Hồng Sa",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Trùng Nhật. Đại Họa,Thiên Cẩu Nhật Thời,Thiên Tặc,Địa Hỏa",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Quan Nhật,Thiên Y,Minh Tinh,Thánh Tâm,Lục Hợp,Bất Tương,Ô Phệ",
        hungThan:
          "Thiên Lại,Chí Tử,Huyết Chi,Vãng Vong,Thiên Lao,Thiên Sứ,Phá Bại,Thọ Tử,Huyết Chi,Thổ Kỵ,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế",
        nen: "An Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Xếp Đặt Buồng Đẻ,Khơi Mương Đào Giếng,Đuổi Bắt,Săn Bắn,Đánh Cá,Gieo Trồng,Chăn Nuôi,Nạp Gia Súc",
      },

      {
        catThan:
          "Thiên Ân,Thủ Nhật,Bất Tương,Thánh Tâm,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Xúc Thủy Long,Huyền Vũ,Thần Cách,Trùng Nhật,Bàng Phế,Tứ Phế,Phu Sát",
        nen: "Xuất Hành,Cưới Gả",
        khongNen:
          "Dâng Biểu Sớ,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Đức,Dương Đức,Tướng Nhật,Cát Kì,Bất Tương,Ích Hậu,Trừ Thần,Tư Mệnh,Ô Phệ,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Kiếp Sát,Thiên Tặc,Ngũ Hư,Bát Phong,Ngũ Li. Địa Tặc,Ngũ Quỷ,Hoang Vu",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,An Táng,Cải Táng",
        khongNen: "Xuất Hành,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Dân Nhật,Thiên Vu,Phúc Đức,Thiên Thương,Bất Tương,Tục Thế,Trừ Thần,Ô Phệ,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Tai Sát,Thiên Hỏa,Huyết Kị,Ngũ Li,Câu Trần,Phi Ma,Thổ Ôn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Yếu An,Thanh Long,Thiên Quý,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Hà Khôi,Tử Thần,Nguyệt Sát,Nguyệt Hư,Thổ Phù,Địa Nang,Tiểu Hao,Ương Bại,Câu Giảo,Ngõa Toái,Thiên Địa Chuyển,Thiên Địa Trù,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Âm Đức,Nguyệt Tài,Thiên Quý,Tam Hợp,Thời Âm,Lục Nghi,Ngọc Vũ,Minh Đường",
        hungThan:
          "Yếm Đối,Chiêu Dao,Tử Khí,Tử Phù,Đại Hao,Thần Cách,Lôi Công,Tứ Phế,Tứ Cùng,Thất Mã,Trùng Nhật",
        nen: "Tu Tạo Động Thổ",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Tứ Tướng,Kim Đường,Giải Thần,Thủ Nhật",
        hungThan:
          "Nguyệt Hại,Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Cửu Khảm,Cửu Tiêu,Qui Kị,Thiên Hình,Thiên Ôn,Nguyệt Hỏa,Hoàng Sa,Mộc Mã,Khô Tiêu,Thiên Địa Chuyển,Hoang Vu,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Đức Hợp,Nguyệt Đức Hợp,Tứ Tướng",
        hungThan:
          "Nguyệt Phá,Đại Hao,Nguyệt Hình,Tứ Kích,Cửu Không,Phục Nhật,Chu Tước,Tiểu Hồng Sa,Trùng Tang",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Mẫu Thương,Ngũ Phú,Ngũ Hợp,Kim Quĩ,Ô Phệ Đối,Thiên Tài,Hoạt Nhãn",
        hungThan: "Du Họa,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Mẫu Thương,Nguyệt Ân,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Kính An,Ngũ Hợp,Bảo Quang,Ô Phệ Đối,Hỉ Thần,Địa Tài",
        hungThan:
          "Đại Sát,Thiên Ngục,Thiên Hỏa,Lâm Nhật,Phi Liêm,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Thời Đức,Thiên Mã,Phổ Hộ,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Ngũ Hư,Bạch Hổ,Thiên Bảng,Thiên Cẩu Nhật Thời,Bát Tòa,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Vương Nhật,Phúc Tinh,Phúc Sinh,Phúc Hậu,Đại Hồng Sa,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Phúc Sinh,Ngọc Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Trùng Nhật,Thiên Cẩu Nhật Thời,Thiên Tặc,Địa Hỏa,Trùng Nhật,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Đức,Thiên Xá,Quan Nhật,Lục Hợp,Bất Tương,Ô Phệ,Thiên Y,Minh Tinh,Thánh Tâm",
        hungThan:
          "Thiên Lại,Chí Tử,Huyết Chi,Thiên Sứ,Thọ Tử,Thổ Kỵ,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Vãng Vong,Thiên Lao",
        nen: "An Táng",
        khongNen: "",
      },

      {
        catThan: "Thủ Nhật,Bất Tương,Thánh Tâm,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Huyền Vũ,Thần Cách,Phu Sát",
        nen: "Xuất Hành,Cưới Gả",
        khongNen:
          "Dâng Biểu Sớ,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Dương Đức,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Tướng Nhật,Cát Kì,Ích Hậu,Trừ Thần,Tư Mệnh,Ô Phệ",
        hungThan:
          "Kiếp Sát,Thiên Tặc,Ngũ Hư,Ngũ Li,Địa Tặc,Ngũ Quỷ,Thiên Địa Chuyển,Hoang Vu,Thiên Địa Trù,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Dân Nhật,Thiên Vu,Phúc Đức,Thiên Thương,Tục Thế,Trừ Thần,Ô Phệ,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan: "Tai Sát,Thiên Hỏa,Huyết Kị,Ngũ Li,Câu Trần,Phi Ma,Thổ Ôn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tứ Tướng,Binh Cát,Binh Phúc,Binh Bảo,Bất Tương,Yếu An,Thanh Long",
        hungThan:
          "Hà Khôi,Tử Thần,Tiểu Hao,Ương Sát,Câu Giảo,Ngõa Toái,Thiên Địa Chuyển,Tứ Đại Mộ,Nguyệt Sát,Nguyệt Hư,Thổ Phù",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Tứ Tướng,Âm Đức,Tam Hợp,Thời Âm,Lục Nghi,Ngọc Vũ,Minh Đường",
        hungThan:
          "Yếm Đối,Chiêu Dao,Tử Phù,Đại Hao,Nhân Cách,Lôi Công,Trùng Tang,Tứ Phế,Tử Khí,Phục Nhật,Trùng Nhật",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",

        khongNen: "",
      },
      {
        catThan: "Nguyệt Không,Kim Đường,Giải Thần,Ô Phệ Đối,Thủ Nhật",
        hungThan:
          "Nguyệt Hại,Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Cửu Khảm,Cửu Tiêu,Qui Kị,Thiên Hình,Phục Binh,Thiên Ôn,Nguyệt Hỏa,Hoàng Sa,Mộc Mã,Khô Tiêu,Hoang Vu,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Ân",
        hungThan:
          "Nguyệt Phá,Đại Họa,Tiểu Hồng Sa,Bất Thành,Nguyệt Hình,Tứ Kích,Cửu Không,Chu Tước",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Mẫu Thương,Thiên Tài,Hoạt Nhãn,Ngũ Phú,Ngũ Hợp,Kim Quĩ,Ô Phệ Đối",
        hungThan: "Du Họa,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm",
        nen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Mẫu Thương,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Kính An,Ngũ Hợp,Bảo Quang,Ô Phệ Đối,Hỉ Thần,Địa Tài",
        hungThan:
          "Đại Sát,Thiên Ngục,Thiên Hỏa,Lâm Nhật,Trùng Nhật,Phi Liêm,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Thiên Đức,Nguyệt Đức,Thời Đức,Thiên Mã,Phổ Hộ,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Ngũ Hư,Thiên Bảng,Thiên Cẩu Nhật Thời,Bát Tòa,Hoang Vu,Bát Phong,Bạch Hổ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Vương Nhật,Phúc Tinh,Phúc Hậu,Đại Hồng Sa,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Phúc Sinh,Ngọc Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Trùng Nhật,Thiên Cẩu Nhật Thời,Thiên Tặc,Địa Hỏa",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Quan Nhật,Lục Hợp,Ô Phệ,Thiên Y,Minh Tinh,Thánh Tâm,Thiên Quý",
        hungThan:
          "Thiên Lại,Thiên Sứ,Phá Bại,Thọ Tử,Vãng Vong,Thổ Kỵ,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Tứ Đại Mộ,Chí Tử,Huyết Chi,Vãng Vong,Thiên Lao,Trục Trận",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Thủ Nhật,Thánh Tâm,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Bát Chuyên,Huyền Vũ,Dương Thác,Thần Cách,Phu Sát",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tứ Tướng,Dương Đức,Tướng Nhật,Cát Kì,Bất Tương,Ích Hậu,Trừ Thần,Tư Mệnh,Thiên Quan,Ích Hậu,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Kiếp Sát,Thiên Tặc,Ngũ Hư,Ngũ Li,Địa Tặc,Ngũ Quỷ,Thiên Địa Chuyển,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Thiên Ân,Tứ Tướng,Dân Nhật,Thiên Vu,Phúc Đức,Thiên Thương,Tục Thế,Trừ Thần,Ô Phệ,Lộc Khố,Thiên Phú,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tai Sát,Thiên Hỏa,Huyết Kị,Phi Ma,Câu Giảo,Thổ Kỵ,Trùng Tang,Huyết Kỵ,Phục Nhật,Ngũ Li,Câu Trần",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Ân,Binh Cát,Binh Phúc,Binh Bảo,Yếu An,Thanh Long",
        hungThan:
          "Hà Khôi,Tử Thần,Phục Binh,Tiểu Hao,Ương Bại,Câu Giảo,Ngõa Toái,Tứ Đại Mộ,Nguyệt Sát,Nguyệt Hư,Thổ Phù",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Ân,Nguyệt Ân,Âm Đức,Tam Hợp,Thời Âm,Lục Nghi,Ngọc Vũ,Minh Đường,Nguyệt Tài",
        hungThan:
          "Yếm Đối,Chiêu Dao,Đại Họa,Tử Phù,Đại Hao,Nhân Cách,Lôi Công,Tứ Phế,Tử Khí,Trùng Nhật",
        nen: "Xuất Hành,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Kim Đường,Giải Thần,Ô Phệ Đối,Thủ Nhật",
        hungThan:
          "Nguyệt Hại,Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Tứ Phế,Ngũ Hư,Cửu Khảm,Qui Kị,Thiên Hình,Thiên Ôn,Nguyệt Hỏa,Hoàng Sa,Mộc Mã,Khô Tiêu,Hoang Vu,Bàng Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân",
        hungThan:
          "Nguyệt Phá,Đại Hao,Nguyệt Hình,Tứ Kích,Cửu Không,Bát Chuyên,Xúc Thủy Long,Chu Tước,Dương Phá  m Xung,Tiểu Hồng Sa,Bất Thành,Trùng Nhật,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Đức,Thiên Tài,Hoạt Nhãn,Nguyệt Đức,Mẫu Thương,Ngũ Phú,Ngũ Hợp,Kim Quĩ,Ô Phệ Đối",
        hungThan: "Du Họa,Bát Chuyên,Thổ Cấm,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng",
        khongNen: "Cưới Gả,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Mẫu Thương,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Kính An,Ngũ Hợp,Bảo Quang,Ô Phệ Đối,Hỉ Thần,Địa Tài",
        hungThan:
          "Tứ Hao,Đại Sát,Thiên Ngục,Thiên Hỏa,Lâm Nhật,Phi Liêm,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Thời Đức,Thiên Mã,Phổ Hộ,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Ngũ Hư,Địa Nang,Bạch Hổ,Thiên Bàng,Thiên Cẩu Nhật Thời,Bát Tòa,Thiên Địa Chuyển,Hoang Vu,Thiên Địa Trù,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Phúc Sinh,Ngọc Đường,Phúc Tinh,Thiên Quý,Phúc Hậu,Đại Hồng Sa",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Trùng Nhật, m Thác,Thiên Cẩu Nhật Thời,Thiên Tặc,Địa Hỏa",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Nguyện,Thiên Y,Minh Tinh,Thánh Tâm,Tứ Tướng,Quan Nhật,Lục Hợp,Bất Tương",
        hungThan:
          "Thiên Lại,Chí Tử,Thiên Sứ,Phá Bại,Thọ Tử,Thổ Kỵ,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Huyết Chi,Vãng Vong,Thiên Lao,Trục Trận",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Binh Cát,Binh Phúc,Binh Bảo,Nguyệt Đức Hợp,Tứ Tướng,Thủ Nhật,Thánh Tâm",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Phục Nhật,Bát Chuyên,Huyền Vũ,Dương Thác,Thần Cách,Trùng Tang,Phu Sát",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Dương Đức,Tướng Nhật,Cát Kì,Ích Hậu,Trừ Thần,Tư Mệnh,Ô Phệ,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Kiếp Sát,Thiên Tặc,Ngũ Hư,Ngũ Li,Bát Chuyên,Phục Binh,Địa Tặc,Ngũ Quỷ,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Ân,Dân Nhật,Thiên Vu,Phúc Đức,Thiên Thương,Tục Thế,Trừ Thần,Ô Phệ,Lộc Khố,Thiên Phú,Tục Thế,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tai Sát,Thiên Hỏa,Huyết Kị,Ngũ Li,Câu Trần,Đại Họa,Phi Ma,Thổ Ôn,Huyết Kỵ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Bất Tương,Yếu An,Thanh Long,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Hà Khôi,Tử Thần,Nguyệt Sát,Nguyệt Hư,Thổ Phù,Tiểu Hao,Ương Bại,Câu Giảo,Ngõa Toái,Bàng Phế,Tứ Phế,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Âm Đức,Tam Hợp,Nguyệt Tài,Thời Âm,Lục Nghi,Ngọc Vũ,Minh Đường",
        hungThan:
          "Yếm Đối,Chiêu Dao,Tử Phù,Đại Hao,Nhân Cách,Trùng Nhật,Lôi Công,Bàng Phế,Tử Khí,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },
    ],
    //Tháng Thân
    [
      {
        catThan:
          "Thiên Ân,Thời Đức,Dân Nhật,Tam Hợp,Lâm Nhật,Thời Âm,Phúc Sinh,Thanh Long,Phúc Tinh,Phúc Sinh",
        hungThan:
          "Tử Khí,Tử Phù,Đại Hao,Lâm Nhật,Trùng Nhật,Thổ Kỵ,Bàng Phế,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Thiên Ân,Mẫu Thương,Minh Đường",
        hungThan: "Tiểu Hao,Qui Kị,Thọ Tử,Ngũ Quỷ,Bàng Phế,Tứ Phế,Tứ Đại Mộ",
        nen: "",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Ân,Dịch Mã,Thiên Hậu,Thánh Tâm,Giải Thần,Ngũ Hơp,Ô Phệ Đối",
        hungThan: "Nguyệt Phá,Đại Hao,Nguyệt Hình,Thiên Hình,Phục Binh,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Nguyệt Đức Hợp,Thiên Ân,Ích Hậu,Ngũ Hợp,Ô Phệ Đối,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Thổ Phù,Chu Tước,Đại Họa,Thiên Sứ,Hoang Vu,Thiên Địa Chuyển,Tứ Phế",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Xuất Hành",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thiên Ân,Mẫu Thương,Tam Hợp,Thiên Hỉ,Thiên Y,Tục Thế,Kim Quĩ,Hỉ Thần,Thiên Tài",
        hungThan:
          "Nguyệt Yếm,Nguyệt Hư,Nguyệt Sát,Phi Liêm,Địa Hỏa,Tứ Kích,Đại Sát,Huyết Kị",
        nen: "Dâng Biểu Sớ,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "Xuất Hành,Cưới Gả",
      },

      {
        catThan: "Thiên Nguyện,Lục Hợp,Địa Tài,Ngũ Phú,Yếu An,Bảo Quang",
        hungThan:
          "Hà Khôi,Kiếp Sát,Trùng Nhật,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Câu Giảo,Bát Tòa,Lôi Công,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Xuất Hành",
      },

      {
        catThan:
          "Thiên Mã,Thời Dương,Sinh Khí,Ngọc Vũ,Ô Phệ,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Tai Sát,Thiên Hỏa,Phục Nhật,Bạch Hổ,Thiên Ngục,Thiên Bảng,Hoàng Sa,Phi Ma,Trùng Tang,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen: "Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Đại Hồng Sa,Kim Đường,Ngọc Đường",
        hungThan:
          "Nguyệt Sát,Thiên Cẩu Nhật Thời,Địa Tặc,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ,Nguyệt Hư,Huyết Chi,Thiên Tặc,Ngũ Hư",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Đức,Nguyệt Ân,Tứ Tướng,Vương Nhật,Thiên Thương,Bất Tương,Trừ Thần,Ô Phệ,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo,Ích Hậu",
        hungThan:
          "Nguyệt Kiến,Tiểu Hao,Thổ Phủ,Ngũ Li,Thiên Lao,Phá Bại,Bất Thành,Thổ Phủ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Thu Nạp Người,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Đức,Tứ Tướng,Âm Đức,Quan Nhật,Cát Kì,Bất Tương,Trừ Thần,Ô Phệ,Nguyệt Đức,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Cửu Khảm,Khô Tiêu,Vãng Vong,Ngũ Li,Huyền Vũ,Thiên Ôn,Nhân Cách,Phu Sát,Nhân Cách,Ương Bại,Thiên Địa Chuyển,Nguyệt Chuyển",
        nen: "Tu Tạo Động Thổ,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
      },

      {
        catThan:
          "Mẫu Thương,Dương Đức,Thủ Nhật,Thiên Vu,Phúc Đức,Lục Nghi,Kính An,Tư Mệnh,Thiên Quan,Lộc Khố,Thiên Phú",
        hungThan:
          "Yếm Đối,Chiêu Dao,Thiên Cẩu,Cửu Không,Thiên Tặc,Trùng Nhật,Thổ Ôn,Bàng Phế,Tứ Phế",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Tướng Nhật,Phổ Hộ,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hại,Du Họa,Ngũ Hư,Trùng Nhật,Câu Trần,Thiên Hại,Nguyệt Hỏa,Mộc Mã,Tiểu Hao,Bàng Phế,Tứ Phế,Ngoãi Toái,Hoang Vu,Câu Trần,",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Thời Đức,Dân Nhật,Tam Hợp,Lâm Nhật,Thời Âm,Phúc Sinh,Thanh Long,Ô Phệ Đối,Phúc Tinh,Phúc Sinh",
        hungThan: "Tử Khí,Xúc Thủy Long,Phục Binh,Đại Hao,Lâm Nhật,Thổ Kỵ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Nguyệt Đức Hợp,Mẫu Thương ,Minh Đường",
        hungThan:
          "Tiểu Hao,Qui Kị,Đại Họa,Thọ Tử,Ngũ Quỷ,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan: "Thiên Đức Hợp,Dịch Mã,Thiên Hậu,Thánh Tâm,Giải Thần,Ngũ Hợp",
        hungThan: "Nguyệt Phá,Đại Hao,Nguyệt Hình,Thiên Hình,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Ích Hậu,Ngũ Hợp,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Thổ Phù,Thiên Sứ,Hoang Vu,Tứ Phế,Chu Tước",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Tam Hợp,Thiên Hỉ,Thiên Y,Tục Thế,Kim Quĩ,Hỉ Thần,Thiên Tài,Thiên Quý",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tứ Kích,Đại Sát,Huyết Kị,Phục Nhật,Đại Hội,Nguyệt Hư,Nguyệt Sát,Phi Liêm,Trùng Tang",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Thiên Ân,Lục Hợp,Ngũ Phú,Yếu An,Bảo Quang,Địa Tài,Thiên Quý",
        hungThan:
          "Hà Khôi,Kiếp Sát,Trùng Nhật,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Câu Giảo,Bát Tòa,Lôi Công,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ",
        nen: "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Xuất Hành",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Ân,Nguyệt Ân,Tứ Tướng,Thiên Mã,Thời Dương,Sinh Khí,Bất Tương,Ngọc Vũ,Ô Phệ,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tai Sát,Thiên Ngục,Thiên Bảng,Hoàng Sa,Phi Ma,Lỗ Ban,Đao Chẩn,Thiên Hỏa,Bạch Hổ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa,Thiên Ân,Mẫu Thương,Tứ Tướng,Bất Tương,Kim Đường,Ngọc Vũ",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Thiên Tặc,Ngũ Hư,Xúc Thủy Long,Thiên Cẩu Nhật Thời,Địa Tặc,Thiên Địa Chuyển,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Vương Nhật,Thiên Thương,Bất Tương,Trừ Thần,Ô Phệ,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Phá Bại,Bất Thành,Trùng Nhật,Bàng Phế,Tứ Phế,Tiểu Thời,Thổ Phủ,Ngũ Li,Thiên Lao",
        nen: "Xuất Hành,Cưới Gả",
        khongNen:
          "Dâng Biểu Sớ,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Âm Đức,Quan Nhật,Binh Cát,Binh Phúc,Binh Bảo,Cát Kì,Bất Tương,Trừ Thần,Ô Phệ",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Cửu Khảm,Cửu Tiêu,Vãng Vong,Ngũ Li,Huyền Vũ,Thiên Ôn,Nhân Cách,Phá Bại,Khô Tiêu,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế,Phu Sát",
        nen: "An Táng",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Lập Ước Giao Dịch",
      },

      {
        catThan:
          "Nguyệt Không,Mẫu Thương,Dương Đức,Thủ Nhật,Thiên Vu,Phúc Đức,Lục Nghi,Kính An,Tư Mệnh,Thiên Quan,Lộc Khố,Thiên Phú",
        hungThan:
          "Yếm Đối,Chiêu Dao,Thiên Cẩu,Cửu Không,Phục Binh,Thiên Tặc,Thổ Ôn",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Nguyệt Đức Hợp,Tướng Nhật,Phổ Hộ,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hai,Du Họa,Ngũ Hư,Bát Phong,Địa Nang,Trùng Nhật,Câu Trần,Nguyệt Hại,Đại Họa,Mộc Mã,Tiểu Hao,Ngõa Toái,Hoang Vu,Thiên Địa Chuyển",
        nen: "Dâng Biểu Sớ,Xuất Hành.",
        khongNen: "Cưới Gả,Tu Tạo Động Thổ",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thời Đức,Dân Nhật,Tam Hợp,Lâm Nhật,Thời Âm,Phúc Sinh,Thanh Long,Phúc Tinh",
        hungThan: "Tử Khí,Tử Phù,Đại Hao,Lâm Nhật,Thổ Kỵ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng.",
        khongNen: "",
      },

      {
        catThan: "Mẫu Thương,Minh Đường",
        hungThan: "Tiểu Hao,Qui Kị,Thọ Tử,Ngũ Quỷ,Tứ Đại Mộ",
        nen: "",
        khongNen: "Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Dịch Mã,Thiên Hậu,Thánh Tâm,Giải Thần,Ngũ Hợp,Ô Phệ Đối,Thiên Quý",
        hungThan:
          "Nguyệt Phá,Trùng Tang,Tứ Phế,Đại Hao,Nguyệt Hình,Phục Nhật,Thiên Hình",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Ích Hậu,Ngũ Hợp,Ô Phệ Đối,Thiên Quý,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chu Tước,Ngũ Hư,Thổ Phù,Câu Trần,Thiên Sứ,Thiên Địa Chuyển,Hoang Vu,Tứ Phề,Tứ Đại Mộ",
        nen: "Cải Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Đức,Hỉ Thần,Thiên Tài,Thiên Tài,Mẫu Thương,Nguyệt Ân,Tứ Tướng,Tam Hợp,Thiên Hỉ,Thiên Y,Tục Thế,Kim Quĩ",
        hungThan:
          "Nguyệt Yến,Địa Hỏa,Tứ Kích,Đại Sát,Huyết Kị,Nguyệt Hư,Phi Liêm",
        nen: "Dâng Biểu Sớ,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng.",
        khongNen: "Xuất Hành,Cưới Gả.",
      },

      {
        catThan:
          "Thiên Đức,Tứ Tướng,Lục Hợp,Ngũ Phú,Bất Tương,Yếu An,Bảo Quang,Địa Tài",
        hungThan:
          "Hà Khôi,Kiếp Sát,Trùng Nhật,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Câu Giảo,Bát Tòa,Lôi Công,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng.",
        khongNen: "Xuất Hành",
      },

      {
        catThan:
          "Thiên Mã,Thời Dương,Sinh Khí,Bất Tương,Ngọc Vũ,Ô Phệ,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tai Sát,Thiên Hỏa,Bạch Hổ,Thiên Ngục,Thiên Bảng,Hoàng Sa,Phi Ma,Trùng Nhật,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn",
        nen: "",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Mẫu Thương,Bất Tương,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa,Kim Đường,Ngọc Đường",
        hungThan:
          "Nguyệt Sát,Thiên Cẩu Nhật Thời,Địa Tặc,Bàng Phế,Tứ Phế,Hoang Vu,Nguyệt Hư,Huyết Chi,Thiên Tặc,Ngũ Hư",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Không,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo,Ích Hậu,Vương Nhật,Thiên Thương,Trừ Thần,Ô Phệ",
        hungThan:
          "Nguyệt Kiến,Phục Binh,Phá Bại,Bất Thành,Tiểu Thời,Thổ Phủ,Ngũ Li,Thiên Lao",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Âm Đức,Binh Cát,Binh Phúc,Binh Bảo,Quan Nhật,Cát Kì,Trừ Thần,Ô Phệ",
        hungThan:
          "Đại Thời,Đại Bai,Hàm Trì,Cửu Khảm,Vãng Vong,Ngũ Li,Huyền Vũ,Đại Họa,Thiên Ôn,Nhân Cách,Ương Bại,Khô Tiêu,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát",
        nen: "Tu Tạo Động Thổ,An Táng",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
      },

      {
        catThan:
          "Thiên Đức Hợp,Mẫu Thương,Dương Đức,Thủ Nhật,Thiên Vu,Phúc Đức,Lục Nghi,Kính An,Tư Mệnh,Thiên Quan,Lộc Khố,Thiên Phú",
        hungThan: "Yếm Đối,Chiêu Dao,Thiên Cẩu,Cửu Không,Thiên Tặc,Thổ Ôn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan: "Tướng Nhật,Phổ Hộ,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hại,Du Họa,Ngũ Hư,Trùng Nhật,Câu Trần,Nguyệt Hỏa,Mộc Mã,Tiểu Hao,Ngõa Toái,Hoang Vu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thời Đức,Dân Nhật,Tam Hợp,Lâm Nhật,Thời Âm,Phúc Sinh,Thanh Long,Ô Phệ Đối,Phúc Tinh,Thiên Quý",
        hungThan:
          "Tử Khí,Tứ Kị,Cửu Hổ,Phục Nhật,Tử Phù,Đại Hao,Lâm Nhật,Trùng Nhật,Thổ Kỵ",
        nen: "Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng.",
        khongNen: "Cưới Gả,An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Minh Đường,Thiên Quý",
        hungThan:
          "Tiểu Hao,Ngũ Mộ,Qui Kị,Thọ Tử,Ngũ Quỷ,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Nguyệt Ân,Tứ Tướng,Dịch Mã,Thiên Hậu,Thánh Tâm,Giải Thần,Ngũ Hợp,Ô Phệ Đối",
        hungThan: "Nguyệt Phá,Đại Hao,Nguyệt Hình,Thiên Hình,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan: "Thiên Đức,Tứ Tướng,Ích Hậu,Ngũ Hợp,Thủ Nhật,Ô Phệ Đối",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Thổ Phù,Chu Tước,Thiên Sứ,Thiên Địa Chuyển,Hoang Vu,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Mẫu Thương,Hỉ Thần,Thiên Tài,Tam Hợp,Thiên Hỉ,Thiên Y,Tục Thế,Kim Quĩ",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Nguyệt Hư,Nguyệt Sát, m Thác,Trùng Nhật,Phi Liêm,Bàng Phế,Tứ Phế,Tứ Kích,Đại Sát,Huyết Kị, m Thác",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường, An Táng,Cải Táng",
      },

      {
        catThan: "Lục Hợp,Địa Tài,Ngũ Phú,Bất Tương,Yếu An,Bảo Quang",
        hungThan:
          "Hà Khôi,Kiếp Sát,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Câu Giảo,Bát Tòa,Lôi Công,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm,Trùng Nhật",
        nen: "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Xuất Hành",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Mã,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo,Thời Dương,Sinh Khí,Ngọc Vũ,Ô Phệ",
        hungThan:
          "Tai Sát,Thiên Hỏa,Bạch Hổ,Phục Binh,Thiên Ngục,Thiên Bảng,Hoàng Sa,Phi Ma,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Phúc Hậu,Mẫu Thương,Kim Đường,Ngọc Đường",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Thiên Tặc,Ngũ Hư,Bát Phong,Bát Chuyên,Ương Bại,Bất Thành,Thổ Phủ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thiên Xá,Vương Nhật,Thiên Thương,Bất Tương,Trừ Thần,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phù,Ngũ Li,Thiên Lao,Ương Bại,Bất Thành",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Binh Cát,Binh Phúc,Binh Bảo,Âm Đức,Quan Nhật,Cát Kì,Trừ Thần,Ô Phệ",
        hungThan:
          "Đại Thời,Thiên Ôn,Huyền Vũ,Tai Sát,Vãng Vong,Khô Tiêu,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Đại Bại,Hàm Trì,Cửu Khảm,Thiên Ôn,Nhân Cách,Ngũ Li",
        nen: "An Táng",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Lập Ước Giao Dịch",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Dương Đức,Thủ Nhật,Thiên Vu,Phúc Đức,Lục Nghi,Thiên Quan,Lộc Khố,Thiên Phú,Thiên Quý,Kính An,Tư Mệnh",
        hungThan:
          "Yếm Đối,Chiêu Dao,Thiên Cẩu,Cửu Không,Phục Nhật,Thiên Tặc,Thổ Ôn,Trùng Tang",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Tướng Nhật,Phổ Hộ,Hoạt Nhãn,Thiên Quý",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hại,Du Họa,Tứ Cung,Cửu Thổ,Ngũ Hư,Trùng Nhật,Câu Trần,Nguyệt Hỏa,Mộc Mã,Tiểu Hao,Ngõa Toái,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Ân,Nguyệt Ân,Tứ Tướng,Thời Đức,Dân Nhật,Tam Hợp,Thời Âm,Phúc Sinh,Thanh Long,Phúc Tinh,Ô Phệ Đối",
        hungThan: "Tử Khí,Tử Phù,Đại Hao,Lâm Nhật,Thổ Kỵ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Thiên Đức,Thiên Ân,Mẫu Thương,Tứ Tướng,Minh Đường",
        hungThan:
          "Tiểu Hao,Thọ Tử,Ngũ Quỷ,Thiên Địa Chuyển,Tứ Đại Mộ,Qui Kị,Bát Chuyên,Xúc Thủy Long",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Dịch Mã,Thiên Nguyện,Thiên Hậu,Thánh Tâm,Giải Thần,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Nguyệt Phá,Đại Hao,Trùng Nhật,Bàng Phế,Nguyệt Hình,Tứ Phế,Bát Chuyên,Thiên Hình",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Ích Hậu,Ngũ Hợp,Ô Phệ Đối,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Tứ Phế,Ngũ Hư,Thiên Sứ,Thổ Phù,Chu Tước,Tam  m,Hoang Vu,Bàng Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Không,Hỉ Thần,Thiên Tài,Mẫu Thương,Tam Hợp,Thiên Hỉ,Thiên Y,Tục Thế,Kim Quĩ",
        hungThan:
          "Nguyệt Yếm,Nguyệt Hư,Phục Binh,Nguyệt Sát,Phi Liêm,Địa Hỏa,Tứ Kích,Đại Sát,Huyết Kị",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Nguyệt Đức Hợp,Lục Hợp,Địa Tài,Ngũ Phú,Yếu An,Bảo Quang",
        hungThan:
          "Hà Khôi,Kiếp Sát,Địa Nang,Đại Họa,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Câu Giảo,Bát Tòa,Lôi Công,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm,Trùng Nhật",
        nen: "Dâng Biểu Sớ,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Xuất Hành,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thiên Mã,Thời Dương,Sinh Khí,Bất Tương,Ngọc Vũ,Nguyệt Hình,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tai Sát. Thiên Hỏa,Tứ Hao,Bạch Hổ,Thiên Ngục,Thiên Bảng,Hoàng Sa,Phi Ma,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan:
          "Mẫu Thương,Kim Đường,Ngọc Đường,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Nguyệt Sát,Thiên Cẩu Nhật Thời,Địa Tặc,Hoang Vu,Nguyệt Hư,Huyết Chi,Thiên Tặc,Ngũ Hư,Bát Chuyên",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Vương Nhật,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Phúc Hậu,Thiên Thương,Trừ Thần,Ô Phệ",
        hungThan:
          "Nguyệt Kiến,Phá Bại,Bất Thành,Dương Thác,Trùng Tang,Tiểu Thời,Thổ Phủ,Phục Nhật,Ngũ Li,Bát Chuyên,Thiên Lao",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Âm Đức,Quan Nhật,Cát Kì,Trừ Thần,Ô Phệ,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý.",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Cửu Khảm,Khô Tiêu,Vãng Vong,Ngũ Li,Huyền Vũ,Thiên Ôn,Nhân Cách,Tai Sát,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Tứ Đại Mộ",
        nen: "An Táng",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Lập Ước Giao Dịch",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Quan,Lộc Khố,Thiên Phú,Mẫu Thương,Nguyệt Ân,Tứ Tướng,Dương Đức,Thủ Nhật,Thiên Vu,Phúc Đức,Lục Nghi,Kính An,Tư Mệnh",
        hungThan: "Yếm Đối,Chiêu Dao,Thiên Cẩu,Cửu Không,Thiên Tặc,Thổ Ôn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan: "Thiến Đức,Tứ Tướng,Tướng Nhật,Phổ Hộ,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hại,Du Họa,Ngũ Hư,Trùng Nhật,Câu Trần,Nguyệt Hỏa,Mộc Mã,Tiểu Hao,Câu Trần,Ngõa Toái,Thiên Địa Chuyển,Hoang Vu",
        nen: "",
        khongNen: "Cưới Gả",
      },
    ],
    //Tháng Dậu
    [
      {
        catThan:
          "Nguyệt Không,Thiên Ân,Thời Đức,Dương Đức,Dân Nhật,Ngọc Vũ,Tư Mệnh,Thiên Quan",
        hungThan:
          "Hà Khôi,Phục Binh,Thiên Sứ,Bất Thành,Tiểu Hao,Câu Giảo,,Bàng Phế,Tứ Phế,Tử Thần,Thiên Lại,Chí Tử,Vãng Vong,",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Đức Hợp,Thiên Ân,Mẫu Thương,Tam Hợp,Thời Âm,Kim Đường",
        hungThan:
          "Tử Khí,Đại Họa,Tử Phù,Đại Hao,Trùng Nhật,Bàng Phế,Tứ Phế,Tứ Đại Mộ,Câu Trần",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan: "Thiên Ân,Giải Thần,Thiên Đức,Ngũ Hợp,Thanh Long,Ô Phệ Đối",
        hungThan: "Kiếp Sát,Tiểu Hao,Địa Nang,Hoàng Sa,Tứ Phế,Qui Kị",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Ngũ Hợp,Minh Đường,Ô Phệ Đối,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Nguyệt Yếm,Địa Hỏa,Nguyệt Yểm,Thiên Tặc,Thần Cách,Phi Ma,Hoang Vu,Thiên Địa Chuyển,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Thiên Ân,Hoạt Nhãn,Mẫu Thương,Lục Hợp,Bất Tương,Kính An",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Tứ Kích,Thiên Hình",
        nen: "",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Tam Hợp,Lâm Nhật,Hỉ Thần,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo,Thiên Y,Phổ Hộ",
        hungThan: "Trùng Nhật,Chu Tước,Ngũ Quỷ,Lâm Nhật,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Xuất Hành,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Phúc Sinh,Thiên Tài,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Kim Quĩ,Ô Phệ",
        hungThan:
          "Thiên Cương,Thiên Cẩu Nhật Thời,Địa Tặc,Bát Tòa,Ngõa Toái,Lỗ Ban,Đao Chẩn,Đại Thời,Đại Bại,Hàm Trì,Thiên Tặc,Cửu Khảm,Khô Tiêu",
        nen: "",
        khongNen: "Xuất Hành,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Mẫu Thương,Âm Đức,Thời Dương,Sinh Khí,Thiên Thương,Bất Tương,Bảo Quang,Địa Tài,Binh Cát,Binh Phúc,Binh Hảo,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Ngũ Hư,Cửu Không,Thổ Phù,Phục Nhật,Nguyệt Hư,Thiên Cẩu Nhật Thời,Nguyệt Sát,Thọ Tử,Nhân Cách,Trùng Tang,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
        khongNen:
          "Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tứ Tướng,Vương Nhật,Thiên Mã,Ngũ Phú,Bất Tương,Thánh Tâm,Trừ Thần,Ô Phệ,Thiên Y,Ngũ Phú,Thánh Tâm,Binh Cát,Binh Phúc,Binh Bảo,Phúc Hậu",
        hungThan:
          "Du Họa,Huyết Chi,Thiên Ôn,Thiên Bảng,Ương Bại,Lôi Công,Ngũ Li,Bạch Hổ",
        nen: "An Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Quan Nhật,Lục Nghi,Ích Hậu,Trừ Thần,Ngọc Đường,Ô Phệ",
        hungThan:
          "Nguyệt Kiến,Thiên Ngục,Thiên Hỏa,Tiểu Hồng Sa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Bảng,Phu Sát,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Yếm Đối,Chiêu Dao,Ngũ Li",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng.",
      },

      {
        catThan: "Nguyệt Không,Mẫu Thương,Hoạt Nhãn,Thủ Nhật,Cát Kì,Tục Thế",
        hungThan:
          "Nguyệt Hại,Huyết Kị,Thiên Lao,Phục Binh,Nguyệt Hỏa,Ương Bại,Bàng Phế,Tứ Phế",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Yếu An,Thiên Đức Hợp,Lộc Khố,Thiên Phú",
        hungThan:
          "Ngũ Hư,Đại Sát,Trùng Nhật,Huyền Vũ,Đại Họa,Thổ Ôn,Phi Liêm,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Thời Đức,Dương Đức,Thiên Quan,Dân Nhật,Ngọc Vũ,Tư Mệnh,Ô Phệ Đối",
        hungThan:
          "Hà Khôi,Tử Thần,Thiên Lại,Thiên Sứ,Bất Thành,Tiểu Hao,Câu Giảo,Chí Tử,Vãng Vong,Xúc Thủy Long",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Tam Hợp,Thời Âm,Kim Đường",
        hungThan:
          "Tử Khí,Câu Trần,Tử Phù,Đại Hao,Mộc Mã,Câu Trần,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan: "Giải Thần,Ngũ Hợp,Thiên Đức,Thanh Long",
        hungThan: "Kiếp Sát,Tiểu Hao,Qui Kị,Hoàng Sa,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Ngũ Hợp,Minh Đường,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Nguyệt Yếm,Địa Hỏa,Ngũ Hư, m Đạo Xung Dương,Thiên Tặc,Thần Cách,Phi Ma,Hoang Vu,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Ân,Mẫu Thương,Thiên Nguyện,Lục Hợp,Kính An,Hoạt Nhãn,Kính An,Thiên Quý",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Tứ Kích,Thiên Hình",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng.",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Bất Tương,Phổ Hộ,Hỉ Thần,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Phục Nhật,Trùng Nhật,Chu Tước,Ngũ Quỷ,Lâm Nhật,Trùng Tang,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Xuất Hành,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Tài,Thiên Ân,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo,Tứ Tướng,Bất Tương,Phúc Sinh,Kim Quĩ,Ô Phệ",
        hungThan:
          "Thiên Cương,Đại Thời,Đại Bại,Hàm Trì,Thiên Tặc,Cửu Khảm,Khô Tiêu,Thiên Cẩu Nhật Thời,Địa Tặc,Bát Tòa,Ngõa Toái,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Nguyệt Ân,Tứ Tướng,Âm Đức,Thời Dương,Sinh Khí,Thiên Thương,Bất Tương,Bảo Quang,Địa Tài,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Ngũ Hư,Cửu Không,Thổ Phù,Xúc Thủy Long,Nguyệt Hư,Thiên Cẩu Nhật Thời,Nguyệt Sát,Thọ Tử,Nhân Cách,Thiên Địa Chuyển,Hoang Vu",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
        khongNen:
          "Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Phúc Hậu,Vương Nhật,Thiên Mã,Ngũ Phú,Bất Tương,Thánh Tâm,Trừ Thần,Ô Phệ",
        hungThan:
          "Du Họa,Huyết Chi,Ngũ Li,Bạch Hổ,Phục Binh,Thiên Ôn,Thiên Bảng,Ương Bại,Lôi Công,Bàng Phế,Tứ Phế",
        nen: "Nạp Tài,An Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Quan Nhật,Lục Nghi,Ích Hậu,Trừ Thần,Ngọc Đường,Ô Phệ",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Yếm Đối,Chiêu Dao,Ngũ Li,Đại Họa,Thiên Ngục,Thiên Hỏa,Tiểu Hồng Sa,Trùng Nhật,Thổ Kỵ,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế,Phu Sát",
        nen: "",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường.",
      },

      {
        catThan: "Mẫu Thương,Minh Tinh,Thủ Nhật,Cát Kì,Tục Thế",
        hungThan: "Nguyệt Hại,Huyết Kị,Nguyệt Hỏa,Phá Bại,Thiên Lao",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Tướng Nhật,Dịch Mã,Thiên Đức Hợp,Lộc Khố,Thiên Phú,Thiên Hậu,Thiên Vu,Phúc Đức,Yếu An",
        hungThan: "Ngũ Hư,Bát Phong,Đại Sát,Trùng Nhật,Huyền Vũ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Khai Trương,Lập Ước Giao Dịch",
        khongNen: "Cưới Gả,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thời Đức,Dương Đức,Dân Nhật,Ngọc Vũ,Tư Mệnh,Thiên Quan",
        hungThan:
          "Hà Khôi,Tử Thần,Thiên Lại,Chí Tử,Vãng Vong,Thiên Sứ,Bất Thành,Tiểu Hao,Câu Giảo",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Tam Hợp,Thời Âm,Kim Đường",
        hungThan: "Tử Khí,Câu Trần,Tử Phù,Đại Hao,Mộc Mã,Tứ Đại Mộ",
        nen: "Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức,Giải Thần,Ngũ Hợp,Thiên Đức,Thiên Quý,Thanh Long,Ô Phệ Đối",
        hungThan: "Kiếp Sát,Tiểu Hao,Qui Kị,Hoàng Sa,Tứ Phế",
        nen: "",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Ngũ Hợp,Minh Đường,Ô Phệ Đối,Thiên Quý,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Nguyệt Yếm,Địa Hỏa,Ngũ Hư,Phục Nhật,Đại Hội,Thiên Tặc,Thần Cách,Phi Ma,Địa Hỏa,Trùng Tang,Thiên Địa Chuyển,Hoang Vu,Tứ Phế,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Mẫu Thương,Tứ Tướng,Lục Hợp,Bất Tương,Kính An,Hoạt Nhãn",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Tứ Kích,Thiên Hình",
        nen: "",
        khongNen: "Dâng Biểu Sớ",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Bất Tương,Phổ Hộ,Hỉ Thần,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Trùng Nhật,Chu Tước,Ngũ Quỷ,Lâm Nhật,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Xuất Hành,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Bất Tương,Phúc Sinh,Kim Quĩ,Ô Phệ,Thiên Tài,Phúc Tinh,Phúc Sinh,Binh Bảo,Binh Cát,Binh Phúc",
        hungThan:
          "Thiên Cương,Đại Thời,Đại Bại,Hàm Trì,Thiên Tặc,Cửu Khảm,Phục Binh,Thiên Cẩu Nhật Thời,Địa Tặc,Bát Tòa,Ngõa Toái,Khô Tiêu,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Mẫu Thương,Âm Đức,Thời Dương,Sinh Khí,Thiên Thương,Bảo Quang,Địa Tài,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Ngũ Hư,Cửu Không,Thổ Phù,Nguyệt Hư,Đại Họa,Thiên Cẩu Nhật Thời,Nguyệt Sát,Thọ Tử,Nhân Cách,Trùng Nhật,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "Dâng Biểu Sớ,Cưới Gả,Khai Trương",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Vương Nhật,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Phúc Hậu,Thiên Mã,Ngũ Phú,Thánh Tâm,Trừ Thần,Ô Phệ",
        hungThan:
          "Du Họa,Huyết Chi,Thiên Ôn,Thiên Bảng,Ương Bại,Lôi Công,Địa Nang,Ngũ Li,Bạch Hổ",
        nen: "An Táng",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Quan Nhật,Lục Nghi,Ích Hậu,Trừ Thần,Ngọc Đường,Ô Phệ",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Yếm Đối,Chiêu Dao,Ngũ Li,Thiên Ngục,Thiên Hỏa,Tiểu Hồng Sa,Thổ Phủ,Thổ Kỵ,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Thủ Nhật,Cát Kì,Tục Thế,Minh Tinh",
        hungThan: "Nguyệt Hại,Huyết Kị,Thiên Lao,Nguyệt Hỏa,Phá Bại",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Yếu An,Thiên Đức Hợp,Lộc Khố,Thiên Phú,Yếu An",
        hungThan: "Ngũ Hư,Đại Sát,Trùng Nhật,Huyền Vũ,Thổ Ôn,Hoang Vu,Phi Liêm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Khai Trương,Lập Ước Giao Dịch,Nạp Tài.",
        khongNen: "Cưới Gả,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Thời Đức,Dương Đức,Dân Nhật,Ngọc Vũ,Tư Mệnh,Ô Phệ Đối,Thiên Quan,Thiên Quý",
        hungThan:
          "Hà Khôi,Tử Thần,Thiên Lại,Chí Tử,Tứ Kị,Cửu Hổ,Vãng Vong,Thiên Sứ,Bất Thành,Tiểu Hao,Câu Giảo,Hà Khôi",
        nen: "",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,An Táng",
      },

      {
        catThan: "Mẫu Thương,Tam Hợp,Thời Âm,Kim Đường,Thiên Quý",
        hungThan:
          "Tử Khí,Ngũ Mộ,Trùng Nhật,Cầu Trần,Tử Phù,Đại Hao,Mộc Mã,Trùng Tang,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,An Táng,Cải Táng",
      },

      {
        catThan: "Tứ Tướng,Giải Thần,Thiên Đức,Ngũ Hợp,Thanh Long,Ô Phệ Đối",
        hungThan: "Kiếp Sát,Tiểu Hao,Qui Kị,Tứ Phế,Hoàng Sa",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng.",
        khongNen: "",
      },

      {
        catThan: "Nguyệt Ân,Tứ Tướng,Ngũ Hợp,Thủ Nhật,Minh Đường,Ô Phệ Đối",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Nguyệt Yếm,Địa Hỏa,Ngũ Hư,Thiên Tặc,Thần Cách,Phi Ma,Thiên Địa Chuyển,Hoang Vu,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Nguyệt Không,Mẫu Thương,Lục Hợp,Bất Tương,Kính An",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Phục Binh,Tứ Phế,Bàng Phế,Tứ Kích,Thiên Hình",
        nen: "",
        khongNen: "Xuất Hành,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Tam Hợp,Hỉ Thần,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo,Lâm Nhật,Thiên Hỉ,Thiên Y,Phổ Hộ",
        hungThan: "Trùng Nhật,Chu Tước",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Xuất Hành",
      },

      {
        catThan:
          "Phúc Sinh,Kim Quĩ,Ô Phệ,Thiên Tài,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Thiên Cương,Thiên Cẩu Nhật Thời,Địa Tặc,Bát Tòa,Ngõa Toái,Khô Tiêu,Lỗ Ban,Đao Chẩn,Đại Thời,Đại Bại,Hàm Trì,Thiên Tặc,Cửu Khảm",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Địa Tài,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa,Âm Đức,Thời Dương,Sinh Khí,Thiên Thương,Bảo Quang",
        hungThan:
          "Nguyệt Hư,Thiên Cẩu Nhật Thời,Nguyệt Sát,Thọ Tử,Nhân Cách,Hoang Vu,Thiên Địa Chuyển,Bát Phong,Cửu Không,Thổ Phủ,Bát Chuyên",
        nen: "Dâng Biểu Sớ,Xuất Hành",
        khongNen:
          "Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường.",
      },

      {
        catThan:
          "Thiên Xá,Thiên Y,Binh Cát,Binh Phúc,Binh Bảo,Phúc Hậu,Vương Nhật,Thiên Mã,Ngũ Phú,Bất Tương,Thánh Tâm,Trừ Thần",
        hungThan:
          "Du Họa,Huyết Chi,Ngũ Li,Bạch Hổ,Thiên Ôn,Thiên Bảng,Ương Bại,Lôi Công",
        nen: "Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Quan Nhật,Lục Nghi,Ích Hậu,Trừ Thần,Ngọc Đường,Ô Phệ",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Yếm Đối,Chiêu Dao,Ngũ Li,Tiểu Hội,Thiên Ngục,Thiên Hỏa,Tiểu Hồng Sa,Thổ Kỵ,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Ân,Minh Tinh,Thiên Quý,Mẫu Thương,Thủ Nhật,Cát Kì,Tục Thế",
        hungThan: "Nguyệt Hại,Huyết Kị,Thiên Lao,Nguyệt Hỏa,Phá Bại",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Tướng Nhật,Thiên Hậu,Thiên Vu,Phúc Đức,Thiên Đức Hợp,Lộc Khố,Dịch Mã,Thiên Phú,Thiên Quý,Yếu An",
        hungThan:
          "Tứ Cùng,Cửu Hổ,Ngũ Hư,Thổ Ôn,Phi Liêm,Trùng Tang,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ,Đại Sát,Phục Nhật,Trùng Nhật,Huyền Vũ",
        nen: "Dâng Biểu Sớ,Xuất Hành",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Thiên Ân,Tứ Tướng,Thời Đức,Dương Đức,Dân Nhật,Ngọc Vũ,Tư Mệnh,Ô Phệ Đối,Thiên Quan",
        hungThan:
          "Hà Khôi,Tử Thần,Thiên Sứ,Bất Thành,Tiểu Hao,Câu Giảo,Thiên Lại,Chí Tử,Vãng Vong",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Nguyệt Ân,Tứ Tương,Tam Hợp,Thời Âm,Kim Đường",
        hungThan:
          "Tử Khí,Tử Phù,Đại Hao,Mộc Mã,Thiên Địa Chuyển,Tứ Đại Mộ,Bát Chuyên,Xúc Thủy Long,Câu Trần",
        nen: "Xuất Hành,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng.",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Đức,Nguyệt Không,Giải Thần,Ngũ Hợp,Thanh Long,Ô Phệ Đối",
        hungThan:
          "Kiếp Sát,Tiểu Hao,Tứ Phế,Qui Kị,Phục Binh,Hoàng Sa,Bàng Phế,Tứ Phế,Bát Chuyên",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Đức Hợp,Thủ Nhật,Ngũ Hợp,Minh Đường,Ô Phệ Đối",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Nguyệt Yếm,Địa Hỏa,Tứ Phế,Ngũ Hư, m Thác,Thiên Tặc,Thần Cách,Phi Ma,Địa Hỏa, m Thác,Trùng Nhật,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Mẫu Thương,Lục Hợp,Kính An,Hoạt Nhãn,Thiên Nguyện",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Tứ Kích,Thiên Hình",
        nen: "",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Tam Hợp,Hỉ Thần,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo,Lâm Nhật,Thiên Hỉ,Thiên Y,Phổ Hộ",
        hungThan:
          "Trùng Nhật,Chu Tước,Ngũ Quỷ,Lâm Nhật,Thiên Địa Chuyển,Thần Cách,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Xuất Hành,An Táng,Cải Táng",
      },

      {
        catThan:
          "Bất Tương,Phúc Sinh,Kim Quĩ,Thiên Tài,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Thiên Cương,Đại Thời,Đại Bại,Hàm Trì,Thiên Tặc,Tứ Hao,Cửu Khảm,Cửu Tiêu,Thiên Cẩu Nhật Thời,Địa Tặc,Bát Tòa,Ngõa Toái,Khô Tiêu,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Mẫu Thương,Âm Đức,Thời Dương,Sinh Khí,Thiên Thương,Bảo Quang,Địa Tài,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Ngũ Hư,Cửu Không,Thổ Phù,Nguyệt Hư,Thiên Cẩu Nhật Thời,Nguyệt Sát,Thọ Tử,Nhân Cách,Hoang Vu,Bát Chuyên",
        nen: "Dâng Biểu Sớ,Xuất Hành",
        khongNen:
          "Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Y,Vương Nhật,Thiên Mã,Ngũ Phú,Thánh Tâm,Trừ Thần,Ô Phệ,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Phúc Hậu",
        hungThan:
          "Du Họa,Huyết Chi,Ngũ Li,Bát Chuyên,Bạch Hổ,Thiên Ôn,Thiên Bảng,Tai Sát,Lôi Công",
        nen: "Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Quan Nhật,Lục Nghi,Ích Hậu,Thiên Quý,Trừ Thần,Ngọc Đường,Ô Phệ",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Thiên Ngục,Thiên Hỏa,Tiểu Hồng Sa, m Thác,Trùng Tang,Thổ Phủ,Thổ Kỵ,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Tứ Đại Mộ,Nguyệt Hình,Yếm Đối,Chiêu Dao,Phục Nhật,Ngũ Li,Dương Thác",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Tứ Tướng,Thủ Nhật,Minh Tinh,Cát Kì,Tục Thế",
        hungThan: "Nguyệt Hại,Huyết Kị,Thiên Lao,Nguyệt Hỏa,Phá Bại",
        nen: "Xuất Hành,Tu Tạo Động Thổ",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Nguyệt Ân,Thiên Đức Hợp,Lộc Khố,Thiên Phú,Tứ Tướng,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Vu,Phúc Đức,Yếu An,",
        hungThan:
          "Ngũ Hư,Đại Sát,Trùng Nhật,Huyền Vũ,Thổ Ôn,Phi Liêm,Thiên Địa Chuyển,Hoang Vu",
        nen: "",
        khongNen: "Cưới Gả,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },
    ],
    //Tháng Tuất
    [
      {
        catThan:
          "Thiên Ân,Thời Đức,Lộc Khố,Minh Tinh,Dân Nhật,Thiên Phú,Phúc Đức,Phổ Hộ",
        hungThan:
          "Tai Sát,Thiên Hỏa,Đại Sát,Qui Kị,Hoàng Sa,Phi Ma,Phá Bại,Ngũ Quỷ,Thổ Ôn,Phi Liêm,Bàng Phế,Tứ Phế,Thiên Lao",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Mẫu Thương,Phúc Sinh,Phúc Tinh,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Sát,Nguyệt Hư,Huyền Vũ,Thần Cách,Mộc Mã,Tiểu Hồng Sa,Tiểu Hao,Bàng Phế,Tứ Phế,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Đức,Thiên Ân,Dương Đức,Tam Hợp,Lâm Nhật,Ngũ Hợp,Tư Mệnh,Ô Phệ Đối,Thiên Quan",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tử Khí,Cửu Khảm,Khô Tiêu,Tử Phù,Đại Hao,Thọ Tử,Lâm Nhật,Lôi Công,Tứ Phế,Cô Thần",
        nen: "",
        khongNen: "Xuất Hành,Cưới Gả.",
      },

      {
        catThan: "Thiên Ân,Lục Hợp,Thánh Tâm,,Thủ Nhật,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Hoang Vu,Thiên Địa Chuyển,Tứ Phế,Ngũ Hư,Câu Trần",
        nen: "Cưới Gả,An Táng,Cải Táng",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hang",
      },

      {
        catThan:
          "Thiên Ân,Binh Cát,Binh Phúc,Binh Bảo,Mẫu Thương,Bất Tương,Ích Hậu,Giải Thần,Thanh Long",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Kích,Ngũ Mộ,Bất Thành,Trùng Tang,Thổ Kỵ,Cửu Không,Vãng Vong,Phục Nhật",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Âm Đức,Tục Thế,Minh Đường,Nguyệt Tài,Binh Cát,Bính Phúc,Binh Bảo",
        hungThan:
          "Du Họa,Thiên Tặc,Huyết Kị,Trùng Nhật,Thiên Ôn,Địa Tặc,Nhân Cách,Trùng Tang,Đao Chẩn,Thổ Cấm",
        nen: "",
        khongNen: "Xuất Hành,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Cát Thần,Nguyệt Ân,Tam Hợp,Thiên Hỉ,Thiên Y,Thiên Thương,Bất Tương,Yếu An,Ô Phệ,Hỉ Thần,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan: "Thiên Hình,Lỗ Ban,Đao Chẩn",
        nen: "Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hang,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Mẫu Thương,Bất Tương,Ngọc Vũ,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Hà Khôi,Nguyệt Hình,Ngũ Hư,Địa Nang,Thiên Cẩu Nhật Thời,Ương Bại,Câu Giảo,Bát Tòa,Ngõa Toái,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ,Chu Tước",
        nen: "",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Không,Tứ Tướng,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Lục Nghi,Kim Đường,Trừ Thần,Kim Quĩ,Ô Phệ,Thiên Tài,Phúc Hậu",
        hungThan:
          "Yếm Đối,Phục Bình,Thiên Cẩu Nhật Thời,Thiên Tặc,Chiêu Dao,Ngũ Li",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Cưới Gả,Lập Ước Giao Dịch",
      },

      {
        catThan: "Tứ Tướng,Địa Tài,Thiên Y,Quan Nhật,Trừ Thần,Bảo Quang,Ô Phệ",
        hungThan:
          "Nguyệt Hại,Thiên Lại,Thiên Sứ,Đại Họa,Nguyệt Hỏa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Chí Tử,Huyết Chi,Ngũ Li",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Thủ Nhật,Thiên Mã",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Bạch Hổ, m Vị,Thiên Bảng,Thổ Phủ,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Tướng Nhật,Cát Kì,Ngũ Phú,Kính An,Ngọc Đường,Ngũ Phú,Kính An",
        hungThan: "Kiếp Sát,Ngũ Hư,Thổ Phù,Trùng Nhật,Hoang vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Đức,Thời Đức,Dân Nhật,Thiên Vu,Phúc Đức,Phổ Hộ,Ô Phệ,Lộc Khố,Thiên Phú,Minh Tinh,Thiên Phú",
        hungThan:
          "Tai Sát,Hoàng Sa,Phi Ma,Ương Bại,Ngũ Quỷ,Thổ Phủ,Phi Liêm,Thiên Hỏa,Đại Sát,Qui Kị,Xúc Thủy Long,Thiên Lao",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan: "Mẫu Thương,Phúc Sinh,Phúc Tinh,Hoạt Nhãn,Phúc Tinh",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Sát,Nguyệt Hư,Huyền Vũ,Nguyệt Hư,Thiên Tinh,Thần Cách,Mộc Mã,Tiểu Hồng Sa,Tiểu Hao,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Dương Đức,Thiên Quan,Tam Hợp,Lâm Nhật,Thời Âm,Ngũ Hư,Tư Mệnh",
        hungThan:
          "Nguyệt Yếm,Tử Phù,Đại Hao,Thọ Tử,Lâm Nhật,Lôi Công,Trùng Tang,Tứ Phế,Địa Hỏa,Tử Khí,Cửu Khảm,Khô Tiêu,Phục Nhật,Cô Thần",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Thủ Nhật,Lục Hợp,Thánh Tâm,Ngũ Hợp",
        hungThan:
          "Đại Thời,Đại Bại,Trùng Nhật,Trung Tang,Hoang Vu,Tứ Phế,Hàm Trì,Tiểu Hao,Ngũ Hư,Câu Trần",
        nen: "Cưới Gả,An Táng",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Ân,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Mẫu Thương,Nguyệt Ân,Bất Tương,Ích Hậu,Giải Thần,Thanh Long",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Kích,Cửu Không,Bất Thành,Thổ Kỵ,Vãng Vong",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Tài,Binh Phúc,Binh Cát,Binh Bảo,Thiên Quý,Nguyệt Đức Hợp,Thiên Ân,Âm Đức,Bất Tương,Tục Thế,Minh Đường",
        hungThan:
          "Du Họa,Thiên Ôn,Nhân Cách,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ,Thiên Tặc,Huyết Kị,Trùng Nhật",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Ân,Tứ Tướng,Tam Hợp,Thiên Hỉ,Thiên Y,Thiên Thương,Yếu An,Ô Phệ,Hỉ Thần,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Thiên Hình,Phục Binh,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng.",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Tứ Tướng,Bất Tương,Ngọc Vũ,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Hà Khôi,Nguyệt Hình,Thiên Cẩu Nhật Thời,Tai Sát,Câu Giảo,Bát Tòa,Ngõa Toái,Thiên Địa Chuyển,Hoang Vu,Ngũ Li,Xúc Thủy Long,Chu Tước",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Lục Nghi,Kim Đường,Trừ Thần,Kim Quĩ,Ô Phệ,Thiên Tài,Phúc Hậu",
        hungThan:
          "Yếm Đối,Chiêu Dao,Ngũ Li,Thiên Cẩu Nhật Thời,Thiên Tặc,Bàng Phế,Tứ Phế.",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương.",
        khongNen: "Cưới Gả,Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Quan Nhật,Trừ Thần,Bảo Quang,Ô Phệ,Địa Tài,Thiên Y",
        hungThan:
          "Nguyệt Hại,Thiên Lại,Thiên Sứ,Nguyệt Hỏa,Huyết Chi,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế,Phu Sát,Chí Tử,Huyết Kị,Ngũ Li",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Đức,Nguyệt Đức,Mẫu Thương,Thủ Nhật,Thiên Mã",
        hungThan: "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Bạch Hổ,Thiên Bảng,Thổ Phủ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Nạp Tài,An Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Tướng Nhật,Cát Kì,Ngũ Phú,Kính An,Ngọc Đường",
        hungThan:
          "Kiếp Sát,Ngũ Hư,Bát Phong,Thiên Địa Chuyển,Thổ Phù,Trùng Nhật",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thời Đức,Lộc Khố,Thiên Phú,Minh Tinh,Phổ Hộ,Dân Nhật,Thiên Vu,Phúc Đức,Phổ Hộ",
        hungThan:
          "Tai Sát,Thiên Hỏa,Đại Sát,Qui Kị,Phục Nhật,Hoàng Sa,Phi Ma,Phá Bại,Ngũ Quỷ,Thổ Ôn,Phi Liêm,Trùng Tang,Thiên Lao",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng.",
      },

      {
        catThan: "Mẫu Thương,Phúc Sinh,Phúc Tinh,Hoạt Nhãn",
        hungThan:
          "Thiên Cương,Thần Cách,Mộc Mã,Tiểu Hồng Sa,Tiểu Hao,Trùng Nhật,Trùng Tang,Tứ Đại Mộ,Tử Thần,Nguyệt Sát,Nguyệt Hư,Huyền Vũ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Ân,Dương Đức,Tam Hợp,Lâm Nhật,Thời Âm,Ngũ Hợp,Tư Mệnh,Ô Phệ Đối,Thiên Quan,Thiên Quý",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Đại Hao,Thọ Tử,Lâm Nhật,Lôi Công,Tử Khí,Cửu Khảm,Khô Tiêu,Hành Ngận,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thiên Quý,Thủ Nhật,Nguyệt Đức Hợp,Thiên Nguyện,Lục Hợp,Bất Tương,Thánh Tâm,Ngũ Hợp,Ô Phệ Đối",
        hungThan: "Đại Thời,Đại Bại,Hàm Trì,Tiểu Hao,Ngũ Hư,Câu Trần",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng.",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Không,Binh Cát,Binh Phúc,Binh Bảo,Mẫu Thương,Tứ Tướng,Bất Tương,Ích Hậu,Giải Thần,Thanh Long",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Kích,Cửu Không,Phục Binh,Bất Thành,Thổ Kỵ,Vãng Vong",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Tứ Tướng,Âm Đức,Binh Cát,Binh Phúc,Binh Bảo,Bất Tương,Tục Thế,Minh Đường,",
        hungThan:
          "Du Họa,Thiên Tặc,Huyết Kị,Trùng Nhật,Đại Họa,Thiên Ôn,Địa Tặc,Nhân Cách,Huyết Kỵ,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm",
        nen: "Cưới Gả,Tu Tạo Động Thổ",
        khongNen: "Xuất Hành,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tam Hợp,Thiên Hỉ,Thiên Y,Hỉ Thần,Kính An,Binh Cát,Binh Phúc,Binh Bảo,Thiên Thương,Yếu An,Ô Phệ",
        hungThan: "Thiên Hình,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Mẫu Thương,Ngọc Vũ,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Hà Khôi,Thiên Cẩu Nhật Thời,Phá Bại,Câu Giảo,Bát Tòa,Ngõa Toái,Hoang Vu,Bàng Phế,Tứ Phế,Nguyệt Hình,Ngũ Hư,Chu Tước",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Thiên Thời,Phúc Hậu,Nguyệt Đức,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Kim Đường,Trừ Thần,Kim Quĩ,Ô Phệ",
        hungThan: "Yếm Đối,Chiêu Dao,Ngũ Li,Thiên Cẩu Nhật Thời,Thiên Tặc",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương.",
        khongNen: "",
      },

      {
        catThan: "Quan Nhật,Địa Tài,Thiên Y,Trừ Thần,Bảo Quang,Ô Phệ",
        hungThan:
          "Nguyệt Hai,Thiên Lại,Thiên Sứ,Nguyệt Hỏa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Sát,Phu Sát,Chí Tử,Huyết Chi,Ngũ Li",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Thủ Nhật,Thiên Mã",
        hungThan:
          "Nguyệt Kiến,Thiên Bảng,Trùng Tang,Tiểu Thời,Thổ Phủ,Phục Nhật,Bạch Hổ,Tiểu Hội,Cô Dương",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Tướng Nhật,Cát Kì,Ngũ Phú,Kính An,Ngọc Đường",
        hungThan: "Kiếp Sát,Trùng Tang,Hoang Vu,Ngũ Hư,Thổ Phù,Trùng Nhật",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Nguyệt Ân,Lộc Khố,Thiên Phú,Minh Tinh,Thiên Quý,Thời Đức,Dân Nhật,Thiên Vu,Phúc Đức,Phổ Hộ,Ô Phệ Đối",
        hungThan:
          "Tai Sát,Hoàng Sa,Phi Ma,Phá Bại,Ngũ Quỷ,Thổ Ôn,Phi Liêm,Thiên Hỏa,Tứ Kị,Cửu Hổ,Đại Sát,Qui Kị,Thiên Lao",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Phúc Tinh,Hoạt Nhãn,Thiên Quý,Nguyệt Đức Hợp,Mẫu Thương,Phúc Sinh",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Sát,Nguyệt Hư,Thần Cách,Mộc Mã,Tiểu Hồng Sa,Tiểu Hao,Thiên Địa Chuyển,Tứ Đại Mộ,Địa Nang,Huyền Vũ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Quan,Tứ Tướng,Dương Đức,Tam Hợp,Lâm Nhật,Thời Âm,Ngũ Hợp,Tư Mệnh,Ô Phệ Đối",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Phục Binh,Tử Phù,Đại Hao,Thọ Tử,Lâm Nhật,Lôi Công,Khô Tiêu,Tứ Phế,Tử Khí,Cửu Khảm,Liễu Lệ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tứ Tướng,Lục Hợp,Bất Tương,Thủ Nhật,Thánh Tâm,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Đại Họa,Thiên Địa Chuyển,Hoang Vu,Tứ Phế,Tiểu Hao,Ngũ Hư,Câu Trần",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng,Cải Táng",
        khongNen: "Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Mẫu Thương,Ích Hậu,Binh Cát,Binh Phúc,Binh Bảo,Giải Thần,Thanh Long,",
        hungThan:
          "Nguyệt Phá,Bất Thành,Thổ Kỵ,Bàng Phế,Tứ Phế,Đại Hao,Tứ Kích,Cửu Không,Vãng Vong",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Âm Đức,Tục Thế,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo,Minh Đường",
        hungThan:
          "Du Họa,Thiên Tặc,Huyết Kị,Trùng Nhật,Thiên Ôn,Địa Tặc,Nhân Cách,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm",
        nen: "",
        khongNen: "Xuất Hành,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Đức,Tam Hợp,Thiên Hỉ,Thiên Y,Thiên Thương,Yếu An,Ô Phệ,Hỉ Thần,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Thiên Hình,Lỗ Ban,Đao Chẩn",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan: "Mẫu Thương,Ngọc Vũ,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Hà Khôi,Nguyệt Hình,Ngũ Hư,Bát Phong,Bát Chuyên,Chu Tước,Thiên Cẩu Nhật Thời,Chu Tước,Ương Bại,Câu Giảo,Bát Tòa,Ngõa Toái,Hoang Vu,Thiên Địa Chuyển",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Xá,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Lục Nghi,Kim Đường,Trừ Thần,Kim Quĩ,Mộc Mã,Thiên Tài,Phúc Hậu",
        hungThan:
          "Yếm Đối,Chiêu Dao,Phục Nhật,Ngũ Li,Thiên Cẩu Nhật Thời,Thiên Tặc,Trùng Tang",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Quan Nhật,Trừ Thần,Bảo Quang,Ô Phệ,Thiên Tài,Thiên Y",
        hungThan:
          "Nguyệt Hại,Thiên Sứ,Nguyệt Hỏa,Huyết Chi,Trùng Nhật,Trùng Tang,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Thiên Lại,Chí Tử,Huyết Chi,Ngũ Li",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan: "Thiên Ân,Thiên Quý,Mẫu Thương,Nguyệt Ân,Thủ Nhật,Thiên Mã",
        hungThan:
          "Nguyệt Kiến,Thiên Bảng,Dương Thác,Thổ Phủ,Tiểu Thời,Thổ Phủ,Bạch Hổ,Dương Thác",
        nen: "Xuất Hành",
        khongNen:
          "Dâng Biểu Sớ,Tu Tạo Động Thổ,Khai Trương Lập Ước Giao Dịch,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thiên Quý,Nguyệt Đức Hợp,Thiên Ân,Tướng Nhật,Cát Kì,Ngũ Phú,Kính An,Ngọc Đường",
        hungThan:
          "Kiếp Sát,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ,Tứ Cùng,Cửu Vĩ,Ngũ Hư,Thổ Phù,Trùng Nhật",
        nen: "Dâng Biểu Sớ,Xuất Hành",
        khongNen:
          "Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Ân,Tứ Tướng,Thời Đức,Dân Nhật,Thiên Vu,Phúc Đức,Thổ Hộ,Ô Phệ Đối,Lộc Khố,Thiên Phú,Minh Tinh",
        hungThan:
          "Tai Sát,Thiên Hỏa,Đại Sát,Qui Kị,Thiên Lao,Phục Binh,Thiên Lao,Thiên Hỏa,Hoàng Sa,Phi Ma,Phá Bại,Ngũ Quỷ,Thổ Ôn,Phi Liêm",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Mẫu Thương,Tứ Tướng,Phúc Sinh,Phúc Tinh",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Sát,Nguyệt Hư,Bát Chuyên,Xúc Thủy Long,Huyền Vũ,Đại Họa,Thần Cách,Mộc Mã,Tiểu Hồng Sa,Tiểu Hao,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Dương Đức,Thiên Quan,Tam Hợp,Lâm Nhật,Thời Âm,Ngũ Hợp,Tư Mệnh,Ô Phệ Đối",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Tử Phù,Đại Hao,Thọ Tử,Lâm Nhật, m Thác,Khô Tiêu,Bàng Phế,Tứ Phế,Tử Khí,Tứ Phế,Cửu Khảm,Bát Chuyên,Cô Thần, m Thác",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Lục Hợp,Thủ Nhật,Thánh Tâm,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Đại Thời,Đại Bại,Hoang Vu,Bàng Phế,Hàm Trì,Tiểu Hao,Tứ Phế,Ngũ Hư,Câu Trần",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Binh Cát,Binh Phúc,Binh Bảo,Nguyệt Đức,Mẫu Thương,Ích Hậu,Giải Thần,Thanh Long",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Kích,Cửu Không,Bất Thành,Thổ Kỵ,Vãng Vong",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
      },

      {
        catThan:
          "Âm Đức,Tục Thế,Minh Đường,Nguyệt Tài,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Du Họa,Thiên Tặc,Huyết Kị,Trùng Nhật,Thiên Ôn,Địa Tặc,Nhân Cách,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm",
        nen: "",
        khongNen: "Xuất Hành,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tam Hợp,Thiên Hỉ,Thiên Y,Thiên Thương,Hỉ Thần,Binh Cát,Binh Phúc,Binh Bảo,Bất Tương,Yếu An",
        hungThan: "Tứ Hao,Phục Nhật,Thiên Hình,Trùng Tang,Lỗ Ban,Đao Chẩn",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Ngọc Vũ,Binh Cát,Binh Phúc,Binh Bảo,Đại Hồng Sa",
        hungThan:
          "Hà Khôi,Thiên Cẩu Nhật Thời,Tai Sát,Bát Tòa,Trùng Nhật,Ngõa Toái,Trùng Tang,Hoang Vu,Nguyệt Hình,Ngũ Hư,Bát Chuyên,Chu Tước",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Ân,Thiên Tài,Thiên Quý,Phúc Hậu,Vương Nhật,Dịch Mã,Thiên Hậu,Thời Dương,Sinh Khí,Lục Nghi,Kim Đường,Trừ Thần,Kim Quĩ,Ô Phệ",
        hungThan:
          "Yếm Đối,Chiêu Dao,Ngũ Li,Bát Chuyên,Thiên Cẩu Nhật Thời,Thiên Tặc",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
        khongNen: "Cưới Gả,Lập Ước Giao Dịch",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Đức Hợp,Quan Nhật,Trừ Thần,Bảo Quang,Ô Phệ,Địa Tài,Thiên Y,Thiên Quý",
        hungThan:
          "Nguyệt Hại,Thiên Sứ,Nguyệt Hỏa,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Tứ Đại Mộ,Thiên Lại,Chí Tử,Huyết Chi,Ngũ Li",
        nen: "",
        khongNen: "Khai Trương",
      },

      {
        catThan: "Nguyệt Không,Mẫu Thương,Tứ Tướng,Thủ Nhật,Thiên Mã",
        hungThan: "Nguyệt Kiến,Phục Binh,Thiên Bảng,Tiểu Thời,Thổ Phủ,Bạch Hổ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Tứ Tướng,Tướng Nhật,Cát Kì,Ngũ Phú,Kính An,Ngọc Đường",
        hungThan:
          "Kiếp Sát,Ngũ Hư,Đại Họa,Thiên Địa Chuyển,Hoang Vu,Thổ Phù,Trùng Nhật",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },
    ],
    //Tháng Hợi
    [
      {
        catThan:
          "Nguyệt Đức,Thiên Ân,Thiên Xá,Tứ Tướng,Quan Nhật,Thiên Mã,Cát Kì,Yếu An",
        hungThan:
          "Đại Thời,Đại Bại,Thiên Bảng,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Hàm Trì,Bạch Hổ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức,Thiên Ân,Lộc Khố,Thiên Phú,Nguyệt Ân,Tứ Tướng,Thủ Nhật,Thiên Vu,Phúc Đức,Ngọc Vũ,Ngọc Đường",
        hungThan:
          "Nguyệt Yếm,Thiên Tặc,Thổ Ôn,Phi Liêm,Địa Hỏa,Cửu Không,Đại Sát,Qui Kị,Cô Thần",
        nen: "",
        khongNen: "Xuất Hành,Cưới Gả",
      },

      {
        catThan:
          "Thiên Ân,Minh Tinh,Thời Đức,Tướng Nhật,Lục Hợp,Ngũ Phú,Kim Đường,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Hà Khôi,Tử Thần,Mộc Mã,Phá Bại,Tiểu Hao,Câu Giảo,Hà Khôi,Ngõa Toái,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế,Du Họa,Ngũ Hư,Thiên Lao",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Binh Cát,Binh Phúc,Binh Bảo,Âm Đức,Dân Nhật,Tam Hợp,Thời Đức,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Tử Khí,Huyền Vũ,Thiên Lao,Thiên Hỏa,Tử Phù,Đại Hao,Nhân Cách,Bàng Phế,Tứ Phế",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Dương Đức,Giải Thần,Tư Mệnh,Thiên Quan,Thiên Nguyện,Giải Thần,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Tiểu Hao,Thiên Tặc,Thổ Phù,Địa Tặc,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Dịch Mã,Thiên Hậu,Thiên Thương,Bất Tương,Kính An,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Nguyệt Phá,Đại Hao,Trùng Nhật,Tiểu Hồng Sa,Tứ Phế,Câu Trần",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức Hợp,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Thủ Nhật,Nguyệt Không,Bất Tương,Phổ Hộ,Thanh Long,Ô Phệ",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Phục Binh,Thiên Sứ,Hoàng Sa,Tai Sát,Lâm Nhật,Hoang Vu,Thiên Địa Chuyển,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Lục Nghi,Phúc Sinh,Minh Đường,Hỉ Thần,Nguyệt Tài,Phúc Tinh",
        hungThan: "Yếm Đối,Chiêu Dao,Tứ Kích,Đại Họa,Thổ Kỵ,Vãng Vong",
        nen: "Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
      },

      {
        catThan: "Mẫu Thương,Trừ Thần,Ô Phệ,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Kiếp Sát,Nguyệt Hại,Phục Nhật,Ngũ Li,Thiên Hình,Thiên Cẩu Nhật Thời,Nguyệt Hỏa,Bát Tòa,Thọ Tử,Trùng Nhật,Trùng Tang,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Thời Dương,Sinh Khí,Thánh Tâm,Trừ Thần,Ô Phệ",
        hungThan:
          "Tai Sát,Thiên Hỏa,Ngũ Li,Chu Tước,Thiên Cẩu Nhật Thời,Phi Ma,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen: "Cưới Gả,Lập Ước Giao Dịch",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Y,Thiên Tài,Đại Hồng Sa,Tứ Tướng,Ích Hậu,Kim Quĩ",
        hungThan: "Nguyệt Sát,Hoang Vu,Nguyệt Hư,Huyết Chi,Ngũ Hư,Bát Phong",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Ân,Tứ Tướng,Vương Nhật,Tục Thế,Bảo Quang,Địa Tài,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Thiên Ôn,Thần Cách,Bất Thành,Ngũ Quỷ,Lôi Công,Khô Tiêu,Huyết Kỵ,Thổ Phủ,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Cửu Khảm,Huyết Chi,Trùng Nhật",
        nen: "",
        khongNen: "Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Quan Nhật,Thiên Mã,Cát Kì,Yếu An,Ô Phệ Đối",
        hungThan:
          "Đại Thời,Thiên Bảng,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế,Phu Sát,Đại Bại,Hàm Trì,Xúc Thủy Long,Bạch Hổ",
        nen: "Xuất Hành,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thủ Nhật,Lộc Khố,Thiên Phú,Thiên Vu,Phúc Đức,Ngọc Vũ,Ngọc Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Thiên Tặc,Thổ Ôn,Phi Liêm,Bàng Phế,Tứ Phế,Cửu Không,Đại Sát,Qui Kị,Cô Thần",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thời Đức,Tướng Nhật,Lục Hợp,Ngũ Phú,Kim Đường,Ngũ Hợp,Minh Tinh",
        hungThan:
          "Hà Khôi,Mộc Mã,Phá Bại,Tiểu Hao,Câu Giảo,Hà Khôi,Ngõa Toái,Hoang Vu,Tử Thần,Du Họa,Ngũ Hư,Địa Nang,Thiên Lao",
        nen: "Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
        khongNen: "",
      },
      {
        catThan:
          "Nguyệt Đức Hợp,Thiên Ân,Âm Đức,Dân Nhật,Tam Hợp,Thời Âm,Bất Tương,Ngũ Hợp,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tử Khí,Huyền Vũ,Thiên Lao,Thiên Hỏa,Tử Phù,Đại Hao,Huyền Vũ,Nhân Cách",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng.",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Không,Thiên Ân,Dương Đức,Bất Tương,Giải Thần,Tư Mệnh,Thiên Quan,Binh Phúc,Binh Cát,Binh Bảo",
        hungThan:
          "Tiểu Hao,Thiên Tặc,Thổ Phù,Phục Binh,Địa Tặc,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Cưới Gả,An Táng",
        khongNen:
          "Xuất Hành,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Ân,Dịch Mã,Thiên Hậu,Thiên Thương,Bất Tương,Kính An,Binh Phúc,Binh Cát,Binh Bảo",
        hungThan:
          "Nguyệt Phá,Đại Hao,Đại Họa,Tiểu Hồng Sa,Tứ Phế,Trùng Nhật,Câu Trần",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Bất Tương,Phổ Hộ,Thanh Long,Ô Phệ,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Phục Nhật,Thiên Sứ,Hoàng Sa,Ương Bại,Lâm Nhật,Trùng Nhật,Trùng Tang,Thiên Địa Chuyển,Hoang Vu,Tứ Phế,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Lục Nghi,Phúc Sinh,Minh Đường,Hỉ Thần,Nguyệt Tài,Phúc Tinh,Phúc Sinh,Thiên Quý",
        hungThan: "Yếm Đối,Chiêu Dao,Tứ Kích,Vãng Vong,Xúc Thủy Long,Thổ Kỵ",
        nen: "Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },
      {
        catThan: "Nguyệt Đức,Mẫu Thương,Tứ Tướng,Trừ Thần,Ô Phệ,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Thiên Cẩu Nhật Thời,Nguyệt Hỏa,Thọ Tử,Bát Tòa,Đao Chẩn,Thổ Cấm,Kiếp Sát,Nguyệt Hại,Ngũ Li,Thiên Hình",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng,Cải Táng.",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Đức,Mẫu Thương,Nguyệt Ân,Tứ Tướng,Thời Dương,Sinh Khí,Thánh Tâm,Trừ Thần,Ô Phệ",
        hungThan:
          "Kiếp Sát,Thiên Hỏa,Ngũ Li,Thiên Cẩu Nhật Thời,Phi Ma,Đao Chẩn,Chu Tước",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan: "Ích Hậu,Kim Quĩ,Thiên Y,Thiên Tài,Đại Hồng Sa",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Ngũ Hư,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Vương Nhật,Tục Thế,Bảo Quang,Địa Tài,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Cửu Khảm,Cửu Tiêu,Huyết Kị,Trùng Nhật,Thiên Ôn,Thần Cách,Bất Thành,Ngũ Quỷ,Lôi Công,Khô Tiêu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thủ Nhật,Thiên Vu,Phúc Đức,Ngọc Vũ,Ngọc Đường,Thiên Mã,Yếu An,Quan Nhật",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Thiên Bảng,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Cửu Không,Đại Sát,Qui Kị,Cô Thần",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thủ Nhật,Thiên Vu,Phúc Đức,Ngọc Vũ,Ngọc Đường,Lộc Khố,Thiên Phú",
        hungThan:
          "Nguyệt Yếm,Thiên Tặc,Thổ Ôn,Phi Liêm,Địa Hỏa,Cửu Không,Đại Sát,Qui Kị,Cô Thần",
        nen: "",
        khongNen: "Xuất Hành,Cưới Gả",
      },

      {
        catThan:
          "Thiên Đức Hợp,Minh Tinh,Nguyệt Không,Thời Đức,Tướng Nhật,Lục Hợp,Ngũ Phú,Bất Tương,Kim Đường,Ngũ Hợp",
        hungThan:
          "Hà Khôi,Tử Thần,Du Họa,Phục Binh,Mộc Mã,Phá Bại,Tiểu Hao,Câu Giảo,Ngõa Toái,Hoang Vu,Thiên Địa Chuyển,Ngũ Hư,Thiên Lao",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng.",
        khongNen: "",
      },

      {
        catThan:
          "Âm Đức,Dân Nhật,Binh Cát,Binh Phúc,Binh Bảo,Tam Hợp,Thời Âm,Bất Tương,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Tử Khí,Huyền Vũ,Địa Họa,Thiên Lao,Thiên Hỏa,Tử Phù,Đại Hao,Huyền Vũ,Nhân Cách",
        nen: "Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Dương Đức,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Bất Tương,Giải Thần,Tư Mệnh",
        hungThan:
          "Tiểu Hao,Địa Tặc,Trùng Nhật,Trùng Tang,Thiên Địa Chuyển,Tứ Đại Mộ,Thiên Tặc,Ngũ Mộ,Thổ Phù,Phục Nhật",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Dịch Mã,Thiên Hậu,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Thiên Thương,Bất Tương,Kính An",
        hungThan: "Nguyệt Phá,Đại Hao,Trùng Nhật,Câu Trần, Tiểu Hồng Sa,Tứ Phế",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Tứ Tướng,Phổ Hộ,Thanh Long,Ô Phệ,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Thủ Nhật",
        hungThan:
          "Thiên Lại,Chí Tử,Ngũ Hư,Thiên Sứ,Hoàng Sa,Tai Sát,Lâm Nhật,Hoang Vu,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Ân,Tứ Tướng,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Lục Nghi,Phúc Sinh,Minh Đường,Hỉ Thần,Nguyệt Tài,Phúc Tinh",
        hungThan: "Yếm Đối,Chiêu Dao,Tứ Kích,Vãng Vong,Thổ Kỵ",
        nen: "Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
      },

      {
        catThan: "Mẫu Thương,Trừ Thần,Ô Phệ,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Kiếp Sát,Thiên Cẩu Nhật Thời,Nguyệt Hỏa,Thọ Tử,Bát Tòa,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm,Nguyệt Hại,Ngũ Li,Thiên Hình",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Mẫu Thương,Thời Dương,Sinh Khí,Thanh Tâm,Trừ Thần,Ô Phệ Đối",
        hungThan:
          "Tai Sát,Thiên Hỏa,Ngũ Li,Chu Tước,Thiên Cẩu Nhật Thời,Chu Tước,Phi Ma,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn",
        nen: "",
        khongNen: "Cưới Gả,Lập Ước Giao Dịch",
      },

      {
        catThan: "Ích Hậu,Kim Quĩ,Thiên Y,Thiên Tài,Ích Hậu,Đại Hồng Sa",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Ngũ Hư,Tuyệt Dương,Hoang Vu",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Nguyệt Đức Hợp,Vương Nhật,Tục Thế,Bảo Quang,Địa Tài,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Thiên Ôn,Thần Cách,Bất Thành,Ngũ Quỷ,Lôi Công,Khô Tiêu,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Cửu Khảm,Huyết Kị,Trùng Nhật,Tiểu Hội,Thuần  m",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Đức Hợp,Nguyệt Không,Quan Nhật,Thiên Mã,Cát Kì,Yếu An,Ô Phệ Đối",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Bạch Hổ,Phục Binh,Thiên Bảng,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,An Táng,Cải Táng.",
        khongNen: "",
      },

      {
        catThan:
          "Thủ Nhật,Lộc Khố,Thiên Phú,Thiên Vu,Phúc Đức,Ngọc Vũ,Ngọc Đường",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Đại Họa,Thiên Tặc,Thổ Ôn,Phi Liêm,Cửu Không,Đại Sát,Qui Kị,Hành Ngận",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Nguyện,Thời Đức,Tướng Nhật,Lục Hợp,Ngũ Phú,Bất Tương,Kim Đường,Ngũ Hợp,Ô Phệ Đối,Minh Tinh,Thiên Quý",
        hungThan:
          "Hà Khôi,Tử Thần,Du Họa,Ngũ Hư,Phục Nhật,Mộc Mã,Phá Bại,Tiểu Hao,Câu Giảo,Trùng Nhật,Ngõa Toái,Trùng Tang,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ,Thiên Lao",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
        khongNen: "",
      },

      {
        catThan:
          "Âm Đức,Dân Nhật,Tam Hợp,Thời Âm,Bất Tương,Ngũ Hợp,Ô Phệ Đối,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Tử Khí,Huyền Vũ,Thiên Lao,Thiên Hỏa,Tử Phù,Đại Hao,Nhân Cách",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Nạp Tài,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo,Tứ Tướng,Dương Đức,Giải Thần. Tư Mệnh",
        hungThan: "Tiểu Hao,Thiên Tặc,Thổ Phù,Địa Tặc,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,An Táng",
        khongNen:
          "Xuất Hành,Tu Tạo Động Thổ,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Ân,Tứ Tướng,Dịch Mã,Thiên Hậu,Thiên Thương,Kính An,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan: "Nguyệt Phá,Đại Hao,Trùng Nhật,Câu Trần,Tiểu Hồng Sa,Tứ Phế",
        nen: "Dỡ Nhà Phá Tường",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Phổ Hộ,Thanh Long,Ô Phệ,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Thủ Nhật",
        hungThan:
          "Thiên Lại,Thiên Sứ,Hoàng Sa,Ương Bại,Lâm Nhật,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Chí Tử,Tứ Phế,Ngũ Hư",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tam Hợp,Lâm Nhật,Thiên Hỉ,Lục Nghi,Phúc Sinh,Minh Đường,Hỉ Thần,Nguyệt Tài,Phúc Sinh,Phúc Tinh",
        hungThan:
          "Yếm Đối,Thổ Kỵ,Bàng Phế,Tứ Phế,Chiêu Dao,Tứ Kích,Vãng Vong,Bát Chuyên",
        nen: "Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
      },

      {
        catThan: "Mẫu Thương,Trừ Thần,Đại Hồng Sa",
        hungThan:
          "Thiên Cương,Thiên Cẩu Nhật Thời,Nguyệt Hỏa,Thọ Tử,Bát Tòa,Đao Chẩn,Thổ Cấm,Kiếp Sát,Nguyệt Hại,Đại Nang,Ngũ Li,Thiên Hình",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thiên Ân,Mẫu Thương,Thời Dương,Sinh Khí,Thánh Tâm,Trừ Thần,Ô Phệ",
        hungThan:
          "Tai Sát,Thiên Cẩu Nhật Thời,Chu Tước,Phi Ma,Lỗ Ban,Đao Chẩn,Thiên Hỏa,Ngũ Li,Chu Tước",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Đức Hợp,Thiên Y,Thiên Tài,Đại Hồng Sa,Nguyệt Không,Thiên Ân,Ích Hậu,Kim Quĩ",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Ngũ Hư,Phục Binh,Hoang Vu,Thiên Địa Chuyển",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường, An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Vương Nhật,Tục Thế,Bảo Quang,Địa Tài,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Cửu Khảm,Huyết Kị,Trùng Nhật,Đại Họa,Thiên Ôn,Thần Cách,Bất Thành,Ngũ Quỷ,Lôi Công,Khô Tiêu",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Thiên Quý,Quan Nhật,Thiên Mã,Cát Kì,Yếu An,Ô Phệ Đối",
        hungThan:
          "Đại Thời,Đại Bại,Hàm Trì,Tứ Kị,Lục Xà,Phục Nhật,Bạch Hổ,Tuế Bạc,Thiên Bảng,Trùng Nhật,Trùng Tang,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Thủ Nhật,Thiên Vu,Phúc Đức,Ngọc Vũ,Ngọc Đường,Lộc Khố,Thiên Phú,Thiên Quý",
        hungThan:
          "Nguyệt Yếm,Địa Hỏa,Cửu Không,Đại Sát,Qui Kị,Bát Chuyên,Xúc Thủy Long,Liễu Lệ, m Thác,Thiên Tặc,Thổ Ôn,Phi Liêm",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Tứ Tướng,Thời Đức,Tướng Nhật,Lục Hợp,Ngũ Phú,Kim Đường,Ngũ Hợp,Ô Phệ Đối,Minh Tinh",
        hungThan:
          "Hà Khôi,Tử Thần,Du Họa,Ngũ Hư,Bát Phong,Bát Chuyên,Thiên Lao,Mộc Mã,Phá Bại,Tiểu Hao,Câu Giảo,Ngõa Toái,Hoang Vu",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng,Cải Táng.",
        khongNen: "Cưới Gả,Săn Bắn,Đánh Cá",
      },

      {
        catThan:
          "Thiên Đức,Nguyệt Ân,Tứ Tướng,Âm Đức,Dân Nhật,Tam Hợp,Thời Âm,Ngũ Hợp,Ô Phệ,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tử Khí,Huyền Vũ,Thiên Lao,Thiên Hỏa,Tử Phù,Đại Hao,Huyền Vũ,Nhân Cách",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Dương Đức,Giải Thần,Tư Mệnh,Thiên Quan,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tiểu Hao,Thiên Tặc,Thổ Phù,Địa Tặc,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ",
        khongNen:
          "Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Dịch Mã,Binh Cát,Binh Phúc,Binh Bảo,Thiên Hậu,Thiên Tương,Kính An",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tứ Phế,Trùng Nhật,Câu Trần, m Dương Giao Phá,Tiểu Hồng Sa,Câu Trần,Bàng Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Phổ Hộ,Thanh Long,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Thủ Nhật",
        hungThan:
          "Thiên Lại,Thiên Sứ,Hoàng Sa,Tai Sát,Lâm Nhật,Hoang Vu,Tứ Phế,Chí Tử,Ngũ Hư",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Tam Hợp,Lâm Nhật,Thiên Hỉ,Thiên Y,Lục Nghi,Phúc Sinh,Minh Đường,Hỉ Thần,Nguyệt Tài,Phúc Sinh,Phúc Tinh",
        hungThan: "Yếm Đối,Chiêu Dao,Tứ Kích,Vãng Vong,Bát Chuyên,Thổ Kỵ",
        nen: "Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
      },

      {
        catThan:
          "Thiên Đức Hợp,Đại Hồng Sa,Nguyệt Không,Mẫu Thương,Trừ Thần,Ô Phệ",
        hungThan:
          "Thiên Cương,Phục Binh,Thiên Cẩu Nhật Thời,Nguyệt Hỏa,Thọ Tử,Bát Tòa,Thiên Địa Chuyển,Đao Chấn,Thổ Cấm,Kiếp Sát,Nguyệt Hại,Ngũ Li,Bát Chuyên,Thiên Hình",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Nạp Tài,An Táng.",
        khongNen: "Cưới Gả",
      },

      {
        catThan: "Mẫu Thương,Thời Dương,Sinh Khí,Thánh Tâm,Trừ Thần,Ô Phệ",
        hungThan:
          "Tai Sát,Đại Họa,Thiên Cẩu Nhật Thời,Phi Ma,Lỗ Ban,Đao Chẩn,Thiên Hỏa,Tứ Hao,Ngũ Li,Chu Tước",
        nen: "",
        khongNen:
          "Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Ích Hậu,Kim Quĩ,Thiên Y,Thiên Tài,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Huyết Chi,Ngũ Hư,Phục Nhật,Trùng Nhật,Trùng Tang,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Vương Nhật,Tục Thế,Bảo Quang,Địa Tài,Thiên Quý,Phúc Hậu",
        hungThan:
          "Nguyệt Kiến,Thiên Ôn,Thần Cách,Bất Thành,Ngũ Quỷ,Dương Thác,Lôi Công,Khô Tiêu,Tiểu Thời,Thổ Phủ,Nguyệt Hình,Tứ Cùng,Lục Xà,Cửu Khảm,Huyết Kị,Trùng Nhật,Dương Thác",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },
    ],
    //Tháng tý
    [
      {
        catThan:
          "Thiên Ân,Thiên Xá,Nguyệt Ân,Tứ Tướng,Quan Nhật,Kính An,Kim Quĩ,Thiên Tài,Thiên Nguyện",
        hungThan:
          "Nguyệt Kiến,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Tiểu Thời,Thổ Phủ,Nguyệt Yếm,Địa Hỏa",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước,Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Địa Tài,Tứ Tướng,Âm Đức,Thủ Nhật,Cát Kì,Lục Hợp,Phổ Hộ,Bảo Quang",
        hungThan: "Thiên Ôn,Nhân Gian",
        nen: "Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Không,Lộc Khố,Thiên Phú,Phúc Tinh,Phúc Sinh,Binh Cát,Binh Phúc,Binh Bảo,Thiên Ân,Thời Đức,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Mã,Thiên Vu,Phúc Đức,Phúc Sinh,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Ngũ Hư,Phục Binh,Thiên Bảng,Hoàng Sa,Địa Ôn,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế,Qui Kị,Bạch Hổ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Cải Táng",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Thiên Ân,Dân Nhật,Bất Tương,Ngũ Hợp,Ngọc Đường,Ô Phệ Đối,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hình,Thiên Lại,Chí Tử,Thiên Tặc,Đại Họa,Thiên Sứ,Địa Tặc,Thọ Tử,Bất Thành,Tiểu Hao,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Thiên Ân,Tam Hợp,Lâm Nhật,Thời Âm,Thiên Thương,Thánh Tâm,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tử Khí,Thiên Lao,Nguyệt Hư,Tử Phù,Đại Hao,Mộc Mã,Phá Bại,Nguyệt Sát,Lâm Nhật,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Ngũ Phú,Bất Tương,Ích Hậu,Thiên Đức,Ngũ Phú,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Kiếp Sát,Tiểu Hao,Trùng Nhật,Huyền Vũ,Tai Sát,Lôi Công,Khô Tiêu,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Dương Đức,Thiên Quan,Thủ Nhật,Lục Nghi,Tục Thế,Giải Thần,Tư Mệnh,Ô Phệ",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Lao,Thiên Tặc,Phi Ma,Hoang Vu,Thiên Địa Chuyển,Tứ Phế,Thiên Hỏa,Yếm Đối,Chiêu Dao,Ngũ Hư,Huyết Kị",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Yếu An",
        hungThan: "Nguyệt Sát,Nguyệt Hư,Nguyệt Hại,Tứ Kích,Câu Trần,Ngũ Quỷ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Mẫu Thương,Tam Hợp,Thiên Hỉ,Thiên Y,Ngọc Vũ,Trừ Thần,Thanh Long,Ô Phệ,Thiên Đức Hợp,Hỉ Thần,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Cửu Khảm,Cửu Tiêu,Thổ Phù,Đại Sát,Ngũ Li,Phi Liêm,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Mẫu Thương,Kim Đường,Trừ Thần,Nguyệt Tài,Thiên Quý,Minh Đường,Ô Phệ",
        hungThan:
          "Hà Khôi,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Câu Giảo,Bát Tòa,Trùng Nhật,Ngõa Toái,Trùng Tang,Lỗ Ban,Đao Chẩn,Đại Thời,Đại Bại,Hàm Trì,Phục Nhật,Ngũ Li",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Ân,Tứ Tướng,Thời Dương,Sinh Khí,Đại Hồng Sa",
        hungThan:
          "Ngũ Hư,Bát Phong,Cửu Không,Vãng Vong,Thiên Hình,Thiên Cẩu Nhật Thời,Thổ Kỵ,Hoang Vu",
        nen: "Tu Tạo Động Thổ",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Tứ Tướng,Vương Nhật,Thiên Y,Phúc Hậu",
        hungThan: "Du Họa,Huyết Chi,Trùng Nhật,Chu Tước",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Không,Thiên Tài,Quan Nhật,Kính An,Kim Quĩ,Ô Phệ Đối",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Phục Binh,Thiên Địa Chuyển,Bàng Phế,Thiên Địa Trù,Nguyệt Chuyển,Tứ Phế,Phu Sát,Thổ Phủ,Nguyệt Yếm,Địa Hỏa,Xúc Thủy Long",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Âm Đức,Thủ Nhật,Cát Kì,Lục Hợp,Bất Tương,Phổ Hộ,Bảo Quang,Địa Tài",
        hungThan: "Đại Họa,Thiên Ôn,Nhân Cách,Bàng Phế,Tứ Phế",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thời Đức,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Mã,Thiên Vu,Phúc Đức,Phúc Sinh,Ngũ Hợp,Lộc Khố,Phúc Tinh,Binh Cát,Binh Bảo,Binh Phúc",
        hungThan: "Ngũ Hư,Qui Kị,Bạch Hổ,Thiên Bảng,Hoàng Sa,Thổ Ôn,Hoang Vu",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Thiên Ân,Hoạt Nhãn,Binh Cát,Binh Bảo,Binh Phúc,Dân Nhật,Bất Tương,Ngũ Hợp,Ngọc Đường",
        hungThan:
          "Thiên Cương,Tử Khí,Nguyệt Hình,Thiên Lại,Thiên Sứ,Địa Tặc,Thọ Tử,Bất Thành,Tiểu Hao,Chí Tử,Thiên Tặc",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Ân,Tam Hợp,Lâm Nhật,Thời Âm,Thiên Hậu,Bất Tương,Thánh Tâm,Minh Tinh,Thánh Tâm,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tử Khí,Thiên Lao,Nguyệt Hư,Tử Phù,Đại Hao,Mộc Mã,Phá Bại,Nguyệt Sát,Lâm Nhật,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Thiên Ân,Ngũ Phú,Thiên Đức,Binh Cát,Binh Phúc,Binh Bảo,Bất Tương,Ích Hậu",
        hungThan:
          "Kiếp Sát,Tiểu Hao,Trùng Nhật,Ương Bại,Lôi Công,Khô Tiêu,Tứ Phế,Huyền Vũ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Ân,Dương Đức,Lục Nghi,Tục Thế,Giải Thần,Tư Mệnh,Ô Phệ,Thiên Quan,Thiên Quý,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Yếm Đối,Chiêu Dao,Ngũ Hư,Huyết Kị,Thiên Lao,Thiên Hỏa,Phi Ma,Huyết Kỵ,Thiên Địa Chuyển,Hoang Vu,Tứ Phế,Tứ Đại Mộ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường, An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Yếu An,Thiên Quý",
        hungThan:
          "Nguyệt Sát,Nguyệt Hư,Nguyệt Hại,Tứ Kích,Phục Nhật,Xúc Thủy Long,Câu Trần,Nguyệt Hỏa,Ngũ Quỷ,Trùng Nhật,Trùng Tang",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Mẫu Thương,Nguyệt Ân,Tứ Tướng,Tam Hợp,Thiên Hỉ,Thiên Y,Ngọc Vũ,Trừ Thần,Thanh Long,Ô Phệ,Thiên Đức Hợp,Hỉ Thần,Đại Hồng Sa",
        hungThan:
          "Cửu Khảm,Cửu Tiêu,Thổ Phù,Đại Sát,Ngũ Li,Phi Liêm,Đao Chẩn,Thổ Cấm",
        nen: "Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Mẫu Thương,Tứ Tướng,Kim Đường,Trừ Thần,Bảo Quang,Ô Phệ,Nguyệt Tài",
        hungThan:
          "Hà Khôi,Đại Thời,Đại Bại,Hàm Trì,Ngũ Li,Thiên Cẩu Nhật Thời,Hỉ Thần,Tiểu Hồng Sa,Câu Giảo,Hà Khôi,Bát Tòa,Ngõa Toái,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Không,Thời Dương,Sinh Khí,Đại Hồng Sa",
        hungThan:
          "Ngũ Hư,Cửu Không,Vãng Vong,Thiên Hình,Phục Binh,Thiên Cẩu Nhật Thời,Thổ Kỵ,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "Tu Tạo Động Thổ",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Nguyệt Đức Hợp,Vương Nhật,Thiên Y,Phúc Hậu",
        hungThan: "Du Họa,Huyết Chi,Trùng Nhật,Chu Tước,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Cưới Gả",
      },

      {
        catThan: "Quan Nhật,Kính An,Kim Quĩ,Thiên Tài",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Nguyệt Yếm,Địa Hỏa,Tiểu Hội,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Âm Đức,Thủ Nhật,Cát Kì,Lục Hợp,Bất Tương,Phổ Hộ,Bảo Quang,Địa Tài",
        hungThan: "Thiên Ôn,Nhân Cách.",
        nen: "Xuất Hành,Cưới Gả,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Thời Đức,Lộc Khố,Thiên Phú,Phúc Tinh,Phúc Sinh,Binh Cát,Binh Phúc,Binh Bảo,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Mã,Thiên Vu,Phúc Đức,Bất Tương,Phúc Sinh,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Ngũ Hư,Qui Kị,Bạch Hổ,Thiên Bảng,Hoàng Sa,Thổ Ôn,Thiên Địa Trù",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Cải Táng",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Dân Nhật,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Dân Nhật,Bất Tương,Ngũ Hợp,Ngọc Đường,Ô Phệ Đối",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hình,Thiên Lại,Chí Tử,Thiên Tặc,Địa Nang,Thiên Sứ,Địa Tặc,Thọ Tử,Bất Thành,Tiểu Hao",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Đức,Tam Hợp,Thời Âm,Thiên Thương,Bất Tương,Thánh Tâm,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Tử Khí,Ngũ Mộ,Thiên Lao,Nguyệt Hư,Tử Phù,Đại Hao,Mộc Mã,Phá Bại,Nguyệt Sát,Lâm Nhật,Thiên Địa Chuyển,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Ngũ Phú,Ích Hậu,Thiên Đức,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý",
        hungThan:
          "Kiếp Sát,Ương Bại,Lôi Công,Khô Tiêu,Trùng Tang,Tứ Phế,Tiểu Hao,Phục Nhật,Trùng Nhật,Huyền Vũ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Dương Đức,Lục Nghi,Tục Thế,Giải Thần,Tư Mệnh,Ô Phệ,Thiên Quan ,Thủ Nhật",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Yếm Đối,Chiêu Dao,Ngũ Hư,Huyết Kị,Thiên Lao,Phi Ma,Huyết Kỵ,Hoang Vu,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Tứ Tướng,Yếu An",
        hungThan: "Nguyệt Sát,Ngũ Quỷ,Nguyệt Hư,Nguyệt Hại,Tứ Kích,Câu Trần",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Mẫu Thương,Tam Hợp,Thiên Hỉ,Thiên Y,Ngọc Vũ,Trừ Thần,Thanh Long,Ô Phệ,Thiên Đức Hợp,Hỉ Thần,Đại Hồng Sa",
        hungThan:
          "Cửu Khảm,Cửu Tiêu,Thổ Phù,Đại Sát,Ngũ Li,Phục Binh,Phi Liêm,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Đao Chẩn,Thổ Cấm",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Mẫu Thương,Kim Đường,Trừ Thần,Minh Đường,Ô Phệ,Nguyệt Tài",
        hungThan:
          "Hà Khôi,Đại Thời,Đại Bại,Hàm Trì,Ngũ Li,Đại Họa,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Câu Giảo,Bát Tòa,Ngõa Toái,Bàng Phế,Lỗ Ban,Tứ Phế,Đao Chẩn",
        nen: "",
        khongNen: "Tu Tạo Động Thổ",
      },

      {
        catThan: "Thời Dương,Sinh Khí,Đại Hồng Sa",
        hungThan:
          "Ngũ Li,Cửu Không,Vãng Vong,Thiên Hình,Thiên Cẩu Nhật Thời,Thổ Kỵ,Hoang Vu",
        nen: "Tu Tạo Động Thổ",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Vương Nhật,Thiên Y,Phúc Hậu",
        hungThan: "Du Họa,Huyết Chi,Trùng Nhật,Chu Tước",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Quan Nhật,Kính An,Kim Quĩ ,Thiên Tài,Ô Phệ Đối",
        hungThan:
          "Nguyệt Kiến,Tiểu Thời,Thổ Phủ,Thiên Địa Chuyển,Thiên Địa Trù,Phu Sát,Nguyệt Yếm,Địa Hỏa",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Âm Đức,Thủ Nhật,Cát Kì,Lục Hợp,Bất Tương,Phổ Hộ,Bảo Quang,Địa Tài",
        hungThan: "Thiên Ôn,Nhân Cách",
        nen: "Cưới Gả,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức,Lộc Khố,Thiên Mã,Thiên Phú,Phúc Tinh,Binh Phúc,Binh Cát,Binh Bảo,Thiên Quý,Thời Đức,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Mã,Thiên Vu,Phúc Đức,Bất Tương,Phúc Sinh,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Ngũ Hư,Qui Kị,Bạch Hổ,Thiên Bảng,Hoàng Sa,Thổ Ôn,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
        khongNen: "",
      },

      {
        catThan:
          "Dân Nhật,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Thiên Quý,Ngũ Hợp,Ngọc Đường,Ô Phệ Đối",
        hungThan:
          "Thiên Cương,Thiên Sứ,Địa Tặc,Thọ Tử,Bất Thành,Tiểu Hao,Trùng Nhật,Trùng Tang,Tử Thần,Nguyệt Hình,Thiên Lại,Chí Tử,Thiên Tặc,Phục Nhật",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Tam Hợp,Lâm Nhật,Thời Âm,Thiên Thương,Thánh Tâm,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Tử Khí,Thiên Lao,Nguyệt Hư,Tử Phù,Đại Hao,Mộc Mã,Phá Bại,Nguyệt Sát,Lâm Nhật,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch",
        khongNen: "Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Tứ Tướng,Ngũ Phú,Ích Hậu,Thiên Đức,Ích Hậu,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Kiếp Sát,Tiểu Hao,Trùng Nhật,Huyền Vũ,Ương Bại,Lôi Công,Khô Tiêu,Tứ Phế",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Không,Thiên Quan,Thủ Nhật,Dương Đức,Lục Nghi,Tục Thế,Giải Thần,Tư Mệnh,Ô Phệ",
        hungThan:
          "Nguyệt Phá,Đại Hao,Tai Sát,Thiên Hỏa,Yếm Đối,Chiêu Dao,Tứ Phế,Ngũ Hư,Huyết Kị, m Dương Kích Xung,Phục Binh,Thiên Lao,Thiên Tặc,Phi Ma,Huyết Kỵ,Thiên Địa Chuyển,Hoang Vu,Bàng Phế,Tứ Phế",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Nguyệt Đức Hợp,Yếu An",
        hungThan:
          "Nguyệt Sát,Ngũ Quỷ,Bàng Phế,Tứ Phế,Nguyệt Hư,Nguyệt Hại,Tứ Kích,Bát Chuyên,Câu Trần",
        nen: "",
        khongNen: "Cưới Gả",
      },

      {
        catThan:
          "Mẫu Thương,Tam Hợp,Thiên Hỉ,Thiên Y,Ngọc Vũ,Trừ Thần,Thanh Long,Thiên Đức Hợp,Hỉ Thần,Đại Hồng Sa",
        hungThan:
          "Cửu Khảm,Cửu Tiêu,Thổ Phù,Đại Sát,Ngũ Li,Phi Liêm,Đao Chẩn,Thổ Cấm",
        nen: "Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan:
          "Thiên Ân,Mẫu Thương,Kim Đường,Trừ Thần,Minh Đường,Ô Phệ,Nguyệt Tài",
        hungThan:
          "Hà Khôi,Đại Thời,Đại Bại,Hàm Trì,Ngũ Li,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Câu Giảo,Bát Tòa,Ngõa Toái,Lỗ Ban,Đao Chẩn",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan: "Thiên Ân,Thời Dương,Sinh Khí,Đại Hồng Sa",
        hungThan:
          "Ngũ Hư,Cửu Không,Vãng Vong,Thiên Hình,Thiên Cẩu Nhật Thời,Thổ Kỵ,Hoang Vu,Thiên Địa Chuyển",
        nen: "Tu Tạo Động Thổ",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan: "Thiên Ân,Vương Nhật,Thiên Y,Phúc Hậu",
        hungThan: "Du Họa,Huyết Chi,Trùng Nhật,Chu Tước",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },

      {
        catThan:
          "Nguyệt Đức,Thiên Tài,Thiên Quý,Quan Nhật,Thiên Ân,Lâm Nhật,Kính An,Kim Quĩ,Ô Phệ Đối",
        hungThan:
          "Nguyệt Kiến,Thiên Địa Chuyển,Thiên Địa Trù,Nguyệt Chuyển,Phu Sát,Tứ Đại Mộ,Tiểu Thời,Thổ Phủ,Nguyệt Yếm,Địa Hỏa,Tứ Kị,Lục Xà,Đại Hội, m Dương Câu Thác",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Thiên Ân,Địa Tài,Thiên Quý,Thiên Nguyện,Âm Đức,Thủ Nhật,Cát Kì,Lục Hợp,Phổ Hộ,Bảo Quang",
        hungThan:
          "Phục Nhật,Bát Chuyên,Xúc Thủy Long,Thiên Ôn,Nhân Cách,Trùng Nhật,Trùng Tang",
        nen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Ân,Tứ Tướng,Lộc Khố,Nguyệt  n,Thiên Phú,Phúc Tinh,Binh Cát,Binh Phúc,Binh Bảo,Thời Đức,Tướng Nhật,Dịch Mã,Thiên Hậu,Thiên Mã,Thiên Vu,Phúc Đức,Phúc Sinh,Ngũ Hợp,Ô Phệ Đối",
        hungThan:
          "Ngũ Hư,Thiên Bảng,Hoàng Sa,Thổ Cấm,Hoang Vu,Bát Phong,Qui Kị,Bát Chuyên,Bạch Hổ",
        nen: "Dâng Biểu Sớ,Xuất Hành,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Cải Táng",
        khongNen: "Cưới Gả,Mở Kho Xuất Tiền Hàng",
      },

      {
        catThan:
          "Tứ Tướng,Hoạt Nhãn,Binh Cát,Binh Phúc,Binh Bảo,Dân Nhật,Ngũ Hợp,Ngọc Đường,Ô Phệ Đối",
        hungThan:
          "Thiên Cương,Tử Thần,Nguyệt Hình,Thiên Lại,Thiên Sứ,Địa Tặc,Thọ Tử,Bất Thành,Tiểu Hao,Chí Tử,Thiên Tặc",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan:
          "Nguyệt Không,Minh Tinh,Binh Cát,Binh Phúc,Binh Bảo,Tam Hợp,Lâm Nhật,Thời Âm,Thiên Thương,Thánh Tâm",
        hungThan:
          "Tử Khí,Thiên Lao,Nguyệt Hư,Phục Binh,Tử Phù,Đại Hao,Mộc Mã,Phá Bại,Nguyệt Sát,Lâm Nhật,Thiên Địa Chuyển,Bàng Phế,Tứ Phế,Tứ Đại Mộ",
        nen: "Dâng Biểu Sớ,Cưới Gả,Tu Tạo Động Thổ,Lập Ước Giao Dịch",
        khongNen: "",
      },

      {
        catThan:
          "Nguyệt Đức Hợp,Ngũ Phú,Bất Tương,Ích Hậu,Thiên Đức,Binh Cát,Binh Phúc,Binh Bảo",
        hungThan:
          "Kiếp Sát,Đại Họa,Ương Bại,Lôi Công,Khô Tiêu,Bàng Phế,Tứ Phế,Tiểu Hao,Tứ Kích,Trùng Nhật,Huyền Vũ",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Dương Đức,Thiên Quan,Thủ Nhật,Lục Nghi,Tục Thế,Giải Thần,Tư Mệnh",
        hungThan:
          "Nguyệt Phá,Thiên Lao,Thiên Hỏa,Phi Ma,Huyết Kỵ,Hoang Vu,Tứ Phế,Đại Hao,Tai Sát,Thiên Hỏa,Yếm Đối,Chiêu Dao,Ngũ Hư,Huyết Kị",
        nen: "",
        khongNen: "Mọi Việc Không Nên Làm",
      },

      {
        catThan: "Yếu An",
        hungThan:
          "Nguyệt Sát,Nguyệt Hỏa,Ngũ Quỷ,Nguyệt Hại,Nguyệt Hư,Tứ Kích,Bát Chuyên,Câu Trần",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan:
          "Tam Hợp,Thiên Hỉ,Thiên Y,Ngọc Vũ,Thanh Long,Mẫu Thương,Trừ Thần,Ô Phệ,Thiên Đức Hợp ,Hỉ Thần,Tam Hợp,Đại Hồng Sa",
        hungThan:
          "Cửu Khảm,Phi Liêm,Thiên Địa Chuyển,Đao Chẩn,Thổ Cấm,Cửu Tiêu,Thổ Phù,Đại Sát,Ngũ Li,Bát Chuyên",
        nen: "Xuất Hành,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,An Táng",
        khongNen: "Cưới Gả,Tu Tạo Động Thổ,Dỡ Nhà Phá Tường",
      },

      {
        catThan: "Mẫu Thương,Nguyệt Tài,Kim Đường,Trừ Thần,Minh Đường,Ô Phệ",
        hungThan:
          "Hà Khôi,Đại Thời,Thiên Cẩu Nhật Thời,Thần Cách,Tiểu Hồng Sa,Câu Giảo,Bát Tòa,Ngõa Toái,Lỗ Ban,Đao Chẩn,Đại Bại,Hàm Trì,Tứ Hao,Địa Nang,Ngũ Li",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,Dỡ Nhà Phá Tường,An Táng,Cải Táng",
      },

      {
        catThan: "Nguyệt Đức,Thời Dương,Sinh Khí,Thiên Quý,Đại Hồng Sa",
        hungThan:
          "Ngũ Hư,Thiên Cẩu Nhật Thời,Thổ Kỵ,Thiên Địa Chuyển,Hoang Vu,Tứ Đại Mộ,Cửu Không,Vãng Vong,Thiên Hình",
        nen: "Tu Tạo Động Thổ,Khai Trương",
        khongNen: "Dâng Biểu Sớ,Xuất Hành,Cưới Gả",
      },

      {
        catThan: "Vương Nhật,Thiên Y,Thiên Quý,Phúc Hậu",
        hungThan:
          "Du Họa,Huyết Chi,Tứ Cùng,Lục Xà,Phục Nhật,Trùng Nhật,Chu Tước,Trùng Nhật,Trùng Tang",
        nen: "",
        khongNen:
          "Dâng Biểu Sớ,Xuất Hành,Cưới Gả,Tu Tạo Động Thổ,Khai Trương,Lập Ước Giao Dịch,Nạp Tài,Mở Kho Xuất Tiền Hàng,An Táng,Cải Táng",
      },
    ],
  ];
  // static ThienBan = { GIAP,AT,BINH,DINH,MAU,KY,CANH,TAN,NHAM,QUY };
  // static CuuTinh = { THIEN_BONG,THIEN_NHUE,THIEN_XUNG,THIEN_PHU,THIEN_TAM,THIEN_CAM,THIEN_NHAM,THIEN_TRU,THIEN_ANH };
  // static DiaBan = { GIAP,AT,BINH,DINH,MAU,KY,CANH,TAN,NHAM,QUY };
  // static BatMon = { KHAI,HUU,SINH,THUONG,DO,CANH,KINH,TU };
  // static BatThan = { XA,DIA,THIEN,TRAN,AM,TUOC,PHU,HOP,HO,VU };
}
