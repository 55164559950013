import vi from "date-fns/locale/vi";
import md5 from "md5";
import React from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../App.css";
import Logofooter from "../image/hoc-phong-thuy-tuong-minh_1.jpg";

const mdfive = md5("HuyenKhongTamNguyenFengShuiWebSiteHitekTuongMinh");
registerLocale("vi", vi);

class Footer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="to-hide-print">
        <footer>
          <div className="coppyright" style={{ minWidth: "1070px" }}>
            <div className="contentner">
              <div className="contentner-int">
                <div className="address">
                  <div>
                    Địa chỉ 4A Đường D7, Khu dân cư Nam Long, Phước Long B, Quận
                    9, TP.Hồ Chí Minh
                  </div>
                  <div>Điện thoại: 0981 229 461</div>
                  <div>Email: lienhe.tmfs@gmail.com</div>
                </div>
                <div className="social">
                  <a
                    className="facebook-link"
                    href="https://business.facebook.com/hocvienphongthuytuongminh/"
                  >
                    <i
                      className="fa fa-facebook"
                      aria-hidden="true"
                      style={{
                        color: "white",
                        position: "relative",
                        paddingTop: "10px",
                      }}
                    ></i>
                  </a>
                  <a
                    className="twitter-link"
                    href="https://www.facebook.com/phongthuytuongminh/"
                  >
                    <i
                      className="fa fa-twitter"
                      aria-hidden="true"
                      style={{
                        color: "white",
                        position: "relative",
                        paddingTop: "10px",
                      }}
                    ></i>
                  </a>
                  <a
                    className="google-link"
                    href="https://www.facebook.com/phongthuytuongminh/"
                  >
                    <i
                      className="fa fa-google-plus"
                      aria-hidden="true"
                      style={{
                        color: "white",
                        position: "relative",
                        paddingTop: "10px",
                      }}
                    ></i>
                  </a>
                  <a
                    className="youtube-link"
                    href="https://www.youtube.com/channel/UCxVMAH7gUiM9-zszIDKj9uA"
                  >
                    <i
                      className="fa fa-youtube"
                      aria-hidden="true"
                      style={{
                        color: "white",
                        position: "relative",
                        paddingTop: "10px",
                      }}
                    ></i>
                  </a>
                </div>
                <div className="footer-bottom">
                  <div className="menu-footer-b">
                    <ul className="menu clearfix">
                      <li className="first expanded">
                        <a
                          href="http://phongthuytuongminh.com/gioi-thieu-ve-tuong-minh-phong-thuy.html"
                          title=""
                        >
                          Về chúng tôi
                        </a>
                        <ul className="menu clearfix">
                          <li className="first leaf">
                            <a
                              href="http://phongthuytuongminh.com/gioi-thieu-ve-tuong-minh-phong-thuy.html"
                              title=""
                            >
                              Về Tường Minh phong thuỷ
                            </a>
                          </li>
                          <li className="leaf">
                            <a
                              href="http://phongthuytuongminh.com/master-phuong-nguyen.html"
                              title=""
                            >
                              Thầy Nguyễn Thành Phương
                            </a>
                          </li>
                          <li className="last leaf">
                            <a
                              href="http://phongthuytuongminh.com/thu-vien-anh.html"
                              title=""
                            >
                              Thư viện ảnh
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a
                          href="http://phongthuytuongminh.com/tu-van-phong-thuy.html"
                          title=""
                        >
                          Dịch Vụ Tư vấn
                        </a>
                        <ul className="menu clearfix">
                          <li className="first leaf">
                            <a
                              href="http://phongthuytuongminh.com/phong-thuy-nha-o.html"
                              title=""
                            >
                              Phong thủy Nhà ở
                            </a>
                          </li>
                          <li className="leaf">
                            <a
                              href="http://phongthuytuongminh.com/chon-ngay-tot-nhat-trach.html-0"
                              title=""
                            >
                              Chọn ngày tốt (Trạch Nhật)
                            </a>
                          </li>
                          <li className="last leaf">
                            <a
                              href="http://phongthuytuongminh.com/phong-thuy-mo-ma-am-trach.html"
                              title=""
                            >
                              Mộ Phần (Âm trạch)
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a
                          href="http://phongthuytuongminh.com/hoc-phong-thuy.html"
                          title=""
                        >
                          Học phong thủy
                        </a>
                        <ul className="menu clearfix">
                          <li className="first leaf">
                            <a
                              href="http://phongthuytuongminh.com/phong-thuy-dia-ly.html"
                              title=""
                            >
                              Phong thủy địa lý
                            </a>
                          </li>
                          <li className="leaf">
                            <a
                              href="http://phongthuytuongminh.com/bat-tu-tu-vi.html"
                              title=""
                            >
                              Bát tự tử vi
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/" title="" className="active">
                              Trạch Nhật (Chọn Ngày Giờ)
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/lich-hoc-phong-thuy" title="">
                              Lịch học phong thủy
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a
                          href="http://phongthuytuongminh.com/kien-thuc-phong-thuy.html-0&gt;"
                          title=""
                        >
                          Kiến thức phong thủy
                        </a>
                        <ul className="menu clearfix">
                          <li className="first leaf">
                            <a
                              href="http://phongthuytuongminh.com/tai-lieu-phong-thuy.html"
                              title=""
                            >
                              Tài liệu phong thủy
                            </a>
                          </li>
                          <li className="last leaf">
                            <a
                              href="http://phongthuytuongminh.com/bai-viet-phong-thuy.html-1"
                              title=""
                            >
                              Bài viết phong thủy
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="last leaf">
                        <a href="/contact" title="Liên hệ">
                          Liên hệ
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="coppyright" style={{ minWidth: "1070px" }}>
            <p>
              by Phong Thuỷ Tường Minh - Hocphongthuy.net 2019 Allright reserved
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
