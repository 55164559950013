import _ from 'lodash';
// import faker from "faker";
import React, { Component } from 'react';
import { Grid, Search } from 'semantic-ui-react';

const initialState = { isLoading: false, results: [], value: '', resultLayout: false };

export default class SearchTest extends Component {
  state = initialState;
  constructor(props) {
    super(props);

    this.state = initialState;
    // this.onPressShowButton = this.onPressShowButton.bind(this);
    // this.self = this.props.this;
  }

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title, openLayoutResult: false });

    setTimeout(() => {
      this.props.result(this.state);
    }, 500);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    const test = this.props.datasearch.join()?.split(',');
    let originalData = [];
    const newData = test.map((v) => {
      if (v === ' ') {
        return '';
      } else {
        return (v = v.replace(' ', '').replace('.', ''));
      }
    });
    const correctData = newData.map((v) => {
      return (v = v?.trim());
    });
    correctData.forEach((element) => {
      if (element) {
        originalData.push(element);
      }
    });
    originalData.sort();

    let findDuplicates = (originalData) => originalData.filter((item, index) => originalData.indexOf(item) !== index);

    const uniqueKey = [...new Set(findDuplicates(originalData))];

    const source = uniqueKey.map((v) => {
      return { title: v };
    });

    setTimeout(() => {
      // if (this.state.value.length < 1) return this.setState(initialState);

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
      const isMatch = (result) => re.test(result.title);
      this.props.result(this.state);

      this.setState({
        isLoading: false,
        // results: source,
        results: _.filter(source, isMatch) || source,
      });
    }, 300);
  };
  renderResultLayout({ categoryContent, resultsContent }) {
    return (
      <div>
        <p>{categoryContent}</p>
        <p>{resultsContent}</p>
      </div>
    );
  }
  renderResult({ title }) {
    return <p>{title}</p>;
  }

  render() {
    let { isLoading, value, results } = this.state;

    return (
      <Grid>
        <Grid.Column width={6}>
          <Search
            ref={(input) => {
              this.SearchRef = input;
            }}
            id="searchBox"
            size="mini"
            categoryLayoutRenderer={this.renderResultLayout}
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            placeholder={'Tìm việc nên làm...'}
            open={this.state.openLayoutResult}
            resultRenderer={this.renderResult}
            results={results}
            noResultsMessage="Không tìm thấy kí tự phù hợp"
            value={value}
            // {...this.props}
            onFocus={() => {
              this.setState({ openLayoutResult: true, value: '' });
              if (value === '') {
                this.handleSearchChange('', '');
              }
            }}
          />
        </Grid.Column>
        {/* <Grid.Column width={10}>
          <Segment>
            <Header>State</Header>
            <pre style={{ overflowX: "auto" }}>
              {JSON.stringify(this.state, null, 2)}
            </pre>
            <Header>Options</Header>
            <pre style={{ overflowX: "auto" }}>
              {JSON.stringify(source, null, 2)}
            </pre>
          </Segment>
        </Grid.Column> */}
      </Grid>
    );
  }
}
