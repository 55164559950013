import React from 'react';
import '../App.css';
import { saoThanSatThang } from '../convert';
class SaoThan extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      saoThanHeader: [
        {
          header: 'Thái Dương\n Đáo Sơn',
          content: '',
        },
        {
          header: 'Thái Âm \nĐáo Sơn',
          content: '',
        },
        {
          header: 'Thiên\n Đức',
          content: '',
        },
        {
          header: 'Thiên\n Đức Hợp',
          content: '',
        },
        {
          header: 'Nguyệt\n Đức',
          content: '',
        },
        {
          header: 'Nguyệt\n Đức Hợp',
          content: '',
        },
        {
          header: 'Thiên\n Đạo',
          content: '',
        },
        {
          header: 'Nguyệt\n Không',
          content: '',
        },
        {
          header: 'Nguyệt\n Phá',
          content: '',
        },
        {
          header: 'Nguyệt\n Yểm',
          content: '',
        },
        {
          header: 'Kiếp\n Sát',
          content: '',
        },
        {
          header: 'Tai\n Sát',
          content: '',
        },
      ],
    };
  }

  inforOneHour(header) {
    return (
      <div
        width="80px"
        style={{
          color: '#434343',
          fontSize: '90%',
          fontWeight: 'bold',
          // textAlign: 'center',
          alignSelf: 'center',

          whiteSpace: 'pre-wrap',
        }}
      >
        {header}
      </div>
    );
  }
  divideData() {
    const dataSaoThan = this.props.chiMonth ? saoThanSatThang(this.props.chiMonth) : '';
    const thaiDuongThaiAm = this.props.thaiDuongThaiAm ? this.props.thaiDuongThaiAm : '';
    if (dataSaoThan) {
      this.state.saoThanHeader.map((v, i) => {
        if (i === 0) {
          return (v.content = thaiDuongThaiAm?.thaiDuong);
        }
        if (i === 1) {
          return (v.content = thaiDuongThaiAm?.thaiAm);
        }
        if (i === 2) {
          return (v.content = dataSaoThan[0]);
        }
        if (i === 3) {
          return (v.content = dataSaoThan[1]);
        }
        if (i === 4) {
          return (v.content = dataSaoThan[2]);
        }
        if (i === 5) {
          return (v.content = dataSaoThan[3]);
        }
        if (i === 6) {
          return (v.content = dataSaoThan[4]);
        }
        if (i === 7) {
          return (v.content = dataSaoThan[5]);
        }
        if (i === 8) {
          return (v.content = dataSaoThan[6]);
        }
        if (i === 9) {
          return (v.content = dataSaoThan[7]);
        }
        if (i === 10) {
          return (v.content = dataSaoThan[8]);
        }
        if (i === 11) {
          return (v.content = dataSaoThan[9]);
        }
        return '';
      });
    }
  }
  render() {
    this.divideData();
    return (
      <table className="saoThan">
        <tbody>
          <tr style={{ whiteSpace: 'nowrap', backgroundColor: '#1667AF' }}>
            <td
              colSpan="12"
              style={{
                textAlign: 'left',
                padding: '5px',
                color: '#fff',
                fontWeight: 'bold',
              }}
            >
              Sao Thần Sát Tháng
            </td>
          </tr>
          <tr>
            {this.state.saoThanHeader.map((v) => {
              return (
                <td
                  key={`saoThanHeader-${v.header}`}
                  width="80px"
                  className="saoThan"
                  style={{
                    padding: '5px',
                  }}
                >
                  {this.inforOneHour(v.header)}
                </td>
              );
            })}
          </tr>
          <tr height="35px">
            {this.state.saoThanHeader.map((v, i) => {
              return (
                <td key={`saoThanValue-${i}`} className="saoThan">
                  {v.content}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    );
  }
}

export default SaoThan;
