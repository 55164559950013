import Const from './Const';

export function YearToCan(year) {
  switch (year % 10) {
    case Const.Can.Canh.code:
      return Const.Can.Canh;
    case Const.Can.Tan.code:
      return Const.Can.Tan;
    case Const.Can.Nham.code:
      return Const.Can.Nham;
    case Const.Can.Qui.code:
      return Const.Can.Qui;
    case Const.Can.Giap.code:
      return Const.Can.Giap;
    case Const.Can.At.code:
      return Const.Can.At;
    case Const.Can.Binh.code:
      return Const.Can.Binh;
    case Const.Can.Dinh.code:
      return Const.Can.Dinh;
    case Const.Can.Mau.code:
      return Const.Can.Mau;
    case Const.Can.Ky.code:
      return Const.Can.Ky;
    default:
      break;
  }
}
export function YearToChi(year) {
  switch (year % 12) {
    case Const.Chi.Ty.code:
      return Const.Chi.Ty;
    case Const.Chi.Suu.code:
      return Const.Chi.Suu;
    case Const.Chi.Dan.code:
      return Const.Chi.Dan;
    case Const.Chi.Mao.code:
      return Const.Chi.Mao;
    case Const.Chi.Thin.code:
      return Const.Chi.Thin;
    case Const.Chi.Ti.code:
      return Const.Chi.Ti;
    case Const.Chi.Ngo.code:
      return Const.Chi.Ngo;
    case Const.Chi.Mui.code:
      return Const.Chi.Mui;
    case Const.Chi.Than.code:
      return Const.Chi.Than;
    case Const.Chi.Dau.code:
      return Const.Chi.Dau;
    case Const.Chi.Tuat.code:
      return Const.Chi.Tuat;
    case Const.Chi.Hoi.code:
      return Const.Chi.Hoi;
    default:
      break;
  }
}
export function MonthToCanChi(canYear, month) {
  const monthNew = month + 1;
  if (canYear.code === Const.Can.Giap.code || canYear.code === Const.Can.Ky.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.dinhSuu;
      }
      case 2: {
        return Const.CanChi.binhDan;
      }
      case 3: {
        return Const.CanChi.dinhMao;
      }
      case 4: {
        return Const.CanChi.mauThin;
      }
      case 5: {
        return Const.CanChi.kyTi;
      }
      case 6: {
        return Const.CanChi.canhNgo;
      }
      case 7: {
        return Const.CanChi.tanMui;
      }
      case 8: {
        return Const.CanChi.nhamThan;
      }
      case 9: {
        return Const.CanChi.quyDau;
      }
      case 10: {
        return Const.CanChi.giapTuat;
      }
      case 11: {
        return Const.CanChi.atHoi;
      }
      case 12: {
        return Const.CanChi.binhTy;
      }
      default:
        break;
    }
  } else if (canYear.code === Const.Can.At.code || canYear.code === Const.Can.Canh.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.kySuu;
      }
      case 2: {
        return Const.CanChi.mauDan;
      }
      case 3: {
        return Const.CanChi.kyMao;
      }
      case 4: {
        return Const.CanChi.canhThin;
      }
      case 5: {
        return Const.CanChi.tanTi;
      }
      case 6: {
        return Const.CanChi.nhamNgo;
      }
      case 7: {
        return Const.CanChi.quiMui;
      }
      case 8: {
        return Const.CanChi.giapThan;
      }
      case 9: {
        return Const.CanChi.atDau;
      }
      case 10: {
        return Const.CanChi.binhTuat;
      }
      case 11: {
        return Const.CanChi.dinhHoi;
      }
      case 12: {
        return Const.CanChi.mauTy;
      }
      default:
        break;
    }
  } else if (canYear.code === Const.Can.Binh.code || canYear.code === Const.Can.Tan.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.tanSuu;
      }
      case 2: {
        return Const.CanChi.canhDan;
      }
      case 3: {
        return Const.CanChi.tanMao;
      }
      case 4: {
        return Const.CanChi.nhamThin;
      }
      case 5: {
        return Const.CanChi.quyTi;
      }
      case 6: {
        return Const.CanChi.giapNgo;
      }
      case 7: {
        return Const.CanChi.atMui;
      }
      case 8: {
        return Const.CanChi.binhThan;
      }
      case 9: {
        return Const.CanChi.dinhDau;
      }
      case 10: {
        return Const.CanChi.mauTuat;
      }
      case 11: {
        return Const.CanChi.kyHoi;
      }
      case 12: {
        return Const.CanChi.canhTy;
      }
      default:
        break;
    }
  } else if (canYear.code === Const.Can.Dinh.code || canYear.code === Const.Can.Nham.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.quiSuu;
      }
      case 2: {
        return Const.CanChi.nhamDan;
      }
      case 3: {
        return Const.CanChi.quiMao;
      }
      case 4: {
        return Const.CanChi.giapThin;
      }
      case 5: {
        return Const.CanChi.atTi;
      }
      case 6: {
        return Const.CanChi.binhNgo;
      }
      case 7: {
        return Const.CanChi.dinhMui;
      }
      case 8: {
        return Const.CanChi.mauThan;
      }
      case 9: {
        return Const.CanChi.kydau;
      }
      case 10: {
        return Const.CanChi.canhTuat;
      }
      case 11: {
        return Const.CanChi.tanHoi;
      }
      case 12: {
        return Const.CanChi.nhamTy;
      }
      default:
        break;
    }
  } else if (canYear.code === Const.Can.Mau.code || canYear.code === Const.Can.Qui.code) {
    switch (monthNew) {
      case 1: {
        return Const.CanChi.atSuu;
      }
      case 2: {
        return Const.CanChi.giapDan;
      }
      case 3: {
        return Const.CanChi.atMao;
      }
      case 4: {
        return Const.CanChi.binhThin;
      }
      case 5: {
        return Const.CanChi.dinhTi;
      }
      case 6: {
        return Const.CanChi.mauNgo;
      }
      case 7: {
        return Const.CanChi.kyMui;
      }
      case 8: {
        return Const.CanChi.canhThan;
      }
      case 9: {
        return Const.CanChi.tanDau;
      }
      case 10: {
        return Const.CanChi.nhamTuat;
      }
      case 11: {
        return Const.CanChi.quyHoi;
      }
      case 12: {
        return Const.CanChi.giapTy;
      }
      default:
        break;
    }
  }
}
export function DayToCanChi(selectedTime) {
  const timeDefault = new Date(1925, 0, 1).getTime();
  const timeCalculator = new Date(selectedTime).getTime();
  let result = Math.ceil(((timeCalculator - timeDefault) / (1000 * 60 * 60 * 24)) % 60);

  if ((result + 22) % 60 === 0) {
    return Const.CanChiArr[59];
  } else {
    return Const.CanChiArr.filter((v) => v.code === (result + 22) % 60)[0];
  }
}
export function HourToCanChi(canDay, chiHour) {
  const CanHour = convertHour(chiHour).code + 1;
  if (canDay === Const.Can.Giap.name || canDay === Const.Can.Ky.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.giapTy;
      }
      case 2: {
        return Const.CanChi.atSuu;
      }
      case 3: {
        return Const.CanChi.binhDan;
      }
      case 4: {
        return Const.CanChi.dinhMao;
      }
      case 5: {
        return Const.CanChi.mauThin;
      }
      case 6: {
        return Const.CanChi.kyTi;
      }
      case 7: {
        return Const.CanChi.canhNgo;
      }
      case 8: {
        return Const.CanChi.tanMui;
      }
      case 9: {
        return Const.CanChi.nhamThan;
      }
      case 10: {
        return Const.CanChi.quyDau;
      }
      case 11: {
        return Const.CanChi.giapTuat;
      }
      case 12: {
        return Const.CanChi.atHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.At.name || canDay === Const.Can.Canh.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.binhTy;
      }
      case 2: {
        return Const.CanChi.dinhSuu;
      }
      case 3: {
        return Const.CanChi.mauDan;
      }
      case 4: {
        return Const.CanChi.kyMao;
      }
      case 5: {
        return Const.CanChi.canhThin;
      }
      case 6: {
        return Const.CanChi.tanTi;
      }
      case 7: {
        return Const.CanChi.nhamNgo;
      }
      case 8: {
        return Const.CanChi.quiMui;
      }
      case 9: {
        return Const.CanChi.giapThan;
      }
      case 10: {
        return Const.CanChi.atDau;
      }
      case 11: {
        return Const.CanChi.binhTuat;
      }
      case 12: {
        return Const.CanChi.dinhHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Binh.name || canDay === Const.Can.Tan.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.mauTy;
      }
      case 2: {
        return Const.CanChi.kySuu;
      }
      case 3: {
        return Const.CanChi.canhDan;
      }
      case 4: {
        return Const.CanChi.tanMao;
      }
      case 5: {
        return Const.CanChi.nhamThin;
      }
      case 6: {
        return Const.CanChi.quyTi;
      }
      case 7: {
        return Const.CanChi.giapNgo;
      }
      case 8: {
        return Const.CanChi.atMui;
      }
      case 9: {
        return Const.CanChi.binhThan;
      }
      case 10: {
        return Const.CanChi.dinhDau;
      }
      case 11: {
        return Const.CanChi.mauTuat;
      }
      case 12: {
        return Const.CanChi.kyHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Dinh.name || canDay === Const.Can.Nham.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.canhTy;
      }
      case 2: {
        return Const.CanChi.tanSuu;
      }
      case 3: {
        return Const.CanChi.nhamDan;
      }
      case 4: {
        return Const.CanChi.quiMao;
      }
      case 5: {
        return Const.CanChi.giapThin;
      }
      case 6: {
        return Const.CanChi.atTi;
      }
      case 7: {
        return Const.CanChi.binhNgo;
      }
      case 8: {
        return Const.CanChi.dinhMui;
      }
      case 9: {
        return Const.CanChi.mauThan;
      }
      case 10: {
        return Const.CanChi.kydau;
      }
      case 11: {
        return Const.CanChi.canhTuat;
      }
      case 12: {
        return Const.CanChi.tanHoi;
      }
      default:
        break;
    }
  } else if (canDay === Const.Can.Mau.name || canDay === Const.Can.Qui.name) {
    switch (CanHour) {
      case 1: {
        return Const.CanChi.nhamTy;
      }
      case 2: {
        return Const.CanChi.quiSuu;
      }
      case 3: {
        return Const.CanChi.giapDan;
      }
      case 4: {
        return Const.CanChi.atMao;
      }
      case 5: {
        return Const.CanChi.binhThin;
      }
      case 6: {
        return Const.CanChi.dinhTi;
      }
      case 7: {
        return Const.CanChi.mauNgo;
      }
      case 8: {
        return Const.CanChi.kyMui;
      }
      case 9: {
        return Const.CanChi.canhThan;
      }
      case 10: {
        return Const.CanChi.tanDau;
      }
      case 11: {
        return Const.CanChi.nhamTuat;
      }
      case 12: {
        return Const.CanChi.quyHoi;
      }
      default:
        break;
    }
  }
}
export function convertHour(Hour) {
  switch (Hour) {
    case 23:
    case 0:
      return Const.HourArr.GioTy;
    case 1:
    case 2:
      return Const.HourArr.GioSuu;
    case 3:
    case 4:
      return Const.HourArr.GioDan;
    case 5:
    case 6:
      return Const.HourArr.GioMao;
    case 7:
    case 8:
      return Const.HourArr.GioThin;
    case 9:
    case 10:
      return Const.HourArr.GioTi;
    case 11:
    case 12:
      return Const.HourArr.GioNgo;
    case 13:
    case 14:
      return Const.HourArr.GioMui;
    case 15:
    case 16:
      return Const.HourArr.GioThan;
    case 17:
    case 18:
      return Const.HourArr.GioDau;
    case 19:
    case 20:
      return Const.HourArr.GioTuat;
    case 21:
    case 22:
      return Const.HourArr.GioHoi;

    default:
      break;
  }
}
export function getQue(canTV, chiTV) {
  let tempCanTV = Const.CanArr.find((v) => v.name === canTV);
  let tempChiTV = Const.ChiArr.find((v) => v.name === chiTV);

  if (!tempChiTV || !tempCanTV) {
    return null;
  }
  const tenHanViet = tempCanTV.hanViet + ' ' + tempChiTV.hanViet;
  const result = Const.Que.find((v) => v.que === tenHanViet);
  return result;
}
export function getQueLast(canTV, chiTV) {
  let tempCanTV = Const.CanArr.find((v) => v.name === canTV);
  let tempChiTV = Const.ChiArr.find((v) => v.name === chiTV);

  if (!tempChiTV || !tempCanTV) {
    return null;
  }
  const tenHanViet = tempCanTV.hanViet + ' ' + tempChiTV.hanViet;
  const result = Const.Que.filter((v) => v.que === tenHanViet);
  return result[result.length - 1];
}
export function getPhiTinhMainNumber(chiDay, chiHour, sauDongChi) {
  if (chiDay === Const.Chi.Ty.code || chiDay === Const.Chi.Ngo.code || chiDay === Const.Chi.Mao.code || chiDay === Const.Chi.Dau.code) {
    switch (chiHour) {
      case 23:
      case 0:
        return sauDongChi ? 1 : 9;
      case 1:
      case 2:
        return sauDongChi ? 2 : 8;
      case 3:
      case 4:
        return sauDongChi ? 3 : 7;
      case 5:
      case 6:
        return sauDongChi ? 4 : 6;
      case 7:
      case 8:
        return sauDongChi ? 5 : 5;
      case 9:
      case 10:
        return sauDongChi ? 6 : 4;
      case 11:
      case 12:
        return sauDongChi ? 7 : 3;
      case 13:
      case 14:
        return sauDongChi ? 8 : 2;
      case 15:
      case 16:
        return sauDongChi ? 9 : 1;
      case 17:
      case 18:
        return sauDongChi ? 1 : 9;
      case 19:
      case 20:
        return sauDongChi ? 2 : 8;
      case 21:
      case 22:
        return sauDongChi ? 3 : 7;

      default:
        break;
    }
  }
  if (chiDay === Const.Chi.Dan.code || chiDay === Const.Chi.Than.code || chiDay === Const.Chi.Ti.code || chiDay === Const.Chi.Hoi.code) {
    switch (chiHour) {
      case 23:
      case 0:
        return sauDongChi ? 7 : 3;
      case 1:
      case 2:
        return sauDongChi ? 8 : 2;
      case 3:
      case 4:
        return sauDongChi ? 9 : 1;
      case 5:
      case 6:
        return sauDongChi ? 1 : 9;
      case 7:
      case 8:
        return sauDongChi ? 2 : 8;
      case 9:
      case 10:
        return sauDongChi ? 3 : 7;
      case 11:
      case 12:
        return sauDongChi ? 4 : 6;
      case 13:
      case 14:
        return sauDongChi ? 5 : 5;
      case 15:
      case 16:
        return sauDongChi ? 6 : 4;
      case 17:
      case 18:
        return sauDongChi ? 7 : 3;
      case 19:
      case 20:
        return sauDongChi ? 8 : 2;
      case 21:
      case 22:
        return sauDongChi ? 9 : 1;

      default:
        break;
    }
  }
  if (chiDay === Const.Chi.Thin.code || chiDay === Const.Chi.Tuat.code || chiDay === Const.Chi.Suu.code || chiDay === Const.Chi.Mui.code) {
    switch (chiHour) {
      case 23:
      case 0:
        return sauDongChi ? 4 : 6;
      case 1:
      case 2:
        return sauDongChi ? 5 : 5;
      case 3:
      case 4:
        return sauDongChi ? 6 : 4;
      case 5:
      case 6:
        return sauDongChi ? 7 : 3;
      case 7:
      case 8:
        return sauDongChi ? 8 : 2;
      case 9:
      case 10:
        return sauDongChi ? 9 : 1;
      case 11:
      case 12:
        return sauDongChi ? 1 : 9;
      case 13:
      case 14:
        return sauDongChi ? 2 : 8;
      case 15:
      case 16:
        return sauDongChi ? 3 : 7;
      case 17:
      case 18:
        return sauDongChi ? 4 : 6;
      case 19:
      case 20:
        return sauDongChi ? 5 : 5;
      case 21:
      case 22:
        return sauDongChi ? 6 : 4;

      default:
        break;
    }
  }
}
export function getNamAp(canChiDay) {
  const result = Const.Que.find((v) => v.queViet === canChiDay);

  return result;
}
export function thapNhiBatTu(dateTime) {
  var date1 = new Date(2019, 2, 14);
  var date2 = new Date(dateTime);
  const msBetweenDate = date2.getTime() - date1.getTime();

  const countDays = msBetweenDate / (1000 * 3600 * 24);

  let surplusForStar = null;
  if (countDays < 0) {
    surplusForStar = (Math.ceil(countDays) % 28) + 27;
  } else {
    surplusForStar = (Math.ceil(countDays) % 28) - 1;
  }
  if (surplusForStar === -1) {
    surplusForStar = 27;
  } else if (surplusForStar < -1) {
    surplusForStar = Math.abs((Math.ceil(countDays) % 28) - 1);
  }

  const star = Const.nhiThapBatTu.find((v) => v.code === surplusForStar);
  return star;
}
export function ngayGioHoangDao(month, chiDateTime) {
  //chidateTime la so thu tu
  const correctMonthNumber = month - 1 === -1 ? 0 : month - 1;
  if (chiDateTime === Const.Chi.Ty.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.thanhLong;
      case 1:
      case 7:
        return Const.HoangDao.tuMenh;
      case 2:
      case 8:
        return Const.HoangDao.thienLao;
      case 3:
      case 9:
        return Const.HoangDao.bachHo;
      case 4:
      case 10:
        return Const.HoangDao.kimQuy;
      case 5:
      case 11:
        return Const.HoangDao.thienHinh;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Suu.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.minhDuong;
      case 1:
      case 7:
        return Const.HoangDao.cauTran;
      case 2:
      case 8:
        return Const.HoangDao.huyenVu;
      case 3:
      case 9:
        return Const.HoangDao.ngocDuong;
      case 4:
      case 10:
        return Const.HoangDao.thienDuc;
      case 5:
      case 11:
        return Const.HoangDao.chuTuoc;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Dan.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.thienHinh;
      case 1:
      case 7:
        return Const.HoangDao.thanhLong;
      case 2:
      case 8:
        return Const.HoangDao.tuMenh;
      case 3:
      case 9:
        return Const.HoangDao.thienLao;
      case 4:
      case 10:
        return Const.HoangDao.bachHo;
      case 5:
      case 11:
        return Const.HoangDao.kimQuy;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Mao.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.chuTuoc;
      case 1:
      case 7:
        return Const.HoangDao.minhDuong;
      case 2:
      case 8:
        return Const.HoangDao.cauTran;
      case 3:
      case 9:
        return Const.HoangDao.huyenVu;
      case 4:
      case 10:
        return Const.HoangDao.ngocDuong;
      case 5:
      case 11:
        return Const.HoangDao.thienDuc;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Thin.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.kimQuy;
      case 1:
      case 7:
        return Const.HoangDao.thienHinh;
      case 2:
      case 8:
        return Const.HoangDao.thanhLong;
      case 3:
      case 9:
        return Const.HoangDao.tuMenh;
      case 4:
      case 10:
        return Const.HoangDao.thienLao;
      case 5:
      case 11:
        return Const.HoangDao.bachHo;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Ti.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.thienDuc;
      case 1:
      case 7:
        return Const.HoangDao.chuTuoc;
      case 2:
      case 8:
        return Const.HoangDao.minhDuong;
      case 3:
      case 9:
        return Const.HoangDao.cauTran;
      case 4:
      case 10:
        return Const.HoangDao.huyenVu;
      case 5:
      case 11:
        return Const.HoangDao.ngocDuong;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Ngo.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.bachHo;
      case 1:
      case 7:
        return Const.HoangDao.kimQuy;
      case 2:
      case 8:
        return Const.HoangDao.thienHinh;
      case 3:
      case 9:
        return Const.HoangDao.thanhLong;
      case 4:
      case 10:
        return Const.HoangDao.tuMenh;
      case 5:
      case 11:
        return Const.HoangDao.thienLao;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Mui.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.ngocDuong;
      case 1:
      case 7:
        return Const.HoangDao.thienDuc;
      case 2:
      case 8:
        return Const.HoangDao.chuTuoc;
      case 3:
      case 9:
        return Const.HoangDao.minhDuong;
      case 4:
      case 10:
        return Const.HoangDao.cauTran;
      case 5:
      case 11:
        return Const.HoangDao.huyenVu;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Than.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.thienLao;
      case 1:
      case 7:
        return Const.HoangDao.bachHo;
      case 2:
      case 8:
        return Const.HoangDao.kimQuy;
      case 3:
      case 9:
        return Const.HoangDao.thienHinh;
      case 4:
      case 10:
        return Const.HoangDao.thanhLong;
      case 5:
      case 11:
        return Const.HoangDao.tuMenh;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Dau.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.huyenVu;
      case 1:
      case 7:
        return Const.HoangDao.ngocDuong;
      case 2:
      case 8:
        return Const.HoangDao.thienDuc;
      case 3:
      case 9:
        return Const.HoangDao.chuTuoc;
      case 4:
      case 10:
        return Const.HoangDao.minhDuong;
      case 5:
      case 11:
        return Const.HoangDao.cauTran;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Tuat.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.tuMenh;
      case 1:
      case 7:
        return Const.HoangDao.thienLao;
      case 2:
      case 8:
        return Const.HoangDao.bachHo;
      case 3:
      case 9:
        return Const.HoangDao.kimQuy;
      case 4:
      case 10:
        return Const.HoangDao.thienHinh;
      case 5:
      case 11:
        return Const.HoangDao.thanhLong;
      default:
        break;
    }
  }
  if (chiDateTime === Const.Chi.Hoi.code) {
    switch (correctMonthNumber) {
      case 0:
      case 6:
        return Const.HoangDao.cauTran;
      case 1:
      case 7:
        return Const.HoangDao.huyenVu;
      case 2:
      case 8:
        return Const.HoangDao.ngocDuong;
      case 3:
      case 9:
        return Const.HoangDao.thienDuc;
      case 4:
      case 10:
        return Const.HoangDao.chuTuoc;
      case 5:
      case 11:
        return Const.HoangDao.minhDuong;
      default:
        break;
    }
  }
}
export function gioHoangDao(chiNgay, chiGio) {
  if (chiNgay === Const.Chi.Ty.code || chiNgay === Const.Chi.Ngo.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.kimQuy;
      case 2:
        return Const.HoangDao.thienDuc;
      case 3:
        return Const.HoangDao.bachHo;
      case 4:
        return Const.HoangDao.ngocDuong;
      case 5:
        return Const.HoangDao.thienLao;
      case 6:
        return Const.HoangDao.huyenVu;
      case 7:
        return Const.HoangDao.tuMenh;
      case 8:
        return Const.HoangDao.cauTran;
      case 9:
        return Const.HoangDao.thanhLong;
      case 10:
        return Const.HoangDao.minhDuong;
      case 11:
        return Const.HoangDao.thienHinh;
      case 12:
        return Const.HoangDao.chuTuoc;

      default:
        break;
    }
  }
  if (chiNgay === Const.Chi.Suu.code || chiNgay === Const.Chi.Mui.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.thienHinh;
      case 2:
        return Const.HoangDao.chuTuoc;
      case 3:
        return Const.HoangDao.kimQuy;
      case 4:
        return Const.HoangDao.thienDuc;
      case 5:
        return Const.HoangDao.bachHo;
      case 6:
        return Const.HoangDao.ngocDuong;
      case 7:
        return Const.HoangDao.thienLao;
      case 8:
        return Const.HoangDao.huyenVu;
      case 9:
        return Const.HoangDao.thanhLong;
      case 10:
        return Const.HoangDao.minhDuong;
      case 11:
        return Const.HoangDao.thanhLong;
      case 12:
        return Const.HoangDao.minhDuong;

      default:
        break;
    }
  }
  if (chiNgay === Const.Chi.Dan.code || chiNgay === Const.Chi.Than.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.thanhLong;
      case 2:
        return Const.HoangDao.minhDuong;
      case 3:
        return Const.HoangDao.thienHinh;
      case 4:
        return Const.HoangDao.chuTuoc;
      case 5:
        return Const.HoangDao.kimQuy;
      case 6:
        return Const.HoangDao.thienDuc;
      case 7:
        return Const.HoangDao.bachHo;
      case 8:
        return Const.HoangDao.ngocDuong;
      case 9:
        return Const.HoangDao.thienLao;
      case 10:
        return Const.HoangDao.huyenVu;
      case 11:
        return Const.HoangDao.tuMenh;
      case 12:
        return Const.HoangDao.cauTran;

      default:
        break;
    }
  }
  if (chiNgay === Const.Chi.Mao.code || chiNgay === Const.Chi.Dau.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.tuMenh;
      case 2:
        return Const.HoangDao.cauTran;
      case 3:
        return Const.HoangDao.thanhLong;
      case 4:
        return Const.HoangDao.minhDuong;
      case 5:
        return Const.HoangDao.thienHinh;
      case 6:
        return Const.HoangDao.chuTuoc;
      case 7:
        return Const.HoangDao.kimQuy;
      case 8:
        return Const.HoangDao.thienDuc;
      case 9:
        return Const.HoangDao.bachHo;
      case 10:
        return Const.HoangDao.ngocDuong;
      case 11:
        return Const.HoangDao.thienLao;
      case 12:
        return Const.HoangDao.huyenVu;

      default:
        break;
    }
  }
  if (chiNgay === Const.Chi.Thin.code || chiNgay === Const.Chi.Tuat.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.thienLao;
      case 2:
        return Const.HoangDao.huyenVu;
      case 3:
        return Const.HoangDao.tuMenh;
      case 4:
        return Const.HoangDao.cauTran;
      case 5:
        return Const.HoangDao.thanhLong;
      case 6:
        return Const.HoangDao.minhDuong;
      case 7:
        return Const.HoangDao.thienHinh;
      case 8:
        return Const.HoangDao.chuTuoc;
      case 9:
        return Const.HoangDao.kimQuy;
      case 10:
        return Const.HoangDao.thienDuc;
      case 11:
        return Const.HoangDao.bachHo;
      case 12:
        return Const.HoangDao.ngocDuong;

      default:
        break;
    }
  }
  if (chiNgay === Const.Chi.Ti.code || chiNgay === Const.Chi.Hoi.code) {
    switch (chiGio) {
      case 1:
        return Const.HoangDao.bachHo;
      case 2:
        return Const.HoangDao.ngocDuong;
      case 3:
        return Const.HoangDao.thienLao;
      case 4:
        return Const.HoangDao.huyenVu;
      case 5:
        return Const.HoangDao.tuMenh;
      case 6:
        return Const.HoangDao.cauTran;
      case 7:
        return Const.HoangDao.thanhLong;
      case 8:
        return Const.HoangDao.minhDuong;
      case 9:
        return Const.HoangDao.thienHinh;
      case 10:
        return Const.HoangDao.chuTuoc;
      case 11:
        return Const.HoangDao.kimQuy;
      case 12:
        return Const.HoangDao.thienDuc;

      default:
        break;
    }
  }
}
export function tuongSinhTuongKhac(Ngay, Gio) {
  if (
    ((Ngay === 4 || Ngay === 9) && (Gio === 1 || Gio === 6)) ||
    ((Ngay === 1 || Ngay === 6) && (Gio === 3 || Gio === 8)) ||
    ((Ngay === 3 || Ngay === 8) && (Gio === 2 || Gio === 7)) ||
    ((Gio === 4 || Gio === 9) && (Ngay === 1 || Ngay === 6)) ||
    ((Gio === 1 || Gio === 6) && (Ngay === 3 || Ngay === 8)) ||
    ((Gio === 3 || Gio === 8) && (Ngay === 2 || Ngay === 7))
  ) {
    return 'Tương Sinh';
  } else if (
    ((Ngay === 1 || Ngay === 6) && (Gio === 2 || Gio === 7)) ||
    ((Ngay === 2 || Ngay === 7) && (Gio === 4 || Gio === 9)) ||
    ((Ngay === 4 || Ngay === 9) && (Gio === 3 || Gio === 8)) ||
    ((Gio === 1 || Gio === 6) && (Ngay === 2 || Ngay === 7)) ||
    ((Gio === 2 || Gio === 7) && (Ngay === 4 || Ngay === 9)) ||
    ((Gio === 4 || Gio === 9) && (Ngay === 3 || Ngay === 8))
  ) {
    return 'Tương Khắc';
  } else if (
    ((Ngay === 4 || Ngay === 9) && (Gio === 9 || Gio === 4)) ||
    ((Ngay === 3 || Ngay === 8) && (Gio === 3 || Gio === 8)) ||
    ((Ngay === 2 || Ngay === 7) && (Gio === 2 || Gio === 7)) ||
    ((Ngay === 1 || Ngay === 6) && (Gio === 1 || Gio === 6))
  ) {
    return 'Hà Đồ Quái';
  } else {
    return ' ';
  }
}
export function tinhHaDoQuai(canNgay, chiNgay, canGio, chiGio) {
  if (canNgay && chiNgay && canGio && chiGio) {
    const queNgay = getQue(canNgay, chiNgay);
    const queGio = getQue(canGio, chiGio);
    //huyền khôgn ngũ hành là số trên
    //huyền không quái vận là số dưới
    const trenNgay = queNgay?.huyenKhongNguHanh;
    const duoiNgay = queNgay?.huyenKhongQuaiVan;
    const trenGio = queGio?.huyenKhongNguHanh;
    const duoiGio = queGio?.huyenKhongQuaiVan;
    if (trenNgay + trenGio === 10) {
      return 'Hợp Thập Quái';
    } else if (trenNgay + trenGio === 10 && duoiNgay + duoiGio === 10) {
      return 'Hợp Thập';
    } else if (trenNgay === trenGio) {
      return 'Cùng Quái';
    } else if (trenNgay + trenGio === 5) {
      return 'Hợp Ngũ Quái';
    } else if (trenNgay + trenGio === 15) {
      return 'H.T.Ngũ Quái';
      //trên ngày so sánh với trên giờ nếu trên ngày là 1 || 6 thì đó là "thủy"
    } else {
      return tuongSinhTuongKhac(trenNgay, trenGio);
    }
  }
}
export function tinhHaDoVan(canNgay, chiNgay, canGio, chiGio) {
  if (canNgay && chiNgay && canGio && chiGio) {
    const queNgay = getQue(canNgay, chiNgay);
    const queGio = getQue(canGio, chiGio);
    //huyền khôgn ngũ hành là số trên
    //huyền không quái vận là số dưới
    const trenNgay = queNgay?.huyenKhongNguHanh;
    const duoiNgay = queNgay?.huyenKhongQuaiVan;
    const trenGio = queGio?.huyenKhongNguHanh;
    const duoiGio = queGio?.huyenKhongQuaiVan;
    if (duoiNgay + duoiGio === 10) {
      return 'Hợp Thập Vận';
    } else if (trenNgay + trenGio === 10 && duoiNgay + duoiGio === 10) {
      return 'Hợp Thập';
    } else if (duoiNgay === duoiGio) {
      return 'Cùng Vận';
    } else if (duoiNgay + duoiGio === 5) {
      return 'Hợp Ngũ Vận';
    } else if (duoiNgay + duoiGio === 15) {
      return 'H.T.Ngũ Vận';
    } else if (
      (duoiNgay === 1 && duoiGio === 6) ||
      (duoiGio === 1 && duoiNgay === 6) ||
      (duoiNgay === 2 && duoiGio === 7) ||
      (duoiGio === 2 && duoiNgay === 7) ||
      (duoiNgay === 3 && duoiGio === 8) ||
      (duoiGio === 3 && duoiNgay === 8) ||
      (duoiNgay === 4 && duoiGio === 9) ||
      (duoiGio === 4 && duoiNgay === 9)
    ) {
      return 'Hà Đồ Vận';
    } else {
      return ' ';
    }
  }
}
export function saoThanSatThang(chiThang) {
  switch (chiThang) {
    case Const.Chi.Ty.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangTy;
    case Const.Chi.Suu.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangSuu;

    case Const.Chi.Dan.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangDan;
    case Const.Chi.Mao.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangMao;
    case Const.Chi.Thin.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangThin;

    case Const.Chi.Ti.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangTi;
    case Const.Chi.Ngo.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangNgo;

    case Const.Chi.Mui.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangMui;

    case Const.Chi.Than.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangThan;
    case Const.Chi.Dau.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangDau;

    case Const.Chi.Tuat.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangTuat;
    case Const.Chi.Hoi.name:
      return Const.saoThanSatThangThienDucToTaiSat.thangHoi;
    default:
      break;
  }
}
export function getNhiThapKienTru(monthLunar, chiNgay, canDay) {
  const correctMonthNumber = monthLunar;

  switch (correctMonthNumber) {
    //dần
    case 1:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Mão
    case 2:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 3 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Thìn
    case 3:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Tỵ
    case 4:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 1 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Ngọ
    case 5:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 3 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 3 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 3 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Mùi
    case 6:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Thân
    case 7:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 1 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Dậu
    case 8:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 3 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 3 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 3 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Tuất
    case 9:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 5 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Hợi
    case 10:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 1 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 3 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 3 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 3 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //Tý
    case 11:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Man.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 8:
              return { truc: Const.trucSao.Man.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 7:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Pha.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Nguy.name, dongCong: 1 };
            case 7:
              return { truc: Const.trucSao.Nguy.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Nguy.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thanh.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Thanh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 2:
              return { truc: Const.trucSao.Khai.name, dongCong: 1 };
            case 4:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Khai.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };
            case 3:
              return { truc: Const.trucSao.Be.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Be.name, dongCong: 5 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    //S
    case 0:
      switch (chiNgay) {
        case Const.Chi.Ty.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Be.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Be.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Be.name, dongCong: 3 };

            default:
              break;
          }
          break;
        case Const.Chi.Suu.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Kien.name, dongCong: 3 };
            case 3:
              return { truc: Const.trucSao.Kien.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Kien.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Kien.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Kien.name, dongCong: 5 };

            default:
              break;
          }
          break;

        case Const.Chi.Dan.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 6:
              return { truc: Const.trucSao.Tru.name, dongCong: 5 };
            case 8:
              return { truc: Const.trucSao.Tru.name, dongCong: 4 };

            default:
              break;
          }
          break;
        case Const.Chi.Mao.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Man.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 5:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };
            case 9:
              return { truc: Const.trucSao.Man.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Thin.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Binh.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Binh.name, dongCong: 4 };
            case 4:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 6:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Binh.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Ti.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Dinh.name, dongCong: 4 };
            case 5:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Dinh.name, dongCong: 1 };
            case 9:
              return { truc: Const.trucSao.Dinh.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Ngo.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 2:
              return { truc: Const.trucSao.Chap.name, dongCong: 5 };
            case 4:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Chap.name, dongCong: 1 };
            case 8:
              return { truc: Const.trucSao.Chap.name, dongCong: 4 };

            default:
              break;
          }
          break;

        case Const.Chi.Mui.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Pha.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };
            case 7:
              return { truc: Const.trucSao.Pha.name, dongCong: 5 };
            case 9:
              return { truc: Const.trucSao.Pha.name, dongCong: 2 };

            default:
              break;
          }
          break;

        case Const.Chi.Than.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 2:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 4:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 6:
              return { truc: Const.trucSao.Nguy.name, dongCong: 3 };
            case 8:
              return { truc: Const.trucSao.Nguy.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Dau.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Thanh.name, dongCong: 4 };
            case 3:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 5:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Thanh.name, dongCong: 3 };
            case 9:
              return { truc: Const.trucSao.Thanh.name, dongCong: 5 };

            default:
              break;
          }
          break;

        case Const.Chi.Tuat.name:
          switch (canDay?.code) {
            case 0:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 2:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 4:
              return { truc: Const.trucSao.Thu.name, dongCong: 4 };
            case 6:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };
            case 8:
              return { truc: Const.trucSao.Thu.name, dongCong: 2 };

            default:
              break;
          }
          break;
        case Const.Chi.Hoi.name:
          switch (canDay?.code) {
            case 1:
              return { truc: Const.trucSao.Khai.name, dongCong: 2 };
            case 3:
              return { truc: Const.trucSao.Khai.name, dongCong: 1 };
            case 5:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };
            case 7:
              return { truc: Const.trucSao.Khai.name, dongCong: 4 };
            case 9:
              return { truc: Const.trucSao.Khai.name, dongCong: 5 };

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
}
export function phiTinhThang(lunarDate) {
  const DateForCount = new Date(lunarDate);
  const Lunar = new Date('1900', '01', '01');
  let distance = DateForCount.getMonth() - Lunar.getMonth();
  if (DateForCount.getTime() > Lunar.getTime()) {
    let distanceYear = DateForCount.getFullYear() - Lunar.getFullYear();
    distance = 12 * distanceYear + (DateForCount.getMonth() - Lunar.getMonth());
  }
  const phiTinhThang = 8 - (distance % 9);

  return phiTinhThang === 0 ? 9 : phiTinhThang;
}
export function phiTinhDay(lunarDate) {
  const DateForCount = new Date('1900', '05', '30');
  const Lunar = new Date('1900', '01', '02');
  const msBetweenDate = DateForCount.getTime() - Lunar.getTime();

  const countDays = msBetweenDate / (1000 * 3600 * 24);
  let result = null;
  if (countDays % 9 === 0) {
    result = 9;
  } else {
    result = countDays % 9;
  }

  return result;
}
export function kiengKy(month, canchiDay) {
  const findMonth = Const.kiengKiTrongNgay.find((v, index) => index === month);
  const result = findMonth.find((v, index) => index === canchiDay?.code - 1);
  return result;
}

