import lunar from "cky-lunar-calendar";
import * as firebase from "firebase";
import Cookies from "js-cookie";
import _ from "lodash";
import md5 from "md5";
import React from "react";
import {
  ChromePicker,
  CompactPicker,
  SketchPicker,
  SliderPicker,
} from "react-color";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Rating from "react-rating";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
} from "semantic-ui-react";
import * as XLSX from "xlsx";
import "./App.css";
import { withTranslation } from "react-i18next";
import ribbonNewYear from "./image/background-lich/ribbonNewYear.png";
// import backgroundGreen from './image/lich_bloc_green.png';
import backgroundGreen from "./image/background-lich/green.png";
import backgroundBlue from "./image/background-lich/blue.png";
import backgroundGreenBlue from "./image/background-lich/greenblue.png";
import backgroundOrange from "./image/background-lich/orange.png";
import backgroundPink from "./image/background-lich/pink.png";
import backgroundRed from "./image/background-lich/red.png";
import backgroundXanhnhat from "./image/background-lich/xanhNhat.png";
import footerGreen from "./image/background-lich/footer_green.png";
import footerBlue from "./image/background-lich/footer_blue.png";
import footerGreenBlue from "./image/background-lich/footer_greenblue.png";
import footerOrange from "./image/background-lich/footer_orange.png";
import footerPink from "./image/background-lich/footer_pink.png";
import footerRed from "./image/background-lich/footer_red.png";
import footerXanhnhat from "./image/background-lich/footer_xanhnhat.png";
import hoaAnhDao from "./image/sakura.jpeg";
import footer_blue from "./image/background-lich/footer_blue.png";
import footer_main_logo from "./image/footer-logo.png";

import HourStar from "./components/HourStar";
import SaoThan from "./components/SaoThan";
import Const from "./Const";
import {
  DayToCanChi,
  getNamAp,
  getNhiThapKienTru,
  getQue,
  getQueLast,
  HourToCanChi,
  kiengKy,
  MonthToCanChi,
  ngayGioHoangDao,
  phiTinhThang,
  thapNhiBatTu,
  translateLetter,
  YearToCan,
  YearToChi,
} from "./convert.js";
import globalUtils from "./globalUntils.js";
import HaDo from "./HaDo.png";
import arrow from "./image/arrow2.png";
import arrow2 from "./image/arrow3.png";
import explain from "./image/explain.png";
import five from "./image/five.png";
import LaBan from "./image/laban.png";
import LaBanV2 from "./image/laban_v2.png";
import nguHoang from "./image/nguHoangCordi.png";
import tamSat from "./image/tamSat.png";
import Logo from "./logo.png";
import PhiTinh from "./PhiTinh";
import SearchTest from "./SearchTest";
import TableQue from "./TableQue";
import image1 from "../src/image/image1.jpg";
import image2 from "../src/image/image2.jpg";
import i18n from "./i18n";
import ReactDatePicker from "react-datepicker";

const optionDropdown = _.map(Const.QueSortConGiap, (state, index) => ({
  key: `${state.stt}-${index}`,
  text: `${state.queViet} ${state.huyenKhongNguHanh}/${state.huyenKhongQuaiVan}`,
  value: index,
}));
const mdfive = md5("HuyenKhongTamNguyenFengShuiWebSiteHitekTuongMinh");
const msADay = 86400000;
const limitArr = 6;
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.excel = [];
    this.tietKhiDate = [];
    this.dataNhiTuTietKhi = [];
    this.wrapperRef = React.createRef();

    this.state = {
      backgroundImage: null,
      itemsToShowHungThan: 2,
      expandedHungThan: false,
      itemsToShowCatThan: 2,
      expandedCatThan: false,
      themeDay: null,
      isMissingDate: false,
      footerBg: null,
      background: null,
      date: new Date(),
      displayColorPicker: false,
      displayColorPickerLunarCalendar: false,
      language: "vi",
      backgroundCalendar: image1,
      colorFontPicker1: null,
      colorFontPicker2: null,
      subImage: null,
      ngayKyAmLich: "",
      ngayKyTietKhi: "",
      dateShowBellowSolarDate: null,
      searchSelectMonth: null,
      searchSelectYear: null,
      canChiDay: null,
      dateLunar: null,
      realDatePhiTinh: null,
      dateTietKhi: null,
      characterSearch: null,
      huongTamSatHour: "",
      huongTamSatDay: "",
      huongTamSatMonth: "",
      huongTamSatYear: "",
      queNam: null,
      kiengkytrongngay: null,
      ngayHoangDao: null,
      nhiThapKienTru: null,
      isCustomer: globalUtils.isCustomer,
      keyWordSearch: null,
      searchParam: null,
      namSinhCanChi: null,
      huongNguHoangYear: null,
      huongNguHoangMonth: null,
      starDay: null,
      taiThan: null,
      searchResult: null,
      hyThan: null,
      thaiDuongThaiAm: null,
      isLoading: false,
      lichVanNien: [],
      modalImage: false,
      star: null,
      chiMonthLunar: null,
      toaCanChi: null,
      napAmNgay: {},
      canDay: "",
      canMonth: "",
      canYear: "",
      canHour: "",
      chiDay: "",
      chiMonth: "",
      modalSearch: false,
      modalLaBan: false,
      toaNumber: 0,
      namSinhNumber: 0,
      momentYear: null,
      momentMonth: null,
      momentDate: null,
      momentHour: new Date().getHours(),
      isSauDongChi: null,
      chiHour: "",
      dateData: Const.initDateData,
      hourDate: Const.initHourDate,
    };
  }

  renderShould(header, content, should, isChina) {
    return (
      <div
        className={
          should
            ? "block-header-third font-Myriad"
            : "block-header-third mt-15 font-Myriad"
        }
        style={{}}
      >
        <h4
          className={`title-third ${isChina ? `font-kaiu` : `font-Myriad`}`}
          style={{
            background: should ? this.state.colorFontPicker1 : "grey",
            fontSize: isChina ? "16px" : "",
            lineHeight: isChina ? "22px" : "22px",
          }}
        >
          {header}
        </h4>
        <div className={"content-third"}>
          <ul
            style={{
              border: `2px solid ${
                should ? this.state.colorFontPicker1 : "grey"
              }`,
            }}
          >
            {content?.length > 0 &&
              content?.map((v, i) => {
                if (i < limitArr) {
                  // if (v.translate) {
                  return (
                    <li>
                      <p
                        className={isChina ? `font-kaiu` : `font-Myriad`}
                        href="!#"
                        style={{
                          color: should ? this.state.colorFontPicker1 : "grey",
                          fontSize: isChina ? "14px" : "",
                        }}
                      >
                        {isChina ? v.translateChina : v.translate || v}
                      </p>
                    </li>
                  );
                  // }
                }
              })}
          </ul>
        </div>
      </div>
    );
  }
  shouldDuplicateQue(thienChi, diaChi) {
    if (thienChi === Const.Can.Giap.name && diaChi === Const.Chi.Ngo.name)
      return true;
    if (thienChi === Const.Can.Giap.name && diaChi === Const.Chi.Ty.name)
      return true;
    if (thienChi === Const.Can.Canh.name && diaChi === Const.Chi.Than.name)
      return true;
    if (thienChi === Const.Can.Canh.name && diaChi === Const.Chi.Dan.name)
      return true;
    return false;
  }

  getDuplicateQue(initialQque, thienChi, diaChi) {
    let que = getQueLast(thienChi, diaChi);
    if (!initialQque || !que) {
      return null;
    }

    if (
      initialQque.huyenKhongNguHanh === que.huyenKhongNguHanh &&
      initialQque.huyenKhongQuaiVan === que.huyenKhongQuaiVan
    ) {
      que = getQue(thienChi, diaChi);
    }
    return que;
  }

  handleChange = (date) => {
    this.setState({
      date: date,
      momentYear: date.getYear(),
      momentDate: date.getDate(),
      momentMonth: date.getMonth(),
    });
    setTimeout(() => {
      this.onChangeData();
    }, 400);
  };
  handleChangeSearch = (date) => {
    this.setState({
      date: date,
      momentYear: date.getYear(),
      momentDate: date.getDate(),
      momentMonth: date.getMonth() + 1,
    });
    setTimeout(() => {
      this.onChangeData();
    }, 400);
  };

  getDayStarOfADate(dateTime) {
    //6/2/1932 -> duong lich-> no7
    const exactlyDate = new Date(
      dateTime?.getFullYear(),
      dateTime.getMonth(),
      dateTime.getDate()
    );
    let result = 7;
    let beginDate = new Date(1932, 1, 6);
    //ha chi mảng so 1, dong chi mảng so 2

    while (beginDate.getTime() < exactlyDate.getTime()) {
      let dongChiHaChi = this.getDongChiHaChi(beginDate?.getFullYear());
      let ngayHaChi = new Date(
        beginDate?.getFullYear(),
        dongChiHaChi[0][1] - 1,
        dongChiHaChi[0][0]
      );
      let ngayDongChi = new Date(
        beginDate?.getFullYear(),
        dongChiHaChi[1][1] - 1,
        dongChiHaChi[1][0]
      );

      // trước hạ chí thì ++

      if (beginDate.getTime() < ngayHaChi.getTime()) {
        result++;
      } else if (
        beginDate.getMonth() === ngayHaChi.getMonth() &&
        beginDate.getDate() === ngayHaChi.getDate() &&
        beginDate?.getFullYear() === ngayHaChi?.getFullYear()
      ) {
        if (
          beginDate?.getFullYear() === 1957 ||
          beginDate?.getFullYear() === 1980 ||
          beginDate?.getFullYear() === 2003 ||
          beginDate?.getFullYear() === 2026 ||
          beginDate?.getFullYear() === 2049
        ) {
          result = 6 - result;
        } else {
          result = 9 - result;
        }
      }
      //trước đông chí và sau hạ chí
      else if (
        beginDate.getTime() < ngayDongChi.getTime() &&
        beginDate.getTime() > ngayHaChi.getTime()
      ) {
        result--;
      } else if (
        beginDate.getMonth() === ngayDongChi.getMonth() &&
        beginDate.getDate() === ngayDongChi.getDate() &&
        beginDate?.getFullYear() === ngayDongChi?.getFullYear()
      ) {
        if (
          beginDate?.getFullYear() === 1934 ||
          beginDate?.getFullYear() === 1945 ||
          beginDate?.getFullYear() === 1968 ||
          beginDate?.getFullYear() === 1991 ||
          beginDate?.getFullYear() === 2014 ||
          beginDate?.getFullYear() === 2037
        ) {
          result = 14 - result;
        } else {
          result = 11 - result;
        }
      }

      //sau đông chí
      else if (beginDate.getTime() > ngayDongChi.getTime()) {
        result++;
      }

      // -----
      result = result % 9;
      if (result === 0) {
        result = 9;
      }

      beginDate.setDate(beginDate.getDate() + 1);
    }

    // result có 3 trường hợp

    //   1 là ngày hạ chí,
    // if (dateTime.getTime() === ngayHaChi.getTime()) {
    //   let tempNumber = 10 - result;
    //   return `${result}/${tempNumber}`;
    // } else {
    return result;
    // }
    //     2 là ngày đông chí
    // 3 là ngày bth,
  }

  getDongChiHaChi(fullYear) {
    const tempDateTime = new Date(fullYear, 1, 1);
    //theo lịch tiết khí bắt đầu từ năm 1932 để lấy khoảng cách
    const distance = tempDateTime?.getFullYear() - 1932;
    const rowNumber = distance * 42 + 39;
    const ngayDongChi = this.excel[rowNumber][21]
      .split("-")[0]
      .split(" ")[0]
      .split("/");
    const ngayHaChi = this.excel[rowNumber][9]
      .split("-")[0]
      .split(" ")[0]
      .split("/");

    return [ngayHaChi, ngayDongChi];
  }

  getIsSauDongChi(dateTime) {
    const tempDateTime = new Date(dateTime);
    //file excel bắt đầu từ năm 1932
    const distance = tempDateTime?.getFullYear() - 1932;

    //lấy tọa độ dòng trong excel
    const rowNumber = distance * 42 + 39;
    //chia nho chuoi lay ngay thang cua dong chi va ha chi
    // const ngayDongChi = this.excel[rowNumber][21].split('-')[0].split(' ')[0].split('/');
    const ngayHaChi = this.excel[rowNumber][9]
      .split("-")[0]
      .split(" ")[0]
      .split("/");
    const stempHaChi = new Date(
      tempDateTime?.getFullYear(),
      ngayHaChi[1] - 1,
      ngayHaChi[0]
    );

    // từ đầu năm đến hạ chí là sau đông chí ==> true
    // từ hạ chí đến ngày đông chí là trước đông chí ==> false
    // ngày được chọn lớn hơn ngày hạ chí là trước đông chí ==> false
    // ngày được chọn nhỏ hơn ngày hạ chí là sau đông chí ==> true

    if (tempDateTime.getTime() > stempHaChi.getTime()) {
      return false;
    } else {
      return true;
    }
  }
  phiTinhNam(lunarCalendar) {
    const DateNew = new Date(lunarCalendar);

    const Year = DateNew?.getFullYear();
    const arrYear = String(Year).split("");
    let sumCharacter =
      Number(arrYear[0]) +
      Number(arrYear[1]) +
      Number(arrYear[2]) +
      Number(arrYear[3]);
    if (sumCharacter > 10) {
      let arrSumCharacter = String(sumCharacter).split("");
      sumCharacter = Number(arrSumCharacter[0]) + Number(arrSumCharacter[1]);
      if (sumCharacter >= 10) {
        let arrSumLast = String(sumCharacter).split("");
        sumCharacter = Number(arrSumLast[0]) + Number(arrSumLast[1]);
      }
    }
    const result = 11 - sumCharacter;
    return result;
  }
  convertSideToCompass(starNumber) {
    if (starNumber + 8 === 14 || starNumber + 8 === 5) {
      return { isActive: true, cordi: "135deg" };
    } else if (starNumber + 4 === 14 || starNumber + 4 === 5) {
      return { isActive: true, cordi: "180deg" };
    } else if (starNumber + 6 === 14 || starNumber + 6 === 5) {
      return { isActive: true, cordi: "225deg" };
    } else if (starNumber + 7 === 14 || starNumber + 7 === 5) {
      return { isActive: true, cordi: "90deg" };
    } else if (starNumber + 0 === 14 || starNumber + 0 === 5) {
      return { isActive: false, cordi: "0" };
    } else if (starNumber + 2 === 14 || starNumber + 2 === 5) {
      return { isActive: true, cordi: "270deg" };
    } else if (starNumber + 3 === 14 || starNumber + 3 === 5) {
      return { isActive: true, cordi: "45deg" };
    } else if (starNumber + 5 === 14 || starNumber + 5 === 5) {
      return { isActive: true, cordi: "0deg" };
    } else if (starNumber + 1 === 14 || starNumber + 1 === 5) {
      return { isActive: true, cordi: "315deg" };
    }
  }
  convertTamSatSideOnCompass(chi) {
    switch (chi) {
      case Const.Chi.Dan.name:
      case Const.Chi.Ngo.name:
      case Const.Chi.Tuat.name:
        return "0deg";
      case Const.Chi.Hoi.name:
      case Const.Chi.Mao.name:
      case Const.Chi.Mui.name:
        return "270deg";
      case Const.Chi.Ti.name:
      case Const.Chi.Dau.name:
      case Const.Chi.Suu.name:
        return "90deg";
      case Const.Chi.Than.name:
      case Const.Chi.Ty.name:
      case Const.Chi.Thin.name:
        return "180deg";
      default:
        break;
    }
  }
  getTietKhiAllYear(dateTimeYear) {
    const distance = dateTimeYear?.getFullYear() - 1932;
    const thangLe = distance * 42 + 36;
    const thangChan = distance * 42 + 38;
    let ngayGioDauThang1 = this.excel[thangLe][1].split(" - ")[0].split(" ");
    let ngayGioDauThang3 = this.excel[thangLe][5].split(" - ")[0].split(" ");
    let ngayGioDauThang5 = this.excel[thangLe][9].split(" - ")[0].split(" ");
    let ngayGioDauThang7 = this.excel[thangLe][13].split(" - ")[0].split(" ");
    let ngayGioDauThang9 = this.excel[thangLe][17].split(" - ")[0].split(" ");
    let ngayGioDauThang11 = this.excel[thangLe][21].split(" - ")[0].split(" ");
    let ngayGioDauThang2 = this.excel[thangChan][2].split(" - ")[0].split(" ");
    let ngayGioDauThang4 = this.excel[thangChan][6].split(" - ")[0].split(" ");
    let ngayGioDauThang6 = this.excel[thangChan][10].split(" - ")[0].split(" ");
    let ngayGioDauThang8 = this.excel[thangChan][14].split(" - ")[0].split(" ");
    let ngayGioDauThang10 = this.excel[thangChan][18]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang12 = this.excel[thangChan][22]
      .split(" - ")[0]
      .split(" ");

    const ngayDauThangMot = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang1[0].split("/")[1]) - 1,
      Number(ngayGioDauThang1[0].split("/")[0]),
      Number(ngayGioDauThang1[1].split(":")[0]),
      Number(ngayGioDauThang1[1].split(":")[1])
    );
    const ngayDauThangHai = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang2[0].split("/")[1]) - 1,
      Number(ngayGioDauThang2[0].split("/")[0]),
      Number(ngayGioDauThang2[1].split(":")[0]),
      Number(ngayGioDauThang2[1].split(":")[1])
    );
    const ngayDauThangBa = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang3[0].split("/")[1]) - 1,
      Number(ngayGioDauThang3[0].split("/")[0]),
      Number(ngayGioDauThang3[1].split(":")[0]),
      Number(ngayGioDauThang3[1].split(":")[1])
    );
    const ngayDauThangTu = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang4[0].split("/")[1]) - 1,
      Number(ngayGioDauThang4[0].split("/")[0]),
      Number(ngayGioDauThang4[1].split(":")[0]),
      Number(ngayGioDauThang4[1].split(":")[1])
    );
    const ngayDauThangNam = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang5[0].split("/")[1]) - 1,
      Number(ngayGioDauThang5[0].split("/")[0]),
      Number(ngayGioDauThang5[1].split(":")[0]),
      Number(ngayGioDauThang5[1].split(":")[1])
    );
    const ngayDauThangSau = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang6[0].split("/")[1]) - 1,
      Number(ngayGioDauThang6[0].split("/")[0]),
      Number(ngayGioDauThang6[1].split(":")[0]),
      Number(ngayGioDauThang6[1].split(":")[1])
    );
    const ngayDauThangBay = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang7[0].split("/")[1]) - 1,
      Number(ngayGioDauThang7[0].split("/")[0]),
      Number(ngayGioDauThang7[1].split(":")[0]),
      Number(ngayGioDauThang7[1].split(":")[1])
    );
    const ngayDauThangTam = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang8[0].split("/")[1]) - 1,
      Number(ngayGioDauThang8[0].split("/")[0]),
      Number(ngayGioDauThang8[1].split(":")[0]),
      Number(ngayGioDauThang8[1].split(":")[1])
    );
    const ngayDauThangChin = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang9[0].split("/")[1]) - 1,
      Number(ngayGioDauThang9[0].split("/")[0]),
      Number(ngayGioDauThang9[1].split(":")[0]),
      Number(ngayGioDauThang9[1].split(":")[1])
    );
    const ngayDauThangMuoi = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang10[0].split("/")[1]) - 1,
      Number(ngayGioDauThang10[0].split("/")[0]),
      Number(ngayGioDauThang10[1].split(":")[0]),
      Number(ngayGioDauThang10[1].split(":")[1])
    );
    const ngayDauThangMuoiMot = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang11[0].split("/")[1]) - 1,
      Number(ngayGioDauThang11[0].split("/")[0]),
      Number(ngayGioDauThang11[1].split(":")[0]),
      Number(ngayGioDauThang11[1].split(":")[1])
    );
    const ngayDauThangMuoiHai = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang12[0].split("/")[1]) - 1,
      Number(ngayGioDauThang12[0].split("/")[0]),
      Number(ngayGioDauThang12[1].split(":")[0]),
      Number(ngayGioDauThang12[1].split(":")[1])
    );
    let lichTietKhi = [];
    lichTietKhi.push(
      ngayDauThangMot,
      ngayDauThangHai,
      ngayDauThangBa,
      ngayDauThangTu,
      ngayDauThangNam,
      ngayDauThangSau,
      ngayDauThangBay,
      ngayDauThangTam,
      ngayDauThangChin,
      ngayDauThangMuoi,
      ngayDauThangMuoiMot,
      ngayDauThangMuoiHai
    );
    this.tietKhiDate = lichTietKhi;
  }
  getCorrectTietKhi(solarDate) {
    var day = solarDate.getDate();
    var month = solarDate.getMonth();
    var year =
      solarDate < this.tietKhiDate[1]
        ? solarDate.getFullYear() - 1
        : solarDate.getFullYear();

    switch (solarDate.getMonth()) {
      // tháng 1 dương lịch nếu sau ngày đầu tháng 1 thì là tháng 12 năm ngoái
      //input ngày 1 tháng 1 2020
      // out put ngày 1 tháng 12 2019
      case 0:
        if (solarDate < this.tietKhiDate[0]) {
          month = 11;
        }
        break;
      case 1:
        // trước ngày lập xuân thì là tháng 12 của năm ngoái, ngược lại là tháng 1 năm sau
        if (solarDate < this.tietKhiDate[1]) {
          month = month - 1;
        }
        break;
      case 2:
        if (solarDate < this.tietKhiDate[2]) {
          month = month - 1;
        }
        break;
      case 3:
        if (solarDate < this.tietKhiDate[3]) {
          month = month - 1;
        }
        break;
      case 4:
        if (solarDate < this.tietKhiDate[4]) {
          month = month - 1;
        }
        break;
      case 5:
        if (solarDate < this.tietKhiDate[5]) {
          month = month - 1;
        }
        break;
      case 6:
        if (solarDate < this.tietKhiDate[6]) {
          month = month - 1;
        }
        break;
      case 7:
        if (solarDate < this.tietKhiDate[7]) {
          month = month - 1;
        }
        break;
      case 8:
        if (solarDate < this.tietKhiDate[8]) {
          month = month - 1;
        }
        break;
      case 9:
        if (solarDate < this.tietKhiDate[9]) {
          month = month - 1;
        }
        break;
      case 10:
        if (solarDate < this.tietKhiDate[10]) {
          month = month - 1;
        }
        break;
      case 11:
        if (solarDate < this.tietKhiDate[11]) {
          month = month - 1;
        }
        break;
      default:
        break;
    }
    return new Date(year, month, day);
  }
  getNgayKyAmLich(dateLunar) {
    const month = dateLunar[1];
    const day = dateLunar[0];
    // const year = dateLunar[2];
    switch (month) {
      case 1: {
        if (day === 6 || day === 9) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 13) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      }
      case 2: {
        if (day === 5 || day === 8) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 11) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      }
      case 3:
        if (day === 3 || day === 5) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 9) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 4:
        if (day === 5 || day === 18) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 7) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 5:
        if (day === 24 || day === 27) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 5) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 6:
        if (day === 23 || day === 26) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 3) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 7:
        if (day === 22 || day === 23) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 1) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 8:
        if (day === 27 || day === 30) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 27) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 9:
        if (day === 17 || day === 22) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 25) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 10:
        if (day === 2 || day === 16) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 23) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 11:
        if (day === 21) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 12:
        if (day === 19) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      default:
        break;
    }
  }
  getNgayKyTietKhi(solarCalendar) {
    // vì lịch tiết khí hiển thị ngày dương nên sử dụng lịch dương để lấy.
    //5 11 17 23 Tứ ly
    //lấy ngày xuân phân
    const ngayXuanPhanString = this.dataNhiTuTietKhi[5][0]
      .split(" ")[0]
      .split("/");
    const ngayXuanPhan = new Date(
      solarCalendar.getFullYear(),
      Number(ngayXuanPhanString[1]) - 1,
      Number(ngayXuanPhanString[0]),
      0,
      0,
      0,
      0
    );

    // lấy ngày hạ chí
    const ngayHaChiString = this.dataNhiTuTietKhi[11][0]
      .split(" ")[0]
      .split("/");
    const ngayHaChi = new Date(
      solarCalendar.getFullYear(),
      Number(ngayHaChiString[1]) - 1,
      Number(ngayHaChiString[0]),
      0,
      0,
      0,
      0
    );

    //lay ngay thu phan
    const ngayThuPhanString = this.dataNhiTuTietKhi[17][0]
      .split(" ")[0]
      .split("/");
    const ngayThuPhan = new Date(
      solarCalendar.getFullYear(),
      Number(ngayThuPhanString[1]) - 1,
      Number(ngayThuPhanString[0]),
      0,
      0,
      0,
      0
    );

    // lay ngay dong chi
    const ngayDongChiString = this.dataNhiTuTietKhi[23][0]
      .split(" ")[0]
      .split("/");
    const ngayDongChi = new Date(
      solarCalendar.getFullYear(),
      Number(ngayDongChiString[1]) - 1,
      Number(ngayDongChiString[0]),
      0,
      0,
      0,
      0
    );

    //2 8 14 20 tứ tuyệt

    //lay ngay Lap xuan ha thu dong
    const ngayLapXuanString = this.dataNhiTuTietKhi[2][0]
      .split(" ")[0]
      .split("/");
    const ngayLapXuan = new Date(
      solarCalendar.getFullYear(),
      Number(ngayLapXuanString[1]) - 1,
      Number(ngayLapXuanString[0]),
      0,
      0,
      0,
      0
    );
    const ngayLapHaString = this.dataNhiTuTietKhi[8][0]
      .split(" ")[0]
      .split("/");
    const ngayLapHa = new Date(
      solarCalendar.getFullYear(),
      Number(ngayLapHaString[1]) - 1,
      Number(ngayLapHaString[0]),
      0,
      0,
      0,
      0
    );

    const ngayLapThuString = this.dataNhiTuTietKhi[14][0]
      .split(" ")[0]
      .split("/");
    const ngayLapThu = new Date(
      solarCalendar.getFullYear(),
      Number(ngayLapThuString[1]) - 1,
      Number(ngayLapThuString[0]),
      0,
      0,
      0,
      0
    );

    const ngayLapDongString = this.dataNhiTuTietKhi[20][0]
      .split(" ")[0]
      .split("/");
    const ngayLapDong = new Date(
      solarCalendar.getFullYear(),
      Number(ngayLapDongString[1]) - 1,
      Number(ngayLapDongString[0]),
      0,
      0,
      0,
      0
    );

    if (
      solarCalendar.getTime() === ngayXuanPhan.getTime() - msADay ||
      solarCalendar.getTime() === ngayHaChi.getTime() - msADay ||
      solarCalendar.getTime() === ngayThuPhan.getTime() - msADay ||
      solarCalendar.getTime() === ngayDongChi.getTime() - msADay
    ) {
      return Const.ngayKy.tuLy.name;
    } else if (
      solarCalendar.getTime() === ngayLapXuan.getTime() - msADay ||
      solarCalendar.getTime() === ngayLapHa.getTime() - msADay ||
      solarCalendar.getTime() === ngayLapThu.getTime() - msADay ||
      solarCalendar.getTime() === ngayLapDong.getTime() - msADay
    ) {
      return Const.ngayKy.tuTuyet.name;
    } else {
      return "";
    }
  }
  getMonthColor(month) {
    switch (month) {
      case 1:
        return Const.colorEachDay.thang1;
      case 2:
        return Const.colorEachDay.thang2;
      case 3:
        return Const.colorEachDay.thang3;
      case 4:
        return Const.colorEachDay.thang4;
      case 5:
        return Const.colorEachDay.thang5;
      case 6:
        return Const.colorEachDay.thang6;
      case 7:
        return Const.colorEachDay.thang7;
      case 8:
        return Const.colorEachDay.thang8;
      case 9:
        return Const.colorEachDay.thang9;
      case 10:
        return Const.colorEachDay.thang10;
      case 11:
        return Const.colorEachDay.thang11;
      case 12:
        return Const.colorEachDay.thang12;

      default:
        break;
    }
  }
  getDayColor(day, month) {
    switch (day) {
      case 1:
        return month.ngay1;
      case 2:
        return month.ngay2;
      case 3:
        return month.ngay3;
      case 4:
        return month.ngay4;
      case 5:
        return month.ngay5;
      case 6:
        return month.ngay6;
      case 7:
        return month.ngay7;
      case 8:
        return month.ngay8;
      case 9:
        return month.ngay9;
      case 10:
        return month.ngay10;
      case 11:
        return month.ngay11;
      case 12:
        return month.ngay12;
      case 13:
        return month.ngay13;
      case 14:
        return month.ngay14;
      case 15:
        return month.ngay15;
      case 16:
        return month.ngay16;
      case 17:
        return month.ngay17;
      case 18:
        return month.ngay18;
      case 19:
        return month.ngay19;
      case 20:
        return month.ngay20;
      case 21:
        return month.ngay21;
      case 22:
        return month.ngay22;
      case 23:
        return month.ngay23;
      case 24:
        return month.ngay24;
      case 25:
        return month.ngay25;
      case 26:
        return month.ngay26;
      case 27:
        return month.ngay27;
      case 28:
        return month.ngay28;
      case 29:
        return month.ngay29;
      case 30:
        return month.ngay30;
      case 31:
        return month.ngay31;

      default:
        break;
    }
  }
  onClickNextDate() {
    let daySetted = this.state.date.setDate(this.state.date.getDate() + 1);
    let date = new Date(daySetted);

    this.setState({ date });
    setTimeout(() => {
      this.onChangeData();
    }, 200);
  }
  onClickBackDate() {
    let daySetted = this.state.date.setDate(this.state.date.getDate() - 1);
    let date = new Date(daySetted);

    this.setState({ date });
    setTimeout(() => {
      this.onChangeData();
    }, 200);
  }
  async refreshPage() {
    try {
      this.setState({ isLoading: true });
      await fetch("lich_van_nien.xlsx")
        .then((res) => res.arrayBuffer())
        .then((ab) => {
          const wb = XLSX.read(ab, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.excel = data;
        });
    } catch (error) {}
    const tempTime = new Date(
      this.state.date.getFullYear(),
      this.state.date.getMonth(),
      this.state.date.getDate(),
      0,
      this.state.date.getMinutes()
    );
    this.getDongChiHaChi(tempTime.getFullYear());
    let dateShowBellowSolarDate = lunar.solar2Lunar(
      this.state.date.getDate(),
      this.state.date.getMonth() + 1,
      this.state.date.getFullYear()
    );
    let lastDateMonthLunar = null;
    for (let ngay = 0; ngay < 31; ngay++) {
      let ngayHienTai = new Date(
        this.state.date.getFullYear(),
        this.state.date.getMonth(),
        this.state.date.getDate() + ngay,
        0,
        this.state.date.getMinutes()
      );
      let toLunarCalendar = lunar.solar2Lunar(
        ngayHienTai.getDate(),
        ngayHienTai.getMonth() + 1,
        ngayHienTai.getFullYear()
      );
      if (toLunarCalendar[1] !== dateShowBellowSolarDate[1]) {
        lastDateMonthLunar = new Date(
          ngayHienTai.getFullYear(),
          ngayHienTai.getMonth(),
          ngayHienTai.getDate() - 1
        );
        break;
      }
    }

    const convertLastDateToSolar = lunar.solar2Lunar(
      lastDateMonthLunar.getDate(),
      lastDateMonthLunar.getMonth() + 1,
      lastDateMonthLunar.getFullYear()
    );
    let isMissingDate = false;
    if (convertLastDateToSolar[0] === 29) {
      isMissingDate = true;
    }
    const starDay = this.getDayStarOfADate(tempTime);
    const getMonthColor = this.getMonthColor(this.state.date.getMonth() + 1);
    const getDayColor = this.getDayColor(
      this.state.date.getDate(),
      getMonthColor
    );

    const getBackGround = this.getBackGround(getDayColor.firstColor);
    const getFooter = this.getFooterColor(getDayColor.firstColor);

    // lấy lịch tiết khí của cả 1 năm
    this.getTietKhiAllYear(tempTime);
    // điều chỉnh lịch dương thành lịch tiết khí(chủ yếu là tháng)
    let dateTietKhi = this.getCorrectTietKhi(tempTime);

    if (Cookies.get("idUser")) {
      await firebase
        .database()
        .ref("/users/" + Cookies.get("idUser")) // check xem có tồn tại chưa
        .once("value")
        .then(function (snapshot) {
          if (snapshot.val()) {
            globalUtils.userInfo = snapshot.val();
          }
        });
    }
    const dateLunar = new Date(this.state.date);

    let keyWordSearch = [];
    for (let i = 0; i <= 30; i++) {
      let date = new Date(
        this.state.date?.getFullYear(),
        this.state.date.getMonth(),
        this.state.date.getDate() + i
      );
      let canChiADay = DayToCanChi(date.toDateString());
      let kiengkytrongngay = kiengKy(dateTietKhi.getMonth(), canChiADay);
      keyWordSearch.push(kiengkytrongngay?.nen);
    }

    const thaiDuongThaiAm = this.thaiDuongThaiAm(dateTietKhi.toDateString());
    const ngayKyAmLich = this.getNgayKyAmLich(dateShowBellowSolarDate);
    const ngayKyTietKhi = this.getNgayKyTietKhi(tempTime);

    const isSauDongChi = this.getIsSauDongChi(dateTietKhi.toDateString());
    //star sử dụng dương lịch không sai
    const star = thapNhiBatTu(this.state.date);
    const canYear = YearToCan(dateTietKhi.getYear());
    const chiYear = YearToChi(dateTietKhi.getYear());
    const canChiMonth = MonthToCanChi(canYear, dateTietKhi.getMonth());
    const canChiDay = DayToCanChi(this.state.date.toDateString());

    const napAmNgay = getNamAp(canChiDay.name);
    const toaCanChi = Const.Que[this.state.toaNumber];
    const namSinhCanChi = Const.Que[this.state.namSinhNumber];
    const huongTamSatYear = this.convertTamSatSideOnCompass(chiYear.name);
    let realDate = new Date(dateTietKhi);
    const starYear = this.phiTinhNam(realDate);
    const huongNguHoangYear = this.convertSideToCompass(starYear);
    const starMonth = phiTinhThang(tempTime);
    const huongNguHoangMonth = this.convertSideToCompass(starMonth);

    const initData = this.state.dateData.map((item) => {
      if (item.header === "Năm") {
        item.canChi = canYear.name;
        item.diaChi = chiYear.name;
        item.qua = getQue(canYear.name, chiYear.name).tenque;
        item.que = getQue(canYear.name, chiYear.name);
        this.setState({ momentYear: dateLunar.getYear(), queNam: chiYear });
      } else if (item.header === "Tháng") {
        //tách chuỗi name trong canChiMonth
        let tempCan = canChiMonth.name.split(" ");

        item.canChi = tempCan[0];
        item.diaChi = tempCan[1];
        item.qua = getQue(tempCan[0], tempCan[1]).tenque;
        item.que = getQue(tempCan[0], tempCan[1]);
        const huongTamSatMonth = this.convertTamSatSideOnCompass(item.diaChi);

        this.setState({
          momentMonth: dateLunar.getMonth() + 1,
          huongTamSatMonth,
          chiMonthLunar: tempCan[1],
        });
      } else if (item.header === "Ngày") {
        let tempCanDay = canChiDay.name.split(" ");
        item.canChi = tempCanDay[0];
        item.diaChi = tempCanDay[1];
        let chiDate = Const.ChiArr.find((v) => v.name === tempCanDay[1]);
        let canDate = Const.CanArr.find((v) => v.name === tempCanDay[0]);

        const nhiThapKienTru = getNhiThapKienTru(
          realDate.getMonth(),
          chiDate.name,
          canDate
        );
        const huongTamSatDay = this.convertTamSatSideOnCompass(item.diaChi);

        let ngayHoangDao = ngayGioHoangDao(
          dateTietKhi.getMonth(),
          chiDate.code
        );
        item.qua = getQue(tempCanDay[0], tempCanDay[1]).tenque;
        item.que = getQue(tempCanDay[0], tempCanDay[1]);
        this.setState({
          canChiDay: item.que,
          huongTamSatDay,
          momentDate: this.state.date.getDate(),
          chiDay: tempCanDay[1],
          nhiThapKienTru,
          ngayHoangDao,
        });
      } else if (item.header === "Giờ") {
        let tempCanDay = canChiDay.name.split(" ");
        let tempCanChiHour = HourToCanChi(tempCanDay[0], 0).name.split(" ");
        item.canChi = tempCanChiHour[0];
        item.diaChi = tempCanChiHour[1];
        item.qua = getQue(tempCanChiHour[0], tempCanChiHour[1]).tenque;
        item.que = getQue(tempCanChiHour[0], tempCanChiHour[1]);
        const huongTamSatHour = this.convertTamSatSideOnCompass(item.diaChi);

        this.setState({
          huongTamSatHour,
          momentHour: 0,
          chiHour: tempCanChiHour,
          canDay: tempCanDay[0],
        });
      } else if (item.header === "Tọa") {
        let tempToaCanChi = toaCanChi.queViet.split(" ");
        item.canChi = tempToaCanChi[0];
        item.diaChi = tempToaCanChi[1];
        item.qua = toaCanChi.tenque;
        item.que = getQue(tempToaCanChi[0], tempToaCanChi[1]);
      } else if (item.header.toUpperCase() === "Năm Sinh".toUpperCase()) {
        let tempToaCanChi = namSinhCanChi.queViet.split(" ");
        item.canChi = tempToaCanChi[0];
        item.diaChi = tempToaCanChi[1];
        item.qua = namSinhCanChi.tenque;
        item.que = getQue(tempToaCanChi[0], tempToaCanChi[1]);
      }
      return item;
    });
    const kiengkytrongngay = kiengKy(dateTietKhi.getMonth(), canChiDay);

    this.setState({
      themeDay: getDayColor,
      isMissingDate,
      footerBg: getFooter,
      background: getBackGround,
      colorFontPicker1: getDayColor.firstColor,
      colorFontPicker2: getDayColor.secondColor,
      ngayKyAmLich,
      ngayKyTietKhi,
      dateData: initData,
      namSinhCanChi,
      searchSelectMonth: this.state.date.getMonth(),
      searchSelectYear: this.state.date.getFullYear(),
      realDatePhiTinh: realDate,
      dateTietKhi,
      huongTamSatYear,
      huongNguHoangYear,
      huongNguHoangMonth,
      isSauDongChi,
      napAmNgay,
      dateShowBellowSolarDate,
      star,
      starMonth,
      kiengkytrongngay,
      thaiDuongThaiAm,
      keyWordSearch,
      isLoading: false,
      starDay,
      toaCanChi,
      dateLunar,
      taiThan: canChiDay.taiThan,
      hyThan: canChiDay.hyThan,
    });
  }

  async componentDidMount() {
    const userSaveStorage = localStorage.getItem(mdfive);
    if (userSaveStorage) {
      const dataUser = JSON.parse(userSaveStorage);
      this.setState({ isCustomer: dataUser.isCustomer });
    }

    await this.refreshPage();
  }
  async onChangeData() {
    const dateTime = new Date(
      this.state.date.getFullYear(),
      this.state.date.getMonth(),
      this.state.date.getDate(),
      0,
      this.state.date.getMinutes()
    );
    let dateShowBellowSolarDate = lunar.solar2Lunar(
      this.state.date.getDate(),
      this.state.date.getMonth() + 1,
      this.state.date.getFullYear()
    );
    let lastDateMonthLunar = null;
    for (let ngay = 0; ngay < 31; ngay++) {
      let ngayHienTai = new Date(
        this.state.date.getFullYear(),
        this.state.date.getMonth(),
        this.state.date.getDate() + ngay,
        0,
        this.state.date.getMinutes()
      );
      let toLunarCalendar = lunar.solar2Lunar(
        ngayHienTai.getDate(),
        ngayHienTai.getMonth() + 1,
        ngayHienTai.getFullYear()
      );
      if (toLunarCalendar[1] !== dateShowBellowSolarDate[1]) {
        lastDateMonthLunar = new Date(
          ngayHienTai.getFullYear(),
          ngayHienTai.getMonth(),
          ngayHienTai.getDate() - 1
        );
        break;
      }
    }

    const convertLastDateToSolar = lunar.solar2Lunar(
      lastDateMonthLunar.getDate(),
      lastDateMonthLunar.getMonth() + 1,
      lastDateMonthLunar.getFullYear()
    );
    let isMissingDate = false;
    if (convertLastDateToSolar[0] === 29) {
      isMissingDate = true;
    }
    this.getTietKhiAllYear(dateTime);
    const getMonthColor = this.getMonthColor(this.state.date.getMonth() + 1);
    const getDayColor = this.getDayColor(
      this.state.date.getDate(),
      getMonthColor
    );

    const getBackGround = this.getBackGround(getDayColor.firstColor);
    const getFooter = this.getFooterColor(getDayColor.firstColor);
    const correctDateTietKhi = this.getCorrectTietKhi(dateTime);
    const starYear = this.phiTinhNam(correctDateTietKhi);
    const huongNguHoangYear = this.convertSideToCompass(starYear);
    const starMonth = phiTinhThang(dateTime);
    const huongNguHoangMonth = this.convertSideToCompass(starMonth);
    const thaiDuongThaiAm = this.thaiDuongThaiAm(
      correctDateTietKhi.toDateString()
    );

    const canYear = YearToCan(correctDateTietKhi.getYear());
    const chiYear = YearToChi(correctDateTietKhi.getYear());
    const huongTamSatYear = this.convertTamSatSideOnCompass(chiYear.name);

    const canChiMonth = MonthToCanChi(canYear, correctDateTietKhi.getMonth());

    const starDay = this.getDayStarOfADate(dateTime);

    const isSauDongChi = this.getIsSauDongChi(correctDateTietKhi);

    const canChiDay = DayToCanChi(this.state.date.toDateString());

    const napAmNgay = getNamAp(canChiDay.name);
    const star = thapNhiBatTu(dateTime);
    const ngayKyAmLich = this.getNgayKyAmLich(dateShowBellowSolarDate);
    const ngayKyTietKhi = this.getNgayKyTietKhi(dateTime);
    const toaCanChi = Const.QueSortConGiap[this.state.toaNumber];
    const namSinhCanChi = Const.Que[this.state.namSinhNumber];
    let realDate = new Date(correctDateTietKhi);

    const initData = this.state.dateData.map((item) => {
      if (item.header === "Năm") {
        item.canChi = canYear.name;
        item.diaChi = chiYear.name;
        item.qua = getQue(canYear.name, chiYear.name).tenque;
        item.que = getQue(canYear.name, chiYear.name);
        this.setState({ queNam: chiYear });
      } else if (item.header === "Tháng") {
        //tách chuỗi name trong canChiMonth
        let tempCan = canChiMonth.name.split(" ");
        item.canChi = tempCan[0];
        item.diaChi = tempCan[1];
        item.qua = getQue(tempCan[0], tempCan[1]).tenque;
        item.que = getQue(tempCan[0], tempCan[1]);
        const huongTamSatMonth = this.convertTamSatSideOnCompass(item.diaChi);

        this.setState({ chiMonthLunar: tempCan[1], huongTamSatMonth });
      } else if (item.header === "Ngày") {
        let tempCanDay = canChiDay.name.split(" ");
        item.canChi = tempCanDay[0];
        item.diaChi = tempCanDay[1];
        let chiDate = Const.ChiArr.find((v) => v.name === tempCanDay[1]);
        let canDate = Const.CanArr.find((v) => v.name === tempCanDay[0]);
        const huongTamSatDay = this.convertTamSatSideOnCompass(item.diaChi);

        const nhiThapKienTru = getNhiThapKienTru(
          realDate.getMonth(),
          chiDate.name,
          canDate,
          huongTamSatDay
        );

        let ngayHoangDao = ngayGioHoangDao(
          correctDateTietKhi.getMonth(),
          chiDate.code
        );
        item.qua = getQue(tempCanDay[0], tempCanDay[1]).tenque;
        item.que = getQue(tempCanDay[0], tempCanDay[1]);
        this.setState({
          canChiDay: item.que,
          huongTamSatDay,
          chiDay: tempCanDay[1],
          ngayHoangDao,
          nhiThapKienTru,
        });
      } else if (item.header === "Giờ") {
        let tempCanDay = canChiDay.name.split(" ");
        let tempCanChiHour = HourToCanChi(tempCanDay[0], 0).name.split(" ");
        item.canChi = tempCanChiHour[0];
        item.diaChi = tempCanChiHour[1];
        item.qua = getQue(tempCanChiHour[0], tempCanChiHour[1]).tenque;
        item.que = getQue(tempCanChiHour[0], tempCanChiHour[1]);
        const huongTamSatHour = this.convertTamSatSideOnCompass(item.diaChi);

        this.setState({
          huongTamSatHour,
          canDay: tempCanDay[0],
        });
      } else if (item.header === "Tọa") {
        let tempToaCanChi = toaCanChi.queViet.split(" ");
        item.canChi = tempToaCanChi[0];
        item.diaChi = tempToaCanChi[1];
        item.qua = toaCanChi.tenque;
        item.que = toaCanChi;
      } else if (item.header.toUpperCase() === "Năm sinh".toUpperCase()) {
        let tempNamSinhCanChi = namSinhCanChi.queViet.split(" ");
        item.canChi = tempNamSinhCanChi[0];
        item.diaChi = tempNamSinhCanChi[1];
        item.qua = namSinhCanChi.tenque;
        item.que = namSinhCanChi;
      }
      return item;
    });
    const kiengkytrongngay = kiengKy(correctDateTietKhi.getMonth(), canChiDay);

    this.setState({
      themeDay: getDayColor,
      subImage: null,
      backgroundImage: null,
      background: getBackGround,
      footerBg: getFooter,
      isMissingDate,
      colorFontPicker1: getDayColor.firstColor,
      colorFontPicker2: getDayColor.secondColor,
      ngayKyAmLich,
      ngayKyTietKhi,
      dateData: initData,
      date: dateTime,
      namSinhCanChi,
      huongTamSatYear,
      dateShowBellowSolarDate,
      dateTietKhi: correctDateTietKhi,
      realDatePhiTinh: realDate,
      toaCanChi,
      huongNguHoangYear,
      huongNguHoangMonth,
      napAmNgay,
      kiengkytrongngay,
      star,
      starDay,
      starMonth,
      isSauDongChi,
      thaiDuongThaiAm,
      taiThan: canChiDay.taiThan,
      hyThan: canChiDay.hyThan,
    });
  }

  changeLanguage() {
    var x = document.getElementById("language");
    var i = x.selectedIndex;
    i18n.changeLanguage(x.options[i].value);
    this.setState({ language: x.options[i].value });
  }

  thaiDuongThaiAm(DateTimeLunar) {
    // 1 năm có 24 tiết khí phải lấy ra được tiết khi của mỗi năm.
    // bắt đầu từ năm 1932 vì trong file excel bắt đầu từ năm 1932

    const tempDateTime = new Date(DateTimeLunar);
    //file excel bắt đầu từ năm 1932
    const distance = tempDateTime?.getFullYear() - 1932;
    //lấy dòng của ngày đầu tháng tiết khí và ngày cuối tháng tiết khí
    //dòng ngày đầu của tháng lẻ 1 năm
    const dongNgayDauThangLe = distance * 42 + 36;
    //dòng ngày cuối tháng lẻ
    const dongNgayCuoiThangLe = distance * 42 + 37;
    //dòng ngày đầu của tháng chẳn 1 năm
    const dongNgayDauThangChan = distance * 42 + 38;
    //dòng ngày cuối tháng chẳn
    const dongNgayCuoiThangChan = distance * 42 + 39;
    //Mỗi tháng cách nhau 4 column
    // + 1 vì getmonth trong JS tính theo mảng bắt đầu từ 0(vd tháng 1 là 0 tháng 2 là 1)
    // const colThang = tempDateTime.getMonth() + 1 + 4;
    // bao gồm 24 tiết khí
    // lấy ra được thông tin từ excel rồi
    const cellStringTieuHan = this.excel[dongNgayDauThangLe][1]
      .split("-")[0]
      .split(" ");
    const cellStringDaiHan = this.excel[dongNgayCuoiThangLe][1]
      .split("-")[0]
      .split(" ");
    const cellStringLapXuan = this.excel[dongNgayDauThangChan][1]
      .split("-")[0]
      .split(" ");
    const cellStringVuThuy = this.excel[dongNgayCuoiThangChan][1]
      .split("-")[0]
      .split(" ");
    const cellStringKinhTrap = this.excel[dongNgayDauThangLe][5]
      .split("-")[0]
      .split(" ");
    const cellStringXuanPhan = this.excel[dongNgayCuoiThangLe][5]
      .split("-")[0]
      .split(" ");
    const cellStringThanhMinh = this.excel[dongNgayDauThangChan][5]
      .split("-")[0]
      .split(" ");
    const cellStringCocVu = this.excel[dongNgayCuoiThangChan][5]
      .split("-")[0]
      .split(" ");
    const cellStringLapHa = this.excel[dongNgayDauThangLe][9]
      .split("-")[0]
      .split(" ");
    const cellStringTieuMan = this.excel[dongNgayCuoiThangLe][9]
      .split("-")[0]
      .split(" ");
    const cellStringMangChung = this.excel[dongNgayDauThangChan][9]
      .split("-")[0]
      .split(" ");
    const cellStringHaChi = this.excel[dongNgayCuoiThangChan][9]
      .split("-")[0]
      .split(" ");
    const cellStringTieuThu = this.excel[dongNgayDauThangLe][13]
      .split("-")[0]
      .split(" ");
    const cellStringDaiThu = this.excel[dongNgayCuoiThangLe][13]
      .split("-")[0]
      .split(" ");
    const cellStringLapThu = this.excel[dongNgayDauThangChan][13]
      .split("-")[0]
      .split(" ");
    const cellStringXuThu = this.excel[dongNgayCuoiThangChan][13]
      .split("-")[0]
      .split(" ");
    const cellStringBachLo = this.excel[dongNgayDauThangLe][17]
      .split("-")[0]
      .split(" ");
    const cellStringThuPhan = this.excel[dongNgayCuoiThangLe][17]
      .split("-")[0]
      .split(" ");
    const cellStringHanLo = this.excel[dongNgayDauThangChan][17]
      .split("-")[0]
      .split(" ");
    const cellStringSuongGiang = this.excel[dongNgayCuoiThangChan][17]
      .split("-")[0]
      .split(" ");
    const cellStringLapDong = this.excel[dongNgayDauThangLe][21]
      .split("-")[0]
      .split(" ");
    const cellStringTieuTuyet = this.excel[dongNgayCuoiThangLe][21]
      .split("-")[0]
      .split(" ");
    const cellStringDaiTuyet = this.excel[dongNgayDauThangChan][21]
      .split("-")[0]
      .split(" ");
    const cellStringDongChi = this.excel[dongNgayCuoiThangChan][21]
      .split("-")[0]
      .split(" ");
    // 06/01 04:30 - Tiểu hàn
    this.dataNhiTuTietKhi = [
      this.excel[dongNgayDauThangLe][1].split(" - "),
      this.excel[dongNgayCuoiThangLe][1].split(" - "),
      this.excel[dongNgayDauThangChan][1].split(" - "),
      this.excel[dongNgayCuoiThangChan][1].split(" - "),
      this.excel[dongNgayDauThangLe][5].split(" - "),
      this.excel[dongNgayCuoiThangLe][5].split(" - "),
      this.excel[dongNgayDauThangChan][5].split(" - "),
      this.excel[dongNgayCuoiThangChan][5].split(" - "),
      this.excel[dongNgayDauThangLe][9].split(" - "),
      this.excel[dongNgayCuoiThangLe][9].split(" - "),
      this.excel[dongNgayDauThangChan][9].split(" - "),
      this.excel[dongNgayCuoiThangChan][9].split(" - "),
      this.excel[dongNgayDauThangLe][13].split(" - "),
      this.excel[dongNgayCuoiThangLe][13].split(" - "),
      this.excel[dongNgayDauThangChan][13].split(" - "),
      this.excel[dongNgayCuoiThangChan][13].split(" - "),
      this.excel[dongNgayDauThangLe][17].split(" - "),
      this.excel[dongNgayCuoiThangLe][17].split(" - "),
      this.excel[dongNgayDauThangChan][17].split(" - "),
      this.excel[dongNgayCuoiThangChan][17].split(" - "),
      this.excel[dongNgayDauThangLe][21].split(" - "),
      this.excel[dongNgayCuoiThangLe][21].split(" - "),
      this.excel[dongNgayDauThangChan][21].split(" - "),
      this.excel[dongNgayCuoiThangChan][21].split(" - "),
    ];
    const dateTimeTieuHan = new Date(
      tempDateTime?.getFullYear(),
      cellStringTieuHan[0].split("/")[1] - 1,
      cellStringTieuHan[0].split("/")[0],
      cellStringTieuHan[1].split(":")[0],
      cellStringTieuHan[1].split(":")[1]
    );
    const dateTimeDaiHan = new Date(
      tempDateTime?.getFullYear(),
      cellStringDaiHan[0].split("/")[1] - 1,
      cellStringDaiHan[0].split("/")[0],
      cellStringDaiHan[1].split(":")[0],
      cellStringDaiHan[1].split(":")[1]
    );
    const dateTimeLapXuan = new Date(
      tempDateTime?.getFullYear(),
      cellStringLapXuan[0].split("/")[1] - 1,
      cellStringLapXuan[0].split("/")[0],
      cellStringLapXuan[1].split(":")[0],
      cellStringLapXuan[1].split(":")[1]
    );
    const dateTimeVuThuy = new Date(
      tempDateTime?.getFullYear(),
      cellStringVuThuy[0].split("/")[1] - 1,
      cellStringVuThuy[0].split("/")[0],
      cellStringVuThuy[1].split(":")[0],
      cellStringVuThuy[1].split(":")[1]
    );
    const dateTimeKinhTrap = new Date(
      tempDateTime?.getFullYear(),
      cellStringKinhTrap[0].split("/")[1] - 1,
      cellStringKinhTrap[0].split("/")[0],
      cellStringKinhTrap[1].split(":")[0],
      cellStringKinhTrap[1].split(":")[1]
    );
    const dateTimeXuanPhan = new Date(
      tempDateTime?.getFullYear(),
      cellStringXuanPhan[0].split("/")[1] - 1,
      cellStringXuanPhan[0].split("/")[0],
      cellStringXuanPhan[1].split(":")[0],
      cellStringXuanPhan[1].split(":")[1]
    );
    const dateTimeThanhMinh = new Date(
      tempDateTime?.getFullYear(),
      cellStringThanhMinh[0].split("/")[1] - 1,
      cellStringThanhMinh[0].split("/")[0],
      cellStringThanhMinh[1].split(":")[0],
      cellStringThanhMinh[1].split(":")[1]
    );
    const dateTimeCocVu = new Date(
      tempDateTime?.getFullYear(),
      cellStringCocVu[0].split("/")[1] - 1,
      cellStringCocVu[0].split("/")[0],
      cellStringCocVu[1].split(":")[0],
      cellStringCocVu[1].split(":")[1]
    );
    const dateTimeLapHa = new Date(
      tempDateTime?.getFullYear(),
      cellStringLapHa[0].split("/")[1] - 1,
      cellStringLapHa[0].split("/")[0],
      cellStringLapHa[1].split(":")[0],
      cellStringLapHa[1].split(":")[1]
    );
    const dateTimeTieuMan = new Date(
      tempDateTime?.getFullYear(),
      cellStringTieuMan[0].split("/")[1] - 1,
      cellStringTieuMan[0].split("/")[0],
      cellStringTieuMan[1].split(":")[0],
      cellStringTieuMan[1].split(":")[1]
    );
    const dateTimeMangChung = new Date(
      tempDateTime?.getFullYear(),
      cellStringMangChung[0].split("/")[1] - 1,
      cellStringMangChung[0].split("/")[0],
      cellStringMangChung[1].split(":")[0],
      cellStringMangChung[1].split(":")[1]
    );
    const dateTimeHaChi = new Date(
      tempDateTime?.getFullYear(),
      cellStringHaChi[0].split("/")[1] - 1,
      cellStringHaChi[0].split("/")[0],
      cellStringHaChi[1].split(":")[0],
      cellStringHaChi[1].split(":")[1]
    );
    const dateTimeTieuThu = new Date(
      tempDateTime?.getFullYear(),
      cellStringTieuThu[0].split("/")[1] - 1,
      cellStringTieuThu[0].split("/")[0],
      cellStringTieuThu[1].split(":")[0],
      cellStringTieuThu[1].split(":")[1]
    );
    const dateTimeDaiThu = new Date(
      tempDateTime?.getFullYear(),
      cellStringDaiThu[0].split("/")[1] - 1,
      cellStringDaiThu[0].split("/")[0],
      cellStringDaiThu[1].split(":")[0],
      cellStringDaiThu[1].split(":")[1]
    );
    const dateTimeLapThu = new Date(
      tempDateTime?.getFullYear(),
      cellStringLapThu[0].split("/")[1] - 1,
      cellStringLapThu[0].split("/")[0],
      cellStringLapThu[1].split(":")[0],
      cellStringLapThu[1].split(":")[1]
    );
    const dateTimeXuThu = new Date(
      tempDateTime?.getFullYear(),
      cellStringXuThu[0].split("/")[1] - 1,
      cellStringXuThu[0].split("/")[0],
      cellStringXuThu[1].split(":")[0],
      cellStringXuThu[1].split(":")[1]
    );
    const dateTimeBachLo = new Date(
      tempDateTime?.getFullYear(),
      cellStringBachLo[0].split("/")[1] - 1,
      cellStringBachLo[0].split("/")[0],
      cellStringBachLo[1].split(":")[0],
      cellStringBachLo[1].split(":")[1]
    );
    const dateTimeThuPhan = new Date(
      tempDateTime?.getFullYear(),
      cellStringThuPhan[0].split("/")[1] - 1,
      cellStringThuPhan[0].split("/")[0],
      cellStringThuPhan[1].split(":")[0],
      cellStringThuPhan[1].split(":")[1]
    );
    const dateTimeHanLo = new Date(
      tempDateTime?.getFullYear(),
      cellStringHanLo[0].split("/")[1] - 1,
      cellStringHanLo[0].split("/")[0],
      cellStringHanLo[1].split(":")[0],
      cellStringHanLo[1].split(":")[1]
    );
    const dateTimeSuongGiang = new Date(
      tempDateTime?.getFullYear(),
      cellStringSuongGiang[0].split("/")[1] - 1,
      cellStringSuongGiang[0].split("/")[0],
      cellStringSuongGiang[1].split(":")[0],
      cellStringSuongGiang[1].split(":")[1]
    );
    const dateTimeLapDong = new Date(
      tempDateTime?.getFullYear(),
      cellStringLapDong[0].split("/")[1] - 1,
      cellStringLapDong[0].split("/")[0],
      cellStringLapDong[1].split(":")[0],
      cellStringLapDong[1].split(":")[1]
    );
    const dateTimeTieuTuyet = new Date(
      tempDateTime?.getFullYear(),
      cellStringTieuTuyet[0].split("/")[1] - 1,
      cellStringTieuTuyet[0].split("/")[0],
      cellStringTieuTuyet[1].split(":")[0],
      cellStringTieuTuyet[1].split(":")[1]
    );
    const dateTimeDaiTuyet = new Date(
      tempDateTime?.getFullYear(),
      cellStringDaiTuyet[0].split("/")[1] - 1,
      cellStringDaiTuyet[0].split("/")[0],
      cellStringDaiTuyet[1].split(":")[0],
      cellStringDaiTuyet[1].split(":")[1]
    );
    const dateTimeDongChi = new Date(
      tempDateTime?.getFullYear(),
      cellStringDongChi[0].split("/")[1] - 1,
      cellStringDongChi[0].split("/")[0],
      cellStringDongChi[1].split(":")[0],
      cellStringDongChi[1].split(":")[1]
    );

    if (tempDateTime.getTime() < dateTimeTieuHan.getTime()) {
      return { thaiDuong: Const.Can.Qui.name, thaiAm: Const.Can.Qui.name };
    } else if (
      tempDateTime.getTime() > dateTimeDaiHan.getTime() &&
      tempDateTime.getTime() < dateTimeLapXuan.getTime()
    ) {
      return { thaiDuong: Const.Chi.Ty.name, thaiAm: Const.Chi.Suu.name };
    } else if (
      tempDateTime.getTime() > dateTimeLapXuan.getTime() &&
      tempDateTime.getTime() < dateTimeVuThuy.getTime()
    ) {
      return { thaiDuong: Const.Can.Nham.name, thaiAm: "Cấn" };
    } else if (
      tempDateTime.getTime() > dateTimeVuThuy.getTime() &&
      tempDateTime.getTime() < dateTimeKinhTrap.getTime()
    ) {
      return { thaiDuong: Const.Chi.Hoi.name, thaiAm: Const.Chi.Dan.name };
    } else if (
      tempDateTime.getTime() > dateTimeKinhTrap.getTime() &&
      tempDateTime.getTime() < dateTimeXuanPhan.getTime()
    ) {
      return { thaiDuong: "Càn", thaiAm: Const.Can.Giap.name };
    } else if (
      tempDateTime.getTime() > dateTimeXuanPhan.getTime() &&
      tempDateTime.getTime() < dateTimeThanhMinh.getTime()
    ) {
      return { thaiDuong: Const.Chi.Tuat.name, thaiAm: Const.Chi.Mao.name };
    } else if (
      tempDateTime.getTime() > dateTimeThanhMinh.getTime() &&
      tempDateTime.getTime() < dateTimeCocVu.getTime()
    ) {
      return { thaiDuong: Const.Can.Tan.name, thaiAm: Const.Can.At.name };
    } else if (
      tempDateTime.getTime() > dateTimeCocVu.getTime() &&
      tempDateTime.getTime() < dateTimeLapHa.getTime()
    ) {
      return { thaiDuong: Const.Chi.Dau.name, thaiAm: Const.Chi.Thin.name };
    } else if (
      tempDateTime.getTime() > dateTimeLapHa.getTime() &&
      tempDateTime.getTime() < dateTimeTieuMan.getTime()
    ) {
      return { thaiDuong: Const.Can.Canh.name, thaiAm: "Tốn" };
    } else if (
      tempDateTime.getTime() > dateTimeTieuMan.getTime() &&
      tempDateTime.getTime() < dateTimeMangChung.getTime()
    ) {
      return { thaiDuong: Const.Chi.Than.name, thaiAm: Const.Chi.Ti.name };
    } else if (
      tempDateTime.getTime() > dateTimeMangChung.getTime() &&
      tempDateTime.getTime() < dateTimeHaChi.getTime()
    ) {
      return { thaiDuong: "Khôn", thaiAm: Const.Can.Binh.name };
    } else if (
      tempDateTime.getTime() > dateTimeHaChi.getTime() &&
      tempDateTime.getTime() < dateTimeTieuThu.getTime()
    ) {
      return { thaiDuong: Const.Chi.Mui.name, thaiAm: Const.Chi.Ngo.name };
    } else if (
      tempDateTime.getTime() > dateTimeTieuThu.getTime() &&
      tempDateTime.getTime() < dateTimeDaiThu.getTime()
    ) {
      return { thaiDuong: Const.Can.Dinh.name, thaiAm: Const.Can.Dinh.name };
    } else if (
      tempDateTime.getTime() > dateTimeDaiThu.getTime() &&
      tempDateTime.getTime() < dateTimeLapThu.getTime()
    ) {
      return { thaiDuong: Const.Chi.Ngo.name, thaiAm: Const.Chi.Mui.name };
    } else if (
      tempDateTime.getTime() > dateTimeLapThu.getTime() &&
      tempDateTime.getTime() < dateTimeXuThu.getTime()
    ) {
      return { thaiDuong: Const.Can.Binh.name, thaiAm: "Khôn" };
    } else if (
      tempDateTime.getTime() > dateTimeXuThu.getTime() &&
      tempDateTime.getTime() < dateTimeBachLo.getTime()
    ) {
      return { thaiDuong: Const.Chi.Ti.name, thaiAm: Const.Chi.Than.name };
    } else if (
      tempDateTime.getTime() > dateTimeBachLo.getTime() &&
      tempDateTime.getTime() < dateTimeThuPhan.getTime()
    ) {
      return { thaiDuong: "Tốn", thaiAm: Const.Can.Canh.name };
    } else if (
      tempDateTime.getTime() > dateTimeThuPhan.getTime() &&
      tempDateTime.getTime() < dateTimeHanLo.getTime()
    ) {
      return { thaiDuong: Const.Chi.Thin.name, thaiAm: Const.Chi.Dau.name };
    } else if (
      tempDateTime.getTime() > dateTimeHanLo.getTime() &&
      tempDateTime.getTime() < dateTimeSuongGiang.getTime()
    ) {
      return { thaiDuong: Const.Can.At.name, thaiAm: "Tân" };
    } else if (
      tempDateTime.getTime() > dateTimeSuongGiang.getTime() &&
      tempDateTime.getTime() < dateTimeLapDong.getTime()
    ) {
      return { thaiDuong: Const.Chi.Mao.name, thaiAm: Const.Chi.Tuat.name };
    } else if (
      tempDateTime.getTime() > dateTimeLapDong.getTime() &&
      tempDateTime.getTime() < dateTimeTieuTuyet.getTime()
    ) {
      return { thaiDuong: Const.Can.Giap.name, thaiAm: "Càn" };
    } else if (
      tempDateTime.getTime() > dateTimeTieuTuyet.getTime() &&
      tempDateTime.getTime() < dateTimeDaiTuyet.getTime()
    ) {
      return { thaiDuong: Const.Chi.Dan.name, thaiAm: Const.Chi.Hoi.name };
    } else if (
      tempDateTime.getTime() > dateTimeDaiTuyet.getTime() &&
      tempDateTime.getTime() < dateTimeDongChi.getTime()
    ) {
      return { thaiDuong: "Cấn", thaiAm: Const.Can.Nham.name };
    } else if (tempDateTime.getTime() > dateTimeDongChi.getTime()) {
      return { thaiDuong: Const.Chi.Suu.name, thaiAm: Const.Chi.Ty.name };
    }
  }

  monthToLetter(month, overrideLanguage) {
    const { t } = this.props;

    switch (month) {
      case 1:
        return t("month.january", { lng: overrideLanguage });
      case 2:
        return t("month.february", { lng: overrideLanguage });
      case 3:
        return t("month.march", { lng: overrideLanguage });
      case 4:
        return t("month.april", { lng: overrideLanguage });
      case 5:
        return t("month.may", { lng: overrideLanguage });
      case 6:
        return t("month.june", { lng: overrideLanguage });
      case 7:
        return t("month.july", { lng: overrideLanguage });
      case 8:
        return t("month.august", { lng: overrideLanguage });
      case 9:
        return t("month.september", { lng: overrideLanguage });
      case 10:
        return t("month.october", { lng: overrideLanguage });
      case 11:
        return t("month.november", { lng: overrideLanguage });
      case 12:
        return t("month.december", { lng: overrideLanguage });
      default:
        break;
    }
  }
  dayWeekToLetter(day) {
    const { t } = this.props;

    switch (day) {
      case 1:
        return {
          dayWeekTranslate: t("date.mon"),
          dayWeekChina: t("date.mon", { lng: "cn" }),
          dayWeekEng: t("date.mon", { lng: "en" }),
        };
      case 2:
        return {
          dayWeekTranslate: t("date.tue"),
          dayWeekChina: t("date.tue", { lng: "cn" }),
          dayWeekEng: t("date.tue", { lng: "en" }),
        };
      case 3:
        return {
          dayWeekTranslate: t("date.wed"),
          dayWeekChina: t("date.wed", { lng: "cn" }),
          dayWeekEng: t("date.wed", { lng: "en" }),
        };
      case 4:
        return {
          dayWeekTranslate: t("date.thu"),
          dayWeekChina: t("date.thu", { lng: "cn" }),
          dayWeekEng: t("date.thu", { lng: "en" }),
        };
      case 5:
        return {
          dayWeekTranslate: t("date.fri"),
          dayWeekChina: t("date.fri", { lng: "cn" }),
          dayWeekEng: t("date.fri", { lng: "en" }),
        };
      case 6:
        return {
          dayWeekTranslate: t("date.sat"),
          dayWeekChina: t("date.sat", { lng: "cn" }),
          dayWeekEng: t("date.sat", { lng: "en" }),
        };
      case 0:
        return {
          dayWeekTranslate: t("date.sun"),
          dayWeekChina: t("date.mon", { lng: "cn" }),
          dayWeekEng: t("date.mon", { lng: "en" }),
        };

      default:
        break;
    }
  }
  totXauConGiap() {
    const chiNgay = this.state.chiDay;
    const chi = Const.Chi;
    if (!chiNgay) {
      return [];
    }
    switch (chiNgay) {
      case Const.Chi.Ty.name:
        return [chi.Than, chi.Ty, chi.Thin, chi.Suu];
      case Const.Chi.Suu.name:
        return [chi.Ty, chi.Ti, chi.Dau, chi.Suu];
      case Const.Chi.Dan.name:
        return [chi.Dan, chi.Ngo, chi.Tuat, chi.Hoi];
      case Const.Chi.Mao.name:
        return [chi.Hoi, chi.Mao, chi.Mui, chi.Tuat];
      case Const.Chi.Thin.name:
        return [chi.Than, chi.Ty, chi.Dau];
      case Const.Chi.Ti.name:
        return [chi.Ti, chi.Dau, chi.Suu];
      case Const.Chi.Ngo.name:
        return [chi.Dan, chi.Tuat, chi.Mui];
      case Const.Chi.Mui.name:
        return [chi.Hoi, chi.Mao, chi.Mui, chi.Ngo];
      case Const.Chi.Than.name:
        return [chi.Than, chi.Ty, chi.Thin];
      case Const.Chi.Dau.name:
        return [chi.Ti, chi.Dau, chi.Suu, chi.Thin];
      case Const.Chi.Tuat.name:
        return [chi.Dan, chi.Ngo, chi.Tuat, chi.Mao];
      case Const.Chi.Hoi.name:
        return [chi.Mao, chi.Mui, chi.Dan];
      default:
        break;
    }
  }

  dayToChina(date) {
    switch (date) {
      case 1:
        return "初一";
      case 2:
        return "初二";
      case 3:
        return "初三";
      case 4:
        return "初四";
      case 5:
        return "初五";
      case 6:
        return "初六";
      case 7:
        return "初七";
      case 8:
        return "初八";
      case 9:
        return "初九";
      case 10:
        return "初十";
      case 11:
        return "十一";
      case 12:
        return "十二";
      case 13:
        return "十三";
      case 14:
        return "十四";
      case 15:
        return "十五";
      case 16:
        return "十六";
      case 17:
        return "十七";
      case 18:
        return "十八";
      case 19:
        return "十九";
      case 20:
        return "二十";
      case 21:
        return "廿一";
      case 22:
        return "廿二";
      case 23:
        return "廿三";
      case 24:
        return "廿四";
      case 25:
        return "廿五";
      case 26:
        return "廿六";
      case 27:
        return "廿七";
      case 28:
        return "廿八";
      case 29:
        return "廿九";
      case 30:
        return "三十";
      case 31:
        return "卅一";

      default:
        break;
    }
  }
  translateCanChi(Can, Chi, isChina) {
    const { t } = this.props;

    const can = Const.CanArr.find((v) => v.name === Can);
    const chi = Const.ChiArr.find((v) => v.name === Chi);
    return `${can ? t(can?.key, { lng: isChina ? "cn" : "" }) : ""} ${
      chi ? t(chi?.key, { lng: isChina ? "cn" : "" }) : ""
    }`;
  }

  translateTietKhi(textTietKhi, isChina) {
    const tietKhiLowerCase = textTietKhi?.toLowerCase();
    const { t } = this.props;

    switch (tietKhiLowerCase) {
      case "lập xuân":
        return t("tiet-khi.lap-xuan", { lng: isChina ? "cn" : "" });
      case "vũ thủy":
        return t("tiet-khi.vu-thuy", { lng: isChina ? "cn" : "" });

      case "kinh trập":
        return t("tiet-khi.kinh-trap", { lng: isChina ? "cn" : "" });

      case "xuân phân":
        return t("tiet-khi.xuan-phan", { lng: isChina ? "cn" : "" });

      case "thanh minh":
        return t("tiet-khi.thanh-minh", { lng: isChina ? "cn" : "" });

      case "cốc vũ":
        return t("tiet-khi.coc-vu", { lng: isChina ? "cn" : "" });

      case "lập hạ":
        return t("tiet-khi.lap-ha", { lng: isChina ? "cn" : "" });

      case "tiểu mãn":
        return t("tiet-khi.tieu-man", { lng: isChina ? "cn" : "" });

      case "mang chủng":
        return t("tiet-khi.mang-chung", { lng: isChina ? "cn" : "" });

      case "hạ chí":
        return t("tiet-khi.ha-chi", { lng: isChina ? "cn" : "" });

      case "tiểu thử":
        return t("tiet-khi.tieu-thu", { lng: isChina ? "cn" : "" });

      case "đại thử":
        return t("tiet-khi.dai-thu", { lng: isChina ? "cn" : "" });

      case "lập thu":
        return t("tiet-khi.lap-thu", { lng: isChina ? "cn" : "" });

      case "xử thử":
        return t("tiet-khi.xu-thu", { lng: isChina ? "cn" : "" });

      case "bạch lộ":
        return t("tiet-khi.bach-lo", { lng: isChina ? "cn" : "" });

      case "thu phân":
        return t("tiet-khi.thu-phan", { lng: isChina ? "cn" : "" });

      case "hàn lộ":
        return t("tiet-khi.han-lo", { lng: isChina ? "cn" : "" });

      case "sương giáng":
        return t("tiet-khi.suong-giang", { lng: isChina ? "cn" : "" });

      case "lập đông":
        return t("tiet-khi.lap-dong", { lng: isChina ? "cn" : "" });

      case "tiểu tuyết":
        return t("tiet-khi.tieu-tuyet", { lng: isChina ? "cn" : "" });

      case "đại tuyết":
        return t("tiet-khi.dai-tuyet", { lng: isChina ? "cn" : "" });

      case "đông chí":
        return t("tiet-khi.dong-chi", { lng: isChina ? "cn" : "" });

      case "tiểu hàn":
        return t("tiet-khi.tieu-han", { lng: isChina ? "cn" : "" });

      case "đại hàn":
        return t("tiet-khi.dai-han", { lng: isChina ? "cn" : "" });

      default:
        break;
    }
  }

  translateNapAm(napAmString, isChina) {
    const { t } = this.props;

    switch (napAmString) {
      case "Bạch Lạp Kim":
        return t("nap-am.bach-lap-kim", { lng: isChina ? "cn" : "" });
      case "Bích Thượng Thổ":
        return t("nap-am.bich-thuong-tho", { lng: isChina ? "cn" : "" });

      case "Bình Địa Mộc":
        return t("nap-am.binh-dia-moc", { lng: isChina ? "cn" : "" });

      case "Dương Liễu Mộc":
        return t("nap-am.duong-lieu-moc", { lng: isChina ? "cn" : "" });

      case "Đại Dịch Thổ":
        return t("nap-am.dai-dich-tho", { lng: isChina ? "cn" : "" });

      case "Đại Hải Thủy":
        return t("nap-am.dai-hai-thuy", { lng: isChina ? "cn" : "" });

      case "Đại Khê Thủy":
        return t("nap-am.dai-khe-thuy", { lng: isChina ? "cn" : "" });

      case "Đại Lâm Mộc":
        return t("nap-am.dai-lam-moc", { lng: isChina ? "cn" : "" });

      case "Giản Hạ Thủy":
        return t("nap-am.gian-ha-thuy", { lng: isChina ? "cn" : "" });

      case "Hải Trung Kim":
        return t("nap-am.hai-trung-kim", { lng: isChina ? "cn" : "" });

      case "Kiếm Phong Kim":
        return t("nap-am.kiem-phong-kim", { lng: isChina ? "cn" : "" });

      case "Kim Bạc Kim":
        return t("nap-am.kim-bac-kim", { lng: isChina ? "cn" : "" });

      case "Lộ Bàng Thổ":
        return t("nap-am.lo-bang-tho", { lng: isChina ? "cn" : "" });

      case "Lư Trung Hỏa":
        return t("nap-am.lu-trung-hoa", { lng: isChina ? "cn" : "" });

      case "Ốc Thượng Thổ":
        return t("nap-am.oc-thuong-tho", { lng: isChina ? "cn" : "" });

      case "Phúc Đăng Hỏa":
        return t("nap-am.phuc-dang-hoa", { lng: isChina ? "cn" : "" });

      case "Sa Trung Kim":
        return t("nap-am.sa-trung-kim", { lng: isChina ? "cn" : "" });

      case "Sa Trung Thổ":
        return t("nap-am.sa-trung-tho", { lng: isChina ? "cn" : "" });

      case "Sơn Đầu Hỏa":
        return t("nap-am.son-dau-hoa", { lng: isChina ? "cn" : "" });

      case "Sơn Hạ Hỏa":
        return t("nap-am.son-ha-hoa", { lng: isChina ? "cn" : "" });

      case "Tang Đố Mộc":
        return t("nap-am.tang-do-moc", { lng: isChina ? "cn" : "" });

      case "Tích Lịch Hỏa":
        return t("nap-am.tich-lich-hoa", { lng: isChina ? "cn" : "" });

      case "Tùng Bách Mộc":
        return t("nap-am.tung-bach-moc", { lng: isChina ? "cn" : "" });

      case "Tuyền Trung Thủy":
        return t("nap-am.tuyen-trung-thuy", { lng: isChina ? "cn" : "" });

      case "Thạch Lựu Mộc":
        return t("nap-am.thach-luu-moc", { lng: isChina ? "cn" : "" });

      case "Thành Đầu Thổ":
        return t("nap-am.thanh-dau-tho", { lng: isChina ? "cn" : "" });

      case "Thiên Hà Thủy":
        return t("nap-am.thien-ha-thuy", { lng: isChina ? "cn" : "" });

      case "Thiên Thượng Hỏa":
        return t("nap-am.thien-thuong-hoa", { lng: isChina ? "cn" : "" });

      case "Thoa Xuyến Kim":
        return t("nap-am.thoa-xuyen-kim", { lng: isChina ? "cn" : "" });

      case "Trường Lưu Thủy":
        return t("nap-am.truong-luu-thuy", { lng: isChina ? "cn" : "" });

      default:
        break;
    }
  }

  translateHuong(huongString, isChina) {
    const { t } = this.props;

    const lowerCaseString = huongString?.toLowerCase();
    switch (lowerCaseString) {
      case "đông":
        return t("huong.dong", { lng: isChina ? "cn" : "" });
      case "tây":
        return t("huong.tay", { lng: isChina ? "cn" : "" });
      case "nam":
        return t("huong.nam", { lng: isChina ? "cn" : "" });
      case "bắc":
        return t("huong.bac", { lng: isChina ? "cn" : "" });
      case "đông bắc":
        return t("huong.dong-bac", { lng: isChina ? "cn" : "" });
      case "tây nam":
        return t("huong.tay-nam", { lng: isChina ? "cn" : "" });
      case "đông nam":
        return t("huong.dong-nam", { lng: isChina ? "cn" : "" });
      case "tây bắc":
        return t("huong.tay-bac", { lng: isChina ? "cn" : "" });

      default:
        break;
    }
  }
  getCanChiTranslate(canChiString, isChina) {
    const { t } = this.props;

    if (canChiString) {
      const lowerCaseString = canChiString?.toLowerCase();
      const arrCharacter = lowerCaseString?.split(" ");
      const can = arrCharacter[arrCharacter.length > 2 ? [1] : [0]];
      const chi = arrCharacter[arrCharacter.length > 2 ? [2] : [1]];
      const dataCan = Const.CanArr.find((v) => v.name?.toLowerCase() === can);
      const dataChi = Const.ChiArr.find((v) => v.name?.toLowerCase() === chi);

      return `${dataCan ? t(dataCan?.key, { lng: isChina ? "cn" : "" }) : ""} ${
        dataChi ? t(dataChi?.key, { lng: isChina ? "cn" : "" }) : ""
      }`;
    } else {
      return;
    }
  }
  //nên thông dụng.
  // Dâng Biểu Sớ, Họp Thân Hữu, Xuất Hành, Cưới Gả, Thu  Nạp Người, Xuất Hành, Tu Tạo Động Thổ, Khai Trương, Lập Ước Giao Dịch, Mở Kho Xuất Tiền Hàng, Dỡ Nhà Phá Tường, An Táng, Cải Táng.
  translateHoangDao(ngayHoangDao, isChina) {
    const { t } = this.props;

    if (ngayHoangDao) {
      switch (ngayHoangDao.code) {
        case 1:
          return {
            ngayHoangDao: t("ngay-hoang-dao.thanh-long", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 2:
          return {
            ngayHoangDao: t("ngay-hoang-dao.minh-duong", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 3:
          return {
            ngayHoangDao: t("ngay-hoang-dao.kim-quy", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 4:
          return {
            ngayHoangDao: t("ngay-hoang-dao.thien-duc", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 5:
          return {
            ngayHoangDao: t("ngay-hoang-dao.ngoc-duong", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 6:
          return {
            ngayHoangDao: t("ngay-hoang-dao.tu-menh", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 7:
          return {
            ngayHoangDao: t("ngay-hoang-dao.thien-hinh", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 8:
          return {
            ngayHoangDao: t("ngay-hoang-dao.chu-tuoc", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 9:
          return {
            ngayHoangDao: t("ngay-hoang-dao.bach-ho", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 10:
          return {
            ngayHoangDao: t("ngay-hoang-dao.thien-lao", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 11:
          return {
            ngayHoangDao: t("ngay-hoang-dao.huyen-vu", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };
        case 12:
          return {
            ngayHoangDao: t("ngay-hoang-dao.cau-tran", {
              lng: isChina ? "cn" : "",
            }),
            isHoangDao: ngayHoangDao.isHoangDao,
          };

        default:
          {
          }
          break;
      }
    }
  }
  translateNguHanh(nguHanh, isChina) {
    const { t } = this.props;

    switch (nguHanh) {
      case "Kim":
        return t("ngu-hanh.hanh-kim", { lng: isChina ? "cn" : "" });
      case "Mộc":
        return t("ngu-hanh.hanh-moc", { lng: isChina ? "cn" : "" });
      case "Thủy":
        return t("ngu-hanh.hanh-thuy", { lng: isChina ? "cn" : "" });
      case "Hỏa":
        return t("ngu-hanh.hanh-hoa", { lng: isChina ? "cn" : "" });
      case "Thổ":
        return t("ngu-hanh.hanh-tho", { lng: isChina ? "cn" : "" });

      default:
        break;
    }
  }
  translate28Star(star, isChina) {
    const { t } = this.props;

    switch (star.code) {
      case 0:
        return {
          sao: t("nhi-thap-bat-sao.giac", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 1:
        return {
          sao: t("nhi-thap-bat-sao.cang", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 2:
        return {
          sao: t("nhi-thap-bat-sao.de", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 3:
        return {
          sao: t("nhi-thap-bat-sao.phong", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 4:
        return {
          sao: t("nhi-thap-bat-sao.tam", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 5:
        return {
          sao: t("nhi-thap-bat-sao.vi", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 6:
        return {
          sao: t("nhi-thap-bat-sao.co", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 7:
        return {
          sao: t("nhi-thap-bat-sao.dau", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 8:
        return {
          sao: t("nhi-thap-bat-sao.nguu", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 9:
        return {
          sao: t("nhi-thap-bat-sao.nu", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 10:
        return {
          sao: t("nhi-thap-bat-sao.hu", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 11:
        return {
          sao: t("nhi-thap-bat-sao.nguy", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 12:
        return {
          sao: t("nhi-thap-bat-sao.that", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 13:
        return {
          sao: t("nhi-thap-bat-sao.bich", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 14:
        return {
          sao: t("nhi-thap-bat-sao.khue", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 15:
        return {
          sao: t("nhi-thap-bat-sao.lau", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 16:
        return {
          sao: t("nhi-thap-bat-sao.vi5", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 17:
        return {
          sao: t("nhi-thap-bat-sao.mao", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 18:
        return {
          sao: t("nhi-thap-bat-sao.tat", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 19:
        return {
          sao: t("nhi-thap-bat-sao.chuy", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 20:
        return {
          sao: t("nhi-thap-bat-sao.sam", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 21:
        return {
          sao: t("nhi-thap-bat-sao.tinh3", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 22:
        return {
          sao: t("nhi-thap-bat-sao.quy", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 23:
        return {
          sao: t("nhi-thap-bat-sao.lieu", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 24:
        return {
          sao: t("nhi-thap-bat-sao.tinh", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 25:
        return {
          sao: t("nhi-thap-bat-sao.truong", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 26:
        return {
          sao: t("nhi-thap-bat-sao.duc", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };
      case 27:
        return {
          sao: t("nhi-thap-bat-sao.chan", { lng: isChina ? "cn" : "" }),
          isGood: star.isGood,
        };

      default:
        break;
    }
  }
  translateNhiThapKienTru(truc, isChina) {
    const { t } = this.props;

    switch (truc.truc) {
      case "Kiến":
        return {
          truc: t("thap-nhi-truc.Kien", { lng: isChina ? "cn" : "" }),
          isGood: true,
        };
      case "Mãn":
        return {
          truc: t("thap-nhi-truc.Man", { lng: isChina ? "cn" : "" }),
          isGood: true,
        };

      case "Định":
        return {
          truc: t("thap-nhi-truc.Dinh", { lng: isChina ? "cn" : "" }),
          isGood: true,
        };

      case "Thành":
        return {
          truc: t("thap-nhi-truc.Thanh", { lng: isChina ? "cn" : "" }),
          isGood: true,
        };

      case "Khai":
        return {
          truc: t("thap-nhi-truc.Khai", { lng: isChina ? "cn" : "" }),
          isGood: true,
        };

      case "Bế":
        return {
          truc: t("thap-nhi-truc.Be", { lng: isChina ? "cn" : "" }),
          isGood: false,
        };

      case "Trừ":
        return {
          truc: t("thap-nhi-truc.Tru", { lng: isChina ? "cn" : "" }),
          isGood: false,
        };

      case "Bình":
        return {
          truc: t("thap-nhi-truc.Binh", { lng: isChina ? "cn" : "" }),
          isGood: false,
        };

      case "Chấp":
        return {
          truc: t("thap-nhi-truc.Chap", { lng: isChina ? "cn" : "" }),
          isGood: false,
        };

      case "Phá":
        return {
          truc: t("thap-nhi-truc.Pha", { lng: isChina ? "cn" : "" }),
          isGood: false,
        };

      case "Nguy":
        return {
          truc: t("thap-nhi-truc.Nguy", { lng: isChina ? "cn" : "" }),
          isGood: false,
        };

      case "Thu":
        return {
          truc: t("thap-nhi-truc.Thu", { lng: isChina ? "cn" : "" }),
          isGood: false,
        };

      default:
        break;
    }
  }
  translateLetter(letter, isChina) {
    const { t } = this.props;
    const letterLowerCase = letter.toLowerCase();
    switch (letterLowerCase) {
      case "âm đức":
        return t("cat-than.am-duc", { lng: isChina ? "cn" : "" });
      case "âm quý":
        return t("cat-than.am-quy", { lng: isChina ? "cn" : "" });
      case "bảo quang":
        return t("cat-than.bao-quang", { lng: isChina ? "cn" : "" });
      case "bất tương":
        return t("cat-than.bat-tuong", { lng: isChina ? "cn" : "" });
      case "cát kì":
        return t("cat-than.cat-ki", { lng: isChina ? "cn" : "" });
      case "dân nhật":
        return t("cat-than.dan-nhat", { lng: isChina ? "cn" : "" });
      case "dịch mã":
        return t("cat-than.dich-ma", { lng: isChina ? "cn" : "" });
      case "dương đức":
        return t("cat-than.duong-duc", { lng: isChina ? "cn" : "" });
      case "dương quý":
        return t("cat-than.duong-quy", { lng: isChina ? "cn" : "" });
      case "đại hồng sa":
        return t("cat-than.dai-hong-sa", { lng: isChina ? "cn" : "" });
      case "địa tài":
        return t("cat-than.dia-tai", { lng: isChina ? "cn" : "" });
      case "địa thương":
        return t("cat-than.dia-thuong", { lng: isChina ? "cn" : "" });
      case "giải thần":
        return t("cat-than.giai-than", { lng: isChina ? "cn" : "" });
      case "hỉ thần":
        return t("cat-than.hi-than", { lng: isChina ? "cn" : "" });
      case "hoạt yếu":
        return t("cat-than.hoat-yeu", { lng: isChina ? "cn" : "" });
      case "ích hậu":
        return t("cat-than.ich-hau", { lng: isChina ? "cn" : "" });
      case "kim đường":
        return t("cat-than.kim-duong", { lng: isChina ? "cn" : "" });
      case "kim quĩ":
        return t("cat-than.kim-quy", { lng: isChina ? "cn" : "" });
      case "kính an":
        return t("cat-than.kinh-an", { lng: isChina ? "cn" : "" });
      case "lâm nhật":
        return t("cat-than.lam-nhat", { lng: isChina ? "cn" : "" });
      case "lộc khố":
        return t("cat-than.loc-kho", { lng: isChina ? "cn" : "" });
      case "lục hợp":
        return t("cat-than.luc-hop", { lng: isChina ? "cn" : "" });
      case "lục nghi":
        return t("cat-than.luc-nghi", { lng: isChina ? "cn" : "" });
      case "mẫu thương":
        return t("cat-than.mau-thuong", { lng: isChina ? "cn" : "" });
      case "minh đường":
        return t("cat-than.minh-duong", { lng: isChina ? "cn" : "" });
      case "minh tinh":
        return t("cat-than.minh-tinh", { lng: isChina ? "cn" : "" });
      case "ngọc đường":
        return t("cat-than.ngoc-duong", { lng: isChina ? "cn" : "" });
      case "ngọc thủ":
        return t("cat-than.ngoc-thu", { lng: isChina ? "cn" : "" });
      case "ngọc vũ":
        return t("cat-than.ngoc-vu", { lng: isChina ? "cn" : "" });
      case "ngũ hợp":
        return t("cat-than.ngu-hop", { lng: isChina ? "cn" : "" });
      case "ngũ phú":
        return t("cat-than.ngu-phu", { lng: isChina ? "cn" : "" });
      case "nguyệt ân":
        return t("cat-than.nguyet-an", { lng: isChina ? "cn" : "" });
      case "nguyệt đức":
        return t("cat-than.nguyet-duc", { lng: isChina ? "cn" : "" });
      case "nguyệt không":
        return t("cat-than.nguyet-khong", { lng: isChina ? "cn" : "" });
      case "nguyệt tài":
        return t("cat-than.nguyet-tai", { lng: isChina ? "cn" : "" });
      case "ô phệ đối":
        return t("cat-than.o-phe-doi", { lng: isChina ? "cn" : "" });
      case "phúc đức":
        return t("cat-than.phuc-duc", { lng: isChina ? "cn" : "" });
      case "phúc hậu":
        return t("cat-than.phuc-hau", { lng: isChina ? "cn" : "" });
      case "phúc sinh":
        return t("cat-than.phuc-sinh", { lng: isChina ? "cn" : "" });
      case "phúc tinh":
        return t("cat-than.phuc-tinh", { lng: isChina ? "cn" : "" });
      case "quan nhật":
        return t("cat-than.quan-nhat", { lng: isChina ? "cn" : "" });
      case "sinh khí":
        return t("cat-than.sinh-khi", { lng: isChina ? "cn" : "" });
      case "tam hợp":
        return t("cat-than.tam-hop", { lng: isChina ? "cn" : "" });
      case "tục thế":
        return t("cat-than.tuc-the", { lng: isChina ? "cn" : "" });
      case "tuế can hợp":
        return t("cat-than.tue-can-hop", { lng: isChina ? "cn" : "" });
      case "tuế đức":
        return t("cat-than.tue-duc", { lng: isChina ? "cn" : "" });
      case "tuế đức hợp":
        return t("cat-than.tue-duc-hop", { lng: isChina ? "cn" : "" });
      case "tuế lộc":
        return t("cat-than.tue-loc", { lng: isChina ? "cn" : "" });
      case "tư mệnh":
        return t("cat-than.tu-menh", { lng: isChina ? "cn" : "" });
      case "tứ tướng":
        return t("cat-than.tu-tuong", { lng: isChina ? "cn" : "" });
      case "tướng nhật":
        return t("cat-than.tuong-nhat", { lng: isChina ? "cn" : "" });
      case "thanh long":
        return t("cat-than.thanh-long", { lng: isChina ? "cn" : "" });
      case "thánh tâm":
        return t("cat-than.thanh-tam", { lng: isChina ? "cn" : "" });
      case "thiên ân":
        return t("cat-than.thien-an", { lng: isChina ? "cn" : "" });
      case "thiên đức":
        return t("cat-than.thien-duc", { lng: isChina ? "cn" : "" });
      case "thiên đức hợp":
        return t("cat-than.thien-duc-hop", { lng: isChina ? "cn" : "" });
      case "thiên hậu":
        return t("cat-than.thien-hau", { lng: isChina ? "cn" : "" });
      case "thiên hỷ":
        return t("cat-than.thien-hy", { lng: isChina ? "cn" : "" });
      case "thiên mã":
        return t("cat-than.thien-ma", { lng: isChina ? "cn" : "" });
      case "thiên phú":
        return t("cat-than.thien-phu", { lng: isChina ? "cn" : "" });
      case "thiên quan":
        return t("cat-than.thien-quan", { lng: isChina ? "cn" : "" });
      case "thiên quý":
        return t("cat-than.thien-quy", { lng: isChina ? "cn" : "" });
      case "thiên tài":
        return t("cat-than.thien-tai", { lng: isChina ? "cn" : "" });
      case "thiên thương":
        return t("cat-than.thien-thuong", { lng: isChina ? "cn" : "" });
      case "thiên vu":
        return t("cat-than.thien-vu", { lng: isChina ? "cn" : "" });
      case "thiên xá":
        return t("cat-than.thien-xa", { lng: isChina ? "cn" : "" });
      case "thiên y":
        return t("cat-than.thien-y", { lng: isChina ? "cn" : "" });
      case "thời âm":
        return t("cat-than.thoi-am", { lng: isChina ? "cn" : "" });
      case "thời dương":
        return t("cat-than.thoi-duong", { lng: isChina ? "cn" : "" });
      case "thời đức":
        return t("cat-than.thoi-duc", { lng: isChina ? "cn" : "" });
      case "thủ nhật":
        return t("cat-than.thu-nhat", { lng: isChina ? "cn" : "" });
      case "trừ thần":
        return t("cat-than.tru-than", { lng: isChina ? "cn" : "" });
      case "vũ hợp":
        return t("cat-than.vu-hop", { lng: isChina ? "cn" : "" });
      case "vương nhật":
        return t("cat-than.vuong-nhat", { lng: isChina ? "cn" : "" });
      case "yếu an":
        return t("cat-than.yeu-an", { lng: isChina ? "cn" : "" });
      case "nguyệt đức hợp":
        return t("cat-than.nguyet-duc-hop", { lng: isChina ? "cn" : "" });
      //----\\
      //------\\
      //--------\\
      case "bạch hổ":
        return t("hung-than.bach-ho", { lng: isChina ? "cn" : "" });
      case "bàng phế":
        return t("hung-than.bang-phe", { lng: isChina ? "cn" : "" });
      case "báo vĩ":
        return t("hung-than.bao-vi", { lng: isChina ? "cn" : "" });
      case "bát chuyên":
        return t("hung-than.bat-chuyen", { lng: isChina ? "cn" : "" });
      case "bát phong":
        return t("hung-than.bat-phong", { lng: isChina ? "cn" : "" });
      case "bát toà":
        return t("hung-than.bat-toa", { lng: isChina ? "cn" : "" });
      case "bệnh phù":
        return t("hung-than.benh-phu", { lng: isChina ? "cn" : "" });
      case "câu lăng":
        return t("hung-than.cau-lang", { lng: isChina ? "cn" : "" });
      case "câu trần":
        return t("hung-than.cau-tran", { lng: isChina ? "cn" : "" });
      case "cửu khảm":
        return t("hung-than.cuu-kim", { lng: isChina ? "cn" : "" });
      case "cửu không":
        return t("hung-than.cuu-khong", { lng: isChina ? "cn" : "" });
      case "cửu tiêu":
        return t("hung-than.cuu-tieu", { lng: isChina ? "cn" : "" });
      case "chí tử":
        return t("hung-than.chi-tu", { lng: isChina ? "cn" : "" });
      case "chích thoái":
        return t("hung-than.chich-thoai", { lng: isChina ? "cn" : "" });
      case "chiêu dao":
        return t("hung-than.chieu-dao", { lng: isChina ? "cn" : "" });
      case "chu tước":
        return t("hung-than.chu-tuoc", { lng: isChina ? "cn" : "" });
      case "du họa":
        return t("hung-than.du-hoa", { lng: isChina ? "cn" : "" });
      case "dương sai":
        return t("hung-than.duong-sai", { lng: isChina ? "cn" : "" });
      case "đại bại":
        return t("hung-than.dai-bao", { lng: isChina ? "cn" : "" });
      case "đại hao":
        return t("hung-than.dai-hao", { lng: isChina ? "cn" : "" });
      case "đại hoạ":
        return t("hung-than.dai-hoa", { lng: isChina ? "cn" : "" });
      case "đại sát":
        return t("hung-than.dai-sat", { lng: isChina ? "cn" : "" });
      case "đại tướng quân":
        return t("hung-than.dai-tuong", { lng: isChina ? "cn" : "" });
      case "đại thời":
        return t("hung-than.dai-thoi", { lng: isChina ? "cn" : "" });
      case "đao châm":
        return t("hung-than.dao-cham", { lng: isChina ? "cn" : "" });
      case "địa hỏa":
        return t("hung-than.dia-hoa", { lng: isChina ? "cn" : "" });
      case "địa kỵ":
        return t("hung-than.dia-ky", { lng: isChina ? "cn" : "" });
      case "địa ôn":
        return t("hung-than.dia-on", { lng: isChina ? "cn" : "" });
      case "địa tặc":
        return t("hung-than.dia-tac", { lng: isChina ? "cn" : "" });
      case "hà khôi":
        return t("hung-than.ha-khoi", { lng: isChina ? "cn" : "" });
      case "hàm trì":
        return t("hung-than.ham-tri", { lng: isChina ? "cn" : "" });
      case "hoàng phan":
        return t("hung-than.hoang-phan", { lng: isChina ? "cn" : "" });
      case "hoàng sa":
        return t("hung-than.hoang-sa", { lng: isChina ? "cn" : "" });
      case "hoang vu":
        return t("hung-than.hoang-vu", { lng: isChina ? "cn" : "" });
      case "huyền vũ":
        return t("hung-than.huyen-vu", { lng: isChina ? "cn" : "" });
      case "huyết chi":
        return t("hung-than.huyet-chi", { lng: isChina ? "cn" : "" });
      case "huyết kỵ":
        return t("hung-than.huye0ky", { lng: isChina ? "cn" : "" });
      case "kiếp sát":
        return t("hung-than.kiep-sat", { lng: isChina ? "cn" : "" });
      case "khô tiêu":
        return t("hung-than.kho-tieu", { lng: isChina ? "cn" : "" });
      case "lâm nhật":
        return t("hung-than.lam-nhat", { lng: isChina ? "cn" : "" });
      case "lỗ ban sát":
        return t("hung-than.lo-ban", { lng: isChina ? "cn" : "" });
      case "lôi Công":
        return t("hung-than.loi-cong", { lng: isChina ? "cn" : "" });
      case "ngoã toái":
        return t("hung-than.ngoa-toai", { lng: isChina ? "cn" : "" });
      case "ngũ hư":
        return t("hung-than.ngu-hu", { lng: isChina ? "cn" : "" });
      case "ngũ li":
        return t("hung-than.ngu-li", { lng: isChina ? "cn" : "" });
      case "ngũ quỷ":
        return t("hung-than.ngu-quy", { lng: isChina ? "cn" : "" });
      case "nguyệt chuyển":
        return t("hung-than.nguyet-chuyen", { lng: isChina ? "cn" : "" });
      case "nguyêt hại":
        return t("hung-than.nguyet-hai", { lng: isChina ? "cn" : "" });
      case "nguyệt hình":
        return t("hung-than.nguyet-hinh", { lng: isChina ? "cn" : "" });
      case "nguyệt hoả":
        return t("hung-than.nguyet-hoa", { lng: isChina ? "cn" : "" });
      case "nguyệt hư":
        return t("hung-than.nguyet-hu", { lng: isChina ? "cn" : "" });
      case "nguyệt kiến":
        return t("hung-than.nguyet-kien", { lng: isChina ? "cn" : "" });
      case "nguyệt phá":
        return t("hung-than.nguyet-pha", { lng: isChina ? "cn" : "" });
      case "nguyệt sát":
        return t("hung-than.nguyet-sat", { lng: isChina ? "cn" : "" });
      case "nguyệt yếm":
        return t("hung-than.nguyet-yem", { lng: isChina ? "cn" : "" });
      case "nhân cách":
        return t("hung-than.nhan-cach", { lng: isChina ? "cn" : "" });
      case "phi liêm":
        return t("hung-than.phi-liem", { lng: isChina ? "cn" : "" });
      case "phi ma":
        return t("hung-than.phi-ma", { lng: isChina ? "cn" : "" });
      case "phu sát":
        return t("hung-than.phu-sat", { lng: isChina ? "cn" : "" });
      case "phục binh":
        return t("hung-than.phuc-binh", { lng: isChina ? "cn" : "" });
      case "phục nhật":
        return t("hung-than.phuc-nhat", { lng: isChina ? "cn" : "" });
      case "qui kị":
        return t("hung-than.qui-ki", { lng: isChina ? "cn" : "" });
      case "tai sát":
        return t("hung-than.tai-sat", { lng: isChina ? "cn" : "" });
      case "tiểu hao":
        return t("hung-than.tieu-hao", { lng: isChina ? "cn" : "" });
      case "tiểu hồng sa":
        return t("hung-than.tieu-hong-sa", { lng: isChina ? "cn" : "" });
      case "tiểu thời":
        return t("hung-than.tieu-thoi", { lng: isChina ? "cn" : "" });
      case "tuế bạc":
        return t("hung-than.tue-bac", { lng: isChina ? "cn" : "" });
      case "tuế hình":
        return t("hung-than.tue-hinh", { lng: isChina ? "cn" : "" });
      case "tuế phá":
        return t("hung-than.tue-pha", { lng: isChina ? "cn" : "" });
      case "tuế sát":
        return t("hung-than.tue-sat", { lng: isChina ? "cn" : "" });
      case "tứ đại mộ":
        return t("hung-than.tu-dai-mo", { lng: isChina ? "cn" : "" });
      case "tử khí":
        return t("hung-than.tu-khi", { lng: isChina ? "cn" : "" });
      case "tứ phế":
        return t("hung-than.tu-phe", { lng: isChina ? "cn" : "" });
      case "tử phù":
        return t("hung-than.tu-phu", { lng: isChina ? "cn" : "" });
      case "thái tuế":
        return t("hung-than.thai-tue", { lng: isChina ? "cn" : "" });
      case "thần cách":
        return t("hung-than.than-cach", { lng: isChina ? "cn" : "" });
      case "thiên bổng":
        return t("hung-than.thien-bong", { lng: isChina ? "cn" : "" });
      case "thiên cẩu":
        return t("hung-than.thien-cau", { lng: isChina ? "cn" : "" });
      case "thiên cương":
        return t("hung-than.thien-cuong", { lng: isChina ? "cn" : "" });
      case "thiên địa chuyển":
        return t("hung-than.thien-dia-chuyen", { lng: isChina ? "cn" : "" });
      case "thiên địa trù":
        return t("hung-than.thien-dia-tru", { lng: isChina ? "cn" : "" });
      case "thiên hình":
        return t("hung-than.thien-hinh", { lng: isChina ? "cn" : "" });
      case "thiên hỏa":
        return t("hung-than.thien-hoa", { lng: isChina ? "cn" : "" });
      case "thiên khôi":
        return t("hung-than.thien khoi", { lng: isChina ? "cn" : "" });
      case "thiên lại":
        return t("hung-than.thien-lai", { lng: isChina ? "cn" : "" });
      case "thiên lao":
        return t("hung-than.thien-lao", { lng: isChina ? "cn" : "" });
      case "thiên ngục":
        return t("hung-than.thien-nguc", { lng: isChina ? "cn" : "" });
      case "thiên ôn":
        return t("hung-than.thien-on", { lng: isChina ? "cn" : "" });
      case "thiên sứ":
        return t("hung-than.thien-su", { lng: isChina ? "cn" : "" });
      case "thiên tặc":
        return t("hung-than.thien-tac", { lng: isChina ? "cn" : "" });
      case "thổ cấm":
        return t("hung-than.tho-cam", { lng: isChina ? "cn" : "" });
      case "thổ phù":
        return t("hung-than.tho-phu", { lng: isChina ? "cn" : "" });
      case "trùng nguyệt":
        return t("hung-than.trung-nguyet", { lng: isChina ? "cn" : "" });
      case "trùng nhật":
        return t("hung-than.trung-nhat", { lng: isChina ? "cn" : "" });
      case "trùng tang":
        return t("hung-than.trung-tang", { lng: isChina ? "cn" : "" });
      case "ương bại":
        return t("hung-than.uong-bai", { lng: isChina ? "cn" : "" });
      case "vãng vong":
        return t("hung-than.vang-vong", { lng: isChina ? "cn" : "" });
      case "yếm đối":
        return t("hung-than.yem-doi", { lng: isChina ? "cn" : "" });

      case "an táng":
        return t("nen-khongnen.an-tang", { lng: isChina ? "cn" : "" });
      case "cải táng":
        return t("nen-khongnen.cai-tang", { lng: isChina ? "cn" : "" });
      // data.toLowerCase() === 'động thổ' ||
      // data.toLowerCase() === 'tu tạo động thổ' ||
      case "động thổ":
      case "tu tạo động thổ":
        return t("nen-khongnen.dong-tho", { lng: isChina ? "cn" : "" });
      // data.toLowerCase() === 'giao dịch' ||
      // data.toLowerCase() === 'lập ước giao dịch' ||
      case "giao dịch":
      case "lập ước giao dịch":
        return t("nen-khongnen.giao-dich", { lng: isChina ? "cn" : "" });
      // data.toLowerCase() === 'cưới gả' ||
      // data.toLowerCase() === 'ăn hỏi' ||
      case "cưới gả":
        return t("nen-khongnen.hon-nhan", { lng: isChina ? "cn" : "" });

      // data.toLowerCase() === 'ký kết' ||
      // data.toLowerCase() === 'dâng biểu sớ' ||
      case "ký kết":
        return t("nen-khongnen.ky-ket", { lng: isChina ? "cn" : "" });
      case "dâng biểu sớ":
        return t("nen-khongnen.nop-giay-to", { lng: isChina ? "cn" : "" });
      // data.toLowerCase() === 'khai trương' ||

      case "khai trương":
        return t("nen-khongnen.khai-truong", { lng: isChina ? "cn" : "" });
      // data.toLowerCase() === 'xuất hành' ||

      case "xuất hành":
        return t("nen-khongnen.xuat-hanh", { lng: isChina ? "cn" : "" });
      // data.toLowerCase() === 'mọi việc không nên làm'

      case "mọi việc không nên làm":
        return t("nen-khongnen.moi-viec-khong-nen-lam", {
          lng: isChina ? "cn" : "",
        });
      // data.toLowerCase() === 'mở kho xuất tiền hàng' ||

      case "mở kho xuất tiền hàng":
        return t("nen-khongnen.mo-kho-xuat-tien-hang", {
          lng: isChina ? "cn" : "",
        });

      // data.toLowerCase() === 'dỡ nhà phá tường' ||
      default:
        break;
    }
  }
  translateArrData(Arr) {
    let tempArr = [];
    //limit arr
    if (Arr) {
      Arr.map((v) => {
        if (v && this.translateLetter(v)) {
          tempArr.push({
            translate: this.translateLetter(v),
            translateChina: this.translateLetter(v, true),
          });
        }
      });
    }
    console.log("cuong log", Arr, tempArr);
    return tempArr;
  }
  getFooterColor(color) {
    switch (color) {
      case "#F26C36":
        return footerOrange;
      case "#ED2790":
        return footerPink;
      case "#D4313B":
      case "#EE333F":
        return footerRed;
      case "#008DD0":
        return footerXanhnhat;
      case "#009D53":
        return footerGreen;
      case "#0668B1":
        return footerBlue;
      case "#00ACAF":
        return footerGreenBlue;
      default:
        break;
    }
  }
  getBackGround(color) {
    switch (color) {
      case "#F26C36":
        return backgroundOrange;
      case "#ED2790":
        return backgroundPink;
      case "#D4313B":
      case "#EE333F":
        return backgroundRed;
      case "#008DD0":
        return backgroundXanhnhat;
      case "#009D53":
        return backgroundGreen;
      case "#0668B1":
        return backgroundBlue;
      case "#00ACAF":
        return backgroundGreenBlue;
      default:
        break;
    }
  }
  handleChange = (date) => {
    this.setState({
      date: date,
      momentYear: date.getYear(),
      momentDate: date.getDate(),
      momentMonth: date.getMonth(),
    });
    setTimeout(() => {
      this.onChangeData();
    }, 400);
  };
  shuffle(array) {
    if (array && array.length > 0) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    }
  }
  onBackgroundChange(event) {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({ backgroundImage: URL.createObjectURL(img) });
      // setCustomerPicture(URL.createObjectURL(img));
    }
  }
  showMoreHungThan() {
    const kiengKy = this.state.kiengkytrongngay
      ? this.state.kiengkytrongngay
      : "";
    this.state.itemsToShowHungThan === 2
      ? this.setState({
          itemsToShowHungThan: kiengKy?.hungThan?.split(",").length,
          expandedHungThan: true,
        })
      : this.setState({ itemsToShowHungThan: 2, expandedHungThan: false });
  }
  showMoreCatThan() {
    const kiengKy = this.state.kiengkytrongngay
      ? this.state.kiengkytrongngay
      : "";
    this.state.itemsToShowCatThan === 2
      ? this.setState({
          itemsToShowCatThan: kiengKy?.catThan?.split(",").length,
          expandedCatThan: true,
        })
      : this.setState({ itemsToShowCatThan: 2, expandedCatThan: false });
  }
  render() {
    const { t } = this.props;
    let possibleYear = 2036;
    let possibleYearArr = [];
    for (let i = 1900; i <= possibleYear; i++) {
      possibleYearArr.push(i);
    }
    let monthInYear = [];
    for (let index = 0; index < 12; index++) {
      monthInYear.push(index);
    }
    const hoangDao =
      this.translateHoangDao(this.state.ngayHoangDao, false) || "";
    const hoangDaoChina =
      this.translateHoangDao(this.state.ngayHoangDao, true) || "";

    const star = this.state.star
      ? this.translate28Star(this.state.star, false)
      : "";
    const starChina = this.state.star
      ? this.translate28Star(this.state.star, true)
      : "";

    const napAm = this.state.napAmNgay ? this.state.napAmNgay.napAm : "";
    const kiengKy = this.state.kiengkytrongngay
      ? this.state.kiengkytrongngay
      : "";
    console.log(
      "Quốc Cường ~ file: Home.js ~ line 3026 ~ Home ~ render ~ kiengKy",
      kiengKy
    );
    const shufferArrayCatThan = this.shuffle(kiengKy?.catThan?.split(","));
    const shufferArrayHungThan = this.shuffle(kiengKy?.hungThan?.split(","));
    const translateCatThan = this.translateArrData(
      kiengKy?.catThan?.split(",")
    );
    const translateHungThan = this.translateArrData(
      kiengKy?.hungThan?.split(",")
    );
    const shufferArrayNen = kiengKy?.nen?.split(",");
    const shufferArrayKhongNen = kiengKy?.khongNen?.split(",");
    const translateNen =
      this.translateArrData(kiengKy?.nen?.split(",")).length === 0
        ? shufferArrayNen
        : this.translateArrData(shufferArrayNen);

    const translateKhongNen =
      this.translateArrData(shufferArrayKhongNen).length === 0
        ? shufferArrayKhongNen
        : this.translateArrData(shufferArrayKhongNen);

    const nhiThapKienTru = this.state.nhiThapKienTru
      ? this.translateNhiThapKienTru(this.state.nhiThapKienTru, false)
      : "";
    const nhiThapKienTruChina = this.state.nhiThapKienTru
      ? this.translateNhiThapKienTru(this.state.nhiThapKienTru, true)
      : "";

    const date = new Date(this.state.date);
    const month = date.getMonth();
    const showTietKhiDauThang = this.dataNhiTuTietKhi[month * 2] || [];
    const showTietKhiGiuaThang = this.dataNhiTuTietKhi[month * 2 + 1] || [];
    const characterDay = this.dayWeekToLetter(this.state.date.getDay());

    const getHour = new Date();
    const dateDataYear = this.state.dateData.find((v) => v.header === "Năm");
    const finalCanChiYear = this.translateCanChi(
      dateDataYear.canChi,
      dateDataYear.diaChi,
      false
    );
    const finalCanChiYearChina = this.translateCanChi(
      dateDataYear.canChi,
      dateDataYear.diaChi,
      true
    );
    const dateDataMonth = this.state.dateData.find((v) => v.header === "Tháng");
    const finalCanChiMonth = this.translateCanChi(
      dateDataMonth.canChi,
      dateDataMonth.diaChi,
      false
    );
    const finalCanChiMonthChina = this.translateCanChi(
      dateDataMonth.canChi,
      dateDataMonth.diaChi,
      true
    );
    const dateDataDay = this.state.dateData.find((v) => v.header === "Ngày");
    console.log(
      "Quốc Cường ~ file: Home.js ~ line 2661 ~ Home ~ render ~ dateDataDay",
      dateDataDay
    );
    const finalCanChiDay = this.translateCanChi(
      dateDataDay.canChi,
      dateDataDay.diaChi,
      false
    );
    const finalCanChiDayChina = this.translateCanChi(
      dateDataDay.canChi,
      dateDataDay.diaChi,
      true
    );
    const dateDataHour = this.state.dateData.find((v) => v.header === "Giờ");
    const finalCanChiHour = this.translateCanChi(
      dateDataHour.canChi,
      dateDataHour.diaChi,
      false
    );
    const finalCanChiHourChina = this.translateCanChi(
      dateDataHour.canChi,
      dateDataHour.diaChi,
      true
    );
    const NguHanhNgay = this.translateNguHanh(
      napAm?.split(" ")[napAm?.split(" ").length - 1],
      false
    );
    const NguHanhNgayChina = this.translateNguHanh(
      napAm?.split(" ")[napAm?.split(" ").length - 1],
      true
    );

    const gioTot = dateDataDay.que.gioTot;
    const gioXau = dateDataDay.que.gioXau;

    const canChiXungTuoi1 = this.state.canChiDay?.xungTuoi?.split(",")[0];
    const canChiXungTuoi2 = this.state.canChiDay?.xungTuoi?.split(",")[1];
    const dataXungTuoi1 = this.getCanChiTranslate(canChiXungTuoi1, false);
    const dataXungTuoi1China = this.getCanChiTranslate(canChiXungTuoi1, true);

    const dataXungTuoi2 = this.getCanChiTranslate(canChiXungTuoi2, false);
    const dataXungTuoi2China = this.getCanChiTranslate(canChiXungTuoi2, true);
    const { colorFontPicker1, colorFontPicker2, isMissingDate } = this.state;
    const textMissingLunarDate = isMissingDate
      ? ` (${t("thieu")})`
      : ` (${t("du")})`;
    const textMissingLunarDateCN = isMissingDate
      ? `${t("thieu", { lng: "cn" })}`
      : `${t("du", { lng: "cn" })}`;

    console.log(
      "Quốc Cường ~ file: Home.js ~ line 2683 ~ Home ~ render ~ this.state.dateData",
      this.state.dateData
    );

    return (
      <>
        {this.state.isLoading && (
          <div className="bg-loading">
            <img
              id="LoadingImage"
              src={Logo}
              alt="logo"
              style={{
                zIndex: 9999999,
                left: 0,
                right: 0,
                top: "15%",
                width: "30%",
                position: "absolute",
                animation: `spin 10s linear infinite`,
                justifyContent: "center",
                alignSelf: "center",
              }}
            />
          </div>
        )}
        <div className="container">
          {/* <h1>{t('Hello', { name: 'Cường' })}</h1>
          <p>{t('Hello', { lng: 'en', name: 'Cường1' })}</p> */}
          <div className="to-hide-print" style={{ display: "flex" }}>
            <select
              value={this.state.language}
              id="language"
              className="to-hide-print"
              onChange={() => {
                this.changeLanguage();
              }}
              style={{
                background: "white",
                height: "29px",
              }}
            >
              <option key={`english`} value={"en"}>
                Tiếng Anh
              </option>
              <option key={`VietNam`} value={"vi"}>
                Tiếng Việt
              </option>
            </select>
            <div style={{ marginLeft: "20px" }}>
              <p className="to-hide-print" style={{ margin: "auto" }}>
                Chọn Ngày:
              </p>
              <div className="to-hide-print" style={{ margin: "auto" }}>
                <ReactDatePicker
                  // disabled={!props.isCustomer}
                  locale="vi"
                  className="input-date-picker to-hide-print"
                  selected={this.state.date}
                  dateFormat="dd - MM - yyyy"
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {/* <div
              className="to-hide-print"
              style={{ marginLeft: "20px", display: "flex", zIndex: "100" }}
            >
              <textarea className="to-hide-print" id="place-image" />
              <button
                className="to-hide-print"
                onClick={() => {
                  const getElement = document.getElementById("place-image");
                  const value = getElement.value;
                  this.setState({ subImage: value });
                }}
              >
                Thêm địa điểm
              </button>
            </div>
            <div style={{ marginLeft: "20px" }}>
              <p className="to-hide-print" style={{ margin: "auto" }}>
                Chọn Hình:
              </p>
              <div className="to-hide-print" style={{ margin: "auto" }}>
                <input
                  accept="image/*"
                  type="file"
                  name="myImage"
                  onChange={(e) => this.onBackgroundChange(e)}
                />
              </div>
            </div> */}
          </div>
          {/* i18n.changeLanguage('en-US'); */}
          <div style={{ position: "absolute", zIndex: -1 }}>
            <img
              src={this.state.backgroundImage || this.state.themeDay?.image}
              alt=""
              style={{ height: "430px", width: "850px" }}
            />
          </div>
          <div style={{ position: "absolute", zIndex: -1 }}>
            {/* <img src={Logo} alt="" style={{ height: '400px', width: '100%' }} /> */}

            <img
              src={this.state.background}
              alt=""
              style={{ height: "1150px", width: "100%" }}
            />
          </div>
          <div
            // style={{ backgroundImage: `url(${backgroundGreen})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: '1500px' }}
            className="wrapper"
          >
            <section id="first">
              {/* <img src={image1} alt="" width="100%" height="420px" /> */}

              <div style={{ width: "100%", height: "465px" }}>
                <p
                  style={{
                    fontSize: "10px",
                    position: "absolute",
                    bottom: 17,
                    left: 35,
                    whiteSpace: "pre-wrap",
                    fontSize: "12px",
                  }}
                >
                  <i>
                    {this.state.subImage || this.state.themeDay?.descriptImage}
                  </i>
                </p>
                {this.state.date.getDate() === 1 &&
                  this.state.date.getMonth() === 0 && (
                    <img
                      src={ribbonNewYear}
                      width="400px"
                      style={{
                        position: "absolute",
                        bottom: -15,
                        left: "27%",
                        zIndex: 3,
                      }}
                    />
                  )}
              </div>
              <div className="p-description font-UTM">
                <div className="flex">
                  <ul>
                    <li
                      className="custom-flexbox-1"
                      style={{
                        WebkitTextStrokeWidth: "1px",
                        WebkitTextStrokeColor: colorFontPicker1,

                        //
                      }}
                    >{`${this.monthToLetter(
                      this.state.date.getMonth() + 1,
                      "en"
                    )} `}</li>
                    <li
                      className="custom-year"
                      style={{
                        color: colorFontPicker1,
                        WebkitTextStrokeWidth: "3px",
                        WebkitTextStrokeColor: "#fff",
                      }}
                    >{`${this.state.date.getFullYear()} `}</li>
                    <li
                      className="custom-flexbox-2"
                      style={{
                        WebkitTextStrokeWidth: "1px",
                        WebkitTextStrokeColor: colorFontPicker1,
                      }}
                    >{`${this.monthToLetter(
                      this.state.date.getMonth() + 1,
                      "vi"
                    )} `}</li>
                  </ul>
                </div>
              </div>
              <div className="p-date">
                <div className="p-absolute">
                  <p
                    className="font-Shippori"
                    style={{ color: colorFontPicker1, lineHeight: "518px" }}
                  >{`${this.state.date.getDate()}`}</p>
                </div>
              </div>
            </section>

            <section id="second">
              <div className="p-flex">
                <div className="col-1">
                  <div className="block-header">
                    <h3
                      className="title font-Alexander"
                      style={{ color: colorFontPicker1 }}
                    >
                      {characterDay.dayWeekTranslate}
                    </h3>
                    <div className="content">
                      <ul>
                        <li>
                          <p
                            href="!#"
                            className="font-Alexander"
                            style={{ color: colorFontPicker2 }}
                          >
                            {this.state?.dateShowBellowSolarDate
                              ? this.monthToLetter(
                                  this.state?.dateShowBellowSolarDate[1],
                                  ""
                                ) + textMissingLunarDate
                              : ""}
                          </p>
                        </li>
                        <li>
                          <p
                            href="!#"
                            className="font-Alexander"
                            style={{ color: colorFontPicker2 }}
                          >
                            {`${t("thang", { value: finalCanChiMonth })}`}
                          </p>
                        </li>
                        <li>
                          <p
                            href="!#"
                            className="font-Alexander"
                            style={{ color: colorFontPicker1 }}
                          >
                            {`${t("ngay", { value: finalCanChiDay })}`}
                          </p>
                        </li>
                        <li>
                          <p
                            href="!#"
                            className="font-Alexander"
                            style={{ color: colorFontPicker1 }}
                          >
                            {`${t("gio", { value: finalCanChiHour })}`}
                          </p>
                        </li>
                      </ul>

                      <div className="p-ti-khi">
                        <p className="font-Myriad">{`${this.translateTietKhi(
                          showTietKhiDauThang[1],
                          false
                        )}: ${
                          showTietKhiDauThang[0]?.split(" ")[0]
                        }/${this.state.date.getFullYear()}`}</p>
                        <p className="font-Myriad">{`${this.translateTietKhi(
                          showTietKhiGiuaThang[1],
                          false
                        )}: ${
                          showTietKhiGiuaThang[0]?.split(" ")[0]
                        }/${this.state.date.getFullYear()}`}</p>
                      </div>
                      <div className="p-ti-khi-2">
                        <p
                          className="font-Myriad"
                          style={{ color: colorFontPicker2 }}
                        >{`${t("nap-am.title")}: ${this.translateNapAm(
                          napAm,
                          false
                        )}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-1 custom-height">
                  <div className="block-header text-center custom-middle">
                    <h3
                      className="title font-Myriad"
                      style={{ color: "#fff", background: colorFontPicker1 }}
                    >{`${characterDay.dayWeekEng}`}</h3>
                    <div className="content">
                      <h4
                        className="text-transform font-Alexander"
                        style={{ color: colorFontPicker1 }}
                      >{`${t("nam", {
                        value: finalCanChiYear,
                      })}`}</h4>
                      <h3
                        className="font-kaiu"
                        style={{ color: colorFontPicker1 }}
                      >{`${t("nam", {
                        value: finalCanChiYearChina.replace(" ", ""),
                        lng: "cn",
                      })}`}</h3>
                      {/* <div style={{ height: '140px' }}></div> */}
                      <h3
                        className="size-125"
                        style={{
                          color: colorFontPicker2,
                          WebkitTextStrokeWidth: "3px",
                          WebkitTextStrokeColor: "#fff",
                          textShadow: `3px 3px  ${colorFontPicker2}70`,
                        }}
                      >{`${
                        this.state?.dateShowBellowSolarDate
                          ? this.state?.dateShowBellowSolarDate[0]
                          : ""
                      }`}</h3>
                      <h3
                        className="size-60 font-kaiu"
                        style={{ color: colorFontPicker2 }}
                      >
                        {this.dayToChina(
                          this.state?.dateShowBellowSolarDate
                            ? this.state?.dateShowBellowSolarDate[0]
                            : 0
                        )}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-1 font-kaiu">
                  <div className="block-header text-right">
                    <h3
                      className="title"
                      style={{ color: colorFontPicker1 }}
                    >{`${characterDay.dayWeekChina}`}</h3>
                    <div className="content">
                      <ul>
                        <li>
                          <p
                            href="!#"
                            className="right-section3-chinese"
                            style={{ color: colorFontPicker2 }}
                          >
                            {`${
                              this.state?.dateShowBellowSolarDate
                                ? this.monthToLetter(
                                    this.state?.dateShowBellowSolarDate[1],
                                    "cn"
                                  ) + textMissingLunarDateCN
                                : ""
                            }`}
                          </p>
                        </li>
                        <li>
                          <p
                            href="!#"
                            className="right-section3-chinese"
                            style={{ color: colorFontPicker2 }}
                          >
                            {`${t("thang", {
                              value: finalCanChiMonthChina.replace(" ", ""),
                              lng: "cn",
                            })}`}
                          </p>
                        </li>
                        <li>
                          <p
                            href="!#"
                            className="right-section3-chinese"
                            style={{ color: colorFontPicker1 }}
                          >
                            {`${t("ngay", {
                              value: finalCanChiDayChina.replace(" ", ""),
                              lng: "cn",
                            })} ${t("gio", {
                              value: finalCanChiHourChina.replace(" ", ""),
                              lng: "cn",
                            })}`}
                          </p>
                        </li>
                      </ul>

                      <div className="p-ti-khi">
                        <p className="tiet-khi-chinese">{`${this.translateTietKhi(
                          showTietKhiDauThang[1],
                          true
                        )}:${
                          showTietKhiDauThang[0]?.split(" ")[0]
                        }/${this.state.date.getFullYear()}`}</p>
                        <p className="tiet-khi-chinese">{`${this.translateTietKhi(
                          showTietKhiGiuaThang[1],
                          true
                        )}:${
                          showTietKhiGiuaThang[0]?.split(" ")[0]
                        }/${this.state.date.getFullYear()}`}</p>
                      </div>
                      <div className="p-ti-khi-2">
                        <p
                          className="tiet-khi-2-chinese"
                          style={{ color: colorFontPicker2 }}
                        >{`${t("nap-am.title", {
                          lng: "cn",
                        })}: ${this.translateNapAm(napAm, true)}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="third">
              <div className="p-flex">
                <div className="col-3">
                  {this.renderShould(t("viec-thuan-loi"), translateNen, true)}
                  {this.renderShould(
                    t("viec-nen-tranh"),
                    translateKhongNen,
                    false
                  )}
                </div>
                <div className="col-5">
                  <div className="block-header">
                    <div className="content-middle">
                      <p
                        className="font-UMT"
                        style={{ color: colorFontPicker1 }}
                      >
                        {t("ngay-ky-tuoi", {
                          chiTuoi: finalCanChiDay?.split(" ")[1],
                        })}{" "}
                        <span className="font-kaiu">
                          {t("ngay-ky-tuoi", {
                            chiTuoi: finalCanChiDayChina.replace(" ", "")[1],
                            lng: "cn",
                          })}
                        </span>
                        : {dataXungTuoi1}
                        {` `}
                        <span className="font-kaiu">
                          {dataXungTuoi1China?.replace(" ", "")}
                        </span>
                        , {dataXungTuoi2}
                        <span className="font-kaiu">
                          {" "}
                          {dataXungTuoi2China?.replace(" ", "")}
                        </span>
                      </p>

                      <p
                        className="font-UMT"
                        style={{
                          color: colorFontPicker2,
                          textTransform: "uppercase",
                        }}
                      >
                        {hoangDao.isHoangDao ? t("hoang-dao") : t("hac-dao")}{" "}
                        <span className="font-kaiu">
                          {hoangDao.isHoangDao
                            ? t("hoang-dao", { lng: "cn" })
                            : t("hac-dao", { lng: "cn" })}
                        </span>{" "}
                        - {hoangDao.ngayHoangDao}{" "}
                        <span className="font-kaiu">
                          {hoangDaoChina.ngayHoangDao}
                        </span>
                      </p>
                      <p
                        className="font-UMT"
                        style={{
                          textTransform: "uppercase",
                          color: colorFontPicker2,
                        }}
                      >
                        {t("ngu-hanh.ngu-hanh", {
                          value: NguHanhNgay,
                        })}{" "}
                        <span className="font-kaiu">
                          {" "}
                          {t(NguHanhNgayChina)} -{" "}
                        </span>
                        {t("nhi-thap-bat-sao.title", {
                          value: star.sao,
                          isGood: star.isGood ? t("tot") : t("xau"),
                        })}{" "}
                        <span className="font-kaiu">
                          {t("nhi-thap-bat-sao.title", {
                            value: starChina.sao,
                            isGood: starChina.isGood
                              ? t("tot", { lng: "cn" })
                              : t("xau", { lng: "cn" }),
                            lng: "cn",
                          })}
                        </span>{" "}
                        -{" "}
                        {t("thap-nhi-truc.title", {
                          value: nhiThapKienTru?.truc,
                          isGood: nhiThapKienTru?.isGood ? t("tot") : t("xau"),
                        })}{" "}
                        <span className="font-kaiu">
                          {t("thap-nhi-truc.title", {
                            value: nhiThapKienTruChina?.truc,
                            isGood: nhiThapKienTruChina?.isGood
                              ? t("tot", { lng: "cn" })
                              : t("xau", { lng: "cn" }),
                            lng: "cn",
                          })}
                        </span>
                      </p>
                      <p
                        className="font-UMT"
                        style={{
                          color: colorFontPicker1,
                          textTransform: "uppercase",
                        }}
                      >
                        {t("huong-xuat-hanh")}{" "}
                        <span className="font-kaiu">
                          {t("huong-xuat-hanh", {
                            lng: "cn",
                          })}
                        </span>
                        :
                      </p>
                      <p
                        className="font-UMT"
                        style={{ color: colorFontPicker1 }}
                      >
                        {t("hy-than")}{" "}
                        <span className="font-kaiu">
                          {t("hy-than", {
                            lng: "cn",
                          })}
                        </span>
                        : {this.translateHuong(this.state.hyThan)}
                        <span className="font-kaiu">
                          {this.translateHuong(this.state.hyThan, true)}
                        </span>{" "}
                        / {t("tai-than")}{" "}
                        <span className="font-kaiu">
                          {t("tai-than", {
                            lng: "cn",
                          })}
                        </span>
                        : {this.translateHuong(this.state.taiThan)}
                        <span className="font-kaiu">
                          {this.translateHuong(this.state.taiThan, true)}
                        </span>
                      </p>
                      <p
                        className="font-UMT"
                        style={{ color: colorFontPicker1 }}
                      >
                        {t("cat-than.title").toUpperCase()}{" "}
                        <span className="font-kaiu">
                          {t("cat-than.title", {
                            lng: "cn",
                          })}
                        </span>
                        :{" "}
                        {translateCatThan.map((v, i) => {
                          if (i <= this.state.itemsToShowCatThan) {
                            return (
                              <>
                                {v.translate}{" "}
                                <span className="font-kaiu">
                                  {v.translateChina}
                                  {i === translateCatThan.length - 1
                                    ? // ||
                                      // i === 5 - 1
                                      ""
                                    : ", "}
                                </span>
                                {i === 1 && <br></br>}
                              </>
                            );
                          }
                        })}
                        {translateCatThan.length > 3 && (
                          <a
                            className="btn btn-primary"
                            onClick={() => this.showMoreCatThan()}
                          >
                            {this.state.expandedCatThan ? (
                              <button
                                style={{
                                  boxShadow: "2px 2px 4px #000000",
                                }}
                              >
                                See less
                              </button>
                            ) : (
                              <button
                                style={{
                                  boxShadow: "2px 2px 4px #000000",
                                }}
                              >
                                See more
                              </button>
                            )}
                          </a>
                        )}
                      </p>
                      <p className="font-UMT" style={{ color: "black" }}>
                        {t("hung-than.title").toUpperCase()}
                        <span className="font-kaiu">
                          {t("hung-than.title", {
                            lng: "cn",
                          })}
                        </span>
                        :{" "}
                        {translateHungThan.map((v, i) => {
                          if (i <= this.state.itemsToShowHungThan) {
                            return (
                              <>
                                {v.translate}{" "}
                                <span className="font-kaiu">
                                  {v.translateChina}
                                  {i === translateHungThan.length - 1
                                    ? // ||
                                      // i === kiengKy.length - 1
                                      ""
                                    : ", "}
                                </span>
                              </>
                            );
                          }
                        })}{" "}
                        {translateHungThan.length > 3 && (
                          <a
                            className="btn btn-primary"
                            onClick={() => this.showMoreHungThan()}
                          >
                            {this.state.expandedHungThan ? (
                              <button
                                style={{ boxShadow: "2px 2px 4px #000000" }}
                              >
                                See less
                              </button>
                            ) : (
                              <button
                                style={{ boxShadow: "2px 2px 4px #000000" }}
                              >
                                See more
                              </button>
                            )}
                          </a>
                        )}
                      </p>
                      <p
                        className="font-UMT"
                        style={{ color: colorFontPicker1 }}
                      >
                        {t("gio-tot")}{" "}
                        <span className="font-kaiu">
                          {t("gio-tot", { lng: "cn" })}
                        </span>
                        :{" "}
                        {gioTot?.map((v, i) => {
                          if (i < 5) {
                            return (
                              <>
                                {this.state.language === "vi" ? v.name : v.eng}
                                <span className="font-kaiu">
                                  {v.chinese}
                                  {i === gioTot.length - 1 || i === 5 - 1
                                    ? ""
                                    : ", "}
                                </span>
                              </>
                            );
                          }
                        })}
                      </p>
                      <p className="font-UMT" style={{ color: "black" }}>
                        {t("gio-xau")}{" "}
                        <span className="font-kaiu">
                          {t("gio-tot", { lng: "cn" })}
                        </span>
                        :{" "}
                        {gioXau?.map((v, i) => {
                          if (i < limitArr) {
                            return (
                              <>
                                {this.state.language === "vi" ? v.name : v.eng}
                                <span className="font-kaiu">
                                  {v.chinese}
                                  {i === gioXau.length - 1 || i === limitArr - 1
                                    ? ""
                                    : ", "}
                                </span>
                              </>
                            );
                          }
                        })}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  {this.renderShould(
                    t("viec-thuan-loi", { lng: "cn" }),
                    translateNen,
                    true,
                    "china"
                  )}
                  {this.renderShould(
                    t("viec-nen-tranh", { lng: "cn" }),
                    translateKhongNen,
                    false,
                    "china"
                  )}
                </div>
              </div>

              {/* <div style={{ backgroundImage: `url(${footer_blue})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div>
                  <p href="#">www.phongthuytuongminh.com</p>
                </div>
                <div>
                  <p>Phong thủy vì sự thịnh vương của cộng đồng</p>
                </div>
                <div>
                  <img src={Logo} width="40px" />
                  phongthuytuongminh
                </div>
              </div> */}
            </section>
            {/* <div style={{ height: "100px", width: "100%" }}>
              <img
                src={this.state.footerBg}
                alt=""
                style={{
                  height: "auto",
                  width: "100%",
                  position: "absolute",
                  zIndex: -1,
                }}
              />
              <div style={{ display: "flex", paddingTop: "80px" }}>
                <p
                  style={{
                    marginRight: "auto",
                    marginTop: "-30px",
                    fontSize: "11px",
                    marginLeft: "15px",
                  }}
                >
                  www.phongthuytuongminh.com
                </p>
                <div>
                  <p
                    style={{
                      margin: "auto",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    PHONG THỦY VÌ SỰ THỊNH VƯỢNG CỦA CỘNG ĐỒNG
                  </p>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    marginTop: "-40px",
                    marginRight: "25px",
                  }}
                >
                  <img src={footer_main_logo} height="40px" />
                  {/* phongthuytuongminh 
                </div>
              </div>
            </div> */}
            <section className="to-hide-print">
              <table
                width="100%"
                style={{ textAlign: "center", marginTop: "100px" }}
              >
                <tbody>
                  <tr>
                    <td width="50%">
                      <button
                        // disabled={!this.state.isCustomer}
                        size="mini"
                        icon="true"
                        compact="true"
                        onClick={() => {
                          this.onClickBackDate();
                        }}
                        ref={(e) => `previous day Button`}
                        type="button"
                        style={{
                          backgroundColor: "#F05828",
                          width: "50%",
                          borderTopLeftRadius: "7px",
                          height: "50px",
                          borderColor: "#fff",
                          borderWidth: "1px",
                        }}
                      >
                        <i
                          color="#fff"
                          className="fa fa-caret-left fa-2x"
                          aria-hidden="true"
                          style={{ color: "#fff" }}
                        ></i>
                      </button>
                    </td>
                    <td width="50%">
                      <button
                        // disabled={!this.state.isCustomer}
                        size="mini"
                        icon="true"
                        compact="true"
                        onClick={() => {
                          this.onClickNextDate();
                        }}
                        type="button"
                        style={{
                          backgroundColor: "#F05828",
                          width: "50%",
                          borderColor: "#fff",
                          borderWidth: "1px",
                          borderTopRightRadius: "7px",
                          height: "50px",
                        }}
                      >
                        <i
                          color="#fff"
                          style={{ color: "#fff" }}
                          className="fa fa-caret-right fa-2x"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        </div>

        {/* <Modal basic open={this.state.isLoading} size="large">
          <Modal.Content>
            <img
              id="LoadingImage"
              src={Logo}
              alt="logo"
              style={{
                animation: `spin 10s linear infinite`,
                justifyContent: 'center',
                alignSelf: 'center',
              }}
            />
          </Modal.Content> 
        </Modal>*/}
      </>
    );
  }
}

export default withTranslation()(Home);
